import React from 'react';

import { IAnswerResponse } from '../../../../../api/types';
import { useAppSelector } from '../../../../../redux/hooks/app-hooks';
import { CopyAnswerAction } from '../../../../Answers/Answer/Actions/Copy/Answer/CopyAnswerAction';
import { CopyLinkAction } from '../../../../Answers/Answer/Actions/Copy/Link/CopyLinkAction';
import { OpenAction } from '../../../../Answers/Answer/Actions/Open/OpenAction';
import { ThumbDownAction } from '../../../../Answers/Answer/Actions/Thumbs/ThumbDownAction';
import { ThumbUpAction } from '../../../../Answers/Answer/Actions/Thumbs/ThumbUpAction';

export const AnswersPreviewFooter: React.FC<{ answer: IAnswerResponse }> = ({ answer }) => {
    const isUatMode = useAppSelector((state) => state.uat.enabled);
    return (
        <>
            <OpenAction answer={answer} />
            <CopyLinkAction answer={answer} />
            <CopyAnswerAction answer={answer} />
            <ThumbUpAction answer={answer} />
            {!isUatMode && <ThumbDownAction answer={answer} />}
        </>
    );
};
