import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import cx from 'clsx';
import { useFormikContext } from 'formik';
import moment from 'moment';

import { DatesFilterRelativeName, IDateFilter } from '../../../../api/types';
import { ALL_TIME_DATES_FILTER } from '../../../../redux/constants';
import { DatePickerWidgetComponent } from '../../../Controls/DatePicker/DatePickerWidget';
import { Accordion } from '../Accordion/Accordion';
import { IFormValues } from '../FiltersForm';

import { formatSelectedDates } from './helpers';

import styles from './DatesFilter.module.scss';

export const DatesFilter = () => {
    const { t } = useTranslation('translations');
    const [dateRange, setDateRange] = useState<Date[]>([]);
    const [customChipLabel, setCustomChipLabel] = useState<string>(t('filters-modal.dates-filter.custom'));

    const { values, setFieldValue } = useFormikContext<IFormValues>();
    const datesFilter = values.datesFilter;

    useEffect(() => {
        const { relative_name, end_date_iso, start_date_iso } = datesFilter;
        if (relative_name === DatesFilterRelativeName.CUSTOM) {
            const startDate = moment(start_date_iso).toDate();
            const endDate = moment(end_date_iso).toDate();
            setDateRange([startDate, endDate]);
            setCustomChipLabel(formatSelectedDates(startDate, endDate));
        }
    }, [datesFilter]);

    const clearDatesFilter = useCallback(() => {
        setFieldValue('datesFilter', ALL_TIME_DATES_FILTER);
    }, [setFieldValue]);

    const onDateClick = (relativeName: DatesFilterRelativeName) => {
        const selectedDateFilter: IDateFilter = { relative_name: relativeName };
        setFieldValue('datesFilter', selectedDateFilter);
    };

    const applyDateRange = () => {
        const [startDate, endDate] = dateRange;
        const selectedCustomRange: IDateFilter = {
            relative_name: DatesFilterRelativeName.CUSTOM,
            start_date_iso: moment(startDate).startOf('day').utc().toISOString(),
            end_date_iso: moment(endDate).endOf('day').utc().toISOString(),
        };
        setFieldValue('datesFilter', selectedCustomRange);
    };

    return (
        <Accordion title={t('filters-modal.dates-filter.title')} handleClearAll={clearDatesFilter}>
            <Box className={styles.datesWrapper}>
                {Object.values(DatesFilterRelativeName).map((relativeName) =>
                    relativeName !== DatesFilterRelativeName.CUSTOM ? (
                        <Box
                            key={relativeName}
                            className={cx(styles.chip, {
                                [styles.selectedChip]: datesFilter.relative_name === relativeName,
                            })}
                            onClick={() => onDateClick(relativeName)}
                        >
                            {relativeName}
                        </Box>
                    ) : (
                        <DatePickerWidgetComponent
                            key={relativeName}
                            dateRange={dateRange}
                            setDateRange={setDateRange}
                            apply={applyDateRange}
                        >
                            <Box
                                className={cx(styles.chip, {
                                    [styles.selectedChip]: datesFilter.relative_name === relativeName,
                                })}
                            >
                                {customChipLabel}
                            </Box>
                        </DatePickerWidgetComponent>
                    )
                )}
            </Box>
        </Accordion>
    );
};
