import { createSlice } from '@reduxjs/toolkit';

interface IFeedbackModalState {
    visible: boolean;
}

const initialState: IFeedbackModalState = {
    visible: false,
};

export const feedBackModalSlice = createSlice({
    name: 'feedback-modal',
    initialState,
    reducers: {
        openFeedbackModal(state) {
            state.visible = true;
        },
        closeFeedbackModal(state) {
            state.visible = false;
        },
    },
});

export const { openFeedbackModal, closeFeedbackModal } = feedBackModalSlice.actions;
