import { batch } from 'react-redux';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { IDateFilter, IUseSmartFilter } from '../../api/types';
import { mixpanelService } from '../../services/mixpanel/mixpanel-service';
import { MixpanelEvent } from '../../services/mixpanel/types';
import {
    BACKEND_URL_STORAGE_KEY,
    LANGUAGE_CODE_STORAGE_KEY,
    LAST_ANSWERS_STORAGE_KEY,
    LAST_MOCKED_DIRECT_ANSWER_KEY,
    LAST_PREVIEW_STORAGE_KEY,
    ONLINE_MEMORY_STORAGE_KEY,
    ORIGINS_BLACKLIST_STORAGE_KEY,
    PROJECT_STORAGE_KEY,
    SEARCH_STATE_RELEVANCE_TIME_STORAGE_KEY,
    SELECTED_DATES_FILTER_KEY,
    SELECTED_SMART_FILTERS_KEY,
    SHOULD_DISPLAY_FLOATING_APP,
    SOURCES_STORAGE_KEY,
    TRAINING_ENABLEMENT_LAST_QUIZ_QUESTION_STORAGE_KEY,
} from '../../services/storage/storage-keys-list';
import { storageService } from '../../services/storage/storage-service';
import { tabsSyncService } from '../../services/tabs/tabs-sync-service';
import { getUrlOrigin } from '../../utils/url';
import { IRootState } from '../core-store';
import {
    _setLanguageCode,
    _setOriginsBlacklist,
    _setProactiveFrequencyGroup,
    setSearchStateRelevanceTime as _setSearchStateRelevanceTime,
    setShouldDisplayFloatingApp,
} from '../slices/settings/settings-slice';
import { ISettingsState } from '../types';

import { sendMetrics } from './metrics-thunk';

const getOriginsBlacklistDiff = (newState: string[], currentState: string[]) => ({
    added: newState.filter((origin) => !currentState.includes(origin)),
    removed: currentState.filter((origin) => !newState.includes(origin)),
});

export const setDisabledSources = createAsyncThunk('settings/setDisabledSources', async (sources: string[]) => {
    await storageService.setStorageValue(SOURCES_STORAGE_KEY, sources);

    return sources;
});

export const setProject = createAsyncThunk('settings/setProject', async (project: string) => {
    await storageService.setStorageValue(PROJECT_STORAGE_KEY, project);
    await storageService.removeStorageValue([
        LAST_ANSWERS_STORAGE_KEY,
        LAST_PREVIEW_STORAGE_KEY,
        LAST_MOCKED_DIRECT_ANSWER_KEY,
        TRAINING_ENABLEMENT_LAST_QUIZ_QUESTION_STORAGE_KEY,
        SOURCES_STORAGE_KEY,
        SELECTED_SMART_FILTERS_KEY,
        SELECTED_DATES_FILTER_KEY,
    ]);

    return project;
});

export const saveBackendUrl = createAsyncThunk('settings/saveBackendUrl', async (url: string) => {
    if (url === '') {
        await storageService.removeStorageValue(BACKEND_URL_STORAGE_KEY);
    } else {
        await storageService.setStorageValue(BACKEND_URL_STORAGE_KEY, url);
    }

    return url;
});

export const setDisableOnlineMemory = createAsyncThunk(
    'settings/setDisableOnlineMemory',
    async (disable: ISettingsState['disableOnlineMemory']) => {
        await storageService.setStorageValue(ONLINE_MEMORY_STORAGE_KEY, disable);
        await storageService.removeStorageValue([
            LAST_ANSWERS_STORAGE_KEY,
            LAST_PREVIEW_STORAGE_KEY,
            LAST_MOCKED_DIRECT_ANSWER_KEY,
        ]);

        return disable;
    }
);

export const setShouldDisplaySidebarApp = createAsyncThunk(
    'settings/setShouldDisplaySidebarApp',
    async (setShouldDisplaySidebarApp: boolean, { dispatch }) => {
        await storageService.setStorageValue(SHOULD_DISPLAY_FLOATING_APP, setShouldDisplaySidebarApp);
        await dispatch(setShouldDisplayFloatingApp(setShouldDisplaySidebarApp));

        return setShouldDisplaySidebarApp;
    }
);

export const setSearchStateRelevanceTime = createAsyncThunk(
    'settings/setsSearchStateRelevanceTime',
    async (relevanceTime: number, { dispatch }) => {
        await storageService.setStorageValue(SEARCH_STATE_RELEVANCE_TIME_STORAGE_KEY, relevanceTime);
        dispatch(_setSearchStateRelevanceTime(relevanceTime));
        return relevanceTime;
    }
);

export const setProactiveFrequencyGroup = createAsyncThunk(
    'settings/setProactiveFrequencyGroup',
    async (group: ISettingsState['proactiveFrequencyCurrentGroup'], { dispatch, getState }) => {
        const {
            auth: { user },
        } = getState() as IRootState;
        const { name, score } = group;

        mixpanelService.setUserProperties(user, {
            proactive_frequency_group: name,
            proactive_frequency_score: `${score}`,
        });

        dispatch(_setProactiveFrequencyGroup(group));
    }
);

export const setLanguageCode = createAsyncThunk(
    'settings/setLanguageCode',
    async (languageCode: string, { dispatch }) => {
        await storageService.setStorageValue(LANGUAGE_CODE_STORAGE_KEY, languageCode);

        dispatch(_setLanguageCode(languageCode));

        return languageCode;
    }
);

export const addOriginsToBlacklist = createAsyncThunk(
    'originsBlacklist/addOriginsToBlacklist',
    async (origins: string[], { dispatch, getState }) => {
        const state = getState() as IRootState;

        const currentOriginsList = state.settings.originsBlacklist;
        const uniqueOriginsList = Array.from(new Set([...currentOriginsList, ...origins]));

        dispatch(setOriginsToBlacklist(uniqueOriginsList));
    }
);

export const setOriginsToBlacklist = createAsyncThunk(
    'originsBlacklist/setOriginsToBlacklist',
    async (origins: string[], { dispatch, getState }) => {
        const state = getState() as IRootState;
        const currentOriginsBlacklist = state.settings.originsBlacklist;
        const _origins = origins.map(getUrlOrigin);
        const diff = getOriginsBlacklistDiff(_origins, currentOriginsBlacklist);

        batch(() => {
            dispatch(_setOriginsBlacklist(_origins));
            dispatch(_sendOriginsBlacklistDiffMetrics(diff));
        });

        await storageService.setStorageValue(ORIGINS_BLACKLIST_STORAGE_KEY, _origins);
        await tabsSyncService.syncOriginsBlacklist(_origins);
    }
);

export const onSyncOriginsBlacklist = createAsyncThunk(
    'originsBlacklist/syncOriginsBlacklist',
    async (origins: string[], { dispatch }) => {
        await storageService.setStorageValue(ORIGINS_BLACKLIST_STORAGE_KEY, origins);

        dispatch(_setOriginsBlacklist(origins));
    }
);

const _sendOriginsBlacklistDiffMetrics = createAsyncThunk(
    'originsBlacklist/sendOriginsBlacklistDiffMetrics',
    async (diff: ReturnType<typeof getOriginsBlacklistDiff>, { dispatch }) => {
        for (const origin of diff.added) {
            dispatch(
                sendMetrics({
                    event: MixpanelEvent.WEBSITES_BLACKLIST_ADD_WEBSITE,
                    meta: { website: origin },
                })
            );
        }

        for (const origin of diff.removed) {
            dispatch(
                sendMetrics({
                    event: MixpanelEvent.WEBSITES_BLACKLIST_REMOVE_WEBSITE,
                    meta: { website: origin },
                })
            );
        }
    }
);

export const setSelectedSmartFilters = createAsyncThunk(
    'settings/setSelectedSmartFilters',
    async (filters: IUseSmartFilter[]) => {
        await storageService.setStorageValue(SELECTED_SMART_FILTERS_KEY, filters);

        return filters;
    }
);

export const setSelectedDatesFilter = createAsyncThunk(
    'settings/setSelectedDatesFilter',
    async (datesFilter: IDateFilter) => {
        await storageService.setStorageValue(SELECTED_DATES_FILTER_KEY, datesFilter);

        return datesFilter;
    }
);
