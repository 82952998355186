import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ModalBody, ModalContent as ChakraModalContent, ModalFooter, ModalHeader } from '@chakra-ui/react';
import { Formik } from 'formik';

import { AskFilters, IDateFilter, IUseSmartFilter, IUseSource } from '../../../api/types';
import { ALL_TIME_DATES_FILTER } from '../../../redux/constants';
import { useAnswers } from '../../../redux/hooks/answer/answer-hooks';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/app-hooks';
import { useDatesFilter, useSmartFilters, useSources } from '../../../redux/hooks/settings-hooks';
import { closeSourcesFilterModal } from '../../../redux/slices/modals/sources-filter-modal-slice';
import { getActiveSmartFiltersIds, getActiveSources } from '../../../utils/transforms';

import { DatesFilter } from './DatesFilter/DatesFilter';
import { ListOfSmartFilters } from './ListOfSmartFilters/ListOfSmartFilters';
import { ListOfSources } from './ListOfSources/ListOfSources';
import { SearchInput } from './SearchInput/SearchInput';
import { getSelectedSourcesCount } from './helpers';

export const FILTER_KEY = '__FILTER__';

export type IFormValues = {
    [FILTER_KEY]: string;
    sources: IUseSource[];
    smartFilters: IUseSmartFilter[];
    datesFilter: IDateFilter;
};
const initialValues: IFormValues = {
    [FILTER_KEY]: '',
    sources: [],
    smartFilters: [],
    datesFilter: ALL_TIME_DATES_FILTER,
};

export const FiltersForm: React.FC = () => {
    const dispatch = useAppDispatch();
    const [answer] = useAnswers();
    const question = useAppSelector((state) => state.question.question);

    const { t } = useTranslation('translations');
    const { sources, disableSources } = useSources();
    const { smartFilters, storeSelectedFilters } = useSmartFilters();
    const { storeSelectedDatesFilter, selectedDatesFilter } = useDatesFilter();

    const handleCloseModal = useCallback(() => {
        dispatch(closeSourcesFilterModal());
    }, [dispatch]);

    const setDisabledSources = useCallback(
        async (sources: IUseSource[]) => {
            const sourcesToDisable = sources.filter((source) => !source.active);

            return disableSources(sourcesToDisable.map((source) => source.id));
        },
        [disableSources]
    );

    const askWithNewSources = useCallback(
        (sources: IUseSource[], smartFilters: IUseSmartFilter[], askFilters: AskFilters) => {
            if (!question) {
                return;
            }
            const activeSources = getActiveSources(sources);
            const activeSmartFilters = getActiveSmartFiltersIds(smartFilters);

            answer({ sources: activeSources, smartFiltersIds: activeSmartFilters, filters: askFilters });
        },
        [question, answer]
    );

    const handleSubmit = useCallback(
        async (formValues: IFormValues) => {
            handleCloseModal();
            const newSources = await setDisabledSources(formValues.sources);
            const newSmartFilters = await storeSelectedFilters(formValues.smartFilters);
            const newDatesFilter = await storeSelectedDatesFilter(formValues.datesFilter);

            const askFilters: AskFilters = {
                dates: newDatesFilter,
            };

            askWithNewSources(newSources, newSmartFilters, askFilters);
        },
        [handleCloseModal, setDisabledSources, askWithNewSources, storeSelectedFilters, storeSelectedDatesFilter]
    );

    const initialFormValues: IFormValues = useMemo(() => {
        return {
            ...initialValues,
            sources: [...sources],
            smartFilters: [...smartFilters],
            datesFilter: selectedDatesFilter,
        };
    }, [sources, smartFilters, selectedDatesFilter]);

    return (
        <Formik initialValues={initialFormValues} onSubmit={handleSubmit}>
            {({ submitForm, isSubmitting, values }) => {
                const isSubmitDisabled = isSubmitting || getSelectedSourcesCount(values.sources) === 0;

                return (
                    <ChakraModalContent>
                        <ModalHeader fontSize="16px" h="auto">
                            {t('filters-modal.header')}
                        </ModalHeader>
                        <SearchInput />
                        <ModalBody p="0 16px 8px 16px">
                            <DatesFilter />
                            <ListOfSources />
                            <ListOfSmartFilters />
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="secondary" onClick={handleCloseModal} isDisabled={isSubmitting}>
                                {t('button.cancel')}
                            </Button>
                            <Button
                                variant="primary"
                                onClick={submitForm}
                                isLoading={isSubmitting}
                                isDisabled={isSubmitDisabled}
                            >
                                {t('button.apply')}
                            </Button>
                        </ModalFooter>
                    </ChakraModalContent>
                );
            }}
        </Formik>
    );
};
