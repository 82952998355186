import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ModalBody, ModalContent, ModalFooter, ModalOverlay, Text } from '@chakra-ui/react';
import { ModalContainer } from 'answers-core/src/components/Modals/Container/ModalContainer';

import styles from './EditTagCtaDialog.module.scss';

export interface EditTagCtaDialogProps {
    onClose: () => void;
    onStartEdit: () => void;
}

export const EditTagCtaDialog: React.FC<EditTagCtaDialogProps> = ({ onClose, onStartEdit }) => {
    const { t } = useTranslation('translations');

    return (
        <ModalContainer isCentered isOpen onClose={onClose} motionPreset="scale" scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent>
                <ModalBody p="32px" className={styles.body}>
                    <Text fontSize="1.6em">{t('annotate-answers.tag-modal-edit-cta')}</Text>
                </ModalBody>
                <ModalFooter className={styles.footer}>
                    <Button minW="104px" variant="secondary" onClick={onClose}>
                        {t('button.close')}
                    </Button>
                    <Button minW="104px" variant="primary" onClick={onStartEdit}>
                        {t('button.edit')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </ModalContainer>
    );
};
