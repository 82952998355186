import React, { MouseEventHandler, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { IAttributionsAnswer } from '../../../../api/types';
import { ReactComponent as OpenIcon } from '../../../../assets/icons/open-in-new.svg';
import { useAppDispatch } from '../../../../redux/hooks/app-hooks';
import { useSources } from '../../../../redux/hooks/settings-hooks';
import { goToPageThunk } from '../../../../redux/thunks/go-to-page-thunk';
import { sendMetrics } from '../../../../redux/thunks/metrics-thunk';
import { ITrackEventOrigin, MixpanelEvent } from '../../../../services/mixpanel/types';
import { Tooltip } from '../../../Tooltips/Tooltip';

import styles from '../Actions.module.scss';

export interface IOpenActionProps {
    answer: IAttributionsAnswer;
}

export const OpenAction: React.FC<IOpenActionProps> = ({ answer }) => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();
    const { sources } = useSources();

    const handleButtonClick: MouseEventHandler = useCallback(
        (e) => {
            e.stopPropagation();

            const url = answer.url;

            dispatch(goToPageThunk(url));

            dispatch(
                sendMetrics({
                    event: MixpanelEvent.CHAT_VISIT_LINK,
                    meta: {
                        selected_answer: answer,
                        link: url,
                        from: ITrackEventOrigin.PREVIEW_WINDOW,
                    },
                    injectAnswersMetrics: {
                        sources,
                    },
                })
            );
        },
        [sources, answer, dispatch]
    );

    return (
        <Tooltip label={t('button.go-to')} placement="top-end" hasArrow>
            <OpenIcon className={styles.action} onClick={handleButtonClick} />
        </Tooltip>
    );
};
