import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';

import { ReactComponent as OpenIcon } from '../../../../../assets/icons/open-in-new.svg';
import { ReactComponent as PreviewIcon } from '../../../../../assets/icons/unavailable-preview.svg';
import { useCurrentAnswer } from '../../../../../redux/hooks/answer/answer-hooks';
import { useGoToPage } from '../../../../../redux/hooks/go-to-page';
import { useSources } from '../../../../../redux/hooks/settings-hooks';
import { ITrackEventOrigin } from '../../../../../services/mixpanel/types';
import { translateAnswerSource } from '../../../../../utils/sources';

import styles from './UnavailablePreview.module.scss';

export const UnavailablePreview: React.FC = () => {
    const { t } = useTranslation('translations');
    const answer = useCurrentAnswer();
    const { sourcesMap } = useSources();
    const goToPage = useGoToPage();

    const source = answer?.source_id ? sourcesMap.get(answer.source_id) : undefined;
    const sourceName = source ? translateAnswerSource(source) : source;

    const handleButtonClick = useCallback(() => {
        goToPage({
            answerId: answer?.doc_id,
            shortcutUsed: false,
            origin: ITrackEventOrigin.PREVIEW_WINDOW,
        });
    }, [answer?.doc_id, goToPage]);

    return (
        <div className={styles.wrapper}>
            <header>
                {sourceName} {answer?.title && `\\ ${answer.title}`}
            </header>
            <main>
                <PreviewIcon />
                <div className={styles.hint}>
                    <h1>{t('preview.unavailable.header')}</h1>
                    <Button variant="primary" rightIcon={<OpenIcon />} onClick={handleButtonClick}>
                        {t('preview.unavailable.button')}
                    </Button>
                    <p>{t('preview.unavailable.suggestion')}</p>
                </div>
            </main>
        </div>
    );
};
