import React from 'react';
import { FormikErrors, FormikHelpers, FormikTouched } from 'formik/dist/types';

import { useTranslateErrors } from '../../redux/hooks/translate-errors';

export interface IWithTranslateErrorsProps<Values> {
    errors: FormikErrors<Values>;
    touched: FormikTouched<Values>;
    setFieldTouched: FormikHelpers<Values>['setFieldTouched'];
    children: React.ReactNode;
}

export const WithTranslateErrors = <Values,>({
    errors,
    touched,
    setFieldTouched,
    children,
}: IWithTranslateErrorsProps<Values>): React.ReactElement => {
    useTranslateErrors(errors, touched, setFieldTouched);

    return <>{children}</>;
};
