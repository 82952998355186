import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Heading } from '@chakra-ui/react';

import { useGetSettingsQuery } from '../../../api/endpoints/settings-endpoint';
import { ReactComponent as LoginPlaceholderIcon } from '../../../assets/icons/login-placeholder.svg';
import { ReactComponent as HelpCenterIcon } from '../../../assets/icons/settings/help-center-icon.svg';

import styles from './LoginView.module.scss';

export interface ILoginViewProps {
    onLoginClick: () => void;
    loading?: boolean;
    error?: string;
    footer?: React.ReactNode;
}

export const LoginView: React.FC<ILoginViewProps> = ({ onLoginClick, loading, error, footer = null }) => {
    const { t } = useTranslation('translations');
    const { data } = useGetSettingsQuery();

    const helpCenterLink = data?.help_center_url;

    const renderHelpCenterButton = () => {
        if (!helpCenterLink) {
            return null;
        }

        return (
            <Button
                onClick={() => window.open(helpCenterLink, '_blank', 'noopener')}
                minWidth="168px"
                minHeight="48px"
                variant="help-center-button"
                leftIcon={<HelpCenterIcon />}
            >
                {t('support.help-center')}
            </Button>
        );
    };

    return (
        <div className={styles.wrapper} data-testid="login.view">
            <LoginPlaceholderIcon style={{ margin: '0 auto 8px' }} />
            <div className={styles.header}>
                <Heading as="h2" size="h1">
                    {t('login.header')}
                </Heading>
            </div>
            <div className={styles.fields}>
                <div className={styles.buttons}>
                    <Button
                        variant="primary"
                        onClick={onLoginClick}
                        minWidth="168px"
                        minHeight="48px"
                        isLoading={loading}
                    >
                        {t('login.button.sign-in')}
                    </Button>
                    {renderHelpCenterButton()}
                </div>
                {error && <div className={styles.error}>{error}</div>}
            </div>
            {footer}
        </div>
    );
};
