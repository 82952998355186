import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { FastField, FieldProps, getIn } from 'formik';

export const Title: React.FC = () => {
    const { t } = useTranslation('translations');
    return (
        <FastField name="title">
            {({ field, form }: FieldProps<string>) => (
                <FormControl isInvalid={getIn(form.errors, field.name) && getIn(form.touched, field.name)}>
                    <FormLabel variant="add-knowledge">{t('ask-ai-kb.title')}</FormLabel>
                    <Input variant="add-knowledge" {...field} isDisabled={form.isSubmitting} />
                </FormControl>
            )}
        </FastField>
    );
};
