import { IAnswerResponse } from '../api/types';

export const getAnswerQuery = (answer?: IAnswerResponse): string => {
    if (!answer) {
        return '';
    }

    const { reader_is_article_or_section, inner_hierarchy = [], text } = answer;

    const hierarchyQuery = inner_hierarchy.length ? inner_hierarchy[inner_hierarchy.length - 1] : '';
    return reader_is_article_or_section ? hierarchyQuery : text;
};

export type GetAnswerUrlParams = {
    url?: IAnswerResponse['url'] | null;
    valid_url?: IAnswerResponse['valid_url'];
};

export const getAnswerUrl = (answer?: GetAnswerUrlParams): string => {
    if (!answer) {
        return '';
    }

    return answer.valid_url ? answer.valid_url : answer.url ?? '';
};
