import React, { ChangeEvent, KeyboardEventHandler, useCallback, useState } from 'react';
import { Input, InputGroup, InputRightElement, ModalHeader } from '@chakra-ui/react';

import { ReactComponent as ArrowLeftIcon } from '../../../../assets/icons/arrow-left.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import { ReactComponent as ZoomInIcon } from '../../../../assets/icons/zoom-in.svg';
import { ReactComponent as ZoomOutIcon } from '../../../../assets/icons/zoom-out.svg';
import { SHORTCUT_ENTER } from '../../../../redux/hooks/shortcut';
import { Tooltip } from '../../../Tooltips/Tooltip';

import styles from './PreviewModalHeader.module.scss';

export type PreviewModalHeaderProps = {
    initialQuestion: string;
    onZoomIn?: () => void;
    onZoomOut?: () => void;
    onNextMatch?: () => void;
    onPrevMatch?: () => void;
    onSearch?: (value: string) => void;
};

export const PreviewPdfModalHeader: React.FC<PreviewModalHeaderProps> = ({
    initialQuestion,
    onSearch,
    onZoomIn,
    onZoomOut,
    onNextMatch,
    onPrevMatch,
}) => {
    const [value, setValue] = useState(initialQuestion);

    const handleQuestionChange = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
        const { value } = target;

        setValue(value);
    }, []);

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
        ({ key }) => {
            if (key === SHORTCUT_ENTER && onSearch) {
                onSearch(value);
            }
        },
        [value, onSearch]
    );

    return (
        <ModalHeader className={styles.wrapper}>
            <div className={styles.search}>
                <InputGroup>
                    <Input value={value} onChange={handleQuestionChange} onKeyDown={handleKeyDown} tabIndex={-1} />
                    <InputRightElement>
                        <SearchIcon />
                    </InputRightElement>
                </InputGroup>
            </div>
            <div className={styles.selector}>
                <Tooltip label="Next match" placement="bottom-end" hasArrow>
                    <ArrowLeftIcon className={styles.nextMatchButton} onClick={onNextMatch} />
                </Tooltip>

                <Tooltip label="Previous match" placement="bottom-end" hasArrow>
                    <ArrowLeftIcon className={styles.prevMatchButton} onClick={onPrevMatch} />
                </Tooltip>

                <Tooltip label="Zoom out" placement="bottom-end" hasArrow>
                    <ZoomOutIcon className={styles.zoomButton} onClick={onZoomOut} />
                </Tooltip>

                <Tooltip label="Zoom in" placement="bottom-end" hasArrow>
                    <ZoomInIcon className={styles.zoomButton} onClick={onZoomIn} />
                </Tooltip>
            </div>
        </ModalHeader>
    );
};
