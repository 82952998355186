export const slackWebHostStyles = (sidebarWidth: number) => {
    const contentContainer = document.querySelector<HTMLElement>('.p-client_container .p-client');
    if (contentContainer) {
        contentContainer.style.width = `100%`;

        return;
    }

    const toolbar = document.querySelector<HTMLElement>('.p-client_container div[role="toolbar"]');
    if (toolbar) {
        toolbar.style.right = `${sidebarWidth}px`;
    }

    const workspace = document.querySelector<HTMLElement>('.p-client_container div[role="tabpanel"]');
    if (workspace) {
        workspace.style.width = `calc(100% - ${sidebarWidth}px)`;
    }
};
