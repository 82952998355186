import { IUser, IUserProject } from '../api/types';
import { IRootState } from '../redux/core-store';

const ASK_AI_PROJECT_ID_TO_TOP = ['demo_ask_ai', 'ask_ai'];

export const getUserInitials = (user: IUser): string => {
    if (!user.firstname) {
        return user.username.charAt(0);
    }

    return user.firstname.charAt(0);
};

export const getUserName = (user: IUser): string => {
    if (!user.firstname && !user.lastname) {
        return user.username;
    }

    return `${user.firstname ?? ''}${user.lastname ? ` ${user.lastname}` : ''}`;
};

export const getUserProject = (data: Pick<IRootState, 'auth' | 'settings'>): string => {
    const {
        auth: { user },
        settings: { project },
    } = data;

    const doesProjectExist = user.customer_projects.find((customerProject) => customerProject.value === project);

    return doesProjectExist ? project : user.customer_projects[0]?.value ?? '';
};

const unshiftAskAiProjectID = (customer_projects_list: IUserProject[], projectId: string[]): IUserProject[] => {
    for (const AskAiProjectID of projectId) {
        const index = customer_projects_list.findIndex((project) => project.value === AskAiProjectID);
        if (index !== -1) {
            const [item] = customer_projects_list.splice(index, 1);
            customer_projects_list.unshift(item);
        }
    }
    return customer_projects_list;
};

export const orderCustomerProjects = (data: IUser): IUserProject[] => {
    const alfabtical_order = data.customer_projects.sort((a, b) => {
        return a.text.localeCompare(b.text);
    });
    return unshiftAskAiProjectID(alfabtical_order, ASK_AI_PROJECT_ID_TO_TOP);
};
