import { popperCSSVars } from '@chakra-ui/popper';
import { TooltipProps } from '@chakra-ui/tooltip/dist/tooltip';
import { Placement, State } from '@popperjs/core';

const ARROW_OFFSET = 1;

export const tooltipModifiers: TooltipProps['modifiers'] = [
    {
        name: 'positionArrow',
        enabled: true,
        phase: 'afterWrite',
        fn: ({ state }) => {
            setArrowStyles(state);
        },
    },
];

const setArrowStyles = (state: Partial<State>) => {
    if (!state.placement) return;
    const overrides = getArrowStyle(state.placement);

    if (state.elements?.arrow && overrides) {
        Object.assign(state.elements.arrow.style, {
            [overrides.property]: overrides.value,
            width: popperCSSVars.arrowSize.varRef,
            height: popperCSSVars.arrowSize.varRef,
            zIndex: -1,
        });

        const vars = {
            [popperCSSVars.arrowSizeHalf.var]: `calc(${popperCSSVars.arrowSize.varRef} / 2)`,
            [popperCSSVars.arrowOffset.var]: `calc(${popperCSSVars.arrowSizeHalf.varRef} * -1)`,
        };

        for (const property in vars) {
            state.elements.arrow.style.setProperty(property, vars[property]);
        }
    }
};

const getArrowStyle = (placement: Placement) => {
    if (placement.startsWith('top')) {
        return { property: 'bottom', value: `calc(${popperCSSVars.arrowOffset.varRef} - ${ARROW_OFFSET}px)` };
    }
    if (placement.startsWith('bottom')) {
        return { property: 'top', value: `calc(${popperCSSVars.arrowOffset.varRef} - ${ARROW_OFFSET}px)` };
    }
};
