/**
 * Returns the string with all non-alphanumeric characters removed.
 *
 * @param str
 *
 * @return string
 */
export const normalize = (str: string) => {
    return str.toLowerCase().replace(/[^a-z0-9\s]/gi, '');
};

/**
 * Check if the given string hits one of regex patterns
 *
 * @param str
 * @param patternsJSON
 */
export const isStringInPatternsList = (str: string, patternsJSON: string | undefined): boolean => {
    if (patternsJSON === undefined) {
        return false;
    }

    try {
        const patterns = JSON.parse(patternsJSON) as string[];

        if (!Array.isArray(patterns)) {
            return false;
        }

        return patterns.some((pattern) => {
            const regex = new RegExp(pattern, 'gm');

            return regex.test(str);
        });
    } catch (e) {
        return false;
    }
};
