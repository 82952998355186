import React from 'react';

import { isContentMode } from '../../../utils/extension-mode';

import { ContentScriptMode } from './PDFPreview/ContentScriptMode';
import { PopUpMode } from './PDFPreview/PopUpMode';

export const ViewPdf: React.FC = () => {
    return isContentMode() ? <ContentScriptMode /> : <PopUpMode />;
};
