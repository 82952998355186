/**
 * Validates a URL with a regular expression.
 *
 * @param url - URL string to validate.
 * @returns boolean - Whether the URL is valid.
 **/
export const isValidUrl = (url: string): boolean => {
    const regexp = /^(?:https?:\/\/)?(?:[\w-]+\.)+[\w-]{2,}(\/[\w\-._~:/?#[\]@!$&'()*+,;=]*)?$/i;

    return regexp.test(url);
};

/**
 * Get the origin of a URL.
 *
 * **Will remove 'www' from the beginning**
 *
 * @example https://www.google.com/search?q=hello => google.com
 *
 * @param url - URL string to prepare.
 * @returns string - origin.
 **/
export const getUrlOrigin = (url: string): string => {
    const regex = /^(https?:\/\/)?(www\.)?/i;
    const prependedUrl = url.replace(regex, 'https://');

    const origin = new URL(prependedUrl).origin;

    return origin.replace(regex, '');
};
