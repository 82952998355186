import { createAsyncThunk } from '@reduxjs/toolkit';

import { browserService } from '../../services/browser/browser-service';
import { REOPEN_SIDEBAR_BY_ANSWER_PAGE } from '../../services/storage/storage-keys-list';
import { storageService } from '../../services/storage/storage-service';
import { isContentMode } from '../../utils/extension-mode';
import { IRootState } from '../core-store';

export const goToPageThunk = createAsyncThunk('goToPage', async (url: string | undefined, { getState }) => {
    const {
        settings: { open_link_in_new_page },
    } = (getState() as IRootState).auth.user;

    if (isContentMode() && !open_link_in_new_page) {
        await storageService.setStorageValue(REOPEN_SIDEBAR_BY_ANSWER_PAGE, url);
    }

    await browserService.createTab({ url, active: !open_link_in_new_page, selected: false });
});
