import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover, PopoverArrow, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import cx from 'clsx';

import { ReactComponent as FirstPlaceBadgeMiniIcon } from '../../../assets/icons/first-rank-badge-mini.svg';
import { ReactComponent as TrophyBadgeMiniIcon } from '../../../assets/icons/trophy-badge-mini.svg';
import { useAppSelector } from '../../../redux/hooks/app-hooks';
import { usePrevious } from '../../../redux/hooks/previous';
import { SingleAnimation } from '../../Animations/Single/SingleAnimation';
import { CompetitionTooltipContent } from '../CompetitionTooltipContent/CompetitionTooltipContent';

import animationDataJson from './confetti.json';

import styles from './Points.module.scss';

export interface PointsProps {
    onPointsClick?: () => void;
}

export const Points: React.FC<PointsProps> = ({ onPointsClick }) => {
    const { t } = useTranslation('translations');
    const { user } = useAppSelector((state) => state.auth);
    const { competition } = user;

    const points = competition?.points ?? 0;
    const prevPoints = usePrevious(points);
    const [isAnimationStarted, setIsAnimationStarted] = useState(false);

    const userPoints = `${t('user.points', { count: points })}`;

    useEffect(() => {
        if (points !== prevPoints) {
            setIsAnimationStarted(true);
        }
    }, [points, prevPoints]);

    const handleAnimationComplete = () => {
        setIsAnimationStarted(false);
    };

    const renderBadge = () => {
        if (!competition) {
            return null;
        }

        return competition?.points_from_first_place === 0 ? <FirstPlaceBadgeMiniIcon /> : <TrophyBadgeMiniIcon />;
    };

    const renderTooltipContent = () => {
        if (typeof competition?.points_from_first_place === 'number') {
            return (
                <CompetitionTooltipContent
                    pointsFromFirstPlace={competition.points_from_first_place}
                    competitionStartDate={competition.competition_start_date}
                    competitionEndDate={competition.competition_end_date}
                    onLearnMoreClick={onPointsClick}
                />
            );
        }

        return t('user.ask-ai-points');
    };

    return (
        <Popover strategy="fixed" trigger="hover" placement="bottom-end" isLazy>
            {({ isOpen }) => (
                <>
                    <PopoverTrigger>
                        <div className={cx(styles.points, { [styles.tooltipActive]: isOpen })}>
                            {renderBadge()}
                            <span>{userPoints}</span>
                            <SingleAnimation
                                start={isAnimationStarted}
                                animationData={animationDataJson}
                                onComplete={handleAnimationComplete}
                                className={styles.lottie}
                            />
                        </div>
                    </PopoverTrigger>

                    <PopoverContent className={styles.popoverContent}>
                        <PopoverArrow />
                        {renderTooltipContent()}
                    </PopoverContent>
                </>
            )}
        </Popover>
    );
};
