import React from 'react';
import cx from 'clsx';

import { useAppSelector } from '../../../redux/hooks/app-hooks';
import { getUserInitials } from '../../../utils/user';

import styles from './UserAvatar.module.scss';

export interface IUserAvatarProps {
    className?: string;
}

export const UserAvatar: React.FC<IUserAvatarProps> = ({ className }) => {
    const { user } = useAppSelector((state) => state.auth);

    return <div className={cx(styles.wrapper, className)}>{getUserInitials(user)}</div>;
};
