import { sanitize } from 'dompurify';
import { v4 } from 'uuid';

import { ISettingsState } from '../../../redux/types';
import { AnswersSortOrder, DEFAULT_ANNOTATE_TAG_VALUE } from '../../consts';
import { IAnnotateAnswerChunk, IAnswerResponse, IApiAnswerResponse } from '../../types';

export const prepareAnswers = (
    answers: IApiAnswerResponse[],
    confidenceSettings: ISettingsState['confidenceSettings']
): IAnswerResponse[] => {
    const result: IAnswerResponse[] = answers.map((answer) => ({
        ...answer,
        html_short: sanitize(answer.html_short.trim()),
        html_long: sanitize(answer.html_long.trim()),
        uuid: v4(),
        direct: false,
        isLowConfidence: false,
        isGrayedOut: false,
    }));

    const maxConfidence = Math.max(...result.map((answer) => answer.confidence));
    result.forEach((answer) => {
        answer.isLowConfidence =
            answer.confidence < confidenceSettings.absolute ||
            answer.confidence < maxConfidence - confidenceSettings.relative;
        answer.isGrayedOut = answer.confidence < confidenceSettings.gray_out;
    });

    return result;
};

export const chunkLabelValueToNumber = (labelValue: string): number => {
    if (!labelValue) {
        return Number.MIN_SAFE_INTEGER;
    } else if (labelValue === DEFAULT_ANNOTATE_TAG_VALUE) {
        return 0;
    } else {
        return parseFloat(labelValue);
    }
};

export const sortChunksByLabelValue = (
    chunks: IAnnotateAnswerChunk[],
    labeledChunksMap: Record<IAnnotateAnswerChunk['chunk_id'], string>,
    order: string
): IAnnotateAnswerChunk[] => {
    if (order === AnswersSortOrder.DEFAULT) {
        return chunks;
    }

    const compareFn = (a: IAnnotateAnswerChunk, b: IAnnotateAnswerChunk) => {
        const labelA = chunkLabelValueToNumber(labeledChunksMap[a.chunk_id]);
        const labelB = chunkLabelValueToNumber(labeledChunksMap[b.chunk_id]);

        return order === AnswersSortOrder.ASC ? labelA - labelB : labelB - labelA;
    };

    return Array.from(chunks).sort(compareFn);
};

const getCurrentUrl = (): string => window.location.href;

export const getCurrentUrlDetails = (): Record<string, string> => {
    const url = new URL(getCurrentUrl());
    return { current_user_url: url.toString(), current_user_domain: url.hostname };
};
