import React from 'react';
import { useTranslation } from 'react-i18next';

import { ISource } from '../../../../../api/types';
import { translateAnswerSource } from '../../../../../utils/sources';
import { Tooltip } from '../../../../Tooltips/Tooltip';

import styles from './SourceLabel.module.scss';

const imageMaxSide = Math.max(parseInt(styles.imageWidth, 10), parseInt(styles.imageHeight, 10));

export interface ISourceLabelProps {
    source: ISource;
}

export const SourceLabel: React.FC<ISourceLabelProps> = ({ source }) => {
    const { t } = useTranslation('translations');
    const sourceName = translateAnswerSource(source);

    return (
        <Tooltip placement="top" label={source.tooltip || sourceName} hasArrow>
            <div className={styles.wrapper}>
                <div className={styles.icon}>
                    <img
                        src={source.icon}
                        width={imageMaxSide}
                        height={imageMaxSide}
                        className={styles.image}
                        alt={t('source.title', { type: sourceName })}
                    />
                </div>
                <span className={styles.text}>{sourceName}</span>
            </div>
        </Tooltip>
    );
};
