import React from 'react';
import { Navigate, Outlet } from 'react-router';
import { useAuth } from 'answers-core/src/redux/hooks/app-hooks';

import { MainRoutes } from '../types';

export interface ILoginRouteProps {
    layout: React.ElementType;
}

export const LoginRoute: React.FC<ILoginRouteProps> = ({ layout: Layout }) => {
    const [isAuthorized] = useAuth();

    if (isAuthorized) {
        return <Navigate to={MainRoutes.HOME} />;
    }

    return (
        <Layout>
            <Outlet />
        </Layout>
    );
};
