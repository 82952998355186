import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react';

import i18n from '../../../i18n';
import { IRootState } from '../../../redux/core-store';
import { getToastService } from '../../../services/toast/toast-service';
import { getUserProject } from '../../../utils/user';
import { baseApi } from '../../base-api';
import { ResponseStatus } from '../../types';

import { API_ASK_AI_KB_URL } from './consts';
import { IAddKnowledgeApi, IAddKnowledgeParams, IAddKnowledgeResponseApi } from './types';

export const askAiKbEndpoint = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        addKnowledge: builder.mutation<IAddKnowledgeResponseApi, IAddKnowledgeParams>({
            queryFn: async (arg, { getState }, _, baseQuery) => {
                const state = getState() as IRootState;
                const project = getUserProject(state);

                const body: IAddKnowledgeApi = {
                    title: arg.title,
                    content: arg.content,
                    access_level: arg.accessLevel,
                };

                const queryResponse = await baseQuery({
                    url: `${API_ASK_AI_KB_URL}/articles/${project}`,
                    method: 'POST',
                    body: body,
                });

                const { data, error, meta } = queryResponse as QueryReturnValue<
                    IAddKnowledgeResponseApi,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >;

                if (error) {
                    const message = i18n.t('error.api.ask-ai-kb.add', { ns: 'errors' });

                    if (error.status !== ResponseStatus.UNAUTHORIZED) {
                        getToastService().error(message, meta);
                    }

                    return {
                        error: {
                            status: -1,
                            data: message,
                        },
                    };
                }

                return { data };
            },
        }),
    }),
});

export const { useAddKnowledgeMutation } = askAiKbEndpoint;
