import { REPLACE_STATE_ACTION } from './constants';
import { IRootState } from './core-store';

export const replaceState = (state: IRootState) => ({
    type: REPLACE_STATE_ACTION,
    payload: state,
});

export const isReplaceStateAction = (action: any): action is ReturnType<typeof replaceState> => {
    return action.type === REPLACE_STATE_ACTION;
};
