import React from 'react';
import cx from 'clsx';

import { SourcesList } from '../Sources/SourcesList/SourcesList';
import { User } from '../User/User';

import styles from './Status.module.scss';

export interface IStatusProps {
    className?: string;
    displaySourceList?: boolean;
}

const StatusComponent: React.FC<IStatusProps> = ({ className, displaySourceList = true }) => {
    return (
        <div className={cx(styles.wrapper, className)}>
            <div className={styles.left}>{displaySourceList && <SourcesList />}</div>
            <div className={styles.right}>
                <User />
            </div>
        </div>
    );
};

export const Status = React.memo(StatusComponent);
