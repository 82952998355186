import theme from '../styles/chakra/theme.module.scss';

export const FLOATING_ICON_HEIGHT_V1 = parseInt(theme.floatingIconHeightV1, 10);
export const FLOATING_ICON_HEIGHT_V2 = parseInt(theme.floatingIconHeightV2, 10);
const DEACTIVATE_BUTTON_SIZE = parseInt(theme.deactivateButtonSize, 10);
const DEACTIVATE_BUTTON_GAP = parseInt(theme.deactivateButtonGap, 10);
export const DEFAULT_TRANSITION_DURATION = parseFloat(theme.defaultTransitionDuration);
export const DEFAULT_SIDEBAR_MIN_WIDTH = parseInt(theme.sidebarMinWidth, 10);
export const CHAT_MIN_WIDTH = parseInt(theme.chatMinWidth, 10);

export const getDefaultTransition = () => ({
    duration: DEFAULT_TRANSITION_DURATION,
    ease: [
        parseFloat(theme.defaultTransitionEaseP1),
        parseFloat(theme.defaultTransitionEaseP2),
        parseFloat(theme.defaultTransitionEaseP3),
        parseFloat(theme.defaultTransitionEaseP4),
    ],
});

export const limitFloatingIconYPositionV1 = (currentPosition: number): number => {
    let newYPosition = Math.min(
        currentPosition,
        window.innerHeight - (DEACTIVATE_BUTTON_SIZE + DEACTIVATE_BUTTON_GAP + (FLOATING_ICON_HEIGHT_V1 * 1.2) / 2)
    );
    newYPosition = Math.max(newYPosition, (FLOATING_ICON_HEIGHT_V1 * 1.2) / 2);

    return newYPosition;
};

export const limitFloatingIconYPositionV2 = (currentPosition: number): number => {
    let newYPosition = Math.min(
        currentPosition,
        window.innerHeight - (DEACTIVATE_BUTTON_SIZE + DEACTIVATE_BUTTON_GAP + FLOATING_ICON_HEIGHT_V2 / 2)
    );
    newYPosition = Math.max(newYPosition, FLOATING_ICON_HEIGHT_V2 / 2);

    return newYPosition;
};
