import { ProactiveSourceAbstract } from './proactive-source-abstract';

export class ProactiveSourceZendesk extends ProactiveSourceAbstract {
    protected regex = /https:\/\/.*?.zendesk.com\/agent\/tickets\/(\d+)/;
    protected regexMatchIndex = 1;

    getName() {
        return 'zendesk';
    }
}
