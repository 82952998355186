import browser, { Runtime } from 'webextension-polyfill';

import { OnCommandEvent } from './events/on-command-event';
import { OnSelectionChangeEvent } from './events/on-selection-change/on-selection-change-event';
import { OnTokenUpdatedEvent } from './events/on-token-updated-event';
import { TabUrlChangedEvent } from './events/tab-url-changed-event';
import { AbstractEventHandler } from './abstract-event-handler';
import { EVENT_BUS_PORT_NAME } from './constants';
import { EventBusListenersMap, IEventBusEmitter, IEventBusMessage } from './types';

class EventBusEmitter {
    private ports: Set<Runtime.Port> = new Set();

    constructor() {
        browser.runtime.onConnect.addListener((port) => {
            if (port.name !== EVENT_BUS_PORT_NAME) {
                return;
            }

            this.addPort(port);
        });
    }

    private addPort(port: Runtime.Port) {
        port.onDisconnect.addListener(() => {
            this.ports.delete(port);
        });

        this.ports.add(port);
    }

    public registerEventHandler<H extends AbstractEventHandler>(handler: new (emitter: IEventBusEmitter) => H) {
        const instance = new handler(this);
        instance.register();
    }

    public emit<K extends keyof EventBusListenersMap>(
        type: K,
        tabId: number | undefined,
        ...args: Parameters<EventBusListenersMap[K]>
    ) {
        const ports = Array.from(this.ports.keys()).filter((port) =>
            tabId === undefined ? true : port.sender?.tab?.id === tabId
        );

        const message: IEventBusMessage<K> = {
            type,
            payload: args,
        };

        ports.forEach((port) => port.postMessage(message));
    }
}

export const eventBusEmitter = new EventBusEmitter();

export const registerEvents = () => {
    eventBusEmitter.registerEventHandler(OnCommandEvent);
    eventBusEmitter.registerEventHandler(TabUrlChangedEvent);
    eventBusEmitter.registerEventHandler(OnTokenUpdatedEvent);
    eventBusEmitter.registerEventHandler(OnSelectionChangeEvent);
};
