import { isAnyOf, Middleware } from '@reduxjs/toolkit';

import { setDragStarted } from '../slices/floating-icon/floating-icon-slice';
import { setSidebarResizeStarted } from '../slices/resize/resize-slice';

export const onMouseDragMiddleware: Middleware = () => (next) => (action) => {
    const isDragAction = isAnyOf(setSidebarResizeStarted, setDragStarted);

    if (isDragAction(action)) {
        const { payload: isStarted } = action;

        if (isStarted) {
            document.body.style.pointerEvents = 'none';
        } else {
            document.body.style.pointerEvents = 'auto';
        }
    }

    return next(action);
};
