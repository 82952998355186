import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle, defineStyleConfig } from '@chakra-ui/react';

import { withCustomInputStyles } from './consts';

import styles from './theme.module.scss';

const formLabelBaseStyle = defineStyle({
    fontSize: '1.3em',
    fontWeight: 700,
    lineHeight: '22px',
    marginBottom: 0,
    color: styles.textMidGray,
});

const formLabelVariants = {
    'add-knowledge': defineStyle({
        fontSize: '1.2em',
        fontWeight: 500,
        lineHeight: '24px',
        paddingInlineStart: '8px',
    }),
};

export const formLabelTheme = defineStyleConfig({ baseStyle: formLabelBaseStyle, variants: formLabelVariants });

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const inputBaseStyle = definePartsStyle({
    field: defineStyle({
        _disabled: {
            backgroundColor: styles.backgroundDisabled,
            opacity: 0.4,
        },
    }),
});

const inputVariants = {
    outline: definePartsStyle({
        field: defineStyle({
            height: '40px',
            ...withCustomInputStyles,
        }),
    }),
    ask: definePartsStyle({
        field: defineStyle({
            pl: '50px',
        }),
    }),
    disabled: definePartsStyle({
        field: defineStyle({
            backgroundColor: styles.inputBackgroundDisabled,
            color: styles.inputColorDisabled,
            fontSize: '14px',
            _disabled: {
                backgroundColor: styles.inputBackgroundDisabled,
                opacity: 1,
                cursor: 'auto',
            },
        }),
    }),
    'preview-search': definePartsStyle({
        field: defineStyle({
            ...withCustomInputStyles,
            height: '40px',
            fontSize: '0.7em',
        }),
    }),
    'add-knowledge': definePartsStyle({
        field: defineStyle({
            ...withCustomInputStyles,
            height: '32px',
            borderWidth: '2px',
            borderColor: styles.borderBlueGray,
            paddingInlineStart: '12px',
            paddingInlineEnd: '12px',
            fontSize: '1.2em',
            '&[aria-invalid="true"]': {
                borderColor: styles.buttonPrimaryOutlinedWarning,
            },
        }),
    }),
    'chat-history-search': definePartsStyle({
        field: defineStyle({
            ...withCustomInputStyles,
            height: '40px',
        }),
    }),
};

export const inputTheme = defineMultiStyleConfig({ baseStyle: inputBaseStyle, variants: inputVariants });
