import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum AnnotateAnswerModalOrigin {
    ADD_ANSWER = 'add_answer',
    ANNOTATE_ANSWER = 'annotate_answer',
}

export interface IAnnotateAnswerModalState {
    visible: boolean;
    origin: AnnotateAnswerModalOrigin;
}

const initialState: IAnnotateAnswerModalState = {
    visible: false,
    origin: AnnotateAnswerModalOrigin.ADD_ANSWER,
};

interface IOpenAnnotateAnswerModalPayload {
    origin: IAnnotateAnswerModalState['origin'];
}

export const annotateAnswerModalSlice = createSlice({
    name: 'annotate-answer-modal',
    initialState,
    reducers: {
        openAnnotateAnswerModal(state, action: PayloadAction<IOpenAnnotateAnswerModalPayload>) {
            const {
                payload: { origin },
            } = action;

            state.visible = true;
            state.origin = origin;
        },
        closeAnnotateAnswerModal(state) {
            state.visible = false;
        },
    },
});

export const { openAnnotateAnswerModal, closeAnnotateAnswerModal } = annotateAnswerModalSlice.actions;
