import React from 'react';
import { Popover, PopoverAnchor } from '@chakra-ui/react';
import { IAnnotateAnswerChunk } from 'answers-core/src/api/types';
import { useAppDispatch, useAppSelector } from 'answers-core/src/redux/hooks/app-hooks';
import { setAnswerComment } from 'answers-core/src/redux/slices/annotate-answers/annotate-answers-slice';

import { AnnotateAnswerActions } from './Actions/AnnotateAnswerActions';
import { PopoverCommentContent } from './Actions/CommentAnswer/PopoverCommentContent';
import { AnnotateAnswerDescription } from './Description/Description';
import { AnswerIcon } from './Icon/Icon';

import styles from './AnnotateAnswer.module.scss';

export interface IAnnotateAnswerProps {
    chunk: IAnnotateAnswerChunk;
    onClick: (answer: IAnnotateAnswerChunk) => void;
}

export const AnnotateAnswer: React.FC<IAnnotateAnswerProps> = ({ chunk, onClick }) => {
    const dispatch = useAppDispatch();
    const { comment_chunks_map } = useAppSelector((state) => state.annotateAnswers);
    const { source_icon, source_id, chunk_id } = chunk;
    const inputRef = React.useRef<HTMLTextAreaElement>(null);
    const handleClick = React.useCallback(() => {
        onClick(chunk);
    }, [chunk, onClick]);

    const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = React.useCallback(
        ({ target }) => {
            dispatch(setAnswerComment({ id: chunk_id, comment: target.value || '' }));
        },
        [chunk_id, dispatch]
    );

    const comment = React.useMemo(() => {
        return comment_chunks_map[chunk_id] || '';
    }, [comment_chunks_map, chunk_id]);

    return (
        <div>
            <Popover
                isLazy
                initialFocusRef={inputRef}
                id={chunk.chunk_id}
                closeOnBlur={false}
                placement="right-start"
                strategy="fixed"
            >
                <PopoverAnchor>
                    <div className={styles.wrapper}>
                        <AnnotateAnswerDescription text={chunk.text} title={chunk.title} onClick={handleClick} />
                        <div className={styles.footer}>
                            <AnswerIcon
                                className={styles.icon}
                                iconData={{ source_icon, source_id }}
                                onClick={handleClick}
                            />
                            <div className={styles.spacer} />
                            <AnnotateAnswerActions chunk={chunk} />
                        </div>
                    </div>
                </PopoverAnchor>
                <PopoverCommentContent inputRef={inputRef} value={comment} onChange={handleChange} />
            </Popover>
        </div>
    );
};
