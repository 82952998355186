import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react';

import i18n from '../../i18n';
import { IRootState } from '../../redux/core-store';
import { setUser } from '../../redux/thunks/user-thunk';
import { getToastService } from '../../services/toast/toast-service';
import { orderCustomerProjects } from '../../utils/user';
import { baseApi } from '../base-api';
import { ApiTagTypes, IUser, ResponseStatus } from '../types';

const baseApiWithTags = baseApi.enhanceEndpoints({
    addTagTypes: [ApiTagTypes.USER],
});

export const usersEndpoint = baseApiWithTags.injectEndpoints({
    endpoints: (builder) => ({
        getUser: builder.query<IUser, void>({
            queryFn: async (arg, { dispatch }, extraOptions, baseQuery) => {
                const userInfoResponse = await baseQuery({
                    url: 'users/me',
                });

                const { data, error } = userInfoResponse as QueryReturnValue<
                    IUser,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >;

                if (error) {
                    return {
                        error: {
                            status: -1,
                            data: i18n.t('error.api.user', { ns: 'errors' }),
                        },
                    };
                }

                dispatch(
                    setUser({
                        ...data,
                        customer_projects: orderCustomerProjects(data),
                    })
                );

                return {
                    data,
                };
            },
            providesTags: [ApiTagTypes.USER],
        }),
        updateUser: builder.mutation<boolean, Partial<IUser>>({
            queryFn: async (arg, { dispatch, getState }, extraOptions, baseQuery) => {
                const response = await baseQuery({
                    url: 'users/me',
                    method: 'POST',
                    body: arg,
                });

                const { error, meta } = response as QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>;

                if (error) {
                    const message = i18n.t('error.api.settings.save', { ns: 'errors' });

                    if (error.status !== ResponseStatus.UNAUTHORIZED) {
                        getToastService().error(message, meta);
                    }

                    return {
                        error: {
                            status: -1,
                            data: message,
                        },
                    };
                }

                const { user } = (getState() as IRootState).auth;

                dispatch(
                    usersEndpoint.util.updateQueryData('getUser', undefined, (draft) => {
                        Object.assign(draft, arg);
                    })
                );

                dispatch(
                    setUser({
                        ...user,
                        ...arg,
                    })
                );

                return {
                    data: true,
                };
            },
        }),
    }),
});

export const { useGetUserQuery, useLazyGetUserQuery, useUpdateUserMutation } = usersEndpoint;
