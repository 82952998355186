import React from 'react';
import Lottie from 'lottie-react';

import animation from './preload-animation.json';

import styles from './PreloadAnimation.module.scss';

export const PreloadAnimation: React.FC = () => {
    return (
        <div className={styles.wrapper}>
            <Lottie animationData={animation} loop className={styles.animation} />
        </div>
    );
};
