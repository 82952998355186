import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'clsx';

import { SHARED_PREVIEW_CACHE_KEY } from '../../../../api/consts';
import { useGetPreviewMutation } from '../../../../api/endpoints/preview-endpoint';
import { useAppSelector } from '../../../../redux/hooks/app-hooks';
import {
    useHighlightPointerContentScriptMode,
    usePreviewIFrameShortcutsHandling,
} from '../../../../redux/hooks/preview';
import { currentPreviewId, getPreviewURL, previewService } from '../../../../services/preview/preview-service';

import styles from './PreviewModalBody.module.scss';

export const IFrameContentScriptMode: React.FC = () => {
    const { t } = useTranslation('translations');
    const [, { data, isLoading }] = useGetPreviewMutation({
        fixedCacheKey: SHARED_PREVIEW_CACHE_KEY,
    });
    const sidebarResizeStarted = useAppSelector((state) => state.resize.sidebarResizeStarted);

    const answerContentRef = useRef<HTMLIFrameElement>(null);

    const { showPointer, hidePointer } = useHighlightPointerContentScriptMode();

    usePreviewIFrameShortcutsHandling(answerContentRef);

    const sendContentToPreviewFrame = async () => {
        if (data) {
            hidePointer();
            await previewService.loadPreview(currentPreviewId, data.content);
            showPointer();
        }
    };

    const src = data && !isLoading ? getPreviewURL() : undefined;

    useEffect(() => {
        if (!isLoading) {
            showPointer();
        }

        return () => {
            hidePointer();
        };
    }, [isLoading, hidePointer, showPointer]);

    return (
        <>
            <iframe
                ref={answerContentRef}
                src={src}
                title={t('preview.hint.content')}
                onLoad={sendContentToPreviewFrame}
                className={cx({ [styles.disableEvents]: sidebarResizeStarted })}
            />
            <div className={styles.dimmer} />
        </>
    );
};
