export const fixMondayHostStyles = (sidebarWidth: number) => {
    const slidePanel = document.querySelector<HTMLElement>('div.slide-panel');
    const firstLevelContent = document.querySelector<HTMLElement>('#first-level-content');

    if (slidePanel && firstLevelContent) {
        slidePanel.style.right = `${sidebarWidth}px`;

        const slidePanelWidth = parseInt(window.getComputedStyle(slidePanel).width, 10);
        const slidePanelIsOpen = !!document.querySelector('div.slide-panel.open');
        const marginRight = slidePanelIsOpen ? slidePanelWidth : 0;

        queueMicrotask(() => {
            firstLevelContent.style.marginRight = `${marginRight}px`;
        });
    }
};
