import React from 'react';
import { Highlight } from '@chakra-ui/react';

import { useAnnotatedTextHighlightQuery } from './hooks';

import styles from './Description.module.scss';

export interface IAnnotateAnswerDescriptionProps {
    text: string;
    title: string;
    onClick: () => void;
}

export const AnnotateAnswerDescription: React.FC<IAnnotateAnswerDescriptionProps> = ({ text, title, onClick }) => {
    const query = useAnnotatedTextHighlightQuery();

    return (
        <div className={styles.wrapper}>
            <div>
                <Highlight query={query} styles={{ fontWeight: 700 }}>
                    {text}
                </Highlight>
            </div>
            <h3 className={styles.title} onClick={onClick}>
                <Highlight query={query} styles={{ fontWeight: 700, color: 'inherit' }}>
                    {title}
                </Highlight>
            </h3>
        </div>
    );
};
