let stylesTag: HTMLStyleElement | null = null;

export const fixLinkedinHostStyles = (sidebarWidth: number) => {
    if (!stylesTag) {
        stylesTag = document.createElement('style');
        stylesTag.setAttribute('type', 'text/css');
        stylesTag.setAttribute('id', 'answers-ce-fix-styles-tag');

        document.body.appendChild(stylesTag);
    }

    stylesTag.innerText = `
    #global-nav {
        width: calc(100vw - ${sidebarWidth}px);
        transition: width 150ms ease-out;
    }
    #artdeco-modal-outlet > * {
        width: calc(100% - ${sidebarWidth}px);
        transition: width 150ms ease-out;
    }
    #msg-overlay {
        margin-inline-end: ${sidebarWidth}px;
        transition: margin 150ms ease-out;
    }
    `;
};
