import { MATCH_ID_TEMPLATE } from '../../../api/consts';
import { IAnswerResponse } from '../../../api/types';
import { getAnswerUrl } from '../../../utils/answer';

enum AnswerSourceType {
    SLACK = 'slack',
}

type UrlExtractor = (previewData: string, currentMatch: number) => string | undefined;

const extractUrlFromSlackConversation: UrlExtractor = (previewData, currentMatch) => {
    const currentMatchIndex = previewData.indexOf(`${MATCH_ID_TEMPLATE}${currentMatch}`);
    const prevLinkIndex = previewData.lastIndexOf('<a', currentMatchIndex);

    if (prevLinkIndex === -1) {
        return undefined;
    }

    const linkSearchRegex = /<a[^>]+href=["|'](.*?)["|'][^>]*>.*?<\/a>/m;

    const result = linkSearchRegex.exec(previewData.substring(prevLinkIndex));

    return result ? result[1] : undefined;
};

const extractorsMap: Record<AnswerSourceType, UrlExtractor> = {
    [AnswerSourceType.SLACK]: extractUrlFromSlackConversation,
};

const getExtractor = (answer: IAnswerResponse): UrlExtractor | undefined => {
    return extractorsMap[answer.source_type as AnswerSourceType];
};

export const extractUrlFromPreview = (
    answer: IAnswerResponse | undefined,
    previewData: string | undefined,
    currentMatch: number
): string | undefined => {
    if (!answer || !previewData) {
        return undefined;
    }

    const extractor = getExtractor(answer);

    if (!extractor) {
        return getAnswerUrl(answer);
    }

    return extractor(previewData, currentMatch);
};
