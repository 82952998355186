import { ISource, IUseSource } from '../api/types';
import i18n from '../i18n';

const { t, exists } = i18n;

export const translateAnswerSource = (source: ISource): string => {
    const { id, type, tooltip } = source;

    if (tooltip) {
        return tooltip;
    }

    if (exists(`source.name.${id}`, { ns: 'translations' })) {
        return t(`source.name.${id}`, { ns: 'translations' });
    }

    return t(`source.type.${type}`, { defaultValue: type, ns: 'translations' });
};

export const sortByActiveFirst = (a: IUseSource, b: IUseSource) => {
    if (a.active && !b.active) {
        return -1;
    }

    if (!a.active && b.active) {
        return 1;
    }

    return 0;
};
