import React, { CSSProperties, useEffect, useRef } from 'react';
import cx from 'clsx';
import Lottie, { LottieOptions, LottieRefCurrentProps } from 'lottie-react';

import styles from './SingleAnimation.module.scss';

export interface ISingleAnimationProps {
    animationData: LottieOptions['animationData'];
    start: boolean;
    onComplete?: LottieOptions['onComplete'];
    className?: string;
    style?: CSSProperties;
}

export const SingleAnimation: React.FC<ISingleAnimationProps> = ({
    animationData,
    start,
    onComplete,
    className,
    style,
}) => {
    const lottieRef = useRef<LottieRefCurrentProps | null>(null);

    useEffect(() => {
        if (start && lottieRef.current) {
            const lottie = lottieRef.current;
            lottie.goToAndPlay(0, false);
        }
    }, [start]);

    const classNameMerged = cx(
        {
            [styles.hidden]: !start,
        },
        className
    );

    return (
        <Lottie
            loop={false}
            autoplay={false}
            animationData={animationData}
            onComplete={onComplete}
            lottieRef={lottieRef}
            className={classNameMerged}
            style={style}
        />
    );
};
