import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TriggerType } from '../../../api/types';
import { ISelectionState } from '../../types';

const initialState: ISelectionState = {
    selectedText: '',
    triggerType: undefined,
};

export const selectionSlice = createSlice({
    name: 'selection',
    initialState,
    reducers: {
        setSelectedText(state, action: PayloadAction<string>) {
            state.selectedText = action.payload;
        },
        setTriggerType(state, action: PayloadAction<TriggerType | undefined>) {
            state.triggerType = action.payload;
        },
    },
});

export const { setSelectedText, setTriggerType } = selectionSlice.actions;
