import escapeStringRegexp from 'escape-string-regexp';
import * as pdfjsDist from 'pdfjs-dist';

import packageJson from '../../package.json';

import { isWebMode } from './extension-mode';

const clientVersion = packageJson.version;
const baseUrl = process.env.REACT_APP_API_URL;
const BASE_64_PREFIX = 'data:application/pdf;base64,';

export const createRegExp = (str: string) => {
    if (!str) {
        return '';
    }

    const _string = escapeStringRegexp(str.trim()).replace(/\s/gm, '( *)');

    return new RegExp(_string, 'gmi');
};

/*
 * Get the url for the answer pdf file
 *
 * @param customerProjectId - current customer project id
 * @param docId - answer doc id
 * @param sourceId - answer source id
 * @param sourceType - answer source type
 */
export const getAnswerFileUrl = (customerProjectId: string, docId: string, sourceId: string, sourceType: string) => {
    const url = new URL('/answers/v1/documents/file', baseUrl);

    url.searchParams.append('client_version', clientVersion);
    url.searchParams.append('customer_project_id', customerProjectId);
    url.searchParams.append('source_id', sourceId);
    url.searchParams.append('source_type', sourceType);

    // doc id is already encoded
    return `${url.toString()}&doc_id=${docId}`;
};

export const getPdfWorkerUrl = () => {
    let path: string;

    if (isWebMode()) {
        path = `//unpkg.com/pdfjs-dist@${pdfjsDist.version}/build`;
    } else {
        const currentIframeHref = new URL(document.location.href);
        const urlOrigin = currentIframeHref.origin;

        path = `${urlOrigin}/scripts`;
    }

    return `${path}/pdf.worker.min.js`;
};

/*
 * Creates Blob file from base64 string of PDF file
 *
 * @param data - base64 string
 */
export const base64PdfFileToBlob = (data: string): Promise<Blob> =>
    new Promise((resolve, reject) => {
        try {
            // replace the prefix `data:application/pdf;base64` from the raw base 64
            const base64WithoutPrefix = data.replace(BASE_64_PREFIX, '');

            const bytes = atob(base64WithoutPrefix);
            let length = bytes.length;
            let out = new Uint8Array(length);

            while (length--) {
                out[length] = bytes.charCodeAt(length);
            }

            resolve(new Blob([out], { type: 'application/pdf' }));
        } catch (e) {
            reject(new Error('Failed to convert base64 PDF file to Blob'));
        }
    });

/*
 * Converts ArrayBuffer to base64 string
 *
 * @param arrayBuffer - ArrayBuffer
 */
export const arrayBufferToBase64 = (arrayBuffer: ArrayBuffer): Promise<string> =>
    new Promise((resolve, reject) => {
        try {
            const typedArray = new Uint8Array(arrayBuffer);
            const bytesToStringReducer = (acc: string, nextByte: number) => acc + String.fromCharCode(nextByte);

            const out = typedArray.reduce(bytesToStringReducer, '');

            resolve(btoa(out));
        } catch (e) {
            reject(new Error('Failed to convert ArrayBuffer to base64 string'));
        }
    });

/**
 * Converts a base64 string to an ArrayBuffer.
 * @param {string} base64 - The base64 string to convert.
 * @returns {ArrayBuffer} - The converted ArrayBuffer.
 */
export const base64ToArrayBuffer = (base64: string) => {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
};

/**
 * Converts an array of objects containing base64-encoded file data into an array of File objects asynchronously.
 *
 * @param base64Files - An array of objects, each containing a file's name, MIME type, and base64-encoded data.
 * @returns A promise that resolves to an array of File objects created from the provided base64 data.
 *
 * The function uses Promise.all to handle multiple files concurrently. Each base64 string is converted to an ArrayBuffer
 * and then used to create a File object with the specified name and MIME type.
 */
export const base64ToFiles = async (
    base64Files: { name: string; type: string; asBase64: string }[]
): Promise<File[]> => {
    const promises = base64Files.map(async (file) => {
        const arrayBuffer = base64ToArrayBuffer(file.asBase64);
        return new File([arrayBuffer], file.name, { type: file.type });
    });
    return Promise.all(promises);
};

/**
 * Converts an array of File objects to an array of objects with base64 strings.
 *
 * @param {File[]} files - The array of File objects to convert.
 * @returns {Promise<Array<{ name: string, type: string, asBase64: string }>>} -
 * A promise that resolves to an array of objects, each containing:
 *   - `name`: The original file name.
 *   - `type`: The MIME type of the file.
 *   - `asBase64`: The file content encoded as a base64 string.
 */
export const filesToBase64 = async (files: File[]) => {
    return await Promise.all(
        files.map(async (file) => ({
            name: file.name,
            type: file.type,
            asBase64: await arrayBufferToBase64(await file.arrayBuffer()),
        }))
    );
};
