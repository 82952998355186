import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { setProject } from '../../thunks/settings-thunk';
import { logout } from '../../thunks/user-thunk';
import { ITabState, Tabs } from '../../types';

const initialState: ITabState = {
    activeTab: Tabs.SEARCH,
};

export const tabsSlice = createSlice({
    name: 'tabs',
    initialState,
    reducers: {
        setActiveTab(state, action: PayloadAction<Tabs>) {
            state.activeTab = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logout.fulfilled, () => {
            return initialState;
        });
        builder.addCase(setProject.fulfilled, () => {
            return initialState;
        });
    },
});

export const { setActiveTab } = tabsSlice.actions;
