import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useResizeObserver from '@react-hook/resize-observer';
import cx from 'clsx';
import { sanitize } from 'dompurify';

import { useCurrentAnswer } from '../../../../redux/hooks/answer/answer-hooks';
import { getAnswerQuery } from '../../../../utils/answer';

import styles from './PreviewModalHeader.module.scss';

export const PreviewTitleContent: React.FC = () => {
    const { t } = useTranslation('translations');
    const [contentOverflows, setContentOverflows] = useState(false);
    const [collapsed, setCollapsed] = useState<boolean>(true);
    const titleTextRef = useRef<HTMLSpanElement | null>(null);

    const answer = useCurrentAnswer();

    const title = answer?.title ?? getAnswerQuery(answer);
    const html = useMemo(() => sanitize(title, { ALLOWED_TAGS: ['br'] }), [title]);

    const handleResize = useCallback(
        ({ target }: ResizeObserverEntry) => {
            // scrollHeight - 1 because of the rounding error in some browsers
            const ifContentOverflows = target.scrollHeight - 1 > target.clientHeight;

            // change state only if it's different
            if (contentOverflows !== ifContentOverflows) {
                setContentOverflows(ifContentOverflows);
            }
        },
        [contentOverflows]
    );

    useResizeObserver(titleTextRef, handleResize);

    return (
        <div className={styles.content}>
            <span
                ref={titleTextRef}
                className={cx(styles.titleText, collapsed && styles.truncatedText)}
                dangerouslySetInnerHTML={{ __html: html }}
            ></span>
            {contentOverflows && collapsed && (
                <button className={styles.collapse} onClick={() => setCollapsed(false)}>
                    {t('preview.action.show-more')}
                </button>
            )}
        </div>
    );
};
