import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Radio,
    RadioGroup,
    Stack,
    Text,
    Textarea,
} from '@chakra-ui/react';
import { Field, FieldProps, Formik } from 'formik';

import { AnnotationType } from '../../../../api/types';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks/app-hooks';
import { useProject, useSources } from '../../../../redux/hooks/settings-hooks';
import { closeAnswerFeedbackModal } from '../../../../redux/slices/modals/answer-feedback-modal-slice';
import { annotateThunk } from '../../../../redux/thunks/annotate-thunk';
import { restoreFocus } from '../../../../redux/thunks/focus-thunk';
import { WithTranslateErrors } from '../../../WithTranslateErrors/WithTranslateErrors';
import { ModalContainer } from '../../Container/ModalContainer';

import { answerFeedbackValidationSchema } from './validation-schema';

import styles from './AnswerFeedbackModal.module.scss';

interface IFormData {
    type: AnnotationType | '';
    details: string;
}

export const AnswerFeedbackModal: React.FC = () => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();
    const { visible, answer } = useAppSelector((state) => state.modals.annotate);
    const { project } = useProject();
    const { sources } = useSources();

    if (!answer) {
        return null;
    }

    const initialFormState: IFormData = {
        type: '',
        details: '',
    };

    const handleCloseModal = () => {
        dispatch(closeAnswerFeedbackModal());
    };

    const handleFormSubmit = async (formData: IFormData) => {
        const { type, details } = formData;

        if (type === '') {
            return false;
        }

        await dispatch(
            annotateThunk({
                answer,
                type,
                project,
                sources,
                details,
            })
        );

        handleCloseModal();
    };

    return (
        <ModalContainer
            isOpen={visible}
            onClose={handleCloseModal}
            motionPreset="scale"
            scrollBehavior="inside"
            onCloseComplete={() => dispatch(restoreFocus())}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t('answer.feedback.header')}</ModalHeader>
                <Formik
                    initialValues={initialFormState}
                    onSubmit={handleFormSubmit}
                    validationSchema={answerFeedbackValidationSchema()}
                >
                    {({
                        errors,
                        touched,
                        setFieldTouched,
                        submitForm,
                        setFieldValue,
                        isSubmitting,
                        isValid,
                        dirty,
                    }) => {
                        return (
                            <WithTranslateErrors errors={errors} touched={touched} setFieldTouched={setFieldTouched}>
                                <ModalBody className={styles.body}>
                                    <Text fontSize="sm" color="mid-gray" fontWeight="medium">
                                        {t('answer.feedback.select-option')}:
                                    </Text>
                                    <Field name="type">
                                        {({ field }: FieldProps<string>) => (
                                            <FormControl isInvalid={!!errors.type && touched.type}>
                                                <RadioGroup
                                                    name={field.name}
                                                    value={field.value}
                                                    onChange={(value) => setFieldValue('type', value)}
                                                >
                                                    <Stack direction="column">
                                                        <Radio value={AnnotationType.INCORRECT_INFO}>
                                                            {t('answer.feedback.option.incorrect')}
                                                        </Radio>
                                                        <Radio value={AnnotationType.DID_NOT_ANSWER}>
                                                            {t('answer.feedback.option.did-not-answer')}
                                                        </Radio>
                                                    </Stack>
                                                </RadioGroup>
                                                <FormErrorMessage>{errors.type}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>

                                    <Field name="details">
                                        {({ field }: FieldProps<string>) => (
                                            <FormControl isInvalid={!!errors.details && touched.details}>
                                                <FormLabel size="xs">{t('answer.feedback.details')}</FormLabel>
                                                <Textarea
                                                    variant="outline"
                                                    name={field.name}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                />
                                                <FormErrorMessage>{errors.details}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </ModalBody>
                                <ModalFooter className={styles.footer}>
                                    <Button variant="secondary" onClick={handleCloseModal}>
                                        {t('button.cancel')}
                                    </Button>
                                    <Button
                                        variant="primary"
                                        onClick={submitForm}
                                        isLoading={isSubmitting}
                                        disabled={!isValid || !dirty}
                                    >
                                        {t('button.submit')}
                                    </Button>
                                </ModalFooter>
                            </WithTranslateErrors>
                        );
                    }}
                </Formik>
            </ModalContent>
        </ModalContainer>
    );
};
