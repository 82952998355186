import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AnnotationType } from '../../api/types';
import { ITrackEventOrigin, MixpanelEvent } from '../../services/mixpanel/types';
import { getToastService, TOAST_BOTTOM_OFFSET } from '../../services/toast/toast-service';
import { getAnswerUrl } from '../../utils/answer';
import { sendMetrics } from '../thunks/metrics-thunk';
import { ICopyActionMeta } from '../types';

import { useAnswers, useCurrentMatchUrl } from './answer/answer-hooks';
import { useAnalytics } from './analytics';
import { useAppDispatch, useAppSelector } from './app-hooks';
import { useSources } from './settings-hooks';

export const useCopyLink = () => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();
    const { currentAnswerId } = useAppSelector((state) => state.modals.preview);
    const [, { data }] = useAnswers();
    const { sources } = useSources();
    const [log] = useAnalytics();
    const extractUrl = useCurrentMatchUrl();

    return useCallback(
        async (meta: ICopyActionMeta) => {
            const { answerId, shortcutUsed, origin } = meta;

            const idToCopy = answerId ?? currentAnswerId;

            const answerToCopy = data?.answers.find((answer) => answer.doc_id === idToCopy);

            const url =
                origin === ITrackEventOrigin.ANSWERS_LIST
                    ? getAnswerUrl(answerToCopy)
                    : extractUrl() ?? getAnswerUrl(answerToCopy);

            const title = answerToCopy?.title || url;

            // Generate the rich format
            const link = `<a href='${url}'>${title}</a>`;
            const htmlType = 'text/html';
            const html = new Blob([link], { type: htmlType });

            const plainType = 'text/plain';
            const text = new Blob([url], { type: plainType });

            const clipboardItem = new ClipboardItem({ [htmlType]: html, [plainType]: text });

            await navigator.clipboard.write([clipboardItem]);
            answerToCopy && log(answerToCopy, AnnotationType.COPY_LINK);
            answerToCopy &&
                dispatch(
                    sendMetrics({
                        event: MixpanelEvent.COPY_LINK,
                        meta: {
                            use_shortcut: shortcutUsed,
                            link: url,
                            origin,
                        },
                        injectAnswersMetrics: {
                            answers: data,
                            sources,
                        },
                    })
                );

            if (answerToCopy) {
                if (answerToCopy.source_is_public) {
                    getToastService().info(t('link.copy.public.success'), {
                        ...(origin === ITrackEventOrigin.PREVIEW_WINDOW && { marginBottom: TOAST_BOTTOM_OFFSET }),
                    });
                } else {
                    getToastService().warning(t('link.copy.private.success'), {
                        ...(origin === ITrackEventOrigin.PREVIEW_WINDOW && { marginBottom: TOAST_BOTTOM_OFFSET }),
                    });
                }
            }
        },
        [currentAnswerId, data, extractUrl, log, dispatch, sources, t]
    );
};
