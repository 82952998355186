import { createSlice } from '@reduxjs/toolkit';

interface IRequestKnowledgeModalState {
    visible: boolean;
}

const initialState: IRequestKnowledgeModalState = {
    visible: false,
};

export const requestKnowledgeModalSlice = createSlice({
    name: 'request-knowledge-modal',
    initialState,
    reducers: {
        _openRequestKnowledgeModal(state) {
            state.visible = true;
        },
        _closeRequestKnowledgeModal(state) {
            state.visible = false;
        },
    },
});

// we have a thunk with send metrics in it for this
export const { _openRequestKnowledgeModal, _closeRequestKnowledgeModal } = requestKnowledgeModalSlice.actions;
