import { v4 as uuid } from 'uuid';

import { IAnswerResponse, IApiAnnotateAnswerChunkResponse } from '../api/types';

export const mapChunkToAnswer = (chunk: IApiAnnotateAnswerChunkResponse): IAnswerResponse => ({
    direct: false,
    isLowConfidence: false,
    isGrayedOut: false,
    uuid: uuid(),
    unique_answer_id: chunk.chunk_id,
    confidence: 0,
    doc_id: chunk.doc_id,
    title: chunk.title,
    html_short: chunk.title,
    html_long: chunk.text,
    source_icon: chunk.source_icon,
    source_id: chunk.source_id,
    source_type: chunk.source_type,
    preview_type: chunk.preview_type,
    text: chunk.text,
    url: chunk.url,
    valid_url: chunk.valid_url,
    reader_is_article_or_section: false,
});
