import type { ILoginResponse } from '@frontegg/rest-api';

export interface IGetCachedTokenAndExpiryDateResponse {
    cachedToken: ILoginResponse | null;
    expiryDate: Date | null;
}

export enum SSOLogoutReason {
    GOT_401 = 'got_401',
    GOT_403 = 'got_403',
    CHANGE_BACKEND_URL = 'change_backend_url',
    USER_CLICKS_LOGOUT = 'user_clicks_logout',
    UNKNOWN = 'unknown',
}
