import React, { MouseEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Collapse, Divider, Text, useDisclosure } from '@chakra-ui/react';
import cx from 'clsx';

import { ReactComponent as ArrowDown } from './arrow-down.svg';

import styles from './Accordion.module.scss';

interface SelectionCount {
    selectedCount: number;
    maxSelection: number;
}
interface AccordionProps {
    title: string;
    selectionCount?: SelectionCount;
    handleClearAll: (e: MouseEvent<HTMLButtonElement>) => void;
    children: React.ReactNode;
}
export const Accordion: React.FC<AccordionProps> = ({ handleClearAll, selectionCount, title, children }) => {
    const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
    const { t } = useTranslation('translations');

    return (
        <>
            <div>
                <div className={styles.accordionWrapper}>
                    <div className={styles.titleWrapper}>
                        <button className={styles.arrowIconWrapper} onClick={onToggle}>
                            <ArrowDown
                                className={cx(styles.arrowIcon, {
                                    [styles.rotate]: isOpen,
                                })}
                            />
                        </button>

                        <div className={styles.titleContainer}>
                            <Text className={styles.title}>{title}</Text>
                            {selectionCount && (
                                <Text className={styles.title}>
                                    <Trans
                                        t={t}
                                        i18nKey="filters-modal.selected-count"
                                        values={{
                                            selected: selectionCount.selectedCount,
                                            total: selectionCount.maxSelection,
                                        }}
                                    />
                                </Text>
                            )}
                        </div>
                    </div>
                    <button className={styles.action} onClick={handleClearAll}>
                        {t('filters-modal.clear-all-button')}
                    </button>
                </div>
                <Divider />
            </div>
            <Collapse in={isOpen}>{children}</Collapse>
        </>
    );
};
