import { createAsyncThunk } from '@reduxjs/toolkit';

import { LAST_FORCE_FEEDBACK_STORAGE_KEY } from '../../services/storage/storage-keys-list';
import { storageService } from '../../services/storage/storage-service';
import { _closeForceFeedback, _openForceFeedback } from '../slices/uat/uat-slice';

export const openForceFeedbackDialog = createAsyncThunk(
    'uat/force-feedback/openForceFeedbackDialog',
    async (_, { dispatch }) => {
        await storageService.setStorageValue(LAST_FORCE_FEEDBACK_STORAGE_KEY, true);
        dispatch(_openForceFeedback());
    }
);

export const closeForceFeedbackDialog = createAsyncThunk(
    'uat/force-feedback/closeForceFeedbackDialog',
    async (_, { dispatch }) => {
        await storageService.removeStorageValue(LAST_FORCE_FEEDBACK_STORAGE_KEY);
        dispatch(_closeForceFeedback());
    }
);
