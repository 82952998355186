import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, MenuButton, MenuDivider, MenuItem, MenuList } from '@chakra-ui/react';

import { useGetSettingsQuery } from '../../api/endpoints/settings-endpoint';
import { ReactComponent as AddMissingAnswerIcon } from '../../assets/icons/add-missing-answer.svg';
import { ReactComponent as BadgeIcon } from '../../assets/icons/badge.svg';
import { ReactComponent as RequestKnowledgeIcon } from '../../assets/icons/request-knowledge.svg';
import { ReactComponent as AdminPanelIcon } from '../../assets/icons/settings/admin-panel.svg';
import { ReactComponent as ContactSupport } from '../../assets/icons/settings/contact-support.svg';
import { ReactComponent as HelpCenterIcon } from '../../assets/icons/settings/help-center-icon.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/settings/logout.svg';
import { ReactComponent as ProvideFeedbackIcon } from '../../assets/icons/settings/provide-feedback.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings/settings.svg';
import { useAnswers } from '../../redux/hooks/answer/answer-hooks';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/app-hooks';
import { openFeedbackModal } from '../../redux/slices/modals/feedback-modal-slice';
import { openPointsModal } from '../../redux/slices/modals/points-modal-slice';
import { openSettingsModal } from '../../redux/slices/modals/settings-modal-slice';
import { openAddAnswerModal, openRequestKnowledgeModal } from '../../redux/thunks/feedback-thunk';
import { sendMetrics } from '../../redux/thunks/metrics-thunk';
import { logout } from '../../redux/thunks/user-thunk';
import { MixpanelEvent } from '../../services/mixpanel/types';
import { SSOLogoutReason } from '../../services/sso/types';
import { getLogger } from '../../utils/logger';
import { sendEmail } from '../../utils/mail';
import openAdminPanel from '../../utils/openAdminPanel';

import { UserAvatar } from './Avatar/UserAvatar';
import { UserDetails } from './Details/UserDetails';
import { Points } from './Points/Points';

import styles from './User.module.scss';

const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_MAIL ?? '';
const logger = getLogger('UserComponent');

export const User: React.FC = () => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();
    const { user, shouldDisplayFronteggAdminPanel } = useAppSelector((state) => state.auth);
    const { competition } = user;
    const { data } = useGetSettingsQuery();
    const [, { isLoading: isAnswersLoading, data: answersData }] = useAnswers();

    if (!data) {
        return null;
    }

    const shouldDisplayCompetitionInfo = !!competition;

    const handlePointsClick = () => {
        dispatch(openPointsModal());
        dispatch(sendMetrics({ event: MixpanelEvent.OPEN_POINT_EXPLANATION }));
    };

    const handleSettingsClick = () => {
        dispatch(openSettingsModal());
        dispatch(sendMetrics({ event: MixpanelEvent.OPEN_SETTINGS }));
    };

    const handleContactSupportClick = () => {
        dispatch(sendMetrics({ event: MixpanelEvent.CONTACT_SUPPORT }));
        sendEmail(SUPPORT_EMAIL, t('support.contact.subject'));
    };

    const handleAddAnswerClick = () => {
        dispatch(openAddAnswerModal());
    };

    const handleRequestKnowledgeClick = () => {
        dispatch(openRequestKnowledgeModal());
    };

    const handleProvideFeedbackClick = () => {
        dispatch(openFeedbackModal());
        dispatch(sendMetrics({ event: MixpanelEvent.OPEN_FEEDBACK_MAIN_PANEL }));
    };

    const handleLogoutClick = () => {
        dispatch(sendMetrics({ event: MixpanelEvent.LOG_OUT_ANSWER }));
        logger.warn('User clicked logout - logging the user out (with portal)');
        dispatch(logout({ logoutFromPortal: true, reason: SSOLogoutReason.USER_CLICKS_LOGOUT }));
    };

    const renderHelpCenterMenuItem = () => {
        if (!data.help_center_url) {
            return null;
        }

        return (
            <MenuItem icon={<HelpCenterIcon />} onClick={() => window.open(data.help_center_url, '_blank', 'noopener')}>
                {t('support.help-center')}
            </MenuItem>
        );
    };

    const areUserActivitiesDisabled = isAnswersLoading || !answersData?.question_id;

    return (
        <div>
            {/* strategy should be "fixed" to fix issue with horizontal scroll on answer content at min width*/}
            <Menu flip offset={[0, 0]} strategy="fixed" isLazy>
                <div className={styles.wrapper}>
                    {shouldDisplayCompetitionInfo && <Points onPointsClick={handlePointsClick} />}
                    <MenuButton as={'div'} className={styles.button} data-testid="user">
                        <UserAvatar />
                    </MenuButton>
                </div>

                <MenuList zIndex="3">
                    <UserDetails />
                    <MenuDivider />
                    {shouldDisplayCompetitionInfo && (
                        <MenuItem icon={<BadgeIcon />} onClick={handlePointsClick}>
                            {t('user.explain-competition')}
                        </MenuItem>
                    )}
                    {shouldDisplayFronteggAdminPanel && (
                        <MenuItem icon={<AdminPanelIcon />} onClick={openAdminPanel}>
                            {t('app.admin-panel')}
                        </MenuItem>
                    )}
                    <MenuItem icon={<SettingsIcon />} onClick={handleSettingsClick} data-testid="user.button.settings">
                        {t('app.settings')}
                    </MenuItem>
                    <MenuItem icon={<ContactSupport />} onClick={handleContactSupportClick}>
                        {t('support.contact')}
                    </MenuItem>
                    {renderHelpCenterMenuItem()}
                    <MenuItem
                        icon={<AddMissingAnswerIcon />}
                        onClick={handleAddAnswerClick}
                        isDisabled={areUserActivitiesDisabled}
                        data-testid="feedback.button.add-missing-answer"
                    >
                        {t('feedback.answer-missing-expected-answer.menu-item')}
                    </MenuItem>
                    <MenuItem
                        icon={<RequestKnowledgeIcon />}
                        onClick={handleRequestKnowledgeClick}
                        isDisabled={areUserActivitiesDisabled}
                        data-testid="feedback.button.request-knowledge"
                    >
                        {t('feedback.answer-request-knowledge.menu-item')}
                    </MenuItem>
                    <MenuItem
                        icon={<ProvideFeedbackIcon />}
                        onClick={handleProvideFeedbackClick}
                        data-testid="user.button.feedback"
                    >
                        {t('feedback.feedback')}
                    </MenuItem>
                    <MenuItem icon={<LogoutIcon />} onClick={handleLogoutClick}>
                        {t('app.logout')}
                    </MenuItem>
                </MenuList>
            </Menu>
        </div>
    );
};
