import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { sanitize } from 'dompurify';

import { useGetSettingsQuery } from '../../../api/endpoints/settings-endpoint';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/app-hooks';
import { closePointsModal } from '../../../redux/slices/modals/points-modal-slice';
import { ModalContainer } from '../Container/ModalContainer';

import styles from './PointsModal.module.scss';

export const PointsModal: React.FC = () => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();
    const { visible } = useAppSelector((state) => state.modals.points);
    const { data } = useGetSettingsQuery();

    const handleCloseModal = () => {
        dispatch(closePointsModal());
    };

    return (
        <ModalContainer isOpen={visible} onClose={handleCloseModal} motionPreset="scale" scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t('points.header')}</ModalHeader>
                <ModalBody>
                    {data && (
                        <div
                            className={styles.body}
                            dangerouslySetInnerHTML={{ __html: sanitize(data.points_explanation_html) }}
                        />
                    )}
                </ModalBody>
                <ModalFooter className={styles.footer}>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        {t('button.close')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </ModalContainer>
    );
};
