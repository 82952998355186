import React, { forwardRef } from 'react';
import { chakra, CheckboxProps, CheckboxState, useCheckbox, useMultiStyleConfig } from '@chakra-ui/react';

import { ReactComponent as CheckedIcon } from './icons/checked.svg';
import { ReactComponent as IndeterminateIcon } from './icons/indeterminate.svg';
import { CustomCheckboxThemeKey } from './styles';

import styles from './CheckBox.module.scss';
export interface ICheckBoxProps extends Omit<CheckboxProps, 'children'> {
    children?: React.ReactNode | ((state: CheckboxState) => React.ReactNode);
}
export const CheckBox = forwardRef<HTMLInputElement, ICheckBoxProps>(({ children, ...checkBoxProps }, ref) => {
    const themeStyles = useMultiStyleConfig(CustomCheckboxThemeKey);
    const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } = useCheckbox(checkBoxProps);

    const isUnchecked = !(state.isChecked || state.isIndeterminate);
    const borderColor = isUnchecked ? styles.uncheckedBorderColor : styles.checkedFillColor;
    const backgroundColor = isUnchecked ? undefined : styles.checkedFillColor;
    const content = typeof children === 'function' ? children(state) : children;

    return (
        <chakra.label __css={themeStyles.container} {...htmlProps}>
            <input hidden ref={ref} {...getInputProps()} />
            <chakra.span
                __css={themeStyles.checkbox}
                borderColor={borderColor}
                backgroundColor={backgroundColor}
                {...getCheckboxProps()}
            >
                {state.isChecked && <CheckedIcon />}
                {state.isIndeterminate && <IndeterminateIcon />}
            </chakra.span>
            <chakra.span __css={themeStyles.text} {...getLabelProps()}>
                {content}
            </chakra.span>
        </chakra.label>
    );
});
