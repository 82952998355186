import React, { useContext } from 'react';
import { Modal, ModalProps } from '@chakra-ui/react';

import { AppContainerContext } from '../../App/app-container-context';
import { SidebarContext } from '../../Sidebar/sidebar-context';

export const ModalContainer: React.FC<ModalProps> = ({ children, ...rest }) => {
    const { isOpen } = rest;
    const isSidebarMode = useContext(SidebarContext);

    if (!isOpen) {
        return null;
    }

    const trapFocus = !!rest.trapFocus;

    return (
        <AppContainerContext.Consumer>
            {(value) => (
                <Modal
                    {...rest}
                    portalProps={{ containerRef: value }}
                    useInert={false}
                    preserveScrollBarGap
                    blockScrollOnMount={false}
                    trapFocus={trapFocus}
                    size={isSidebarMode ? 'sidebar' : undefined}
                >
                    {children}
                </Modal>
            )}
        </AppContainerContext.Consumer>
    );
};
