import React from 'react';
import { useRadioGroup } from '@chakra-ui/react';
import { IAnnotateAnswerChunk } from 'answers-core/src/api/types';
import { useAppDispatch, useAppSelector } from 'answers-core/src/redux/hooks/app-hooks';
import { setAnswerLabel } from 'answers-core/src/redux/slices/annotate-answers/annotate-answers-slice';

import { ReactComponent as NaLabelIcon } from '../../../../../assets/icons/na-label.svg';

import { LabelRadio } from './LabelRadio';
import { levelLabels, naLabel } from './labels-data';

import styles from './LabelAnswer.module.scss';

export interface ILabelAnswerProps {
    chunk: IAnnotateAnswerChunk;
}

export const LabelAnswer: React.FC<ILabelAnswerProps> = ({ chunk }) => {
    const dispatch = useAppDispatch();
    const { labeled_chunks_map, annotated_labeled_chunks_map } = useAppSelector((state) => state.annotateAnswers);

    const annotatedLabel = React.useMemo(() => {
        return annotated_labeled_chunks_map[chunk.chunk_id] || '';
    }, [annotated_labeled_chunks_map, chunk]);

    const label = React.useMemo(() => {
        return labeled_chunks_map[chunk.chunk_id] || '';
    }, [labeled_chunks_map, chunk]);

    const { value, getRadioProps, getRootProps, setValue } = useRadioGroup({
        defaultValue: label,
        onChange(nextValue: string) {
            dispatch(setAnswerLabel({ id: chunk.chunk_id, label: nextValue }));
        },
    });

    // fix for radio button not reset value on reset action
    React.useEffect(() => {
        setValue(label);
    }, [label, setValue]);

    return (
        <div {...getRootProps()} className={styles.radioWrapper}>
            <div className={styles.naWrapper}>
                <LabelRadio
                    label={<NaLabelIcon />}
                    defaultStyles={
                        annotatedLabel === naLabel.value ? naLabel.previouslyCheckedStyles : naLabel.defaultStyles
                    }
                    checkedStyles={naLabel.checkedStyles}
                    {...getRadioProps({ value: naLabel.value })}
                />
            </div>
            <div className={styles.radioGroup}>
                {levelLabels.map((item) => {
                    const forceCheck = value !== item.value && value?.toString().replace('.5', '') === item.value;

                    return (
                        <LabelRadio
                            key={item.label}
                            label={item.label}
                            decimal={item.decimal}
                            forceChecked={forceCheck}
                            checkedStyles={item.checkedStyles}
                            previouslyChecked={annotatedLabel === item.value}
                            {...getRadioProps({ value: item.value })}
                        />
                    );
                })}
            </div>
        </div>
    );
};
