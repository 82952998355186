import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import { ActionType, ActionTypeV2, IProactiveAnswersMeta } from '../../../api/types';
import { clearQuestion } from '../../thunks/question-thunk';
import { setDisableOnlineMemory, setProject } from '../../thunks/settings-thunk';
import { logout } from '../../thunks/user-thunk';
import { IQuestionState } from '../../types';
import { answersSlice } from '../answers/answers-slice';

export const initialState: IQuestionState = {
    answers: [], // will be loaded asynchronously in App Init middleware
    question_id: '', // will be loaded asynchronously in App Init middleware
    question: '', // will be loaded asynchronously in App Init middleware
    expect_direct_answer: false, // will be loaded asynchronously in App Init middleware
    action_type: null,
    custom_action: null,
    userStartedTyping: false,
    isInitial: true,
    proactivelyGenerated: false,
    proactiveMeta: null,
    forceFeedback: false,
};

export const questionSlice = createSlice({
    name: 'question',
    initialState,
    reducers: {
        setQuestion(state, action: PayloadAction<string>) {
            const { payload: question } = action;

            state.userStartedTyping = question !== '';
            state.question = question;
        },
        setQuestionId(state, action: PayloadAction<string>) {
            state.question_id = action.payload;
        },
        setActionType(
            state,
            action: PayloadAction<{
                action_type: ActionType | ActionTypeV2 | null;
                custom_action: IQuestionState['custom_action'];
            }>
        ) {
            state.action_type = action.payload.action_type;
            state.custom_action = action.payload.custom_action;
        },
        setQuestionSliceState(state, action: PayloadAction<Omit<IQuestionState, 'isInitial'>>) {
            return {
                ...action.payload,
                isInitial: false,
            };
        },
        setProactiveMeta(state, action: PayloadAction<IProactiveAnswersMeta | null>) {
            state.proactiveMeta = action.payload;
            state.proactivelyGenerated = action.payload !== null;
        },
        setProactivelyGenerated(state, action: PayloadAction<boolean>) {
            state.proactivelyGenerated = action.payload;
        },
        setIsInitial(state, action: PayloadAction<boolean>) {
            state.isInitial = action.payload;
        },
        setForceFeedbackForQuestion(state, action: PayloadAction<boolean>) {
            state.forceFeedback = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logout.fulfilled, (state) => {
            state.question = '';
            state.question_id = '';
            state.answers = [];
            state.proactiveMeta = null;
            state.proactivelyGenerated = false;
        });

        builder.addCase(answersSlice.actions.setSearchFocused, (state) => {
            state.userStartedTyping = false;
        });

        builder.addMatcher(isClearAnswersAction, (state) => {
            state.question = '';
            state.question_id = '';
            state.answers = [];
            state.action_type = null;
            state.custom_action = null;
            state.expect_direct_answer = false;
            state.isInitial = true;
            state.proactiveMeta = null;
            state.proactivelyGenerated = false;
            state.forceFeedback = false;
        });
    },
});

export const isClearAnswersAction = isAnyOf(
    setProject.fulfilled,
    setDisableOnlineMemory.fulfilled,
    clearQuestion.fulfilled
);

export const {
    setQuestion,
    setQuestionId,
    setActionType,
    setQuestionSliceState,
    setProactiveMeta,
    setProactivelyGenerated,
    setIsInitial,
    setForceFeedbackForQuestion,
} = questionSlice.actions;
