import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react';

import i18n from '../../i18n';
import { IRootState } from '../../redux/core-store';
import { getUserProject } from '../../utils/user';
import { baseApi } from '../base-api';
import { ApiTagTypes, IApiSourcesList, ISource } from '../types';

const baseApiWithTags = baseApi.enhanceEndpoints({
    addTagTypes: [ApiTagTypes.SOURCES],
});

export const sourcesEndpoint = baseApiWithTags.injectEndpoints({
    endpoints: (builder) => ({
        getSources: builder.query<ISource[], string | undefined>({
            queryFn: async (arg, { getState }, _, baseQuery) => {
                const project = arg ?? getUserProject(getState() as IRootState);

                const sourcesResponse = await baseQuery({
                    url: `/v1/data_sources?customer_project_id=${project}`,
                });

                const { data, error } = sourcesResponse as QueryReturnValue<
                    IApiSourcesList,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >;

                if (error) {
                    return {
                        error: {
                            status: -1,
                            data: i18n.t('error.api.sources', { ns: 'errors' }),
                        },
                    };
                } else {
                    const sources = Object.keys(data).map((key) => ({
                        id: key,
                        type: data[key].type,
                        icon: data[key].icon,
                        tooltip: data[key].tooltip,
                    }));

                    return {
                        data: sources,
                    };
                }
            },
        }),
    }),
});

export const { useGetSourcesQuery, useLazyGetSourcesQuery } = sourcesEndpoint;
