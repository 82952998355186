const FLOATING_APP_WHITE_LIST = [
    'slack',
    'mail.google',
    'calendar.google',
    'monday',
    'intercom',
    'notion',
    'guru',
    'zendesk',
    'wordpress',
    'wixanswers',
    'atlassian',
    'ownbackup',
    'outlook',
    'yotpo',
    'lumen',
    'walkme',
    'coralogix',
    'claroty',
    'armis',
    'connecteam',
    'snyk',
    'singular',
    'lightning.force',
    'localhost',
];

export const isDomainIncludeInWhiteList = (input: string[]): boolean => {
    const userURL = new URL(window.location.href);

    if (userURL.hostname.includes('localhost')) {
        return true;
    }

    const whitelistArray = input.length > 0 ? input : FLOATING_APP_WHITE_LIST;

    const domainInWhiteList = whitelistArray.find((domain) => isUrlIncludesDomain(userURL, domain));

    return Boolean(domainInWhiteList);
};

export const isUrlIncludesDomain = (url: URL, domain: string): boolean => {
    return url.hostname.includes(domain);
};

type EventType = 'keydown' | 'keypress' | 'keyup' | 'scroll' | 'wheel' | 'paste';
export const preventEventBubbling = (root: HTMLElement, events: EventType[]): void => {
    if (!root || !events.length) {
        return;
    }

    const preventEventPropagation = (event: Event): void => {
        if ('stopPropagation' in event) {
            event.stopPropagation();
        }
    };

    for (const eventType of events) {
        root.addEventListener(eventType, preventEventPropagation);
    }
};
