import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IFloatingIconState } from '../../types';
import { setSidebarVisible } from '../app/app-slice';

const initialState: IFloatingIconState = {
    mouseHover: false,
    dragStarted: false,
    draggedBefore: false,
    yPosition: null,
    deactivateMenuVisible: false,
    autoHideTimerId: undefined,
};

export const floatingIconSlice = createSlice({
    name: 'floating-icon',
    initialState,
    reducers: {
        _setFloatingMouseHover(state, action: PayloadAction<boolean>) {
            state.mouseHover = action.payload;

            if (action.payload) {
                // clear auto hide timer when floating icon is hovered
                clearTimeout(state.autoHideTimerId);
                state.autoHideTimerId = undefined;
            }
        },
        setDragStarted(state, action: PayloadAction<boolean>) {
            state.dragStarted = action.payload;
        },
        setDraggedBefore(state, action: PayloadAction<boolean>) {
            state.draggedBefore = action.payload;
        },
        setYPosition(state, action: PayloadAction<number | null>) {
            state.yPosition = action.payload;
        },
        setDeactivateMenuVisible(state, action: PayloadAction<boolean>) {
            state.deactivateMenuVisible = action.payload;
        },
        _setAutoHideTimerId(state, action: PayloadAction<IFloatingIconState['autoHideTimerId']>) {
            clearTimeout(state.autoHideTimerId);
            state.autoHideTimerId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setSidebarVisible, (state) => {
            // clear auto hide timer when sidebar visibility changes
            clearTimeout(state.autoHideTimerId);
            state.autoHideTimerId = undefined;
        });
    },
});

export const {
    _setFloatingMouseHover,
    _setAutoHideTimerId,
    setDragStarted,
    setDraggedBefore,
    setYPosition,
    setDeactivateMenuVisible,
} = floatingIconSlice.actions;
