import React from 'react';

import { useGetLastNotificationQuery } from '../../api/endpoints/notifications-endpoint';
import { NotificationVersion } from '../../api/types';

import { NotificationBannerV1 } from './V1/NotificationBannerV1';
import { NotificationBannerV2 } from './V2/NotificationBannerV2';

const NOTIFICATION_POLLING_INTERVAL = 5 * 60 * 1000; // 5min.

export const NotificationBanner: React.FC = () => {
    const { data, error, isLoading } = useGetLastNotificationQuery(undefined, {
        pollingInterval: NOTIFICATION_POLLING_INTERVAL,
    });

    if (error || !data || isLoading) {
        return null;
    }

    switch (data.version) {
        case NotificationVersion.V1:
            return <NotificationBannerV1 notification={data} />;
        case NotificationVersion.V2:
            return <NotificationBannerV2 notification={data} />;
        default:
            return <NotificationBannerV1 notification={data} />;
    }
};
