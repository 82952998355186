const HAMBURGER_CLASS_NAME = '.om-mm-hamburger';
const HAMBURGER_COLOR = '#22272c';

const DISPLAY_FLEX = 'flex';
const DISPLAY_NONE = 'none';

const HEADER_MARGIN = 'auto';
const HEADER_TOP_PADDING = '30px';
const HEADER_BOTTOM_PADDING = '20px';
const HOST_CONTAINER_WIDTH = '100%';

const HOST_CONTENT_WIDTH = 1000;
const MIN_DESKTOP_VIEW = 1350;

export const ownbackupSupportHostStyles = (sidebarWidth: number) => {
    const container = document.querySelector<HTMLElement>('main.container');
    if (container) {
        container.style.width = HOST_CONTAINER_WIDTH;
    }

    const headerRow = document.querySelector<HTMLElement>('div.oxy-header-row');
    if (headerRow) {
        headerRow.style.paddingRight = `${sidebarWidth}px`;
    }
    const modal = document.querySelector<HTMLElement>(`div[data-trigger-selector="${HAMBURGER_CLASS_NAME}"]`);

    if (modal) {
        modal.style.paddingRight = `${sidebarWidth}px`;
    }

    function getHeaderStyles(showHeader: boolean) {
        const headerRightBlock = document.querySelector<HTMLElement>('div.oxy-header-right');
        const headerCenterBlock = document.querySelector<HTMLElement>('.oxy-header-center');

        if (headerRightBlock && headerCenterBlock) {
            const hamburgerBlock = headerRightBlock.querySelector<HTMLElement>(HAMBURGER_CLASS_NAME);
            const headerRightBlockContent = headerRightBlock.querySelector<HTMLElement>('.ct-div-block');

            if (hamburgerBlock && headerRightBlockContent) {
                if (showHeader) {
                    hamburgerBlock.style.display = DISPLAY_FLEX;
                    hamburgerBlock.style.color = HAMBURGER_COLOR;
                    headerRightBlock.style.marginLeft = HEADER_MARGIN;
                    headerRightBlockContent.style.display = DISPLAY_NONE;
                    headerCenterBlock.style.display = DISPLAY_NONE;

                    if (headerRow) {
                        headerRow.style.paddingTop = HEADER_TOP_PADDING;
                        headerRow.style.paddingBottom = HEADER_BOTTOM_PADDING;
                    }
                } else {
                    hamburgerBlock.style.display = DISPLAY_NONE;
                    headerRightBlockContent.style.display = DISPLAY_FLEX;
                    headerCenterBlock.style.display = DISPLAY_FLEX;
                }
            }
        }
    }

    const hostContentSize = window.innerWidth - sidebarWidth;

    getHeaderStyles(hostContentSize < HOST_CONTENT_WIDTH);

    const mediaQuery = window.matchMedia(`(max-width: ${MIN_DESKTOP_VIEW}px`);
    mediaQuery.addEventListener('change', (evt) => evt.matches && getHeaderStyles(evt.matches));
};
