import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { setProject } from '../../thunks/settings-thunk';
import { logout } from '../../thunks/user-thunk';

export interface IChatTabSliceState {
    historyOpen: boolean;
    activeConversationId: string | null;
}

const initialState: IChatTabSliceState = {
    historyOpen: false,
    activeConversationId: null,
};

export const chatTabSlice = createSlice({
    name: 'chat-tab',
    initialState,
    reducers: {
        _openHistory(state, _: PayloadAction) {
            state.historyOpen = true;
        },
        _closeHistory(state, _: PayloadAction) {
            state.historyOpen = false;
        },
        setActiveConversationId(state, action: PayloadAction<IChatTabSliceState['activeConversationId']>) {
            state.activeConversationId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logout.fulfilled, () => {
            return initialState;
        });
        builder.addCase(setProject.fulfilled, () => {
            return initialState;
        });
    },
});

export const { _openHistory, _closeHistory, setActiveConversationId } = chatTabSlice.actions;
