import {
    ActionType,
    ActionTypeV2,
    IAiHelperUserAction,
    IAnswerResponse,
    IApiProactiveFrequencySettingsGroup,
    IBaseApiSettings,
    IDateFilter,
    IDirectAnswerResult,
    IProactiveAnswersMeta,
    IProvideQuestionResponse,
    IQuizAnswer,
    ISource,
    IUser,
    IUseSmartFilter,
    IUseSource,
    TriggerType,
} from '../api/types';
import { ITrackEventOrigin } from '../services/mixpanel/types';

export interface IUseSourcesHook {
    sources: IUseSource[];
    sourcesMap: Map<IUseSource['id'], IUseSource>;
    lazyGetSources: () => Promise<IUseSource[]>;
    toggleSource: (sourceId: string) => IUseSource[];
    disableSources: (sourceIds: string[]) => Promise<IUseSource[]>;
}

export interface IUseProjectHookParams {
    skipMetrics?: boolean;
}

export interface IUseProjectHook {
    project: string;
    setProject: (project: string, params?: IUseProjectHookParams) => Promise<void>;
}

export interface IStateProactiveFrequencyGroup extends IApiProactiveFrequencySettingsGroup {
    name: string;
}

export interface IStateProactiveFrequencySettings {
    groups: IStateProactiveFrequencyGroup[];
    timeouts: IBaseApiSettings['proactive_frequency']['timeouts'];
}

export interface ISettingsState {
    disabledSources: string[];
    backendUrl: string;
    project: string;
    confidenceSettings: IBaseApiSettings['show_low_confidence_tresh'];
    disableOnlineMemory: boolean | null;
    shouldDisplayFloatingApp: boolean | null;
    originsBlacklist: string[];
    gatewayUrl?: string;
    simulateCustomerProject?: string;
    fullTicketAppUrl?: string;
    searchStateRelevanceTime: number;
    proactiveFrequencySettings: IStateProactiveFrequencySettings;
    proactiveFrequencyCurrentGroup: {
        name: string;
        score: number;
    };
    languageCode: string;
    currentUrl: string;
    selectedSmartFilters: IUseSmartFilter[];
    floatingIconHideDelayMs: number;
    selectedDatesFilter: IDateFilter;
}

export interface IAnswersState {
    searchFocused: boolean;
    activeAnswerUuid: string;
    prevAnswerUuid: string;
    collapsed: boolean;
    analyticsSent: boolean;
}

export interface INextPayload {
    answers: IAnswerResponse[];
    threshold: number;
}

export enum SidebarOpenReason {
    USER = 'user', // Opened by user either clicked by the floating icon or by the keyboard shortcut
    AI_HELPER = 'ai_helper', // Opened after clicking on any action of the AI Helper panel (Rephrase, Summarize etc...)
    PROACTIVE_POPUP = 'proactive_popup', // Opened by clicking on the proactive answer popup
    ROUTE = 'route', // WE have a bunch of the sidebar auto open URLs (when user successfully logged in using SSO for example)
}

export interface IAppState {
    initialized: boolean;
    sidebarVisible: boolean;
    sidebarOpenedBy: SidebarOpenReason | null;
    loading: boolean;
    error: boolean;
}

export enum Tabs {
    SEARCH = 'search',
    CHAT = 'chat',
}

export interface ITabState {
    activeTab: Tabs;
}

export interface IAuthPermissions {
    preCannedQuestionsAccess: boolean;
    disableOnlineMemory: boolean;
    changeBackendApi: boolean;
    debugProactiveAccess: boolean;
}

export interface IAuthState {
    username: string;
    token: string | null;
    user: IUser;
    shouldDisplayFronteggAdminPanel: boolean;
    isAskAIUser: boolean;
    roles: string[];
    permissions: IAuthPermissions;
}

export interface IQuestionState {
    answers: IAnswerResponse[];
    question: string;
    question_id: string;
    expect_direct_answer: boolean;
    action_type: ActionType | ActionTypeV2 | null;
    custom_action: IAiHelperUserAction | null;
    userStartedTyping: boolean;
    isInitial: boolean;
    proactivelyGenerated: boolean;
    proactiveMeta: IProactiveAnswersMeta | null;
    forceFeedback: boolean;
}

export interface ICopyActionMeta {
    answerId?: string;
    shortcutUsed: boolean;
    origin: ITrackEventOrigin;
}

export interface ISuggestionsState {
    searchHistory: string[];
    suggestions: string[];
}

export interface IResizeState {
    sidebarResizeStarted: boolean;
    sidebarWidth: number;
    prevSidebarWidth: number;
    floatingBarWidth: number;
    minWidth: number;
    shouldAnimateWidth?: boolean;
}

export interface IMetricsState {
    floatingEnabled: boolean;
}

export interface ISelectionState {
    selectedText: string;
    triggerType: TriggerType | undefined;
}

export interface IFloatingIconState {
    mouseHover: boolean;
    dragStarted: boolean;
    draggedBefore: boolean;
    yPosition: number | null;
    deactivateMenuVisible: boolean;
    autoHideTimerId: ReturnType<typeof setTimeout> | undefined;
}

export interface IProactivePendingToSaveData {
    answers: Omit<IQuestionState, 'isInitial'>;
    directAnswer?: IDirectAnswerResult;
}

export type ProactiveShowedForEntry = [string, number];
export type ProactiveShowedForEntries = ProactiveShowedForEntry[];
export type ProactiveShowedForMap = Record<string, number>;

export interface IProactiveAnswersState {
    questionId: string;
    sessionId: string | null;
    isTimerEnabled: boolean;
    isLoading: boolean;
    loadingMessage: string;
    resultReady: boolean;
    directAnswerReady: boolean;
    pendingToSave: IProactivePendingToSaveData | null;
    floatingPopupVisible: boolean;
    noDirectAnswerBannerVisible: boolean;
    proactiveFrequencyEnabled: boolean;
    directAnswerPopupEnabled: boolean;
    floatingAnimationEnabled: boolean;
    showedForTTL: number | undefined;
    showedFor: ProactiveShowedForMap;
}

export type ISourcesMap = Map<string, ISource>;

export interface ISourcesState {
    sources: ISourcesMap;
}

export interface INotificationsState {
    upgradeToBetaVisible: boolean;
}

export interface IChangeProject {
    open_link_in_new_page: boolean | null;
    customer_project: string;
    backend_url: string;
    disable_online_memory: boolean | null;
    display_floating_app: boolean;
    simulate_customer_project: string;
    proactive_url?: string;
    full_ticket_app_url?: string;
    lang_code?: string;
}

export enum TrainingEnablementPopupMode {
    'MINI' = 'mini',
    'FULL' = 'full',
}

export interface ITrainingEnablementState {
    visible: boolean;
    mode: TrainingEnablementPopupMode;
    question: IProvideQuestionResponse['quiz_question'];
    selectedAnswerId: IQuizAnswer['quiz_answer_id'] | null;
}

export enum FloatingIconVisibility {
    MINI = 'mini',
    ICON_ONLY = 'icon_only',
    FULL = 'full',
}
