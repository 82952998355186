import { batch } from 'react-redux';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react';

import packageJson from '../../../package.json';
import i18n from '../../i18n';
import { IRootState } from '../../redux/core-store';
import {
    resetAnswersLabel,
    setAnswersToAnnotate,
    setAnswersToAnnotateFromAnnotationResult,
} from '../../redux/slices/annotate-answers/annotate-answers-slice';
import { setQuestion, setQuestionId } from '../../redux/slices/question/question-slice';
import { clearQuestion } from '../../redux/thunks/question-thunk';
import { setDisabledSources, setProject } from '../../redux/thunks/settings-thunk';
import { getToastService } from '../../services/toast/toast-service';
import { mapChunkToAnswer } from '../../utils/annotate-answers';
import { getUserProject } from '../../utils/user';
import { baseApi } from '../base-api';
import {
    ApiTagTypes,
    IApiAnnotateAnswersResponse,
    IApiAnnotationResult,
    IApiGetAnnotateAnswerRequestBody,
    IApiLabelAnnotateAnswersRequestBody,
    IAskRequestParams,
    IGetAnnotationResultRequestParams,
    IGetAnswers,
    ISource,
    ResponseStatus,
} from '../types';

import { EMPTY_RESPONSE } from './ask-endpoint';
import { sourcesEndpoint } from './sources-endpoint';

const baseApiWithTags = baseApi.enhanceEndpoints({
    addTagTypes: [ApiTagTypes.USER],
});

export const annotateAnswersEndpoint = baseApiWithTags.injectEndpoints({
    endpoints: (builder) => ({
        annotateAnswers: builder.mutation<boolean, void>({
            queryFn: async (_, { getState, dispatch }, extraOptions, baseQuery) => {
                const {
                    labeled_chunks_map,
                    comment_chunks_map,
                    comment_general: comment,
                    annotate_answers: annotateAnswers,
                } = (getState() as IRootState).annotateAnswers;

                const body: IApiLabelAnnotateAnswersRequestBody = {
                    ...annotateAnswers,
                    comment,
                    labeled_chunks: annotateAnswers.chunks.map((chunk) => ({
                        ...chunk,
                        label: labeled_chunks_map[chunk.chunk_id],
                        comment: comment_chunks_map[chunk.chunk_id] || '',
                    })),
                };

                const annotateAnswersResponse = await baseQuery({
                    url: '/v1/annotations/save',
                    method: 'POST',
                    body,
                });

                const { data, error, meta } = annotateAnswersResponse as QueryReturnValue<
                    boolean,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >;

                if (error) {
                    const message = i18n.t('error.api.annotate', { ns: 'errors' });

                    if (error.status !== ResponseStatus.UNAUTHORIZED) {
                        getToastService().error(message, meta);
                    }

                    return {
                        error: {
                            status: -1,
                            data: message,
                        },
                    };
                }

                dispatch(resetAnswersLabel());

                return {
                    data: !!data,
                };
            },
            invalidatesTags: [ApiTagTypes.USER],
        }),
        getAnnotateAnswers: builder.mutation<IGetAnswers, IAskRequestParams>({
            queryFn: async (arg, { getState, dispatch }, extraOptions, baseQuery) => {
                const state = getState() as IRootState;
                const { sources } = arg;
                const {
                    question: { question },
                    annotateAnswers: { annotate_answers },
                    uat: { enabled: isUATEnabled },
                } = state;
                const project = getUserProject(state);

                const body: IApiGetAnnotateAnswerRequestBody = {
                    question: question.trim(),
                    customer_project_id: project,
                    client_version: packageJson.version,
                    selected_data_source_ids: sources,
                    tags: annotate_answers.tags,
                    uat_mode: isUATEnabled,
                };

                const apiResponse = await baseQuery({ url: '/v1/annotations/ask', method: 'POST', body });

                const { data, error, meta } = apiResponse as QueryReturnValue<
                    IApiAnnotateAnswersResponse,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >;

                if (error) {
                    if (error.status === ResponseStatus.PARSING_ERROR) {
                        // No results were found - BE returns string if no results were found - will return empty data
                        return { data: EMPTY_RESPONSE };
                    }

                    const message = i18n.t('error.api.answers', { ns: 'errors' });

                    if (error.status !== ResponseStatus.UNAUTHORIZED) {
                        getToastService().error(message, meta);
                    }
                    dispatch(clearQuestion());
                    return {
                        error: {
                            status: -1,
                            data: message,
                        },
                    };
                } else if (typeof apiResponse.data === 'string') {
                    // No results were found - BE returns string if no results were found - will return empty data
                    return { data: EMPTY_RESPONSE };
                } else {
                    dispatch(setAnswersToAnnotate(data));

                    return {
                        data: {
                            answers: data.chunks.map(mapChunkToAnswer),
                            question_id: data.question_id,
                            expect_direct_answer: false,
                            proactiveMeta: null,
                        },
                    };
                }
            },
            invalidatesTags: [ApiTagTypes.USER],
        }),
        getAnnotationResult: builder.query<IGetAnswers, IGetAnnotationResultRequestParams>({
            queryFn: async (arg, { getState, dispatch }, extraOptions, baseQuery) => {
                const { annotationId } = arg;

                const apiResponse = await baseQuery({ url: `/v1/annotations/${annotationId}`, method: 'GET' });

                const { data, error, meta } = apiResponse as QueryReturnValue<
                    IApiAnnotationResult,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >;

                if (error) {
                    const translationKey =
                        error.status === ResponseStatus.NOT_FOUND
                            ? 'error.api.annotations.not-found'
                            : 'error.api.answers';
                    const message = i18n.t(translationKey, { ns: 'errors' });

                    if (error.status !== ResponseStatus.UNAUTHORIZED) {
                        getToastService().error(message, meta);
                    }

                    return {
                        error: {
                            status: -1,
                            data: message,
                        },
                    };
                } else {
                    // try to set project id from annotation result
                    await dispatch(setProject(data.customer_project_id));

                    // try to refresh sources after setting a new project id
                    let sources: ISource[];
                    try {
                        sources = await dispatch(
                            sourcesEndpoint.endpoints.getSources.initiate(data.customer_project_id, {
                                forceRefetch: true,
                            })
                        ).unwrap();
                    } catch (e) {
                        const message = i18n.t('error.api.sources', { ns: 'errors' });
                        getToastService().error(message);
                        sources = [];
                    }

                    // try to set sources state from annotation result
                    const sourceIds = sources?.map(({ id }) => id) || [];
                    const disabledSources = sourceIds.filter((id) => !data.selected_data_source_ids.includes(id));
                    await dispatch(setDisabledSources(disabledSources));

                    batch(() => {
                        dispatch(setAnswersToAnnotateFromAnnotationResult(data));
                        dispatch(setQuestion(data.question));
                        dispatch(setQuestionId(data.question_id));
                    });

                    return {
                        data: {
                            answers: data.chunks.map(mapChunkToAnswer),
                            question_id: data.question_id,
                            expect_direct_answer: false,
                            proactiveMeta: null,
                        },
                    };
                }
            },
        }),
    }),
});

export const { useAnnotateAnswersMutation, useGetAnnotateAnswersMutation, useGetAnnotationResultQuery } =
    annotateAnswersEndpoint;
