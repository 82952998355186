import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISmartFiltersState } from '../../../api/types';

const initialState: ISmartFiltersState = {
    smartFiltersEnabled: false,
};

export const smartFiltersSlice = createSlice({
    name: 'smart-filters',
    initialState,
    reducers: {
        setSmartFiltersEnabled(state, action: PayloadAction<boolean>) {
            state.smartFiltersEnabled = action.payload;
        },
    },
});

export const { setSmartFiltersEnabled } = smartFiltersSlice.actions;
