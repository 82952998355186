import { VERTICAL_SIDEBAR_CONSUMING_SPACE } from 'answers-chrome-extension/src/components/ActionBar/constants';

let style: HTMLStyleElement | null = null;

const applyStyles = (styles: string) => {
    if (!style) {
        style = document.createElement('style');
    }

    style.innerHTML = styles;

    document.head.appendChild(style);
};

const removeStyles = () => {
    if (style) {
        document.head.removeChild(style);
        style = null;
    }
};

const isSidebarOpen = (sidebarWidth: number) => sidebarWidth > 0;

const calcViewWidth = (sidebarWidth: number) => {
    let verticalNavWidth = 0;
    const verticalNav = document.querySelector<HTMLElement>('div.verticalNavContainer');

    if (verticalNav) {
        const styles = getComputedStyle(verticalNav);

        verticalNavWidth = parseInt(styles.width ?? '0', 10);
    }

    const offsetWidth = isSidebarOpen(sidebarWidth) ? sidebarWidth + verticalNavWidth : verticalNavWidth;

    return `calc(100% - ${offsetWidth}px)`;
};

const fixHeaderViewWidth = (_: number, viewWidth: string) => {
    const header = document.querySelector<HTMLElement>('header.oneHeader');

    if (header) {
        header.style.width = viewWidth;
    }
};

const fixHeaderTabsViewWidth = (_: number, viewWidth: string) => {
    const tabsetHeader = document.querySelector<HTMLElement>('.tabsetHeader');

    if (tabsetHeader) {
        tabsetHeader.style.width = viewWidth;

        queueMicrotask(() => {
            // triggers recalculation of tabs of the nav container
            window.dispatchEvent(new Event('resize'));
        });
    }
};

const fixNavViewWidth = (_: number, viewWidth: string) => {
    const navContainer = document.querySelector<HTMLElement>('.oneAppNavContainer');
    if (navContainer) {
        navContainer.style.width = viewWidth;

        queueMicrotask(() => {
            // triggers recalculation of tabs of the nav container
            window.dispatchEvent(new Event('resize'));
        });
    }
};

const fixUtilityBarViewWidth = (_: number, viewWidth: string) => {
    const utilityBar = document.querySelector<HTMLElement>('ul.utilitybar');
    if (utilityBar) {
        utilityBar.style.width = viewWidth;
    }
};

const fixCaseTemplatesViewWidth = (sidebarWidth: number, _: string) => {
    const caseTemplates = document.querySelectorAll<HTMLElement>('div.recordHomeTemplate');
    if (caseTemplates.length > 0) {
        caseTemplates.forEach((caseTemplate) => {
            caseTemplate.style.width = 'auto';
            caseTemplate.style.minWidth = isSidebarOpen(sidebarWidth) ? '15vw' : 'auto';
        });
    }
};

const fixMainViewportViewWidth = (sidebarWidth: number, viewWidth: string) => {
    const mainViewport = document.querySelector<HTMLElement>('div.viewport');
    if (mainViewport) {
        mainViewport.style.width = isSidebarOpen(sidebarWidth) ? viewWidth : 'auto';
    }
};

const fixUtilityBarDialogsView = (sidebarWidth: number, _: string) => {
    if (isSidebarOpen(sidebarWidth)) {
        const utilityBar = document.querySelector<HTMLElement>('ul.utilitybar');

        if (!utilityBar) {
            return;
        }

        // condition is true when the utility bar is on the right side (mirrored)
        if (getComputedStyle(utilityBar).justifyContent === 'flex-end') {
            // 6 is the padding of the utility bar
            const reservedActionsSpace = VERTICAL_SIDEBAR_CONSUMING_SPACE + 6;
            const right = reservedActionsSpace + sidebarWidth;

            // this fix is for the popups like the Omni chat on the Case page
            const fixSldsDialogs = `
                [role="dialog"][aria-hidden="false"].slds-utility-panel {
                    right: ${right}px !important;
                }
            `;
            applyStyles(fixSldsDialogs);
        }
    } else {
        removeStyles();
    }
};

const fixFns: Array<(sidebarWidth: number, viewWidth: string) => void> = [
    fixHeaderViewWidth,
    fixHeaderTabsViewWidth,
    fixNavViewWidth,
    fixUtilityBarViewWidth,
    fixCaseTemplatesViewWidth,
    fixMainViewportViewWidth,
    fixUtilityBarDialogsView,
];

export const fixSalesforceHostStyles = (sidebarWidth: number) => {
    const viewWidth = calcViewWidth(sidebarWidth);

    for (const fixFn of fixFns) {
        fixFn(sidebarWidth, viewWidth);
    }
};
