import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISuggestionsState } from '../../types';

const initialState: ISuggestionsState = {
    searchHistory: [],
    suggestions: [],
};

export const suggestionsSlice = createSlice({
    name: 'suggestions',
    initialState,
    reducers: {
        setSearchHistory(state, action: PayloadAction<string[]>) {
            state.searchHistory = action.payload;
        },
        setSuggestions(state, action: PayloadAction<string[]>) {
            state.suggestions = action.payload;
        },
    },
});

export const { setSearchHistory, setSuggestions } = suggestionsSlice.actions;
