import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { Formik } from 'formik';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks/app-hooks';
import { sendMetrics } from '../../../redux/thunks/metrics-thunk';
import { closeOriginsBlacklistModal } from '../../../redux/thunks/origins-blacklist-thunk';
import { setOriginsToBlacklist } from '../../../redux/thunks/settings-thunk';
import { MixpanelEvent } from '../../../services/mixpanel/types';
import { ModalContainer } from '../Container/ModalContainer';

import { WebsiteInput } from './WebsiteInput';
import { WebsitesList } from './WebsitesList';

import styles from './WebsitesBlacklist.module.scss';

export interface IWebsitesBlacklistFormState {
    url: string;
    origins: string[];
}

export const WebsitesBlacklist: React.FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('translations');
    const visible = useAppSelector((state) => state.modals.originsBlacklist.visible);
    const originsBlacklist = useAppSelector((state) => state.settings.originsBlacklist);

    const handleCloseModal = () => {
        dispatch(closeOriginsBlacklistModal({ location: 'WebsitesBlacklistSettings', url: window.location.href }));
    };

    const initialFormState: IWebsitesBlacklistFormState = {
        url: '',
        origins: originsBlacklist,
    };

    const handleFormSubmit = async ({ origins }: IWebsitesBlacklistFormState) => {
        await dispatch(setOriginsToBlacklist(origins));

        if (origins.includes(window.location.origin)) {
            dispatch(
                sendMetrics({
                    event: MixpanelEvent.DEACTIVATE_FLOATING_APP,
                    meta: {
                        location: 'WebsitesBlacklistSettings',
                        url: window.location.href,
                    },
                })
            );
        }

        handleCloseModal();
    };

    return (
        <ModalContainer
            isOpen={visible}
            onClose={handleCloseModal}
            motionPreset="scale"
            scrollBehavior="inside"
            variant="websites-blacklist"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader className={styles.header}>{t('websites-blacklist.header')}</ModalHeader>
                <Formik initialValues={initialFormState} onSubmit={handleFormSubmit}>
                    {({ submitForm, isSubmitting }) => {
                        return (
                            <>
                                <div className={styles.inputWrapper}>
                                    <WebsiteInput />
                                </div>
                                <ModalBody className={styles.body}>
                                    <WebsitesList />
                                </ModalBody>
                                <ModalFooter className={styles.footer}>
                                    <Button variant="secondary" onClick={handleCloseModal} isDisabled={isSubmitting}>
                                        {t('button.cancel')}
                                    </Button>
                                    <Button variant="primary" onClick={submitForm} isLoading={isSubmitting}>
                                        {t('button.apply')}
                                    </Button>
                                </ModalFooter>
                            </>
                        );
                    }}
                </Formik>
            </ModalContent>
        </ModalContainer>
    );
};
