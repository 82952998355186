import { combineSlices } from '@reduxjs/toolkit';

import { baseApi } from '../api/base-api';

import { annotateAnswersSlice } from './slices/annotate-answers/annotate-answers-slice';
import { answersSlice } from './slices/answers/answers-slice';
import { appSlice } from './slices/app/app-slice';
import { authSlice } from './slices/auth/auth-slice';
import { floatingIconSlice } from './slices/floating-icon/floating-icon-slice';
import { metricsSlice } from './slices/metrics/metrics-slice';
import { annotateAnswerModalSlice } from './slices/modals/annotate-answer-modal-slice';
import { annotateTagsModalSlice } from './slices/modals/annotate-tags-modal-slice';
import { answerFeedbackModalSlice } from './slices/modals/answer-feedback-modal-slice';
import { askAiKbModalSlice } from './slices/modals/ask-ai-kb-modal-slice';
import { askAnExpertModalSlice } from './slices/modals/ask-an-expert-modal-slice';
import { chatEditModalSlice } from './slices/modals/chat-edit-model-slice';
import { directAnswerEditModalSlice } from './slices/modals/direct-answer-edit-modal-slice';
import { directAnswerFeedbackModalSlice } from './slices/modals/direct-answer-feedback-modal-slice';
import { feedBackModalSlice } from './slices/modals/feedback-modal-slice';
import { originsBlacklistModalSlice } from './slices/modals/origins-blacklist-modal-slice';
import { pointsModalSlice } from './slices/modals/points-modal-slice';
import { previewModalSlice } from './slices/modals/preview-modal-slice';
import { requestKnowledgeModalSlice } from './slices/modals/request-knowledge-modal-slice';
import { settingsModalSlice } from './slices/modals/settings-modal-slice';
import { sourcesFilterSlice } from './slices/modals/sources-filter-modal-slice';
import { successModalSlice } from './slices/modals/success-modal-slice';
import { notificationsSlice } from './slices/notifications/notifications-slice';
import { proactiveAnswersSlice } from './slices/proactive-answers/proactive-answers-slice';
import { questionSlice } from './slices/question/question-slice';
import { resizeSlice } from './slices/resize/resize-slice';
import { selectionSlice } from './slices/selection/selection-slice';
import { settingsSlice } from './slices/settings/settings-slice';
import { smartFiltersSlice } from './slices/smart-filters/smart-filters-slice';
import { suggestionsSlice } from './slices/suggestions/suggestions-slice';
import { tabsSlice } from './slices/tabs/tabs-slice';
import { trainingEnablementSlice } from './slices/training-enablement/training-enablement-slice';
import { uatSlice } from './slices/uat/uat-slice';
import { isReplaceStateAction } from './helpers';

export interface DynamicallyLoadedSlices {}

const sliceReducers = combineSlices({
    [baseApi.reducerPath]: baseApi.reducer,
    app: appSlice.reducer,
    tabs: tabsSlice.reducer,
    auth: authSlice.reducer,
    settings: settingsSlice.reducer,
    question: questionSlice.reducer,
    answers: answersSlice.reducer,
    proactiveAnswers: proactiveAnswersSlice.reducer,
    suggestions: suggestionsSlice.reducer,
    resize: resizeSlice.reducer,
    metrics: metricsSlice.reducer,
    selection: selectionSlice.reducer,
    floatingIcon: floatingIconSlice.reducer,
    annotateAnswers: annotateAnswersSlice.reducer,
    notifications: notificationsSlice.reducer,
    uat: uatSlice.reducer,
    smartFilters: smartFiltersSlice.reducer,
    trainingEnablement: trainingEnablementSlice.reducer,
    modals: combineSlices({
        answerFeedback: annotateAnswerModalSlice.reducer,
        annotateDirectAnswer: directAnswerFeedbackModalSlice.reducer,
        requestKnowledge: requestKnowledgeModalSlice.reducer,
        editDirectAnswer: directAnswerEditModalSlice.reducer,
        chatEdit: chatEditModalSlice.reducer,
        annotate: answerFeedbackModalSlice.reducer,
        preview: previewModalSlice.reducer,
        settings: settingsModalSlice.reducer,
        originsBlacklist: originsBlacklistModalSlice.reducer,
        feedback: feedBackModalSlice.reducer,
        points: pointsModalSlice.reducer,
        success: successModalSlice.reducer,
        annotateTags: annotateTagsModalSlice.reducer,
        askAnExpert: askAnExpertModalSlice.reducer,
        sourcesFilter: sourcesFilterSlice.reducer,
        askAiKb: askAiKbModalSlice.reducer,
    }),
}).withLazyLoadedSlices<DynamicallyLoadedSlices>();

type ReducerParameters = Parameters<typeof sliceReducers>;
const withReplaceStateReducer = (state: ReducerParameters[0], action: ReducerParameters[1]) => {
    if (isReplaceStateAction(action)) {
        return sliceReducers(action.payload, action);
    }

    return sliceReducers(state, action);
};

export const coreReducer: typeof sliceReducers = Object.assign(withReplaceStateReducer, {
    inject: sliceReducers.inject,
    selector: sliceReducers.selector,
    withLazyLoadedSlices: sliceReducers.withLazyLoadedSlices,
});
