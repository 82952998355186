import { useEffect } from 'react';

export enum ShortcutModifier {
    ALT = 'Alt',
    CTRL = 'Ctrl',
    SHIFT = 'Shift',
}

export interface IShortcutDefinition {
    key: string;
    modifiers: ShortcutModifier[];
}

export type ShortcutCallback = (event: KeyboardEvent) => void;

export interface IShortcutsMap extends Record<string, ShortcutCallback> {}

export type CompliedShortcuts = Map<IShortcutDefinition, ShortcutCallback>;

export const compileShortcuts = (map: IShortcutsMap): CompliedShortcuts => {
    const definitions: CompliedShortcuts = new Map();
    Object.entries(map).forEach(([key, value]) => {
        const parts = key.split('+');
        const [keyboardKey, ...rest] = parts;
        const definition: IShortcutDefinition = {
            key: keyboardKey,
            modifiers: rest.reduce<ShortcutModifier[]>((result, modifier) => {
                result.push(modifier as ShortcutModifier);

                return result;
            }, []),
        };

        definitions.set(definition, value);
    });

    return definitions;
};

export const handleShortcut = (shortcuts: CompliedShortcuts, event: KeyboardEvent) => {
    const definition = Array.from(shortcuts.keys()).find((def) => {
        const keyConverted = KeysConversionMap[def.key] ?? def.key;

        if (keyConverted === event.key || keyConverted === '*') {
            if (def.modifiers.length === 0) {
                return true;
            }

            return def.modifiers.every((modifier) => {
                switch (modifier) {
                    case ShortcutModifier.ALT:
                        return event.altKey;
                    case ShortcutModifier.CTRL:
                        return event.ctrlKey;
                    case ShortcutModifier.SHIFT:
                        return event.shiftKey;
                    default:
                        return false;
                }
            });
        }
        return false;
    });

    if (definition) {
        const callback = shortcuts.get(definition);

        callback && callback(event);
    }
};

export const useShortcut = (map: IShortcutsMap, node: Document | HTMLElement | null) => {
    useEffect(() => {
        const definitions = compileShortcuts(map);

        const handleKeyDown = (ev: Event) => {
            if (ev.type !== 'keydown') {
                return;
            }

            const event = ev as KeyboardEvent;

            handleShortcut(definitions, event);
        };

        node && node.addEventListener('keydown', handleKeyDown);

        return () => {
            node && node.removeEventListener('keydown', handleKeyDown);
        };
    }, [map, node]);
};

export const SHORTCUT_LEFT_ARROW = 'Left';
export const SHORTCUT_RIGHT_ARROW = 'Right';
export const SHORTCUT_UP_ARROW = 'Up';
export const SHORTCUT_DOWN_ARROW = 'Down';
export const SHORTCUT_ENTER = 'Enter';
export const SHORTCUT_PLUS = 'Add';
export const SHORTCUT_MINUS = 'Subtract';
export const SHORTCUT_PRE_BACKED_QUESTIONS = '*+Ctrl+Alt';
export const SHORTCUT_COPY_LINK = 'C+Shift';
export const BACK_SPACE = 'Backspace';
export const SHORTCUT_MOVE_TO_ASK_AI_PROJECT = 'A+Shift+Ctrl';

export const KeysConversionMap: Record<string, string> = {
    Add: '+',
    Subtract: '-',
    Left: 'ArrowLeft',
    Right: 'ArrowRight',
    Up: 'ArrowUp',
    Down: 'ArrowDown',
};
