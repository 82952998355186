import React from 'react';
import { IAnnotateAnswerChunk } from 'answers-core/src/api/types';

import { CommentAnswer } from './CommentAnswer/CommentAnswer';
import { LabelAnswer } from './LabelAnswer/LabelAnswer';

import styles from './AnnotateAnswerActions.module.scss';

export interface IAnswerActionsProps {
    chunk: IAnnotateAnswerChunk;
}

export const AnnotateAnswerActions: React.FC<IAnswerActionsProps> = ({ chunk }) => {
    return (
        <div className={styles.wrapper}>
            <CommentAnswer chunk={chunk} />
            <LabelAnswer chunk={chunk} />
        </div>
    );
};
