import { ReactElement } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ISuccessModalState {
    visible: boolean;
    header: string;
    message: string;
    description: string | ReactElement;
    link?: {
        url: string;
        label: string;
        clickCallback: () => void;
    };
}

const initialState: ISuccessModalState = {
    visible: false,
    header: '',
    message: '',
    description: '',
};

export const successModalSlice = createSlice({
    name: 'success-modal',
    initialState,
    reducers: {
        openSuccessModal(state, action: PayloadAction<Omit<ISuccessModalState, 'visible'>>) {
            const {
                payload: { header, message, description, link },
            } = action;
            state.header = header;
            state.message = message;
            state.description = description;
            state.link = link;
            state.visible = true;
        },
        closeSuccessModal(state) {
            state.visible = false;
        },
    },
});

export const { openSuccessModal, closeSuccessModal } = successModalSlice.actions;
