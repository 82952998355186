import React from 'react';
import { useRadio, UseRadioProps } from '@chakra-ui/react';
import cx from 'clsx';

import styles from './LabelAnswer.module.scss';

export interface ILabelRadioProps extends UseRadioProps {
    label: React.ReactNode;
    decimal?: boolean;
    forceChecked?: boolean;
    previouslyChecked?: boolean;
    defaultStyles?: React.CSSProperties;
    checkedStyles?: React.CSSProperties;
}

export const LabelRadio: React.FC<ILabelRadioProps> = (props) => {
    const { label, defaultStyles, checkedStyles, decimal, forceChecked, previouslyChecked, ...radioProps } = props;
    const { getInputProps, getCheckboxProps, htmlProps } = useRadio(radioProps);

    const inputProps = getInputProps();

    const style = React.useMemo(() => {
        if (props.isChecked || forceChecked) {
            return checkedStyles;
        }

        return defaultStyles;
    }, [defaultStyles, checkedStyles, props.isChecked, forceChecked]);

    const content = decimal ? null : label;

    return (
        <label {...htmlProps} className={styles.radioLabel}>
            <input {...inputProps} hidden />
            <div
                {...getCheckboxProps()}
                className={cx(styles.radioViewInput, {
                    [styles.decimalVieInput]: decimal,
                    [styles.previouslyChecked]: previouslyChecked,
                })}
                style={style}
            >
                {content}
            </div>
        </label>
    );
};
