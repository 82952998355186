import React from 'react';

import { useAppSelector } from '../../../redux/hooks/app-hooks';
import { getUserName } from '../../../utils/user';
import { UserAvatar } from '../Avatar/UserAvatar';

import styles from './UserDetails.module.scss';

export const UserDetails: React.FC = () => {
    const { user } = useAppSelector((state) => state.auth);

    return (
        <div className={styles.wrapper}>
            <UserAvatar className={styles.avatar} />
            <div className={styles.details}>
                <span className={styles.name}>{getUserName(user)}</span>
                <span>{user.email}</span>
            </div>
        </div>
    );
};
