import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLazyGetUserQuery } from '../../../api/endpoints/users-endpoint';
import { useAppDispatch } from '../../../redux/hooks/app-hooks';
import { loginSSO } from '../../../redux/thunks/user-thunk';
import { LoginView } from '../LoginView/LoginView';

import { PopupAlert } from './PopupAlert/PopupAlert';

export const LoginForm: React.FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('translations');

    const [getUser, { isLoading }] = useLazyGetUserQuery();
    const [error, setError] = useState<string | undefined>(undefined);
    const [shouldShowPopupAlert, setShouldShowPopupAlert] = useState<boolean>(false);

    const handleFormSubmit = async () => {
        setShouldShowPopupAlert(false);
        const errorMessage = t('error.api.general', { ns: 'errors' });

        try {
            setError(undefined);

            setShouldShowPopupAlert(true);
            await dispatch(loginSSO());
            getUser();
            setShouldShowPopupAlert(false);
        } catch (e: unknown) {
            setError(errorMessage);
        }
    };

    return (
        <LoginView
            onLoginClick={handleFormSubmit}
            error={error}
            footer={shouldShowPopupAlert && <PopupAlert />}
            loading={shouldShowPopupAlert || isLoading}
        />
    );
};
