import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { useGetSettingsQuery } from '../../api/endpoints/settings-endpoint';
import { IAppDispatch, IRootState } from '../core-store';

export const useAppDispatch = () => useDispatch<IAppDispatch>();
export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector;

export const useAuth = () => {
    const token = useAppSelector((state) => state.auth.token);

    return [token !== null];
};

export const useAppInit = () => {
    const { initialized, loading } = useAppSelector((state) => state.app);
    const { isFetching: isSettingsLoading, error: settingsLoadingError } = useGetSettingsQuery(undefined, {
        skip: !initialized,
    });

    return [!isSettingsLoading && initialized && !loading, settingsLoadingError];
};
