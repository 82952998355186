import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import { prepareShowedForMap } from '../../../utils/proactive-answers/proactive-answer';
import { setSidebarVisibleThunk } from '../../thunks/app-thunk';
import { setDisableOnlineMemory, setProject } from '../../thunks/settings-thunk';
import { logout } from '../../thunks/user-thunk';
import { IProactiveAnswersState, ProactiveShowedForEntries } from '../../types';
import { isClearAnswersAction } from '../question/question-slice';

const initialState: IProactiveAnswersState = {
    questionId: '',
    sessionId: null,
    isTimerEnabled: true,
    isLoading: false,
    loadingMessage: '',
    resultReady: false,
    directAnswerReady: false,
    // temporary field for storing the last result from the proactive answers,
    // so they can be stored in the local storage when a user opens the sidebar
    pendingToSave: null,
    floatingPopupVisible: false,
    noDirectAnswerBannerVisible: true,
    proactiveFrequencyEnabled: false,
    directAnswerPopupEnabled: false,
    floatingAnimationEnabled: false,
    showedForTTL: undefined,
    showedFor: {},
};

interface ISetFloatingPopupVisiblePayload {
    visible: boolean;
    byUser: boolean;
}

export const proactiveAnswersSlice = createSlice({
    name: 'proactive-answers',
    initialState,
    reducers: {
        setQuestionId(state, action: PayloadAction<string>) {
            state.questionId = action.payload;
        },
        setSessionId(state, action: PayloadAction<IProactiveAnswersState['sessionId']>) {
            state.sessionId = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setLoadingMessage(state, action: PayloadAction<string>) {
            state.loadingMessage = action.payload;
        },
        setIsTimerEnabled(state, action: PayloadAction<boolean>) {
            state.isTimerEnabled = action.payload;
        },
        setResultReady(state, action: PayloadAction<boolean>) {
            const isReady = action.payload;
            state.resultReady = isReady;

            if (!isReady) {
                state.questionId = '';
            }
        },
        _setProactiveDirectAnswerReady(state, action: PayloadAction<boolean>) {
            state.directAnswerReady = action.payload;
        },
        setPendingToSave(state, action: PayloadAction<IProactiveAnswersState['pendingToSave']>) {
            state.pendingToSave = action.payload;
        },
        clearPendingToSave(state) {
            state.pendingToSave = null;
        },
        setFloatingPopupVisible(state, action: PayloadAction<ISetFloatingPopupVisiblePayload>) {
            const { visible } = action.payload;

            state.floatingPopupVisible = visible;
        },
        setProactiveNoDirectAnswerBannerVisible(state, action: PayloadAction<boolean>) {
            state.noDirectAnswerBannerVisible = action.payload;
        },
        setProactiveFrequencyEnabled(state, action: PayloadAction<boolean>) {
            state.proactiveFrequencyEnabled = action.payload;
        },
        setDirectAnswerPopupEnabled(state, action: PayloadAction<boolean>) {
            state.directAnswerPopupEnabled = action.payload;
        },
        setFloatingAnimationEnabled(state, action: PayloadAction<boolean>) {
            state.floatingAnimationEnabled = action.payload;
        },
        _setShowedForTTL(state, action: PayloadAction<IProactiveAnswersState['showedForTTL']>) {
            state.showedForTTL = action.payload;
        },
        _setTicketVisited(state, action: PayloadAction<string>) {
            const id = action.payload;

            state.showedFor[id] = Date.now();
        },
        updateShowedFor(state, action: PayloadAction<ProactiveShowedForEntries>) {
            state.showedFor = prepareShowedForMap(action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setSidebarVisibleThunk.fulfilled, (state, action) => {
            const visible = action.payload;

            if (visible) {
                state.floatingPopupVisible = false;
            }
        });
        builder.addMatcher(isClearPendingToSaveAction, (state) => {
            state.pendingToSave = null;
        });
        builder.addMatcher(isClearAnswersAction, (state) => {
            state.noDirectAnswerBannerVisible = false;
            state.sessionId = null;
        });
    },
});

export const isClearPendingToSaveAction = isAnyOf(
    logout.fulfilled,
    setProject.fulfilled,
    setDisableOnlineMemory.fulfilled
);

export const {
    setQuestionId,
    setSessionId,
    setLoading,
    setLoadingMessage,
    setIsTimerEnabled,
    setResultReady,
    _setProactiveDirectAnswerReady,
    setPendingToSave,
    clearPendingToSave,
    setFloatingPopupVisible,
    setProactiveNoDirectAnswerBannerVisible,
    setProactiveFrequencyEnabled,
    setDirectAnswerPopupEnabled,
    setFloatingAnimationEnabled,
    _setShowedForTTL,
    _setTicketVisited,
    updateShowedFor,
} = proactiveAnswersSlice.actions;
