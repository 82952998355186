import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { ReactComponent as CloseIcon } from 'answers-core/src/assets/icons/close.svg';
import { ReactComponent as HintIcon } from 'answers-core/src/assets/icons/help.svg';
import { ReactComponent as ClipIcon } from 'answers-core/src/assets/icons/paperclip.svg';
import { Tooltip } from 'answers-core/src/components/Tooltips/Tooltip';
import { FieldProps } from 'formik';

import styles from './FileInput.module.scss';

interface FileInputProps extends FieldProps {
    tooltip: string;
    accept: string;
}

export const FileInput: React.FC<FileInputProps> = ({ field, meta, form, tooltip, accept }) => {
    const { t } = useTranslation('translations');
    const value: File[] = field.value ?? [];
    const fileInputRef = useRef<HTMLInputElement>(null);
    const isInvalid = !!meta.error;

    const onClick = useCallback(() => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }, []);

    const onChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const newFiles = Array.from(event.target.files ?? []);
            form.setFieldValue(field.name, [...field.value, ...newFiles]);
        },
        [field.name, field.value, form]
    );

    const onDelete = (index: number) => {
        form.setFieldValue(
            field.name,
            field.value.filter((_: File, i: number) => i !== index)
        );
    };

    return (
        <FormControl isInvalid={isInvalid} className={styles.formControl}>
            <FormLabel size="xs">
                {t('files-upload.label')}
                <Tooltip label={tooltip}>
                    <HintIcon />
                </Tooltip>
            </FormLabel>
            <button className={styles.placeholder} onClick={onClick}>
                {t('files-upload.placeholder')}
                <ClipIcon />
            </button>
            <ul className={styles.fileList}>
                {value.map((file, index) => (
                    <li key={file.name + index} className={styles.fileItem}>
                        <button className={styles.deleteFile} onClick={() => onDelete(index)}>
                            <CloseIcon width={5} height={5} />
                        </button>
                        <div className={styles.fileName}>{file.name}</div>
                    </li>
                ))}
            </ul>
            <input ref={fileInputRef} type="file" onChange={onChange} hidden multiple accept={accept} />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    );
};
