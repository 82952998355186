const ASK_AI_MESSAGE_TARGET = 'Ask-Ai-Preview';

const extractMessage = (event: MessageEvent): string | undefined => {
    try {
        const message = JSON.parse(event.data);
        if ('target' in message && message.target === ASK_AI_MESSAGE_TARGET) {
            return message.payload;
        }
    } catch (_) {}

    return undefined;
};

export const isDomContentLoadedMessage = (event: MessageEvent): boolean => extractMessage(event) === 'DOMContentLoaded';
