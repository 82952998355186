import { modalAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

import { isAnnotateWebMode, isPortalWebMode } from '../../utils/extension-mode';

import { withCustomScrollbar } from './consts';

import styles from './theme.module.scss';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(modalAnatomy.keys);

const portalWebMode = isPortalWebMode();
const annotateWebMode = isAnnotateWebMode();

const baseStyle = definePartsStyle({
    overlay: defineStyle({
        position: 'absolute',
        backgroundColor: styles.overlayBackground,
        width: '100%',
    }),
    dialog: defineStyle({
        margin: '24px',
        borderRadius: '16px',
        fontSize: undefined,
    }),
    dialogContainer: defineStyle({
        position: 'absolute',
        width: '100%',
    }),
    header: defineStyle({
        borderRadius: '16px 16px 0 0',
        height: '56px',
        background:
            'linear-gradient(0deg, #F6FAFF, #F6FAFF), linear-gradient(180deg, rgba(245, 245, 245, 0) 0%, #F5F5F5 68.75%, #F5F5F5 100%)',
        fontWeight: 500,
        fontSize: '2em',
        lineHeight: '24px',
    }),
    body: defineStyle({
        padding: '24px',
        ...withCustomScrollbar,
    }),
    footer: {
        height: '80px',
        borderTop: `1px solid rgba(137, 153, 177, 0.4)`,
        gap: '10px',
        justifyContent: 'center',
    },
});

const variants = {
    preview: definePartsStyle({
        header: defineStyle({
            padding: '12px',
        }),
        footer: defineStyle({
            padding: '16px',
        }),
        dialog: defineStyle({
            borderRadius: '16px',
            minHeight: `calc(${annotateWebMode ? styles.appHeightWebAnnotate : styles.appHeight} - 48px)`,
            maxWidth: 'none',
        }),
    }),
    'preview-sidebar': definePartsStyle({
        header: defineStyle({
            padding: '12px',
        }),
        footer: defineStyle({
            padding: '16px',
        }),
        dialog: defineStyle({
            borderRadius: '16px',
            maxWidth: 'none',
            height: `calc(100vh - 16px)`,
            maxHeight: 'none',
        }),
    }),
    'force-feedback': definePartsStyle({
        dialog: defineStyle({
            margin: '10% 8px 8px',
        }),
        body: defineStyle({
            display: 'flex',
            flexDirection: 'column',
        }),
    }),
    'sources-filter': definePartsStyle({
        dialog: defineStyle({
            maxWidth: '520px',
        }),
        body: defineStyle({
            padding: '8px 16px',
        }),
    }),
    'websites-blacklist': definePartsStyle({
        header: defineStyle({
            fontSize: '1.9em',
        }),
        dialog: defineStyle({
            height: '100%',
        }),
        body: defineStyle({
            borderTopWidth: '1px',
            borderTopColor: styles.borderLightGray,
            borderTopStyle: 'solid',
            padding: '0 16px 24px',
        }),
    }),
    'add-knowledge': {
        dialog: defineStyle({
            height: portalWebMode ? '80dvh' : '100%',
            maxHeight: 'auto',
            margin: portalWebMode ? 'auto' : 0,
            overflow: 'hidden',
            borderRadius: portalWebMode ? '16px' : 0,
            background: 'transparent',
        }),
        footer: defineStyle({
            height: '49px',
            padding: '8px',
            borderTop: '1px solid',
            borderColor: styles.borderBlueGray,
            gap: '10px 8px',
        }),
    },
    confirm: {
        dialog: defineStyle({
            maxHeight: 'auto',
            borderRadius: '16px',
            maxWidth: '328px',
            overflow: 'auto',
        }),
    },
};

const sizes = {
    sidebar: definePartsStyle({
        dialog: defineStyle({
            margin: '8px',
        }),
    }),
};

export const modalTheme = defineMultiStyleConfig({ baseStyle, variants, sizes });
