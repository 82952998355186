import browser, { Runtime } from 'webextension-polyfill';

import { EVENT_BUS_PORT_NAME } from '../constants';
import { EventBusListenersMap, IEventBus, IEventBusMessage } from '../types';

type ListenersMap = {
    [K in keyof EventBusListenersMap]?: Set<EventBusListenersMap[K]>;
};

export default class EventBusRuntime implements IEventBus<EventBusListenersMap> {
    private port: Runtime.Port | null = null;

    private listenersMap: ListenersMap = {};

    constructor() {
        this.initPort();
        this.trackBFCacheRestoration();
    }

    private initPort() {
        if (this.port) {
            return;
        }

        this.port = browser.runtime.connect({ name: EVENT_BUS_PORT_NAME });
        this.port.onMessage.addListener(this.onMessage);
        this.port.onDisconnect.addListener(() => {
            this.disconnect();
            this.initPort();
        });
    }

    private disconnect() {
        this.port?.disconnect();
        this.port?.onMessage.removeListener(this.onMessage);
        this.port = null;
    }

    private trackBFCacheRestoration() {
        if (typeof window !== 'undefined') {
            window.addEventListener('pageshow', ({ persisted }) => {
                if (persisted) {
                    this.disconnect();
                    this.initPort();
                }
            });
        }
    }

    private onMessage = <K extends keyof EventBusListenersMap>(message: IEventBusMessage<K>) => {
        const { type, payload } = message;
        const listeners = this.listenersMap[type];

        if (listeners) {
            listeners.forEach((listener) => listener.apply(this, payload));
        }
    };

    private getListeners<K extends keyof EventBusListenersMap>(type: K): Set<EventBusListenersMap[K]> {
        const listeners = this.listenersMap[type];

        if (listeners) {
            return listeners;
        }

        const newListeners = new Set<EventBusListenersMap[K]>();
        this.listenersMap[type] = newListeners;

        return newListeners;
    }

    public addListener<K extends keyof EventBusListenersMap>(type: K, listener: EventBusListenersMap[K]) {
        const listeners = this.getListeners(type);
        listeners.add(listener);
    }

    public removeListener<K extends keyof EventBusListenersMap>(type: K, listener: EventBusListenersMap[K]) {
        const listeners = this.getListeners(type);
        listeners.delete(listener);
    }
}
