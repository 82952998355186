import { GroupBase, StylesConfig, Theme, ThemeConfig } from 'react-select';

import { OptionType } from '../../components/Controls/Select/Select';

import styles from './theme.module.scss';

export const getTheme: ThemeConfig = (theme: Theme) => ({
    ...theme,
    borderRadius: 4,
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)',
    colors: {
        ...theme.colors,
        primary: styles.hoverBlue,
    },
});

export const getStyles: StylesConfig<OptionType, boolean, GroupBase<OptionType>> = {
    multiValue: (base) => ({
        ...base,
        backgroundColor: styles.chipBlue,
    }),
    multiValueLabel: (base) => ({
        ...base,
        color: styles.chipLabelBlue,
    }),
    multiValueRemove: (base) => ({
        ...base,
        color: styles.chipLabelBlue,
        ':hover': {
            backgroundColor: 'transparent',
        },
    }),
};
