import React from 'react';
import { Tooltip as ChakraTooltip, TooltipProps } from '@chakra-ui/react';

import { AppContainerContext } from '../App/app-container-context';

import { tooltipModifiers } from './tooltip-modifiers';

import styles from './Tooltip.module.scss';

export interface ITooltipProps {
    children: React.ReactNode;
    label: TooltipProps['label'];
    hasArrow?: boolean;
    placement?: TooltipProps['placement'];
    offset?: TooltipProps['offset'];
    isOpen?: boolean;
    isDisabled?: TooltipProps['isDisabled'];
    openDelay?: TooltipProps['openDelay'];
}

export const Tooltip: React.FC<ITooltipProps> = ({
    label,
    children,
    hasArrow = false,
    isOpen,
    offset,
    placement = 'top-start',
    isDisabled,
    openDelay,
}) => {
    const renderLabel = () => {
        return <div className={styles.label}>{label}</div>;
    };

    return (
        <AppContainerContext.Consumer>
            {(value) => (
                <ChakraTooltip
                    isOpen={isOpen}
                    hasArrow={hasArrow}
                    label={renderLabel()}
                    placement={placement}
                    offset={offset}
                    portalProps={{ containerRef: value }}
                    bg={styles.backgroundColor}
                    className={styles.wrapper}
                    modifiers={tooltipModifiers}
                    isDisabled={isDisabled}
                    openDelay={openDelay}
                >
                    {children}
                </ChakraTooltip>
            )}
        </AppContainerContext.Consumer>
    );
};
