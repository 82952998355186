import HIGHLIGHT_POINTER_IMAGE from '../assets/icons/ask-ai-pointer.svg';
import HIGHLIGHT_POINTER_IMAGE_ROTATED from '../assets/icons/ask-ai-pointer-rotated.svg';
import { getExtensionURLPrefix } from '../services/resources/resources-service';

import styles from './chakra/theme.module.scss';

export const HIGHLIGHT_POINTER_CSS_CLASS = 'ask-ai-highlight-pointer';
export const HIGHLIGHT_POINTER_ROTATED_CSS_CLASS = 'ask-ai-highlight-pointer-rotated';
export const HIGHLIGHT_POINTER_HEIGHT = styles.previewHighlightPointHeight;

export const getPreviewStyles = () => {
    return `
        <style>
            @import url("https://fonts.googleapis.com/css?family=Inter:400,500,600,700");
            
            @media only screen and (max-width: 284px) {
                body {
                    transform: scale(0.9);
                    transform-origin: top center;
                    margin-inline: -5% !important; /* is to compensate scale factor of 10%, important is to "beat" the style from the BE */
                }
            }
            
            html {
                padding: 20px;
            }
            
            body {
                font-family: Inter,system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue";
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
                margin: 0;
            }
            
            h1, h2, h3, h4, h5, h6 {
                margin-top: 0;
                margin-bottom: 0.5rem;
            }
            
            h1, h2 {
                font-weight: 700;
                font-size: 20px;
                line-height: 25px;
            }
        
            h3, h3[id^="m-"], h4 {
                font-size: 14px;
                line-height: 22px;
            }
        
            a {
                text-decoration: underline;
                color: ${styles.linkPrimary};
            }
            
            a:hover {
              text-decoration: underline;
            }
            
            img {
                max-width: 100%;
                height: auto!important;
            }
        
            body::-webkit-scrollbar {
                width: ${styles.scrollBarWidth};
                height: ${styles.scrollBarWidth};
                background: ${styles.scrollBarBackground};
            }
            
            body::-webkit-scrollbar-thumb {
                background: ${styles.scrollBarTrack};
                border-radius: 3px;
            }
            
            iframe[src^="https://player.vimeo.com/video/"],
            iframe[src^="https://www.youtube.com/embed/"],
            iframe[src^="https://fast.wistia.com/embed/iframe/"] {
                position: static;
                display: block;
                margin-inline: auto;
                width: 100%;
                max-width: 100%;
                aspect-ratio: 16 / 9;
                
                /* 560px is a width of the video from the example in the ticket */
                @media only screen and (min-width: 561px) {
                    max-width: 560px;
                }
            }
            
        </style>
   `;
};

export const getPointerStyles = () => {
    return `
        <style>
            .${HIGHLIGHT_POINTER_CSS_CLASS} {
                position: relative;
            }
            
            .${HIGHLIGHT_POINTER_CSS_CLASS}:before {
                content: " ";
                display: block;
                width: ${styles.previewHighlightPointWidth};
                height: ${styles.previewHighlightPointHeight};
                background: 50% 0 no-repeat url('${getExtensionURLPrefix()}${HIGHLIGHT_POINTER_IMAGE}');
                filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.25));
                position: absolute;
                top: ${'-'}${styles.previewHighlightPointHeight};
                left: 0;
            }
    
            .${HIGHLIGHT_POINTER_ROTATED_CSS_CLASS}:before {
                background: 50% 0 no-repeat url('${getExtensionURLPrefix()}${HIGHLIGHT_POINTER_IMAGE_ROTATED}');
            }
        </style>
    `;
};
