import { IAuthPermissions } from '../redux/types';

const preCannedQuestionsAllowedFor: string[] = process.env.REACT_APP_PRE_CANNED_QUESTIONS_ALLOWED_FOR?.split(',') ?? [];
const disableOnlineMemoryAllowedFor: string[] = process.env.REACT_APP_ONLINE_MEMORY_ALLOWED_FOR?.split(',') ?? [];
const changeBackendAllowedFor: string[] = process.env.REACT_APP_CHANGE_BACKEND_ALLOWED_FOR?.split(',') ?? [];
const changeProactiveAllowedFor: string[] = process.env.REACT_APP_CHANGE_PROACTIVE_ALLOWED_FOR?.split(',') ?? [];

const hasRole = (roles: string[], requiredRole: string): boolean => {
    return roles.includes(requiredRole);
};

const hasOneOfRoles = (roles: string[], requiredRoles: string[]): boolean => {
    return requiredRoles.some((requiredRole) => hasRole(roles, requiredRole));
};

export const getPermissionsFromRoles = (roles: string[]): IAuthPermissions => {
    return {
        preCannedQuestionsAccess: hasOneOfRoles(roles, preCannedQuestionsAllowedFor),
        disableOnlineMemory: hasOneOfRoles(roles, disableOnlineMemoryAllowedFor),
        changeBackendApi: hasOneOfRoles(roles, changeBackendAllowedFor),
        debugProactiveAccess: hasOneOfRoles(roles, changeProactiveAllowedFor),
    };
};
