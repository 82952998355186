import { marked } from 'marked';
import TurndownService, { Options } from 'turndown';

export const markdownToHtml = (markdownText: string, inline = false): string => {
    if (inline) {
        // https://marked.js.org/using_advanced#inline
        // this would not wrap with <p></p>
        return marked.parseInline(markdownText) as string;
    }
    return marked.parse(markdownText) as string;
};

export function htmlToMarkdown(htmlText: string, convertorOptions?: Options) {
    const converter = new TurndownService(convertorOptions);
    // Quill uses OL element for both bullet list and ordered list
    // https://quilljs.com/guides/upgrading-to-2-0
    converter.addRule('dataListBullet', {
        filter: function (node, _options) {
            return (
                node.nodeName === 'LI' &&
                node.parentNode?.nodeName === 'OL' &&
                node.getAttribute('data-list') === 'bullet'
            );
        },
        replacement: function (content, node, options) {
            return options.bulletListMarker + ' ' + content + '\n';
        },
    });
    converter.addRule('underline', {
        filter: ['u'],
        replacement: function (content) {
            return '<u>' + content + '</u>';
        },
    });
    converter.addRule('newline', {
        filter: ['br'],
        replacement: function () {
            return '<br>';
        },
    });
    return converter.turndown(htmlText);
}
