import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '@chakra-ui/react';

import { ReactComponent as AskAiLogo } from '../../assets/icons/ask-ai-logo-text.svg';

import styles from './LoginLayout.module.scss';

export interface ILoginLayoutProps {
    children: React.ReactElement;
}

export const LoginLayout: React.FC<ILoginLayoutProps> = ({ children }) => {
    const { t } = useTranslation('translations');
    const year = new Date().getFullYear();

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.logo}>
                    <AskAiLogo />
                </div>
                <Heading as="h1" size="h1" className={styles.tagline}>
                    {t('app.tagline')}
                </Heading>
            </div>
            <div className={styles.bevel}></div>
            {children}
            <div className={styles.footer}>
                <span>{t('app.copyright', { year })}</span>
            </div>
        </div>
    );
};
