import { createAsyncThunk } from '@reduxjs/toolkit';

import packageJson from '../../../package.json';
import { usageMetricsEndpoint } from '../../api/endpoints/usage-metrics-endpoint';
import { getCurrentUrlDetails } from '../../api/endpoints/utils/api-answers-utils';
import { getFeatureByActionType } from '../../api/endpoints/utils/api-direct-answer-utils';
import { IGetAnswers, IUseSource, UsageMetricsEventType } from '../../api/types';
import { datesFilterMapping } from '../../components/Modals/SourcesFilter/DatesFilter/helpers';
import { mixpanelService } from '../../services/mixpanel/mixpanel-service';
import { MixpanelEvent, MixpanelEventProperties } from '../../services/mixpanel/types';
import { isPopupMode, isPortalWebMode, isWebMode } from '../../utils/extension-mode';
import { getLogRocketSessionUrlTimed } from '../../utils/get-logrocket-session-url';
import { prepareAnswersMetricsInfo } from '../../utils/metrics';
import { getUserProject } from '../../utils/user';
import { AppThunk, IRootState } from '../core-store';

const CLIENT_VERSION = packageJson.version;

export interface IMetricsThunkPayload {
    event: MixpanelEvent;
    meta?: MixpanelEventProperties;
    injectAnswersMetrics?: {
        answers?: IGetAnswers;
        sources: IUseSource[];
    };
    injectProactiveFrequencyMetrics?: {
        question?: string;
    };
}

export const getExtendedMeta = async (meta?: MixpanelEventProperties) => {
    let viewMode = 'floating';
    if (isPopupMode()) {
        viewMode = 'popup';
    } else if (isWebMode()) {
        viewMode = 'web';
    } else if (isPortalWebMode()) {
        viewMode = 'answers-web-app';
    }

    const extendedMeta: MixpanelEventProperties = {
        client_version: CLIENT_VERSION,
        view_mode: viewMode,
        ...getCurrentUrlDetails(),
        ...(meta || {}),
    };

    if (isPopupMode()) {
        try {
            // 0.5s timeout to wait for LogRocket to return the session URL
            extendedMeta.session_url = await getLogRocketSessionUrlTimed(500);
        } catch {}
    }

    return extendedMeta;
};

const backwardCompatibilityMap: Map<MixpanelEvent, UsageMetricsEventType> = new Map([
    [MixpanelEvent.OPEN_EXTENSION, UsageMetricsEventType.APPLICATION_OPEN],
    [MixpanelEvent.LOG_OUT_ANSWER, UsageMetricsEventType.APPLICATION_LOGOUT],
    [MixpanelEvent.OPEN_SETTINGS, UsageMetricsEventType.SETTINGS_OPEN],
    [MixpanelEvent.CONTACT_SUPPORT, UsageMetricsEventType.CONTACT_SUPPORT],
    [MixpanelEvent.CONTACT_SUPPORT_ERROR_NOTIFICATION, UsageMetricsEventType.CONTACT_SUPPORT_ERROR_NOTIFICATION],
]);

const mixpanelEventsPassedToUsageMetrics = new Set<MixpanelEvent>([
    // Backward compatibility
    MixpanelEvent.OPEN_EXTENSION,
    MixpanelEvent.LOG_OUT_ANSWER,
    MixpanelEvent.OPEN_SETTINGS,
    MixpanelEvent.CONTACT_SUPPORT,
    MixpanelEvent.CONTACT_SUPPORT_ERROR_NOTIFICATION,

    // Was interacted events
    MixpanelEvent.THUMB_UP,
    MixpanelEvent.THUMB_DOWN,
    MixpanelEvent.COPY_LINK,
    MixpanelEvent.COPY_SNIPPET_MAIN_PANEL,
    MixpanelEvent.SHOW_MORE_ANSWERS_MAIN_PANEL,
    MixpanelEvent.SHOW_MORE_IN_ANSWER,
    MixpanelEvent.VISIT_LINK,
    MixpanelEvent.OPEN_SMART_PREVIEW,
    MixpanelEvent.DIRECT_ANSWER_COPY,
    MixpanelEvent.DIRECT_ANSWER_COPY_WITH_ATTRIBUTION,
    MixpanelEvent.DIRECT_ANSWER_SHARE_CLICK,
    MixpanelEvent.DIRECT_ANSWER_SHARE_VIEWED,
    MixpanelEvent.WEBSITES_BLACKLIST_OPEN_SETTINGS,
    MixpanelEvent.WEBSITES_BLACKLIST_CLOSE_SETTINGS,
    MixpanelEvent.WEBSITES_BLACKLIST_ADD_WEBSITE,
    MixpanelEvent.WEBSITES_BLACKLIST_REMOVE_WEBSITE,

    // Proactive
    MixpanelEvent.PROACTIVE_INITIATED,
    MixpanelEvent.PROACTIVE_NOT_INITIATED,
    MixpanelEvent.PROACTIVE_CANCELED,
    MixpanelEvent.PROACTIVE_RECEIVED,
    MixpanelEvent.PROACTIVE_RECEIVED_NO_ANSWER,
    MixpanelEvent.PROACTIVE_GENERATIVE_STARTED,
    MixpanelEvent.PROACTIVE_SHOWN,
    MixpanelEvent.PROACTIVE_VIEWED,
    MixpanelEvent.PROACTIVE_POPUP_CLOSED,

    // Ask an expert
    MixpanelEvent.OPEN_ASK_AN_EXPERT_DIALOG,
    MixpanelEvent.ASKED_AN_EXPERT,
    MixpanelEvent.CANCEL_ASK_AN_EXPERT,
    MixpanelEvent.ASK_AN_EXPERT_OPEN_SLACK,

    // Chat tab
    MixpanelEvent.CHAT_TAB_SEND_MESSAGE,
    MixpanelEvent.CHAT_TAB_ATTRIBUTION_CLICK,
    MixpanelEvent.CHAT_MESSAGE_COPY,
    MixpanelEvent.CHAT_MESSAGE_THUMBS_UP,
    MixpanelEvent.CHAT_MESSAGE_THUMBS_DOWN,
]);

export const sendMetrics = createAsyncThunk(
    'metrics/common',
    async (payload: IMetricsThunkPayload, { dispatch, getState }) => {
        const state = getState() as IRootState;
        const isUatMode = state.uat.enabled;
        const { event, meta, injectAnswersMetrics, injectProactiveFrequencyMetrics } = payload;

        const {
            auth: { user },
            question: { question, question_id, proactiveMeta },
            answers: { activeAnswerUuid },
            metrics: { floatingEnabled },
            settings: {
                proactiveFrequencyCurrentGroup,
                proactiveFrequencySettings,
                languageCode,
                selectedSmartFilters,
                selectedDatesFilter,
            },
            smartFilters: { smartFiltersEnabled },
        } = state;
        const extendedMeta = await getExtendedMeta(meta);

        const getAnswersMeta = () => {
            if (!injectAnswersMetrics) {
                return undefined;
            }

            const { answers, sources } = injectAnswersMetrics;
            const activeAnswer = answers?.answers.find((answer) => answer.uuid === activeAnswerUuid);

            return {
                question,
                question_id,
                proactively_generated: proactiveMeta !== null,
                selected_answer: activeAnswer ?? {},
                ...prepareAnswersMetricsInfo(answers?.answers ?? []),
                sources,
            };
        };

        const getProactiveMeta = () => {
            if (!injectProactiveFrequencyMetrics) {
                return undefined;
            }

            const { proactiveFrequencyEnabled, directAnswerPopupEnabled, floatingAnimationEnabled, sessionId } =
                state.proactiveAnswers;
            const { timeouts } = proactiveFrequencySettings;
            const { score, name } = proactiveFrequencyCurrentGroup;
            const frequencyGroup = proactiveFrequencySettings.groups.find((group) => group.name === name);
            const questionForProactive = injectProactiveFrequencyMetrics.question ?? question;

            return {
                proactive: {
                    proactiveSessionId: sessionId,
                    proactiveFrequencyEnabled,
                    generativeAnswerPopupEnabled: directAnswerPopupEnabled,
                    twinkleAnimationEnabled: floatingAnimationEnabled,
                    ...(proactiveFrequencyEnabled && {
                        score,
                        group: frequencyGroup,
                        timeouts,
                    }),
                },
                ...(questionForProactive && {
                    question: questionForProactive,
                }),
            };
        };

        const getSelectedSmartFilters = () => {
            if (selectedSmartFilters.length === 0 || !smartFiltersEnabled) {
                return undefined;
            }

            return { selectedSmartFilters: selectedSmartFilters };
        };

        const getAskFilters = () => {
            return {
                filters: {
                    dates: datesFilterMapping[selectedDatesFilter.relative_name](
                        selectedDatesFilter.start_date_iso,
                        selectedDatesFilter.end_date_iso
                    ),
                },
            };
        };

        const project = getUserProject(state);

        const finalMeta = {
            ...extendedMeta,
            ...getAnswersMeta(),
            ...getProactiveMeta(),
            floating_enabled: floatingEnabled,
            uat_mode: isUatMode,
            lang_code: languageCode,
            ...getSelectedSmartFilters(),
            ...getAskFilters(),
        };

        if (mixpanelEventsPassedToUsageMetrics.has(event)) {
            const metricConverted = backwardCompatibilityMap.get(event) ?? event;

            await dispatch(
                usageMetricsEndpoint.endpoints.sendMetrics.initiate({
                    type: metricConverted,
                    meta: {
                        ...finalMeta,
                        timestamp: Date.now(),
                    },
                })
            );
        }

        await mixpanelService.track({
            event,
            meta: finalMeta,
            user: {
                user_id: user.uid,
                user_org: user.user_org,
                user_project: project ?? '',
                user_points: user?.competition?.points,
            },
        });
    }
);

export const sendDirectAnswerMetrics =
    (event: MixpanelEvent, meta?: { [x: string]: unknown }): AppThunk =>
    (dispatch, getState) => {
        const state = getState() as IRootState;

        const {
            question: { question, question_id, action_type },
        } = state;

        const customer_project_id = getUserProject(state);

        const actionType = typeof meta?.action_type === 'string' ? meta.action_type : action_type;

        const feature = getFeatureByActionType(action_type);

        dispatch(
            sendMetrics({
                event,
                meta: { question, question_id, feature, customer_project_id, ...meta, action_type: actionType },
            })
        );
    };
