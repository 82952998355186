import React from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
} from '@chakra-ui/react';
import { Field, FieldProps, Formik } from 'formik';

import { DEFAULT_ANNOTATE_TAG_VALUE } from '../../../api/consts';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/app-hooks';
import { updateTag } from '../../../redux/slices/annotate-answers/annotate-answers-slice';
import { closeAnnotateEditTagModal } from '../../../redux/slices/modals/annotate-tags-modal-slice';
import { WithTranslateErrors } from '../../WithTranslateErrors/WithTranslateErrors';
import { ModalContainer } from '../Container/ModalContainer';

import { validationSchema } from './validation-schema';

import styles from './AnnotatorTagNameModal.module.scss';

interface IFormData {
    tag: string;
}

export const AnnotatorTagNameModal = () => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();
    const { visible, editTagName } = useAppSelector((state) => state.modals.annotateTags);

    const handleClose = React.useCallback(() => {
        dispatch(closeAnnotateEditTagModal());
    }, [dispatch]);

    const handleFormSubmit = React.useCallback(
        (values: IFormData) => {
            batch(() => {
                dispatch(closeAnnotateEditTagModal());
                dispatch(updateTag({ prev: editTagName || '', next: values.tag }));
            });
        },
        [dispatch, editTagName]
    );

    const initialFormState = React.useMemo(() => {
        return { tag: editTagName || DEFAULT_ANNOTATE_TAG_VALUE };
    }, [editTagName]);

    const isOpen = visible && !!editTagName;

    return (
        <ModalContainer
            isCentered
            isOpen={isOpen}
            onClose={handleClose}
            motionPreset="scale"
            scrollBehavior="inside"
            data-testid="modal.tag-name"
        >
            <ModalOverlay />
            <ModalContent>
                <Formik
                    initialValues={initialFormState}
                    onSubmit={handleFormSubmit}
                    validationSchema={validationSchema()}
                    validateOnChange={false}
                >
                    {({ errors, touched, setFieldTouched, submitForm, isSubmitting }) => {
                        return (
                            <WithTranslateErrors errors={errors} touched={touched} setFieldTouched={setFieldTouched}>
                                <ModalBody p="32px">
                                    <Field name="tag">
                                        {({ field }: FieldProps<string, IFormData>) => {
                                            return (
                                                <FormControl isInvalid={!!errors.tag && touched.tag}>
                                                    <FormLabel size="xs" marginBottom="4px">
                                                        {t('annotate-answers.tag-modal-title')}
                                                    </FormLabel>
                                                    <Input
                                                        name={field.name}
                                                        value={field.value}
                                                        size="sm"
                                                        onChange={field.onChange}
                                                    />
                                                    <FormErrorMessage>{errors.tag}</FormErrorMessage>
                                                </FormControl>
                                            );
                                        }}
                                    </Field>
                                </ModalBody>
                                <ModalFooter p="16px" className={styles.footer}>
                                    <Button
                                        minW="104px"
                                        variant="secondary"
                                        onClick={handleClose}
                                        isDisabled={isSubmitting}
                                    >
                                        {t('button.cancel')}
                                    </Button>
                                    <Button
                                        minW="104px"
                                        variant="primary"
                                        onClick={submitForm}
                                        isLoading={isSubmitting}
                                    >
                                        {t('button.apply')}
                                    </Button>
                                </ModalFooter>
                            </WithTranslateErrors>
                        );
                    }}
                </Formik>
            </ModalContent>
        </ModalContainer>
    );
};
