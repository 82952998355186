import React from 'react';
import { Navigate } from 'react-router';
import { useAuth } from 'answers-core/src/redux/hooks/app-hooks';

import { MainRoutes } from '../types';

export interface ISecureRouteProps {
    children: React.ReactElement;
}

export const SecureRoute: React.FC<ISecureRouteProps> = ({ children }) => {
    const [isAuthorized] = useAuth();

    if (!isAuthorized) {
        return <Navigate to={MainRoutes.LOGIN} />;
    }

    return children;
};
