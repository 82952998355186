import React from 'react';
import cx from 'clsx';

import { ReactComponent as EditIcon } from '../../../assets/icons/tag-edit.svg';
import { ITag } from '../Tags';

import styles from './Tag.module.scss';

export interface ITagProps {
    value: ITag;
    onEditClick: (value: ITag) => void;
}

export const Tag: React.FC<ITagProps> = ({ value, onEditClick }) => {
    const handleEditIconClick = React.useCallback(() => {
        onEditClick(value);
    }, [value, onEditClick]);

    return (
        <div className={cx(styles.wrapper, styles.tag)}>
            {value}
            <span className={styles.icon}>
                <EditIcon title="Edit tag name" onClick={handleEditIconClick} />
            </span>
        </div>
    );
};
