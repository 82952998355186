import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Textarea,
} from '@chakra-ui/react';
import { Field, FieldProps, Formik } from 'formik';

import { DirectAnnotationType } from '../../../../api/types';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks/app-hooks';
import { useProject } from '../../../../redux/hooks/settings-hooks';
import { closeDirectAnswerFeedbackModal } from '../../../../redux/slices/modals/direct-answer-feedback-modal-slice';
import {
    annotateDirectAnswerThunk,
    IAnnotateDirectAnswerByThumbThunkPayload,
} from '../../../../redux/thunks/annotate-thunk';
import { restoreFocus } from '../../../../redux/thunks/focus-thunk';
import { WithTranslateErrors } from '../../../WithTranslateErrors/WithTranslateErrors';
import { ModalContainer } from '../../Container/ModalContainer';

import { answerFeedbackValidationSchema } from './validation-schema';

import styles from './DirectAnswerFeedbackModal.module.scss';

interface IFormData {
    details: string;
}

export const DirectAnswerFeedbackModal: React.FC = () => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();
    const { visible, questionId } = useAppSelector((state) => state.modals.annotateDirectAnswer);
    const { project } = useProject();

    if (!questionId) {
        return null;
    }

    const initialFormState: IFormData = {
        details: '',
    };

    const handleCloseModal = () => {
        dispatch(closeDirectAnswerFeedbackModal());
    };

    const handleFormSubmit = async (formData: IFormData) => {
        const { details } = formData;

        const payload: IAnnotateDirectAnswerByThumbThunkPayload = {
            customerProjectId: project,
            annotationType: DirectAnnotationType.ThumbsDown,
            questionId,
            feedback: details,
        };

        await dispatch(annotateDirectAnswerThunk(payload));

        handleCloseModal();
    };

    return (
        <ModalContainer
            isOpen={visible}
            onClose={handleCloseModal}
            motionPreset="scale"
            scrollBehavior="inside"
            onCloseComplete={() => dispatch(restoreFocus())}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t('answer.feedback.header')}</ModalHeader>
                <Formik
                    initialValues={initialFormState}
                    onSubmit={handleFormSubmit}
                    validationSchema={answerFeedbackValidationSchema()}
                >
                    {({ errors, touched, setFieldTouched, submitForm, isSubmitting, isValid, dirty }) => {
                        return (
                            <WithTranslateErrors errors={errors} touched={touched} setFieldTouched={setFieldTouched}>
                                <ModalBody className={styles.body}>
                                    <Text fontSize="sm" color="mid-gray" fontWeight="medium">
                                        {t('answer.feedback.direct-answer.sub-title')}
                                    </Text>
                                    <Field name="details">
                                        {({ field }: FieldProps<string>) => (
                                            <FormControl isInvalid={!!errors.details && touched.details}>
                                                <FormLabel size="xs">
                                                    {t('answer.feedback.direct-answer.details')}
                                                </FormLabel>
                                                <Textarea
                                                    variant="outline"
                                                    name={field.name}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                />
                                                <FormErrorMessage>{errors.details}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </ModalBody>
                                <ModalFooter className={styles.footer}>
                                    <Button variant="secondary" onClick={handleCloseModal}>
                                        {t('button.cancel')}
                                    </Button>
                                    <Button
                                        variant="primary"
                                        onClick={submitForm}
                                        isLoading={isSubmitting}
                                        disabled={!isValid || !dirty}
                                    >
                                        {t('button.submit')}
                                    </Button>
                                </ModalFooter>
                            </WithTranslateErrors>
                        );
                    }}
                </Formik>
            </ModalContent>
        </ModalContainer>
    );
};
