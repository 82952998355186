import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_SIDEBAR_MIN_WIDTH } from '../../../utils/ui';
import { IResizeState } from '../../types';

import theme from '../../../styles/chakra/theme.module.scss';

const initialState: IResizeState = {
    sidebarResizeStarted: false,
    sidebarWidth: parseInt(theme.sidebarDefaultWidth, 10),
    prevSidebarWidth: parseInt(theme.sidebarDefaultWidth, 10),
    floatingBarWidth: 0, // this value will be updated to the real one upon mounting the floating bar
    minWidth: DEFAULT_SIDEBAR_MIN_WIDTH,
};

export const resizeSlice = createSlice({
    name: 'resize',
    initialState,
    reducers: {
        setSidebarResizeStarted(state, action: PayloadAction<boolean>) {
            state.sidebarResizeStarted = action.payload;
        },
        setMinWidth(state, action: PayloadAction<number>) {
            state.minWidth = action.payload;
            if (state.sidebarWidth < action.payload) {
                state.shouldAnimateWidth = true;
                state.sidebarWidth = action.payload;
            }
        },
        setSidebarWidth(state, action: PayloadAction<number>) {
            state.prevSidebarWidth = state.sidebarWidth;
            state.sidebarWidth = action.payload;
        },
        setGrowSidebarWidthWithAnimation(state, action: PayloadAction<number>) {
            // grow only if bigger than current sidebar width
            if (action.payload > state.sidebarWidth) {
                state.prevSidebarWidth = state.sidebarWidth;
                state.shouldAnimateWidth = true;
                state.sidebarWidth = action.payload;
            }
        },
        setMinimizeSidebarWidthWithAnimation(state) {
            state.shouldAnimateWidth = true;
            state.sidebarWidth = state.prevSidebarWidth;
        },
        cancelWidthAnimation(state) {
            state.shouldAnimateWidth = false;
        },
        setFloatingBarWidth(state, action: PayloadAction<number>) {
            state.floatingBarWidth = action.payload;
        },
    },
});

export const {
    setSidebarResizeStarted,
    setSidebarWidth,
    setFloatingBarWidth,
    setMinWidth,
    setGrowSidebarWidthWithAnimation,
    cancelWidthAnimation,
    setMinimizeSidebarWidthWithAnimation,
} = resizeSlice.actions;
