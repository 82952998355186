import { useCallback, useMemo } from 'react';

import { AnnotationType, IAnswerResponse } from '../../api/types';
import { sendAnnotationThunk } from '../thunks/analytics-thunk';

import { useAppDispatch } from './app-hooks';
import { useSources } from './settings-hooks';

export const useAnalytics = () => {
    const dispatch = useAppDispatch();
    const { sources } = useSources();

    const sendAnnotation = useCallback(
        async (answer: IAnswerResponse, type: AnnotationType, additionalData?: string) => {
            return dispatch(
                sendAnnotationThunk({
                    answer,
                    type,
                    sources,
                    additionalData,
                })
            );
        },
        [dispatch, sources]
    );

    return useMemo(() => [sendAnnotation] as const, [sendAnnotation]);
};
