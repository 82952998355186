import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAnswerResponse } from '../../../api/types';

interface IAnnotateModalState {
    visible: boolean;
    answer: IAnswerResponse | null;
}

const initialState: IAnnotateModalState = {
    visible: false,
    answer: null,
};

export const answerFeedbackModalSlice = createSlice({
    name: 'annotate-modal',
    initialState,
    reducers: {
        openAnswerFeedbackModal(state, action: PayloadAction<IAnswerResponse>) {
            state.answer = action.payload;
            state.visible = true;
        },
        closeAnswerFeedbackModal(state) {
            state.answer = null;
            state.visible = false;
        },
    },
});

export const { openAnswerFeedbackModal, closeAnswerFeedbackModal } = answerFeedbackModalSlice.actions;
