import { IProactiveSource } from '../types';

import { ProactiveSourceIntercom } from './proactive-source-intercom';
import { ProactiveSourceSalesforce } from './proactive-source-salesforce';
import { ProactiveSourceZendesk } from './proactive-source-zendesk';

export const PROACTIVE_SOURCES_LIST: IProactiveSource[] = [
    new ProactiveSourceIntercom(),
    new ProactiveSourceZendesk(),
    new ProactiveSourceSalesforce(),
];
