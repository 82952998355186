import styles from './theme.module.scss';

export const withCustomScrollbar = {
    '::-webkit-scrollbar': {
        width: styles.scrollBarWidth,
        background: styles.scrollBarBackground,
    },
    '::-webkit-scrollbar-thumb': {
        background: styles.scrollBarTrack,
        borderRadius: '3px',
    },
};

export const withCustomInputStyles = {
    background: styles.inputBackground,
    borderRadius: '4px',
    border: '1px solid',
    borderColor: 'rgba(26, 26, 26, 0.2)',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)',
    fontSize: '1.4em',
    color: 'rgba(26, 26, 26, 0.8)',
    _focus: {
        borderColor: styles.hoverBlue,
        borderWidth: '2px',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)',
    },
};
