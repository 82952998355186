import React, { FC, ReactElement, useMemo } from 'react';
import { GroupBase, MenuListProps } from 'react-select';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List, ListRowProps } from 'react-virtualized';

import { OptionType, Select, SelectProps } from '../Select/Select';

interface CustomCellMeasurerProps extends ListRowProps {
    cache: CellMeasurerCache;
    children: ReactElement;
}

const defaultHeight = 300;

const CustomCellMeasurer = ({ children, style, ...props }: CustomCellMeasurerProps) => {
    return (
        <CellMeasurer {...props}>
            <div style={style}>{children}</div>
        </CellMeasurer>
    );
};

const MenuList = ({ children }: MenuListProps<OptionType, false, GroupBase<OptionType>>) => {
    const cellCache = useMemo(() => {
        return new CellMeasurerCache({
            fixedWidth: true,
            defaultHeight: 30,
        });
    }, []);

    const menuHeight = useMemo(() => {
        if (!Array.isArray(children)) return defaultHeight;
        const childrenHeight = children.length * 37;
        return childrenHeight < defaultHeight ? childrenHeight : defaultHeight;
    }, [children]);

    if (!Array.isArray(children)) {
        // For children like: "Loading" or "No Options" provided by 'react-select'
        return <>{children}</>;
    }

    const rowRenderer = (props: ListRowProps) => (
        <CustomCellMeasurer cache={cellCache} {...props}>
            {children[props.index]}
        </CustomCellMeasurer>
    );

    return (
        <div style={{ height: menuHeight }}>
            <AutoSizer disableHeight>
                {({ width }) => (
                    <List
                        width={width}
                        height={menuHeight}
                        deferredMeasurementCache={cellCache}
                        rowHeight={cellCache.rowHeight}
                        rowCount={children.length}
                        rowRenderer={rowRenderer}
                    />
                )}
            </AutoSizer>
        </div>
    );
};

export const VirtualizedSelect: FC<SelectProps> = (props) => {
    return <Select {...props} components={{ MenuList }} />;
};
