import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import { AnswersSortOrder, DEFAULT_ANNOTATE_TAG_VALUE } from '../../../api/consts';
import { sortChunksByLabelValue } from '../../../api/endpoints/utils/api-answers-utils';
import { IAnnotateAnswers, IAnnotateAnswersState, IApiAnnotationResult } from '../../../api/types';
import { setDisableOnlineMemory, setProject } from '../../thunks/settings-thunk';
import { logout } from '../../thunks/user-thunk';

const initialState: IAnnotateAnswersState = {
    annotate_answers: {
        question: '',
        question_id: '',
        chunks: [],
        tags: [DEFAULT_ANNOTATE_TAG_VALUE],
        customer_project_id: '',
        selected_data_source_ids: [],
    },
    annotated_labeled_chunks_map: {} as Record<string, string>,
    labeled_chunks_map: {} as Record<string, string>,
    comment_chunks_map: {} as Record<string, string>,
    comment_general: '',
    sort_order: AnswersSortOrder.DEFAULT,
    sorted_chunks: [],
};

export const annotateAnswersSlice = createSlice({
    name: 'annotateAnswers',
    initialState,
    reducers: {
        setAnswersToAnnotate(state, action: PayloadAction<IAnnotateAnswers>) {
            const { payload } = action;

            return {
                ...payload,
                annotate_answers: {
                    ...payload,
                    tags: payload.tags?.length === 0 ? initialState.annotate_answers.tags : payload.tags,
                },
                annotated_labeled_chunks_map: {},
                labeled_chunks_map: {},
                comment_chunks_map: {},
                comment_general: '',
                sort_order: initialState.sort_order,
                sorted_chunks: payload.chunks,
            };
        },
        setAnswersToAnnotateFromAnnotationResult(state, action: PayloadAction<IApiAnnotationResult>) {
            const { payload } = action;

            const { comment, labeled_chunks, created_at, annotation_id, tags, ...rest } = payload;

            state.comment_general = comment;
            state.annotate_answers = {
                ...rest,
                tags: tags?.length === 0 ? initialState.annotate_answers.tags : tags,
                chunks: [],
            };

            state.labeled_chunks_map = {};
            state.comment_chunks_map = {};
            state.sort_order = AnswersSortOrder.DEFAULT;
            state.sorted_chunks = [];

            labeled_chunks.forEach(({ label, comment, ...chunk }) => {
                state.annotate_answers.chunks.push(chunk);
                state.annotated_labeled_chunks_map[chunk.chunk_id] = label;
                state.comment_chunks_map[chunk.chunk_id] = comment;
            });

            state.sorted_chunks = Array.from(state.annotate_answers.chunks);
        },
        setAnswerLabel(state, action: PayloadAction<{ id: string; label: string }>) {
            const { id, label } = action.payload;

            state.labeled_chunks_map[id] = label;
        },
        setAnswerComment(state, action: PayloadAction<{ id: string; comment: string }>) {
            const { id, comment } = action.payload;

            if (comment) {
                state.comment_chunks_map[id] = comment;
            } else {
                delete state.comment_chunks_map[id];
            }
        },
        setGeneralComment(state, action: PayloadAction<{ question_id: string; comment: string }>) {
            const { question_id, comment } = action.payload;

            if (state.annotate_answers.question_id === question_id) {
                state.comment_general = comment;
            }
        },
        resetAnswersLabel(state) {
            return {
                ...initialState,
                annotate_answers: { ...state.annotate_answers },
                sort_order: initialState.sort_order,
                sorted_chunks: state.annotate_answers.chunks,
            };
        },
        updateTag(state, action: PayloadAction<{ prev: string; next: string }>) {
            const index = state.annotate_answers.tags.indexOf(action.payload.prev);
            state.annotate_answers.tags[index] = action.payload.next;
        },
        setSortOrder(state, action: PayloadAction<string>) {
            state.sort_order = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(isClearLastPreviewAction, () => {
                return initialState;
            })
            .addMatcher(isSortAnswersAction, (state, action) => {
                const order = action.payload;
                const labeled_chunks =
                    order === AnswersSortOrder.DESC_PAST_ANNOTATIONS
                        ? state.annotated_labeled_chunks_map
                        : state.labeled_chunks_map;

                state.sorted_chunks = sortChunksByLabelValue(state.annotate_answers.chunks, labeled_chunks, order);

                return state;
            });
    },
});

export const isSortAnswersAction = isAnyOf(annotateAnswersSlice.actions.setSortOrder);

export const isClearLastPreviewAction = isAnyOf(
    logout.fulfilled,
    setProject.fulfilled,
    setDisableOnlineMemory.fulfilled
);

export const {
    setAnswersToAnnotate,
    setAnswerLabel,
    setAnswerComment,
    setGeneralComment,
    resetAnswersLabel,
    updateTag,
    setSortOrder,
    setAnswersToAnnotateFromAnnotationResult,
} = annotateAnswersSlice.actions;
