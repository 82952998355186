import { isPlainObject } from '@reduxjs/toolkit';
import { FetchArgs } from '@reduxjs/toolkit/dist/query/react';
import { base64ToFiles } from 'answers-core/src/utils/preview';

const objToFormData = async (obj: Record<string, any>): Promise<FormData> => {
    const formData = new FormData();

    for (const key of Object.keys(obj)) {
        if (key === 'files') {
            const files = await base64ToFiles(obj[key]);
            files.forEach((file: File) => {
                formData.append(key, file);
            });
            continue;
        }
        formData.append(key, obj[key]);
    }

    return formData;
};

export const isFormDataContentType = (args: string | FetchArgs): boolean => {
    if (typeof args === 'string' || !isPlainObject(args.headers)) {
        return false;
    }

    const headers = new Headers(args.headers as Record<string, string>);

    return headers.get('content-type') === 'multipart/form-data';
};

export const prepareArgsBodyToFormData = async (args: string | FetchArgs) => {
    if (typeof args !== 'string') {
        args.body = await objToFormData(args.body);
    }

    return args;
};
