import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Heading, Text } from '@chakra-ui/react';

import { ReactComponent as PlaceholderIcon } from '../../../assets/icons/no-results-placeholder.svg';
import { ReactComponent as FiltersIcon } from '../../../assets/icons/source-list/sources-filter.svg';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/app-hooks';
import { useFilters } from '../../../redux/hooks/filters-hook';
import { openSourcesFilterModal } from '../../../redux/slices/modals/sources-filter-modal-slice';
import { sendMetrics } from '../../../redux/thunks/metrics-thunk';
import { MixpanelEvent } from '../../../services/mixpanel/types';

import styles from './NoRelevantResultsPlaceholder.module.scss';

export const NoRelevantResultsPlaceholder: React.FC = () => {
    const { t } = useTranslation('translations');
    const question = useAppSelector((state) => state.question.question);
    const dispatch = useAppDispatch();
    const [questionToDisplay] = useState<string>(question);
    const { appliedFiltersCount } = useFilters();

    const handleCLick = async () => {
        dispatch(
            sendMetrics({
                event: MixpanelEvent.MODIFY_APPLIED_FILTERS_BUTTON,
            })
        );
        dispatch(openSourcesFilterModal());
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.results}>
                <PlaceholderIcon />
                <Heading as="h1" size="h2" className={styles.heading}>
                    {t('placeholder.no-relevant-results')}
                </Heading>
                <Heading as="h2" size="h2" className={styles.question} color="text-control" title={questionToDisplay}>
                    {questionToDisplay}
                </Heading>
            </div>
            {appliedFiltersCount > 0 ? (
                <Button variant="secondary" onClick={handleCLick} leftIcon={<FiltersIcon />}>
                    {t('answers-empty-results.applied-filters-button')}
                </Button>
            ) : (
                <Text as="span" fontSize="1.3em" fontWeight={400}>
                    {t('placeholder.no-relevant-results.explanation')}
                </Text>
            )}
        </div>
    );
};
