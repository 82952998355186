import { ReactComponent as EveryoneIcon } from '../../../../assets/icons/ask-ai-knowledgebase/access-level-icon-everyone.svg';
import { ReactComponent as OnlyMeIcon } from '../../../../assets/icons/ask-ai-knowledgebase/access-level-icon-only-me.svg';
import { ReactComponent as PublicIcon } from '../../../../assets/icons/ask-ai-knowledgebase/access-level-icon-public.svg';
import { ReactComponent as UserGroupIcon } from '../../../../assets/icons/ask-ai-knowledgebase/access-level-icon-user-group.svg';
import i18n from '../../../../i18n';

import { AccessLevelSelectOption } from './components/types';

const t = i18n.t;

export const accessLevelOptions: AccessLevelSelectOption[] = [
    { label: t('ask-ai-kb.access-level-only-me', { ns: 'translations' }), value: 'user', icon: OnlyMeIcon },
    { label: t('ask-ai-kb.access-level-user-group', { ns: 'translations' }), value: 'customer', icon: UserGroupIcon },
    { label: t('ask-ai-kb.access-level-everyone', { ns: 'translations' }), value: 'everyone', icon: EveryoneIcon },
    { label: t('ask-ai-kb.access-level-public', { ns: 'translations' }), value: 'public', icon: PublicIcon },
];
