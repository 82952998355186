import React from 'react';
import { SpecialZoomLevel, Viewer, ViewerProps } from '@react-pdf-viewer/core';

import styles from './PreviewModalBody.module.scss';

export type ViewerBodyProps = {
    height?: number;
} & ViewerProps;

export const PreviewPdfModalBody: React.FC<ViewerBodyProps> = ({ height, ...props }) => {
    return (
        // the highlight function is working only if the Viewer is wrapped in an element with a fixed height
        <div style={height ? { height: `${height}px` } : undefined} className={styles.pdfViewerWrapper}>
            <Viewer defaultScale={SpecialZoomLevel.PageWidth} {...props} />
        </div>
    );
};
