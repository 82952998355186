import { batch } from 'react-redux';
import { redirect } from 'react-router-dom';
import { api, ILoginResponse } from '@frontegg/rest-api';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { IUser } from '../../api/types';
import { MainRoutes } from '../../routes/types';
import { browserService } from '../../services/browser/browser-service';
import { mixpanelService } from '../../services/mixpanel/mixpanel-service';
import { ssoService } from '../../services/sso/sso-service';
import { SSOLogoutReason } from '../../services/sso/types';
import {
    LANGUAGE_CODE_STORAGE_KEY,
    LAST_ANSWERS_STORAGE_KEY,
    LAST_PREVIEW_STORAGE_KEY,
    ONLINE_MEMORY_STORAGE_KEY,
    PROACTIVE_VIEW_HISTORY_STORAGE_KEY,
    PROJECT_STORAGE_KEY,
    SELECTED_DATES_FILTER_KEY,
    SELECTED_SMART_FILTERS_KEY,
    SOURCES_STORAGE_KEY,
    TOKEN_STORAGE_KEY,
    TRAINING_ENABLEMENT_LAST_QUIZ_QUESTION_STORAGE_KEY,
} from '../../services/storage/storage-keys-list';
import { storageService } from '../../services/storage/storage-service';
import { isPopupMode, isPortalWebMode } from '../../utils/extension-mode';
import { IRootState } from '../core-store';
import { setRoles, setToken } from '../slices/auth/auth-slice';

import { openLoginWindowAndWait } from './utils/login-sso-utils';

const loginPortalUrl = process.env.REACT_APP_LOGIN_PORTAL ?? '';

export const loginSSO = createAsyncThunk('auth/loginSSO', async (_, { dispatch }) => {
    await ssoService.removeLockout();

    let user: Awaited<ReturnType<typeof openLoginWindowAndWait>> = undefined;

    if (isPortalWebMode()) {
        user = await ssoService.getUser();
    } else {
        user = await openLoginWindowAndWait(`${loginPortalUrl}/login-extension`);
    }

    if (user) {
        await dispatch(afterSSOLogin(user));
    }
});

export const afterSSOLogin = createAsyncThunk('auth/afterSSOLogin', (data: ILoginResponse, { dispatch }) => {
    batch(() => {
        const token = data.accessToken;
        const roles = data?.roles?.map((role) => role.key) ?? [];

        dispatch(setToken(token));
        dispatch(setRoles(roles));
    });
    redirect(MainRoutes.HOME);
});

export interface ILogoutData {
    logoutFromPortal: boolean;
    reason: SSOLogoutReason;
    requestUrl?: string;
}

export const logout = createAsyncThunk('auth/logout', async (data: ILogoutData) => {
    const { logoutFromPortal, reason, requestUrl } = data;

    await storageService.removeStorageValue([
        TOKEN_STORAGE_KEY,
        LAST_ANSWERS_STORAGE_KEY,
        SOURCES_STORAGE_KEY,
        PROJECT_STORAGE_KEY,
        ONLINE_MEMORY_STORAGE_KEY,
        LAST_PREVIEW_STORAGE_KEY,
        TRAINING_ENABLEMENT_LAST_QUIZ_QUESTION_STORAGE_KEY,
        PROACTIVE_VIEW_HISTORY_STORAGE_KEY,
        LANGUAGE_CODE_STORAGE_KEY,
        SELECTED_SMART_FILTERS_KEY,
        SELECTED_DATES_FILTER_KEY,
    ]);

    // will log out from the SSO
    await ssoService.logout(reason, requestUrl);

    if (logoutFromPortal) {
        try {
            if (isPortalWebMode()) {
                // will try to remove SSO session
                await api.auth.logout();
            } else {
                // will log out from the portal
                await browserService.createTab({ url: `${loginPortalUrl}/logout`, active: false, selected: true });
            }
        } catch {}
    }
});

export const setUser = createAsyncThunk('auth/setUser', async (user: IUser, { getState }) => {
    const {
        auth: { permissions },
    } = getState() as IRootState;
    const currentDisableOnlineMemory = await storageService.getStorageValue(ONLINE_MEMORY_STORAGE_KEY, null);

    const disableOnlineMemory = currentDisableOnlineMemory === null && permissions.disableOnlineMemory;

    if (disableOnlineMemory) {
        await storageService.setStorageValue(ONLINE_MEMORY_STORAGE_KEY, false);
    }

    mixpanelService.updateUserProfile(user);

    if (isPopupMode()) {
        const LogRocket = await import(
            /* webpackMode: "eager" */
            'logrocket'
        );
        LogRocket.identify(user.uid, {
            name: user.username,
            user_org: user.user_org,
        });
    }

    return {
        user,
        disableOnlineMemory,
    };
});
