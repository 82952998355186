import { AskFilters, DatesFilterRelativeName, IUseSmartFilter, IUseSource } from '../../../api/types';

export const getSelectedSourcesCount = (sources: IUseSource[]) =>
    sources.reduce((acc, source) => acc + (source.active ? 1 : 0), 0);

export const getSelectedSmartFiltersCount = (filters: IUseSmartFilter[]) =>
    filters.reduce((acc, filter) => acc + (filter.active ? 1 : 0), 0);

export const getActiveFilters = (filters: AskFilters): AskFilters => {
    const dates = filters.dates?.relative_name !== DatesFilterRelativeName.ANY_TIME ? filters.dates : undefined;
    return {
        dates,
    };
};
