import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldProps } from 'formik';

import { ReactComponent as RemoveIcon } from '../../../assets/icons/websites-black-list/remove-from-list.svg';

import { IWebsitesBlacklistFormState } from './WebsitesBlacklist';

import styles from './WebsitesBlacklist.module.scss';

export const WebsitesList: React.FC = () => {
    const { t } = useTranslation('translations');

    return (
        <Field name="origins">
            {({ form, field }: FieldProps<string[], IWebsitesBlacklistFormState>) => {
                const handleRemoveOrigin =
                    (value: string): MouseEventHandler =>
                    (event) => {
                        event.stopPropagation();

                        const filtered = field.value.filter((origin) => origin !== value);

                        form.setFieldValue(field.name, filtered);
                    };
                return (
                    <ul className={styles.list}>
                        {field.value.map((origin) => {
                            return (
                                <li key={origin} className={styles.listItem}>
                                    {origin}
                                    <button
                                        aria-label={t('websites-blacklist.domain.remove-tooltip')}
                                        className={styles.removeButton}
                                        onClick={handleRemoveOrigin(origin)}
                                    >
                                        <RemoveIcon />
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                );
            }}
        </Field>
    );
};
