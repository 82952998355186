import { v4 } from 'uuid';

import { CUSTOM_DOC_EVENT, DOC_EVENT_ERROR, DOC_EVENT_FILE_URL } from '../../components/PDFViewer/custom-doc-events';
import * as utils from '../../utils/pointer';
import { base64PdfFileToBlob, getAnswerFileUrl } from '../../utils/preview';
import { fetchInBackground } from '../background-fetch/background-fetch-service';
import { createWorkerService } from '../core/worker/worker-service';
import { getExtensionURLPrefix } from '../resources/resources-service';
import { ssoService } from '../sso/sso-service';
import { ServiceWorkerContext } from '../types';

import { previewPassKeydownEventService } from './preview-pass-keydown-event';
import { PdfPreviewArgs } from './types';

const PREVIEW_ID_PARAM_NAME = 'previewId';
const uuid = v4();

export const currentPreviewId = uuid;

export const getPreviewURL = () => `${getExtensionURLPrefix()}/preview.html?${PREVIEW_ID_PARAM_NAME}=${uuid}`;
export const getPreviewPdfURL = () => `${getExtensionURLPrefix()}/preview-pdf.html?${PREVIEW_ID_PARAM_NAME}=${uuid}`;

const isPreviewIdTheSame = (id: string) => {
    const iFrameUrlParams = document.location.search;
    const iFramePreviewId = new URLSearchParams(iFrameUrlParams).get(PREVIEW_ID_PARAM_NAME);

    return id === iFramePreviewId;
};
const registerDocEvent = (previewId: string) => {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = `${getExtensionURLPrefix()}/scripts/iframe-preload.js`;
        script.onload = resolve;

        document.body.appendChild(script);
        document.body.addEventListener('keydown', (event) => {
            previewPassKeydownEventService.passKeyDownEvent(
                {
                    type: event.type,
                    key: event.key,
                    altKey: event.altKey,
                    ctrlKey: event.ctrlKey,
                    code: event.code,
                    shiftKey: event.shiftKey,
                },
                previewId
            );
        });
        window.focus();
    });
};

const loadPreview = async (previewId: string, content: string) => {
    if (!isPreviewIdTheSame(previewId)) {
        return;
    }

    document.body.innerHTML = content;

    return registerDocEvent(previewId);
};
const loadPdfPreview = async (previewId: string, params: PdfPreviewArgs) => {
    const { project_id = '', doc_id, source_id = '', source_type = '', initial_question } = params;
    const fileUrl = getAnswerFileUrl(project_id, doc_id, source_id, source_type);

    try {
        const user = await ssoService._passthrough.getUser();

        if (!user?.accessToken) {
            return;
        }

        const options = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${user.accessToken}`,
            },
        };

        const response = await fetchInBackground(fileUrl, options);

        if (!response.ok) {
            await dispatchDocEvent(DOC_EVENT_ERROR, { error: 'Failed to load doc.' });
        }

        // due to postMessage limitations, we will receive a base64 string instead of file
        const base64 = await response.text();

        // https://react-pdf-viewer.dev/examples/preview-a-pdf-file-from-base-64/
        const blob = await base64PdfFileToBlob(base64);
        const url = URL.createObjectURL(blob);

        await dispatchDocEvent(DOC_EVENT_FILE_URL, { url, initial_question });
    } catch (error) {
        await dispatchDocEvent(DOC_EVENT_ERROR, { error: 'Failed to load doc.' });
    }

    return registerDocEvent(previewId);
};

export const dispatchDocEvent = async (name: string, data: any) => {
    const event = new CustomEvent(CUSTOM_DOC_EVENT, { detail: { name, data } });

    document.dispatchEvent(event);
};

const showPointer = async (previewId: string, currentMatch: number) => {
    if (!isPreviewIdTheSame(previewId)) {
        return;
    }

    utils.showPointer(document, currentMatch);
};

const hidePointer = async (previewId: string) => {
    if (!isPreviewIdTheSame(previewId)) {
        return;
    }

    utils.hidePreviousPointer();
};

const service = createWorkerService({
    name: 'preview',
    context: ServiceWorkerContext.PREVIEW,
    handlers: () => ({
        dispatchEvent: dispatchDocEvent,
        loadPreview: loadPreview,
        loadPdfPreview: loadPdfPreview,
        showPointer: showPointer,
        hidePointer: hidePointer,
    }),
    keepAlive: false,
});

export const previewService = service.actions;

export const registerPreviewService = service.register;
