import { createSlice } from '@reduxjs/toolkit';

import { IDirectAnswerResult } from '../../../api/types';

interface IDirectAnswerEditModalState {
    visible: boolean;
    directAnswer?: IDirectAnswerResult;
}

const initialState: IDirectAnswerEditModalState = {
    visible: false,
};

export const directAnswerEditModalSlice = createSlice({
    name: 'direct-answer-edit-modal',
    initialState,
    reducers: {
        openDirectAnswerEditModal(state, action) {
            if (!action.payload) {
                return;
            }
            state.directAnswer = action.payload;
            state.visible = true;
        },
        closeDirectAnswerEditModal(state) {
            state.visible = false;
        },
    },
});

export const { openDirectAnswerEditModal, closeDirectAnswerEditModal } = directAnswerEditModalSlice.actions;
