import { batch } from 'react-redux';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { SIDEBAR_OPENED_ATTRIBUTE } from '../../constants';
import { MixpanelEvent } from '../../services/mixpanel/types';
import {
    FLOATING_ICON_POSITION_STORAGE_KEY,
    LAST_ANSWERS_STORAGE_KEY,
    LAST_MOCKED_DIRECT_ANSWER_KEY,
} from '../../services/storage/storage-keys-list';
import { storageService } from '../../services/storage/storage-service';
import { tabsSyncService } from '../../services/tabs/tabs-sync-service';
import { isSearchShouldBeFocused } from '../../utils/direct-answer';
import { getShadowDomSelection } from '../../utils/shadow-dom';
import { updateHostDocumentWidth } from '../../utils/slider-app-host-styles-fix';
import { AppThunk, IAppDispatch, IRootState } from '../core-store';
import { setSearchFocused } from '../slices/answers/answers-slice';
import { ISetSidebarVisiblePayload, setSidebarVisible } from '../slices/app/app-slice';
import { setDraggedBefore, setDragStarted } from '../slices/floating-icon/floating-icon-slice';
import { clearPendingToSave, setResultReady } from '../slices/proactive-answers/proactive-answers-slice';
import { setSelectedText } from '../slices/selection/selection-slice';
import { SidebarOpenReason } from '../types';

import { makeFloatingIconHidden } from './floating-icon-thunk';
import { sendMetrics } from './metrics-thunk';
import { setProactiveDirectAnswerReady } from './proactive-answer-thunk';

export const setSidebarWidthThunk =
    (width: number): AppThunk =>
    (dispatch) => {
        updateHostDocumentWidth(width);
        dispatch(
            sendMetrics({
                event: MixpanelEvent.SET_SIDEBAR_WIDTH,
                meta: {
                    width,
                    url: window.location.href,
                },
            })
        );
    };

interface ISetSidebarVisibleThunkPayload extends ISetSidebarVisiblePayload {
    doNotFocusSearch?: boolean;
}

export const setSidebarVisibleThunk = createAsyncThunk(
    'app/setSidebarVisible',
    async (payload: ISetSidebarVisibleThunkPayload, { dispatch: thunkDispatch, getState }) => {
        const dispatch = thunkDispatch as IAppDispatch;
        const { visible, openedBy, doNotFocusSearch = false } = payload;
        const {
            question: { action_type, question_id },
            proactiveAnswers: { pendingToSave },
        } = getState() as IRootState;
        const host = document.querySelector<HTMLDivElement>(`#${SHADOW_DOM_ROOT_ID}`);

        if (host) {
            if (visible) {
                host.dataset[SIDEBAR_OPENED_ATTRIBUTE] = '';
            } else {
                delete host.dataset[SIDEBAR_OPENED_ATTRIBUTE];
            }
        }

        if (visible) {
            const searchShouldBeFocused = doNotFocusSearch ? false : isSearchShouldBeFocused(action_type);
            dispatch(setSearchFocused(searchShouldBeFocused));

            if (pendingToSave) {
                if (pendingToSave.answers.answers.length === 0) {
                    // we don't want to display the `No Proactive Answers` placeholder
                    // next time a user re-opens the sidebar/popup
                    await storageService.removeStorageValue(LAST_ANSWERS_STORAGE_KEY);
                } else {
                    await storageService.setStorageValue(LAST_ANSWERS_STORAGE_KEY, pendingToSave.answers);
                    await storageService.setStorageValue(LAST_MOCKED_DIRECT_ANSWER_KEY, pendingToSave.directAnswer);
                }

                dispatch(clearPendingToSave());
            }
        }

        batch(() => {
            dispatch(setResultReady(false));
            dispatch(setProactiveDirectAnswerReady(false));
            dispatch(setSidebarVisible({ visible, openedBy }));
            dispatch(makeFloatingIconHidden());
            dispatch(
                sendMetrics({
                    event: visible ? MixpanelEvent.OPEN_FLOATING_APP : MixpanelEvent.CLOSE_FLOATING_APP,
                    meta: {
                        url: window.location.href,
                        question_id,
                    },
                })
            );

            if (!visible) {
                const selectionWithinExtension = host?.shadowRoot ? getShadowDomSelection(host.shadowRoot) : undefined;

                if (selectionWithinExtension?.anchorNode) {
                    dispatch(setSelectedText(''));
                }
            }
        });

        return visible;
    }
);

export const toggleSidebarVisibilityThunk = createAsyncThunk(
    'app/toggleSidebarVisibilityThunk',
    async (_, { dispatch, getState }) => {
        const {
            app: { sidebarVisible },
        } = getState() as IRootState;

        await dispatch(
            setSidebarVisibleThunk({
                visible: !sidebarVisible,
                openedBy: SidebarOpenReason.USER,
            })
        );
    }
);

export const setFloatingIconDragging = createAsyncThunk(
    'app/setFloatingIconDragging',
    async (isDragging: boolean, { dispatch, getState }) => {
        batch(() => {
            dispatch(setDragStarted(isDragging));
            dispatch(setDraggedBefore(!isDragging));
        });

        if (!isDragging) {
            const { yPosition } = (getState() as IRootState).floatingIcon;

            await storageService.setStorageValue(FLOATING_ICON_POSITION_STORAGE_KEY, yPosition);
            await tabsSyncService.syncFloatingPosition(yPosition);

            dispatch(
                sendMetrics({
                    event: MixpanelEvent.FLOATING_APP_MOVE,
                    meta: {
                        y_position: yPosition,
                        url: window.location.href,
                        window_height: window.innerHeight,
                    },
                })
            );
        }
    }
);
