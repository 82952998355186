import { v4 } from 'uuid';

import { IUser } from '../../api/types';
import { browserService } from '../browser/browser-service';
import { createWorkerService } from '../core/worker/worker-service';
import { ServiceWorkerContext } from '../types';

import { ITrackEventData } from './types';

const token = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN ?? 'c0cf69d24d268fe21c2cd6b0a012e886';
const apiHost = 'https://api-secure.mixpanel.com';
const appType = process.env.REACT_APP_APP_TYPE ?? 'dev';

const sendRequest = async (url: string, data: object) => {
    const options: RequestInit = {
        method: 'POST',
        headers: {
            'content-type': 'application/x-www-form-urlencoded',
        },
        body: `data=${encodeURIComponent(JSON.stringify([data]))}`,
    };

    await fetch(url, options);
};

const track = async (data: ITrackEventData) => {
    const { event, meta, user } = data;

    const browserInfo = await browserService._passthrough.getInfo();

    const body = {
        event: `${event}`,
        properties: {
            ...meta,
            token,
            $insert_id: v4(),
            distinct_id: user?.user_id ?? '',
            time: Date.now(),
            $os: browserInfo.os,
            $browser: 'Chrome',
            $browser_version: browserInfo.version,
            app_type: appType,
            ...user,
        },
    };

    await sendRequest(`${apiHost}/track?verbose=1&ip=1`, body);
};

const setUserProperties = async (user: IUser, properties: Record<string, string>) => {
    const { uid } = user;

    const body = {
        token,
        $distinct_id: uid,
        $set: {
            ...properties,
        },
    };

    await sendRequest(`${apiHost}/engage#profile-set?verbose=1&ip=1`, body);
};

const updateUserProfile = async (user: IUser) => {
    const { username, email } = user;

    await setUserProperties(user, {
        $name: username,
        $email: email,
    });
};

const service = createWorkerService({
    name: 'mixpanel',
    context: ServiceWorkerContext.BACKGROUND,
    handlers: () => ({
        track,
        updateUserProfile,
        setUserProperties,
    }),
});

export const mixpanelService = service.actions;

export const registerMixpanelService = service.register;
