import { IEventBusEmitter } from './types';

export abstract class AbstractEventHandler {
    protected emitter: IEventBusEmitter;

    constructor(emitter: IEventBusEmitter) {
        this.emitter = emitter;
    }

    public abstract register(): void;
}
