import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ModalBody } from '@chakra-ui/react';
import useResizeObserver from '@react-hook/resize-observer';
import { Worker, ZoomEvent } from '@react-pdf-viewer/core';
import { searchPlugin } from '@react-pdf-viewer/search';
import { zoomPlugin } from '@react-pdf-viewer/zoom';

import { useCurrentAnswer } from '../../../../redux/hooks/answer/answer-hooks';
import { useAppSelector } from '../../../../redux/hooks/app-hooks';
import { usePreviewKeyDownHandler } from '../../../../redux/hooks/preview';
import { useProject } from '../../../../redux/hooks/settings-hooks';
import { getAnswerQuery } from '../../../../utils/answer';
import { decrease, increase } from '../../../../utils/pdf-zoom-utils';
import { createRegExp, getAnswerFileUrl, getPdfWorkerUrl } from '../../../../utils/preview';
import { PDFViewerLoader } from '../../../PDFViewer/PDFViewerLoader';
import { searchPluginProps } from '../../../PDFViewer/search-plugin-props';
import { LoadDocError } from '../Body/LoadDocError/LoadDocError';
import { PreviewPdfModalBody } from '../Body/PreviewPdfModalBody';
import { PreviewPdfModalHeader } from '../Header/PreviewPdfModalHeader';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';
import styles from '../Body/PreviewModalBody.module.scss';

export const PopUpMode: React.FC = () => {
    const [isLoaded, setLoaded] = useState(false);
    const zoomScale = useRef(1);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const answer = useCurrentAnswer();
    const initialQuestion = getAnswerQuery(answer);
    const { project } = useProject();
    const searchPluginInstance = searchPlugin(searchPluginProps);
    const zoomPluginInstance = zoomPlugin();
    const { token } = useAppSelector((state) => state.auth);
    const [modalHeight, setModalHeight] = useState<number | undefined>(
        modalRef?.current?.getBoundingClientRect()?.height
    );

    const handleIFrameKeyDown = usePreviewKeyDownHandler();

    const handleSearch = useCallback(
        (value: string) => {
            if (value) {
                searchPluginInstance.highlight(createRegExp(value));
            } else {
                searchPluginInstance.clearHighlights();
            }
        },
        [searchPluginInstance]
    );

    const handleZoomIn = useCallback(() => {
        const nextZoomScale = increase(zoomScale.current);

        zoomPluginInstance.zoomTo(nextZoomScale);
    }, [zoomPluginInstance]);

    const handleZoomOut = useCallback(() => {
        const nextZoomScale = decrease(zoomScale.current);

        zoomPluginInstance.zoomTo(nextZoomScale);
    }, [zoomPluginInstance]);

    const handleNextMatch = useCallback(() => {
        searchPluginInstance.jumpToNextMatch();
    }, [searchPluginInstance]);

    const handlePrevMatch = useCallback(() => {
        searchPluginInstance.jumpToPreviousMatch();
    }, [searchPluginInstance]);

    const handleZoomChange = useCallback((e: ZoomEvent) => {
        zoomScale.current = e.scale;
    }, []);

    const handleDocumentLoad = useCallback(() => {
        setLoaded(true);
    }, [setLoaded]);

    const headers = useMemo(() => {
        return token ? { Authorization: `Bearer ${token}` } : undefined;
    }, [token]);

    useResizeObserver(modalRef, (entry) => setModalHeight(entry.target.getBoundingClientRect().height));

    useEffect(() => {
        if (isLoaded) {
            if (initialQuestion) {
                searchPluginInstance.highlight(createRegExp(initialQuestion));
            } else {
                searchPluginInstance.clearHighlights();
            }
        }
        // DO NOT add searchPluginInstance to dependencies, it will cause infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoaded, initialQuestion]);

    useEffect(() => {
        modalRef.current?.addEventListener('keydown', handleIFrameKeyDown);
        modalRef.current?.focus();

        const node = modalRef.current;

        return () => {
            node?.removeEventListener('keydown', handleIFrameKeyDown);
        };
    }, [handleIFrameKeyDown]);

    if (!answer) {
        return null;
    }

    const fileUrl = getAnswerFileUrl(project, answer.doc_id, answer.source_id, answer.source_type);

    const workerUrl = getPdfWorkerUrl();

    return (
        <>
            <PreviewPdfModalHeader
                initialQuestion={initialQuestion}
                onSearch={handleSearch}
                onZoomIn={handleZoomIn}
                onZoomOut={handleZoomOut}
                onNextMatch={handleNextMatch}
                onPrevMatch={handlePrevMatch}
            />
            <ModalBody ref={modalRef} tabIndex={0} className={styles.wrapper}>
                <Worker workerUrl={workerUrl}>
                    <PreviewPdfModalBody
                        fileUrl={fileUrl}
                        httpHeaders={headers}
                        plugins={[searchPluginInstance, zoomPluginInstance]}
                        onDocumentLoad={handleDocumentLoad}
                        renderLoader={() => <PDFViewerLoader />}
                        onZoom={handleZoomChange}
                        renderError={() => <LoadDocError />}
                        height={modalHeight}
                    />
                </Worker>
            </ModalBody>
        </>
    );
};
