import { createAsyncThunk } from '@reduxjs/toolkit';

import { askEndpoint } from '../../api/endpoints/ask-endpoint';
import { LAST_ANSWERS_STORAGE_KEY, LAST_MOCKED_DIRECT_ANSWER_KEY } from '../../services/storage/storage-keys-list';
import { storageService } from '../../services/storage/storage-service';
import { SHARED_ANSWERS_CACHE_KEY } from '../constants';
import { clearPendingToSave } from '../slices/proactive-answers/proactive-answers-slice';

export const clearQuestion = createAsyncThunk('question/clearQuestion', async (_, { dispatch }) => {
    await storageService.removeStorageValue([LAST_ANSWERS_STORAGE_KEY, LAST_MOCKED_DIRECT_ANSWER_KEY]);
    const runningRequest = dispatch(askEndpoint.util.getRunningMutationThunk('getAnswers', SHARED_ANSWERS_CACHE_KEY));

    if (runningRequest) {
        runningRequest.abort();
    }

    dispatch(askEndpoint.internalActions.removeMutationResult({ fixedCacheKey: SHARED_ANSWERS_CACHE_KEY }));
    dispatch(clearPendingToSave());
});
