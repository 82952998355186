export const fixZendeskHostStyles = (sidebarWidth: number) => {
    const footers = document.querySelectorAll<HTMLElement>(`#main_panes section footer`);

    if (footers.length === 0) {
        return;
    }

    footers.forEach((footer) => {
        footer.style.right = `${sidebarWidth}px`;
    });
};
