import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import english from './locales/en/en-locale';

const resources = {
    en: english,
};

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        debug: false,
        fallbackLng: 'en',
        resources,
        react: {
            useSuspense: true,
        },
    });

export default i18n;
