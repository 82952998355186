import { IUseSmartFilter, IUseSource } from '../api/types';

export const getActiveSources = (sources: IUseSource[]): string[] => {
    return sources.filter((source) => source.active).map((source) => source.id);
};
export const getActiveSmartFilters = (filters: IUseSmartFilter[]): IUseSmartFilter[] => {
    return filters.filter((filter) => filter.active);
};
export const getActiveSmartFiltersIds = (filters: IUseSmartFilter[]): string[] => {
    return getActiveSmartFilters(filters).map((filter) => filter.id);
};
