import { MATCH_ID_TEMPLATE } from '../api/consts';
import {
    HIGHLIGHT_POINTER_CSS_CLASS,
    HIGHLIGHT_POINTER_HEIGHT,
    HIGHLIGHT_POINTER_ROTATED_CSS_CLASS,
} from '../styles/preview';

let previousPointer: HTMLSpanElement | null = null;
const hidePointer = (element: HTMLSpanElement) => {
    element.hidden = true;
    element.classList.remove(HIGHLIGHT_POINTER_CSS_CLASS, HIGHLIGHT_POINTER_ROTATED_CSS_CLASS);
    element.style.top = '';

    previousPointer = null;
};

export const hidePreviousPointer = () => {
    if (!previousPointer) {
        return;
    }

    hidePointer(previousPointer);
};

export const showPointer = (target: Document | null, currentMatch: number): HTMLSpanElement | undefined => {
    hidePreviousPointer();
    const element = target?.querySelector<HTMLSpanElement>(`#${MATCH_ID_TEMPLATE}${currentMatch}`);

    if (!element) {
        return undefined;
    }

    const pointerHeight = parseInt(HIGHLIGHT_POINTER_HEIGHT);
    element.hidden = false;

    const iframeScrollPosition = target?.defaultView?.scrollY || 0;
    const { y } = element.getBoundingClientRect();
    const elementRelativePosition = iframeScrollPosition + y;
    if (elementRelativePosition < pointerHeight) {
        const parentHeight = element.parentElement?.getBoundingClientRect().height ?? 0;
        element.classList.add(HIGHLIGHT_POINTER_CSS_CLASS, HIGHLIGHT_POINTER_ROTATED_CSS_CLASS);
        element.style.top = `${parentHeight + pointerHeight}px`;
    } else {
        element.classList.add(HIGHLIGHT_POINTER_CSS_CLASS);
    }

    previousPointer = element;
    element.scrollIntoView({ block: 'center' });
};
