import { batch } from 'react-redux';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { annotateDirectAnswerEndpoint } from '../../api/endpoints/annotate-direct-answer-endpoint';
import { annotateEndpoint } from '../../api/endpoints/annotate-endpoint';
import {
    AnnotationType,
    DirectAnnotationType,
    IAnnotateDirectAnswerRequestParams,
    IAnswerResponse,
    IUseSource,
} from '../../api/types';
import i18n from '../../i18n';
import { getToastService, TOAST_HIDE_DELAY } from '../../services/toast/toast-service';
import { IRootState } from '../core-store';
import { clearFocus, setPreviousAnswerUuid } from '../slices/answers/answers-slice';
import { openAnswerFeedbackModal } from '../slices/modals/answer-feedback-modal-slice';
import { openDirectAnswerFeedbackModal } from '../slices/modals/direct-answer-feedback-modal-slice';

export interface IAnnotateByThumbThunkPayload {
    answer: IAnswerResponse;
    type: AnnotationType;
    project: string;
    sources: IUseSource[];
    details?: string;
}

export type IAnnotateDirectAnswerByThumbThunkPayload = IAnnotateDirectAnswerRequestParams;

const postAnnotate = (description: string) => {
    getToastService().toast({
        description,
        position: 'top-right',
        containerStyle: {
            marginRight: '25px',
            marginTop: '6px', // don't set too high, it will overlap with the animation
        },
        isClosable: true,
        duration: TOAST_HIDE_DELAY,
    });
};

export const annotateThunk = createAsyncThunk(
    'annotate',
    async (payload: IAnnotateByThumbThunkPayload, { dispatch, getState }) => {
        const state = getState() as IRootState;
        const { answer, type, project, sources, details } = payload;
        const { question, question_id } = state.question;

        await dispatch(
            annotateEndpoint.endpoints.annotateAnswer.initiate({
                type,
                question,
                question_id,
                answer: answer.text,
                isArticleOrSection: false,
                title: answer.title,
                url: answer.url,
                isAnswerMissing: false,
                isProbablyExists: false,
                project,
                sources,
                details,
                unique_answer_id: answer.unique_answer_id,
            })
        );

        const description =
            type === AnnotationType.THUMB_UP
                ? i18n.t('annotate.message.successful.answer', { ns: 'translations' })
                : i18n.t('annotate.message.successful.thumb-down.feedback', { ns: 'translations' });
        postAnnotate(description);
    }
);

export const annotateByThumbThunk = createAsyncThunk(
    'annotateByThumb',
    async (payload: IAnnotateByThumbThunkPayload, { dispatch, getState }) => {
        const state = getState() as IRootState;
        const { type, answer } = payload;
        const { activeAnswerUuid } = state.answers;

        if (type === AnnotationType.THUMB_UP) {
            await dispatch(annotateThunk(payload));
        } else {
            batch(() => {
                dispatch(setPreviousAnswerUuid(activeAnswerUuid));
                dispatch(clearFocus());
                dispatch(openAnswerFeedbackModal(answer));
            });
        }
    }
);

export const annotateDirectAnswerThunk = createAsyncThunk(
    'annotateDirectAnswerThunk',
    async (payload: IAnnotateDirectAnswerByThumbThunkPayload, { dispatch, getState }) => {
        const { annotationType } = payload;
        await dispatch(annotateDirectAnswerEndpoint.endpoints.annotateDirectAnswer.initiate(payload));

        const description =
            annotationType === DirectAnnotationType.ThumbsUp
                ? i18n.t('annotate.message.successful.answer', { ns: 'translations' })
                : i18n.t('annotate.message.successful.thumb-down.feedback', { ns: 'translations' });
        postAnnotate(description);
    }
);

export const annotateDirectAnswerByThumbsThunk = createAsyncThunk(
    'annotateDirectAnswerByThumbsThunk',
    async (payload: IAnnotateDirectAnswerByThumbThunkPayload, { dispatch }) => {
        const { annotationType } = payload;

        if (annotationType === DirectAnnotationType.ThumbsUp) {
            await dispatch(annotateDirectAnswerThunk(payload));
        } else {
            dispatch(openDirectAnswerFeedbackModal(payload.questionId));
        }
    }
);
