import React, {
    ChangeEvent,
    FocusEvent,
    forwardRef,
    MouseEvent,
    useCallback,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Fade, IconButton, Textarea } from '@chakra-ui/react';
import useResizeObserver from '@react-hook/resize-observer';
import cx from 'clsx';

import { ReactComponent as Logo } from '../../assets/icons/ask-ai-logo.svg';
import { ReactComponent as Delete } from '../../assets/icons/close.svg';
import { ReactComponent as Search } from '../../assets/icons/search-history.svg';

import styles from './AskArea.module.scss';

export interface ISearchBarProps {
    value?: string;
    focused?: boolean;
    typingStarted?: boolean;
    recentSearchesEnabled?: boolean;
    onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void;
    onClick?: (e: MouseEvent<HTMLTextAreaElement>) => void;
    onClear?: () => void;
    onPaste?: () => void;
    activeSuggestion?: string;
    suggestions?: string[];
    onSelectSuggestion?: (suggestion: string, e: MouseEvent<HTMLElement>) => void;
    onDeleteSuggestion?: (suggestion: string, e: MouseEvent<HTMLElement>) => void;
    rightIcon?: React.ReactElement;
    triggerSearchQuery?: () => void;
}

export type Ref = HTMLTextAreaElement;

const MAX_LINES_COUNT = 4;

export const SearchBar = forwardRef<Ref, ISearchBarProps>(
    (
        {
            recentSearchesEnabled: showRecentSearches,
            typingStarted: userStartedTyping,
            focused: searchFocused,
            value: question,
            onChange,
            onBlur,
            onClick,
            onClear,
            onPaste,
            suggestions = [],
            onSelectSuggestion,
            onDeleteSuggestion = () => false,
            activeSuggestion,
            rightIcon,
            triggerSearchQuery,
        },
        inputRef
    ) => {
        const { t } = useTranslation('translations');
        const hiddenInputRef = useRef<HTMLTextAreaElement>(null);
        const previousWidth = useRef<number | undefined>(undefined);
        const [textAreaHeight, setTextAreaHeight] = useState<number>(0);

        const recalculateTextAreaHeight = useCallback(() => {
            if (hiddenInputRef?.current) {
                const lineHeight = parseInt(getComputedStyle(hiddenInputRef.current).lineHeight, 10) || 0;
                const hiddenScrollHeight = hiddenInputRef.current.scrollHeight;
                const questionLinesCount = (hiddenScrollHeight - 2) / lineHeight;

                setTextAreaHeight(Math.min(MAX_LINES_COUNT, questionLinesCount) * lineHeight);
            }
        }, []);

        useLayoutEffect(() => {
            recalculateTextAreaHeight();
        }, [question, hiddenInputRef, recalculateTextAreaHeight]);

        useResizeObserver(hiddenInputRef, (entry) => {
            if (previousWidth.current !== entry.contentRect.width) {
                previousWidth.current = entry.contentRect.width;
                recalculateTextAreaHeight();
            }
        });

        // do not lose focus within the search bar
        const handleButtonMouseDown = (e: MouseEvent<Element>) => {
            e.preventDefault();
        };

        const questionTrimmed = (question ?? '').trim();
        const isLogoVisible = !searchFocused && questionTrimmed.length === 0;
        const areSuggestionsVisible = Boolean(userStartedTyping && showRecentSearches && suggestions.length > 0);
        const isClearVisible = questionTrimmed !== '';

        return (
            <div className={styles.relativeWrapper}>
                <div className={cx(styles.shadowWrapper, { [styles.suggestionsVisible]: areSuggestionsVisible })}>
                    <div
                        className={cx(styles.wrapper, {
                            [styles.focused]: searchFocused,
                        })}
                        data-testid="askArea.wrapper"
                    >
                        <div className={cx(styles.border)}>
                            <div className={styles.visible}>
                                <Fade in={isLogoVisible} className={styles.logo} unmountOnExit>
                                    <Logo />
                                </Fade>
                                <Textarea
                                    ref={inputRef}
                                    name="ask"
                                    className={cx(styles.search, {
                                        [styles.notEmpty]: isClearVisible,
                                        [styles.withRightIcon]: Boolean(rightIcon),
                                    })}
                                    placeholder={t('ask.placeholder')}
                                    value={question}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    variant="search-bar"
                                    data-testid="askArea.input"
                                    onClick={onClick}
                                    onPaste={onPaste}
                                    sx={{
                                        height: `${textAreaHeight}px`,
                                    }}
                                />

                                {rightIcon && (
                                    <IconButton
                                        className={styles.rightIcon}
                                        onClick={triggerSearchQuery}
                                        onMouseDown={handleButtonMouseDown}
                                        icon={rightIcon}
                                        variant="icon-button"
                                        aria-label={t('ask.search-button')}
                                        size="sm"
                                    />
                                )}

                                <Fade in={isClearVisible} unmountOnExit className={styles.clearIcon}>
                                    <Delete onMouseDown={handleButtonMouseDown} onClick={onClear} />
                                </Fade>

                                <div className={styles.hidden}>
                                    <Textarea
                                        ref={hiddenInputRef}
                                        className={cx(styles.search, styles.hiddenTextArea, {
                                            [styles.notEmpty]: isClearVisible,
                                            [styles.withRightIcon]: Boolean(rightIcon),
                                        })}
                                        value={question}
                                        readOnly
                                        variant="search-bar"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {areSuggestionsVisible && (
                    <div className={styles.suggestionsShadowWrapper}>
                        <div className={styles.suggestionsWrapper}>
                            <div className={styles.suggestions}>
                                {suggestions.map((suggestion) => (
                                    <div
                                        className={cx(styles.item, {
                                            [styles.chosenItem]: activeSuggestion === suggestion,
                                        })}
                                        key={suggestion}
                                        onMouseDown={(e) => onSelectSuggestion && onSelectSuggestion(suggestion, e)}
                                        data-testid="askArea.suggestionItem"
                                    >
                                        <div className={styles.searchAndSuggestionWrapper}>
                                            <div>
                                                <Search className={styles.searchIcon} />
                                            </div>

                                            <div className={styles.suggestionText}>{suggestion}</div>
                                        </div>
                                        <div
                                            className={styles.deleteBtn}
                                            onMouseDown={(e) => onDeleteSuggestion(suggestion, e)}
                                            data-testid="askArea.deleteSuggestion"
                                        >
                                            <Delete className={styles.deleteIcon} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
);
