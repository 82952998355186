import React, { ChangeEventHandler, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import {
    Button,
    Checkbox,
    FormControl,
    FormErrorMessage,
    FormLabel,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Link,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Spinner,
} from '@chakra-ui/react';
import { useFlag, useVariant } from '@unleash/proxy-client-react';
import { Field, FieldProps, Formik } from 'formik';

import packageJson from '../../../../package.json';
import { useGetUserQuery } from '../../../api/endpoints/users-endpoint';
import { IUser } from '../../../api/types';
import { ReactComponent as Clear } from '../../../assets/icons/close.svg';
import { AppToggle } from '../../../clients/unleash/app-toggle-names';
import { AppVariants } from '../../../clients/unleash/app-variants-names';
import { DEFAULT_LANGUAGE_CODE } from '../../../redux/constants';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/app-hooks';
import { useChangeSettings, useProject } from '../../../redux/hooks/settings-hooks';
import { closeSettingsModal } from '../../../redux/slices/modals/settings-modal-slice';
import { sendMetrics } from '../../../redux/thunks/metrics-thunk';
import { openOriginsBlacklistModal } from '../../../redux/thunks/origins-blacklist-thunk';
import { setDebugMode, setDisableOnlineMemory, setShouldDisplaySidebarApp } from '../../../redux/thunks/settings-thunk';
import { IChangeProject } from '../../../redux/types';
import { browserService } from '../../../services/browser/browser-service';
import { MixpanelEvent } from '../../../services/mixpanel/types';
import { isPortalWebMode } from '../../../utils/extension-mode';
import { WithTranslateErrors } from '../../WithTranslateErrors/WithTranslateErrors';
import { ModalContainer } from '../Container/ModalContainer';

import { languageCodeOptions } from './language-code-options';
import { userSettingsValidationSchema } from './validation-schema';

import styles from './SettingsModal.module.scss';

export const SettingsModal: React.FC = () => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();
    const changeSettings = useChangeSettings();
    const { visible } = useAppSelector((state) => state.modals.settings);
    const { token, permissions } = useAppSelector((state) => state.auth);
    const {
        backendUrl,
        disableOnlineMemory,
        shouldDisplayFloatingApp,
        simulateCustomerProject,
        languageCode,
        fullTicketAppUrl,
        debugMode,
    } = useAppSelector((state) => state.settings);
    const { data } = useGetUserQuery(undefined, { skip: token === null });
    const { project } = useProject();
    const showFloatingAppMenuItemFlag = useFlag(AppToggle.FLOATING_APP);
    const whiteList = useVariant(AppVariants.PROACTIVE_ANSWERS_URLS_WHITELIST);
    const enabledProactive = useFlag(AppToggle.PROACTIVE_ANSWERS);
    const canChangeLanguageCode = useFlag(AppToggle.MULTI_LINGUAL_SHOW_LANG_SELECTOR);
    const isChangeBackendAllowed = permissions.changeBackendApi;
    const isDisableOnlineMemoryAllowed = permissions.disableOnlineMemory;
    const isDebugModeAllowed = permissions.debugMode;
    const canViewProactiveAdminSettings = enabledProactive && permissions.debugProactiveAccess;
    const showFloatingAppMenuItem = !isPortalWebMode() && showFloatingAppMenuItemFlag;

    const handleCloseModal = () => {
        dispatch(closeSettingsModal());
        dispatch(sendMetrics({ event: MixpanelEvent.CLOSE_SETTING_PANEL }));
    };

    const handleUpdateShortcutClick = async () => {
        await browserService.createTab({ url: 'chrome://extensions/shortcuts', active: true, selected: false });
    };

    const handleOriginsBlacklistSettings: MouseEventHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();

        dispatch(openOriginsBlacklistModal({ location: 'Settings', url: window.location.href }));
    };

    const renderForm = (user: IUser) => {
        const initialFormState: IChangeProject = {
            open_link_in_new_page: user.settings.open_link_in_new_page,
            customer_project: project,
            backend_url: backendUrl,
            disable_online_memory: isDisableOnlineMemoryAllowed ? disableOnlineMemory : null,
            debug_mode: isDebugModeAllowed ? debugMode : null,
            display_floating_app: shouldDisplayFloatingApp ?? true,
            simulate_customer_project: simulateCustomerProject || project,
            proactive_url: '',
            full_ticket_app_url: fullTicketAppUrl,
            lang_code: canChangeLanguageCode ? languageCode : DEFAULT_LANGUAGE_CODE,
        };

        const handleFormSubmit = async (changeProjectData: IChangeProject) => {
            await changeSettings(changeProjectData);

            batch(() => {
                dispatch(setShouldDisplaySidebarApp(changeProjectData.display_floating_app));
                dispatch(setDisableOnlineMemory(changeProjectData.disable_online_memory));
                dispatch(setDebugMode(changeProjectData.debug_mode));
                dispatch(closeSettingsModal());
            });
        };

        return (
            <Formik
                initialValues={initialFormState}
                onSubmit={handleFormSubmit}
                validationSchema={userSettingsValidationSchema(whiteList.payload?.value)}
                validateOnChange={false}
            >
                {({ values, errors, touched, setFieldTouched, submitForm, isSubmitting, setFieldValue }) => {
                    const isBackendUrlChanged = values['backend_url'] !== backendUrl;

                    return (
                        <WithTranslateErrors errors={errors} touched={touched} setFieldTouched={setFieldTouched}>
                            <ModalBody className={styles.body}>
                                {showFloatingAppMenuItem && (
                                    <Field name="display_floating_app">
                                        {({ field }: FieldProps<boolean>) => (
                                            <div className={styles.checkboxWrapper}>
                                                <Checkbox
                                                    name={field.name}
                                                    isChecked={field.value}
                                                    onChange={field.onChange}
                                                    alignItems="flex-start"
                                                />
                                                <div
                                                    onClick={() => {
                                                        setFieldValue(field.name, !field.value);
                                                    }}
                                                >
                                                    <p className={styles.label}>{t('settings.enable-floating-app')}</p>
                                                    <p className={styles.label}>
                                                        {t('settings.enable-floating-app.helper')}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </Field>
                                )}
                                <Link
                                    fontSize="1.4em"
                                    fontWeight={500}
                                    onClick={handleOriginsBlacklistSettings}
                                    variant="main"
                                >
                                    {t('settings.display-floating-app.exclude-domains-list')}
                                </Link>
                                <Field name="open_link_in_new_page">
                                    {({ field }: FieldProps<boolean>) => (
                                        <Checkbox
                                            name={field.name}
                                            isChecked={field.value}
                                            onChange={field.onChange}
                                            disabled={isBackendUrlChanged}
                                        >
                                            {t('settings.open-link')}
                                        </Checkbox>
                                    )}
                                </Field>
                                {user.customer_projects.length > 1 && (
                                    <Field name="customer_project">
                                        {({ field, form }: FieldProps<string>) => {
                                            return (
                                                <>
                                                    <FormLabel size="xs">{t('settings.project')}</FormLabel>
                                                    <Select
                                                        name={field.name}
                                                        value={field.value}
                                                        size="sm"
                                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                            if (canViewProactiveAdminSettings) {
                                                                // Make sure the proactive project is the same as the selected project IF the user can use proactive
                                                                form.setFieldValue(
                                                                    'simulate_customer_project',
                                                                    e.target.value
                                                                );
                                                            }
                                                            field.onChange(e);
                                                        }}
                                                        disabled={isBackendUrlChanged}
                                                        data-testid="settings.project"
                                                    >
                                                        {user.customer_projects.map((project) => (
                                                            <option key={project.value} value={project.value}>
                                                                {project.text}
                                                            </option>
                                                        ))}
                                                    </Select>
                                                </>
                                            );
                                        }}
                                    </Field>
                                )}
                                {canChangeLanguageCode && (
                                    <Field name="lang_code">
                                        {({ field }: FieldProps<string>) => {
                                            return (
                                                <>
                                                    <FormLabel size="xs">{t('settings.language.title')}</FormLabel>
                                                    <Select
                                                        name={field.name}
                                                        value={field.value}
                                                        size="sm"
                                                        onChange={field.onChange}
                                                        disabled={isBackendUrlChanged}
                                                    >
                                                        {languageCodeOptions.map(({ value, label }) => (
                                                            <option key={value} value={value}>
                                                                {label}
                                                            </option>
                                                        ))}
                                                    </Select>
                                                </>
                                            );
                                        }}
                                    </Field>
                                )}
                                {canViewProactiveAdminSettings && (
                                    <>
                                        {user.customer_projects.length > 1 && (
                                            <Field name="simulate_customer_project">
                                                {({ field }: FieldProps<string>) => {
                                                    return (
                                                        <>
                                                            <FormLabel size="xs">
                                                                {t('settings.simulated-customer-project')}
                                                            </FormLabel>
                                                            <Select
                                                                name={field.name}
                                                                value={field.value}
                                                                size="sm"
                                                                onChange={field.onChange}
                                                                disabled={isBackendUrlChanged}
                                                                data-testid="settings.simulated-customer-project"
                                                            >
                                                                {user.customer_projects.map((project) => (
                                                                    <option key={project.value} value={project.value}>
                                                                        {project.text}
                                                                    </option>
                                                                ))}
                                                            </Select>
                                                        </>
                                                    );
                                                }}
                                            </Field>
                                        )}
                                        <Field name="proactive_url">
                                            {({ field, form }: FieldProps<string, IChangeProject>) => {
                                                const handleChange: ChangeEventHandler = (evt) => {
                                                    form.setFieldValue('full_ticket_app_url', '');
                                                    field.onChange(evt);
                                                };

                                                return (
                                                    <FormControl
                                                        isInvalid={!!errors.proactive_url && touched.proactive_url}
                                                    >
                                                        <FormLabel size="xs">{t('settings.proactive-url')}</FormLabel>
                                                        <Input
                                                            name={field.name}
                                                            value={field.value}
                                                            size="sm"
                                                            onChange={handleChange}
                                                            isDisabled={!!form.values.full_ticket_app_url}
                                                        />
                                                        <FormErrorMessage>{errors.proactive_url}</FormErrorMessage>
                                                    </FormControl>
                                                );
                                            }}
                                        </Field>
                                        <Field name="full_ticket_app_url">
                                            {({ field, form }: FieldProps<string, IChangeProject>) => {
                                                const showClearButton = field.value.length > 0;
                                                const onClear = () => form.setFieldValue(field.name, '');
                                                const handleChange: ChangeEventHandler = (evt) => {
                                                    form.setFieldValue('proactive_url', '');
                                                    field.onChange(evt);
                                                };
                                                return (
                                                    <FormControl
                                                        isInvalid={
                                                            !!errors.full_ticket_app_url && touched.full_ticket_app_url
                                                        }
                                                    >
                                                        <FormLabel size="xs">
                                                            {t('settings.full-ticket-app-url')}
                                                        </FormLabel>
                                                        <InputGroup size="sm">
                                                            <Input
                                                                name={field.name}
                                                                value={field.value}
                                                                size="sm"
                                                                onChange={handleChange}
                                                                isDisabled={!!form.values.proactive_url}
                                                            />
                                                            {showClearButton && (
                                                                <InputRightElement>
                                                                    <IconButton
                                                                        size="sm"
                                                                        variant="solid"
                                                                        color="gray"
                                                                        colorScheme="transparent"
                                                                        aria-label="clears input"
                                                                        icon={<Clear />}
                                                                        onClick={onClear}
                                                                    />
                                                                </InputRightElement>
                                                            )}
                                                        </InputGroup>
                                                        <FormErrorMessage>
                                                            {errors.full_ticket_app_url}
                                                        </FormErrorMessage>
                                                    </FormControl>
                                                );
                                            }}
                                        </Field>
                                    </>
                                )}
                                {isChangeBackendAllowed && (
                                    <Field name="backend_url">
                                        {({ field }: FieldProps<string, IChangeProject>) => {
                                            return (
                                                <FormControl isInvalid={!!errors.backend_url && touched.backend_url}>
                                                    <FormLabel size="xs">{t('settings.backend-url')}</FormLabel>
                                                    <Input
                                                        name={field.name}
                                                        value={field.value}
                                                        size="sm"
                                                        onChange={field.onChange}
                                                    />
                                                    <FormErrorMessage>{errors.backend_url}</FormErrorMessage>
                                                </FormControl>
                                            );
                                        }}
                                    </Field>
                                )}

                                {isDisableOnlineMemoryAllowed && (
                                    <Field name="disable_online_memory">
                                        {({ field }: FieldProps<boolean>) => (
                                            <Checkbox
                                                name={field.name}
                                                isChecked={field.value}
                                                onChange={field.onChange}
                                                disabled={isBackendUrlChanged}
                                            >
                                                {t('settings.disable-online-memory')}
                                            </Checkbox>
                                        )}
                                    </Field>
                                )}

                                {isDebugModeAllowed && (
                                    <Field name="debug_mode">
                                        {({ field }: FieldProps<boolean>) => (
                                            <Checkbox
                                                name={field.name}
                                                isChecked={field.value}
                                                onChange={field.onChange}
                                            >
                                                {t('settings.debug-mode')}
                                            </Checkbox>
                                        )}
                                    </Field>
                                )}
                                <div>
                                    <Link onClick={handleUpdateShortcutClick} variant="main">
                                        {t('settings.change-shortcut')}
                                    </Link>
                                </div>
                            </ModalBody>
                            <ModalFooter className={styles.footer}>
                                <Button
                                    variant="secondary"
                                    onClick={handleCloseModal}
                                    data-testid="settings.button.cancel"
                                >
                                    {t('button.cancel')}
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={submitForm}
                                    isLoading={isSubmitting}
                                    data-testid="settings.button.save"
                                >
                                    {t('button.save')}
                                </Button>
                            </ModalFooter>
                        </WithTranslateErrors>
                    );
                }}
            </Formik>
        );
    };

    return (
        <ModalContainer
            isOpen={visible}
            onClose={handleCloseModal}
            motionPreset="scale"
            scrollBehavior="inside"
            data-testid="modal.settings"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader className={styles.header}>
                    {t('settings.header')}
                    <span className={styles.version}>
                        {t('settings.version')}: {packageJson.version}
                    </span>
                </ModalHeader>

                {!data ? (
                    <ModalBody>
                        <Spinner />
                    </ModalBody>
                ) : (
                    renderForm(data)
                )}
            </ModalContent>
        </ModalContainer>
    );
};
