import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import createDOMPurify from 'dompurify';

import { AnnotationType } from '../../api/types';
import { ITrackEventOrigin, MixpanelEvent } from '../../services/mixpanel/types';
import { getToastService, TOAST_BOTTOM_OFFSET } from '../../services/toast/toast-service';
import { sendMetrics } from '../thunks/metrics-thunk';
import { ICopyActionMeta } from '../types';

import { useAnswers } from './answer/answer-hooks';
import { useAnalytics } from './analytics';
import { useAppDispatch } from './app-hooks';
import { useSources } from './settings-hooks';

const DOMPurifier = createDOMPurify();
const ALLOWED_TAGS = ['br', 'li'];
const FORBID_TAGS = ['strong', 'b'];

const uponSanitizeElement = (node: Element) => {
    if (ALLOWED_TAGS.includes(node.tagName?.toLowerCase()) && node.parentNode) {
        node.outerHTML = '\n' + node.textContent ?? '';
    }
};

export const useCopyAnswer = () => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();
    const [, { data }] = useAnswers();
    const { sources } = useSources();
    const [log] = useAnalytics();

    return useCallback(
        async (meta: ICopyActionMeta) => {
            const { answerId, origin } = meta;
            const answerToCopy = data?.answers.find((answer) => answer.doc_id === answerId);
            const url = answerToCopy?.valid_url ? answerToCopy.valid_url : answerToCopy?.url;

            const rawHtml = answerToCopy?.reader_is_article_or_section ? '' : answerToCopy?.html_long ?? '';

            // Generate the rich text to copy
            const link = `<a href='${url}'>${answerToCopy?.title ?? ''}</a>`;
            const htmlSanitized = DOMPurifier.sanitize(rawHtml, { FORBID_TAGS });
            const richText = htmlSanitized ? `${htmlSanitized}<br><br>${link}` : link;
            const htmlType = 'text/html';
            const html = new Blob([richText], { type: htmlType });

            // Generate the plain text to copy
            DOMPurifier.addHook('uponSanitizeElement', uponSanitizeElement);
            const description = DOMPurifier.sanitize(rawHtml, {
                ALLOWED_TAGS,
                RETURN_DOM: true,
            }).innerText.replaceAll(/\n *!/gi, '\n');
            const plainText = `${description ? `${description}\n\n` : ''}${t('preview.copy.read.more', {
                url: url ?? '',
            })}`;
            DOMPurifier.removeHook('uponSanitizeElement');

            const plainType = 'text/plain';
            const text = new Blob([plainText], { type: plainType });

            const clipboardItem = new ClipboardItem({ [htmlType]: html, [plainType]: text });

            await navigator.clipboard.write([clipboardItem]);

            answerToCopy && log(answerToCopy, AnnotationType.COPY_ANSWER);
            answerToCopy &&
                dispatch(
                    sendMetrics({
                        event: MixpanelEvent.COPY_SNIPPET_MAIN_PANEL,
                        meta: {
                            rich_text: richText,
                            plain_text: plainText,
                        },
                        injectAnswersMetrics: {
                            answers: data,
                            sources,
                        },
                    })
                );

            if (answerToCopy) {
                if (answerToCopy.source_is_public) {
                    getToastService().info(t('answer.copy.public.success'), {
                        ...(origin === ITrackEventOrigin.PREVIEW_WINDOW && { marginBottom: TOAST_BOTTOM_OFFSET }),
                    });
                } else {
                    getToastService().warning(t('answer.copy.private.success'), {
                        ...(origin === ITrackEventOrigin.PREVIEW_WINDOW && { marginBottom: TOAST_BOTTOM_OFFSET }),
                    });
                }
            }
        },
        [data, t, log, dispatch, sources]
    );
};
