import errors from './errors.json';
import languages from './languages.json';
import relativeDate from './relative-date.json';
import translations from './translations.json';

const namespaces = {
    errors,
    translations,
    languages,
    'relative-date': relativeDate,
};

export default namespaces;
