import { Dispatch } from '@reduxjs/toolkit';

import { getEventBus } from '../../../services/core/event-bus/event-bus';
import { getLogger } from '../../../utils/logger';
import { setToken } from '../../slices/auth/auth-slice';

const logger = getLogger('TokenUpdaterListener');

export const subscribeToTokenUpdate = (dispatch: Dispatch) => {
    const onTokenUpdatedHandler = (token: string) => {
        if (token) {
            logger.log('Token was updated, setting in store');
            dispatch(setToken(token));
        }
    };

    getEventBus().addListener('onTokenUpdated', onTokenUpdatedHandler);
};
