import browser from 'webextension-polyfill';

import { AbstractEventHandler } from '../abstract-event-handler';

export class TabUrlChangedEvent extends AbstractEventHandler {
    register() {
        browser.tabs.onUpdated.addListener((tabId, changeInfo) => {
            this.emitter.emit('tabURLChanged', tabId, changeInfo);
        });
    }
}
