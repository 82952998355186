import React from 'react';
import { useTranslation } from 'react-i18next';

import { ActionType, AnnotationType, DirectAnnotationType } from '../../../../../api/types';
import { ReactComponent as ThumbDown } from '../../../../../assets/icons/thumb-down.svg';
import { useAnswers } from '../../../../../redux/hooks/answer/answer-hooks';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks/app-hooks';
import { useSources } from '../../../../../redux/hooks/settings-hooks';
import { sendMetrics } from '../../../../../redux/thunks/metrics-thunk';
import { ITrackEventOrigin, MixpanelEvent, MixpanelEventProperties } from '../../../../../services/mixpanel/types';

import { ThumbAction } from './ThumbAction';
import { IThumbActionProps } from './types';

export interface IThumbDownActionProps extends Pick<IThumbActionProps, 'answer' | 'isDirectAnswer'> {
    actionType?: ActionType;
}

export const ThumbDownAction: React.FC<IThumbDownActionProps> = ({ answer, actionType, isDirectAnswer = false }) => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();
    const { visible: previewModalVisible } = useAppSelector((state) => state.modals.preview);
    const [, { data }] = useAnswers();
    const { sources } = useSources();

    const handleButtonClick = () => {
        const meta: MixpanelEventProperties = {
            use_shortcut: false,
            origin: previewModalVisible ? ITrackEventOrigin.PREVIEW_WINDOW : ITrackEventOrigin.ANSWERS_LIST,
            is_direct_answer: isDirectAnswer,
        };

        if (actionType) {
            meta.action_type = actionType;
        }

        dispatch(
            sendMetrics({
                event: MixpanelEvent.THUMB_DOWN,
                meta,
                injectAnswersMetrics: {
                    answers: data,
                    sources,
                },
            })
        );
    };

    return (
        <ThumbAction
            answer={answer}
            type={isDirectAnswer ? DirectAnnotationType.ThumbsDown : AnnotationType.THUMB_DOWN}
            icon={ThumbDown}
            title={t('answer.feedback.thumb-down.hint')}
            onClick={handleButtonClick}
            isDirectAnswer={isDirectAnswer}
        />
    );
};
