import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IMetricsState } from '../../types';

const initialState: IMetricsState = {
    floatingEnabled: false,
};

export const metricsSlice = createSlice({
    name: 'metrics',
    initialState,
    reducers: {
        setFloatingEnabled(state, action: PayloadAction<boolean>) {
            state.floatingEnabled = action.payload;
        },
    },
});

export const { setFloatingEnabled } = metricsSlice.actions;
