import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, MenuButton, MenuGroup, MenuItem, MenuList, MenuProps } from '@chakra-ui/react';
import { AnswersSortOrder } from 'answers-core/src/api/consts';
import cx from 'clsx';

import { ReactComponent as ChevronDownIcon } from '../../../assets/icons/chevron-down.svg';
import { ReactComponent as OrderAscIcon } from '../../../assets/icons/order-asc.svg';
import { ReactComponent as OrderDefaultIcon } from '../../../assets/icons/order-default.svg';
import { ReactComponent as OrderDescIcon } from '../../../assets/icons/order-desc.svg';

import { useAnswersSort } from './useAnswersSort';

import styles from './SortAnswersList.module.scss';

const DEFAULT_IS_OPEN = false;
const MENU_OFFSET: MenuProps['offset'] = [0, -14];

interface ISortAnswersListProps {
    annotationId: string;
}

export const SortAnswersList: React.FC<ISortAnswersListProps> = ({ annotationId }) => {
    const [order, changeSortOrder] = useAnswersSort();
    const { t } = useTranslation('translations');
    const [isOpen, setIsOpen] = useState(DEFAULT_IS_OPEN);

    const menuItems = useMemo(() => {
        return [
            {
                translationKey: 'annotate-answers.sort-order.default',
                icon: OrderDefaultIcon,
                value: AnswersSortOrder.DEFAULT,
            },
            {
                translationKey: 'annotate-answers.sort-order.ascending',
                icon: OrderAscIcon,
                value: AnswersSortOrder.ASC,
            },
            {
                translationKey: 'annotate-answers.sort-order.descending',
                icon: OrderDescIcon,
                value: AnswersSortOrder.DESC,
            },
            ...(annotationId
                ? [
                      {
                          translationKey: 'annotate-answers.sort-order.descending-past-annotations',
                          icon: OrderDescIcon,
                          value: AnswersSortOrder.DESC_PAST_ANNOTATIONS,
                      },
                  ]
                : []),
        ];
    }, [annotationId]);

    const handleOnOpen = useCallback(() => {
        setIsOpen(true);
    }, [setIsOpen]);
    const handleOnClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    return (
        <div>
            <Menu offset={MENU_OFFSET} defaultIsOpen={DEFAULT_IS_OPEN} onOpen={handleOnOpen} onClose={handleOnClose}>
                <MenuButton as="div" className={cx(styles.button, { [styles.open]: isOpen })}>
                    <div className={styles.buttonContent}>
                        <ChevronDownIcon className={styles.buttonIcon} />
                        <p>{t('annotate-answers.sort-by', { order: t(order.translationKey) })}</p>
                    </div>
                </MenuButton>

                <MenuList>
                    <MenuGroup m="0 16px 4px" title={t('annotate-answers.sort-order.title')}>
                        {menuItems.map(({ translationKey, icon: Icon, value }) => (
                            <MenuItem
                                p="10px 16px"
                                key={translationKey}
                                icon={<Icon />}
                                onClick={() => changeSortOrder(value)}
                            >
                                {t(translationKey)}
                            </MenuItem>
                        ))}
                    </MenuGroup>
                </MenuList>
            </Menu>
        </div>
    );
};
