import { batch } from 'react-redux';

import { AppThunk, IRootState } from '../core-store';
import { _setAutoHideTimerId, _setFloatingMouseHover } from '../slices/floating-icon/floating-icon-slice';

export const showFloatingIconFirstTime = (): AppThunk => (dispatch, getState) => {
    const { floatingIconHideDelayMs } = (getState() as IRootState).settings;

    const timerId = setTimeout(() => {
        dispatch(_setAutoHideTimerId(undefined));
    }, floatingIconHideDelayMs);

    dispatch(_setAutoHideTimerId(timerId));
};

export const makeFloatingIconVisible = (): AppThunk => (dispatch) => {
    dispatch(_setFloatingMouseHover(true));
};

export const makeFloatingIconHidden = (): AppThunk => (dispatch) => {
    batch(() => {
        dispatch(_setAutoHideTimerId(undefined));
        dispatch(_setFloatingMouseHover(false));
    });
};
