import React, { useCallback } from 'react';
import { ModalContent, ModalOverlay } from '@chakra-ui/react';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks/app-hooks';
import { closeSourcesFilterModal } from '../../../redux/slices/modals/sources-filter-modal-slice';
import { ModalContainer } from '../Container/ModalContainer';

import { FiltersForm } from './FiltersForm';

export const FiltersModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const visible = useAppSelector((state) => state.modals.sourcesFilter.visible);

    const handleCloseModal = useCallback(() => {
        dispatch(closeSourcesFilterModal());
    }, [dispatch]);

    return (
        <ModalContainer
            closeOnEsc
            closeOnOverlayClick
            isOpen={visible}
            onClose={handleCloseModal}
            motionPreset="scale"
            scrollBehavior="inside"
            variant="sources-filter"
        >
            <ModalOverlay />
            <ModalContent>
                <FiltersForm />
            </ModalContent>
        </ModalContainer>
    );
};
