import { getEventBus } from '../../services/core/event-bus/event-bus';
import { isUrlIncludesDomain } from '../floating-app';

import { fixGmailHostStyles } from './gmail-host';
import { fixLinkedinHostStyles } from './linkedin';
import { fixMondayHostStyles } from './monday-host';
import { fixNotionHostStyles } from './notion-host';
import { ownbackupSupportHostStyles } from './ownbackup-support-host';
import { fixSalesforceHostStyles } from './salesforce-host';
import { slackWebHostStyles } from './slack-web';
import { fixZendeskHostStyles } from './zendesk-host';

export type HostStylesFixCallback = (sidebarWidth: number) => void;

const noOp = () => {};

const hostStylesFixesMap: Record<string, HostStylesFixCallback> = {
    'monday.com': fixMondayHostStyles,
    'mail.google.com': fixGmailHostStyles,
    'lightning.force.com': fixSalesforceHostStyles,
    notion: fixNotionHostStyles,
    ownbackup: ownbackupSupportHostStyles,
    'slack.com': slackWebHostStyles,
    'zendesk.com': fixZendeskHostStyles,
    'linkedin.com': fixLinkedinHostStyles,
};

const getHostStylesFix = (url: URL): HostStylesFixCallback => {
    const callback = Object.entries(hostStylesFixesMap).find(([key]) => isUrlIncludesDomain(url, key));

    return callback ? callback[1] : noOp;
};

export const sliderAppHostStylesFix = getHostStylesFix(new URL(window.location.href));

let triggerStylesFix = () => {};
export const updateHostDocumentWidth = (sidebarWidth: number) => {
    try {
        getEventBus().removeListener('tabURLChanged', triggerStylesFix);
    } catch {}

    if (sidebarWidth > 0) {
        const { marginLeft, marginRight } = getComputedStyle(document.body);
        const marginLeftParsed = isNaN(parseInt(marginLeft, 10)) ? 0 : parseInt(marginLeft, 10);
        const marginRightParsed = isNaN(parseInt(marginRight, 10)) ? 0 : parseInt(marginRight, 10);

        document.body.style.width = `calc(100% - ${sidebarWidth + marginLeftParsed + marginRightParsed}px)`;
    } else {
        document.body.style.width = '';
    }

    triggerStylesFix = () => sliderAppHostStylesFix(sidebarWidth);
    try {
        getEventBus().addListener('tabURLChanged', triggerStylesFix);
    } catch {}

    triggerStylesFix();
};
