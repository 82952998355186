import { RefObject, useCallback, useEffect, useRef } from 'react';

import { SHARED_PREVIEW_CACHE_KEY } from '../../api/consts';
import { useGetPreviewMutation } from '../../api/endpoints/preview-endpoint';
import { useGetSettingsQuery } from '../../api/endpoints/settings-endpoint';
import { AnnotationType, ILastPreviewData } from '../../api/types';
import { getEventBus } from '../../services/core/event-bus/event-bus';
import { ITrackEventOrigin, MixpanelEvent } from '../../services/mixpanel/types';
import { currentPreviewId, previewService } from '../../services/preview/preview-service';
import { LAST_PREVIEW_STORAGE_KEY } from '../../services/storage/storage-keys-list';
import { storageService } from '../../services/storage/storage-service';
import { isContentMode } from '../../utils/extension-mode';
import * as utils from '../../utils/pointer';
import { annotateByThumbThunk } from '../thunks/annotate-thunk';
import { sendMetrics } from '../thunks/metrics-thunk';
import { closePreview, restorePreview } from '../thunks/preview-thunk';

import { useAnswers, useCurrentAnswer } from './answer/answer-hooks';
import { useAppDispatch, useAppSelector } from './app-hooks';
import { useCopyLink } from './copy-link';
import { useGoToPage } from './go-to-page';
import { useProject, useSources } from './settings-hooks';
import {
    compileShortcuts,
    handleShortcut,
    SHORTCUT_COPY_LINK,
    SHORTCUT_ENTER,
    SHORTCUT_LEFT_ARROW,
    SHORTCUT_MINUS,
    SHORTCUT_PLUS,
} from './shortcut';

const POINTER_HIDE_INTERVAL_CONFIG_VALUE = parseInt(process.env.REACT_APP_PREVIEW_POINTER_TIMEOUT ?? '');
const POINTER_HIDE_INTERVAL = isNaN(POINTER_HIDE_INTERVAL_CONFIG_VALUE) ? 1400 : POINTER_HIDE_INTERVAL_CONFIG_VALUE;

const hidePreviousPointer = () => {
    if (isContentMode()) {
        previewService.hidePointer(currentPreviewId);
    } else {
        utils.hidePreviousPointer();
    }
};

export const useHighlightPointerContentScriptMode = () => {
    const { currentMatch } = useAppSelector((state) => state.modals.preview);

    const hideTimer = useRef<number>(0);

    const hidePointer = useCallback(() => {
        if (hideTimer.current === 0) {
            return;
        }

        clearTimeout(hideTimer.current);
        hideTimer.current = 0;
        hidePreviousPointer();
    }, []);

    const showPointer = useCallback(() => {
        previewService.showPointer(currentPreviewId, currentMatch);

        hideTimer.current = Number(
            setTimeout(() => {
                hidePointer();
            }, POINTER_HIDE_INTERVAL)
        );
    }, [currentMatch, hidePointer]);

    return {
        showPointer,
        hidePointer,
    };
};

export const useHighlightPointerPopupMode = () => {
    const hideTimer = useRef<number>(0);

    const hidePointer = useCallback(() => {
        if (hideTimer.current === 0) {
            return;
        }

        clearTimeout(hideTimer.current);
        hideTimer.current = 0;
        hidePreviousPointer();
    }, []);

    const showPointer = useCallback(
        (target: Document, currentMatch: number) => {
            utils.showPointer(target, currentMatch);

            hideTimer.current = Number(
                setTimeout(() => {
                    hidePointer();
                }, POINTER_HIDE_INTERVAL)
            );
        },
        [hidePointer]
    );

    return {
        showPointer,
        hidePointer,
    };
};

export const usePreviewKeyDownHandler = () => {
    const dispatch = useAppDispatch();
    const answer = useCurrentAnswer();
    const [, { data: answersData }] = useAnswers();
    const { sources } = useSources();
    const { project } = useProject();
    const goToPage = useGoToPage();
    const copyLink = useCopyLink();

    return useCallback(
        (event: KeyboardEvent) => {
            const handleCloseShortcut = () => {
                dispatch(closePreview());
                dispatch(
                    sendMetrics({
                        event: MixpanelEvent.CLOSE_SMART_PREVIEW,
                        meta: {
                            use_shortcut: true,
                            doc_id: answer?.doc_id,
                        },
                        injectAnswersMetrics: {
                            sources,
                        },
                    })
                );
            };

            const definitions = compileShortcuts({
                [SHORTCUT_LEFT_ARROW]: () => handleCloseShortcut(),
                [SHORTCUT_ENTER]: () => goToPage({ shortcutUsed: true, origin: ITrackEventOrigin.PREVIEW_WINDOW }),
                [SHORTCUT_PLUS]: () => {
                    if (!answer) {
                        return;
                    }

                    dispatch(annotateByThumbThunk({ answer, type: AnnotationType.THUMB_UP, project, sources }));
                    dispatch(
                        sendMetrics({
                            event: MixpanelEvent.THUMB_UP,
                            meta: {
                                use_shortcut: true,
                                origin: ITrackEventOrigin.PREVIEW_WINDOW,
                            },
                            injectAnswersMetrics: {
                                answers: answersData,
                                sources,
                            },
                        })
                    );
                },
                [SHORTCUT_MINUS]: () => {
                    if (!answer) {
                        return;
                    }

                    dispatch(annotateByThumbThunk({ answer, type: AnnotationType.THUMB_DOWN, project, sources }));
                    dispatch(
                        sendMetrics({
                            event: MixpanelEvent.THUMB_DOWN,
                            meta: {
                                use_shortcut: true,
                                origin: ITrackEventOrigin.PREVIEW_WINDOW,
                            },
                            injectAnswersMetrics: {
                                answers: answersData,
                                sources,
                            },
                        })
                    );
                },
                [SHORTCUT_COPY_LINK]: () =>
                    copyLink({
                        shortcutUsed: true,
                        origin: ITrackEventOrigin.PREVIEW_WINDOW,
                    }),
            });

            handleShortcut(definitions, event);
        },
        [dispatch, answer, sources, goToPage, project, answersData, copyLink]
    );
};

export const usePreviewIFrameShortcutsHandling = (targetRef: RefObject<HTMLIFrameElement>) => {
    const handleIFrameKeyDown = usePreviewKeyDownHandler();

    useEffect(() => {
        targetRef.current?.contentDocument?.removeEventListener('keydown', handleIFrameKeyDown);
    }, [handleIFrameKeyDown, targetRef]);

    useEffect(() => {
        const handlePreviewKeyDown = (payload: { event: KeyboardEvent; frameId?: string }) => {
            if (payload.frameId === currentPreviewId) {
                handleIFrameKeyDown(payload.event);
            }
        };

        getEventBus().addListener('preview-keydown', handlePreviewKeyDown);

        return () => {
            getEventBus().removeListener('preview-keydown', handlePreviewKeyDown);
        };
    }, [handleIFrameKeyDown]);
};

export interface IUseRestoreLastPreviewParams {
    metrics?: boolean;
}
export const useRestoreLastPreview = (params?: IUseRestoreLastPreviewParams) => {
    const { metrics = true } = params || {};
    const dispatch = useAppDispatch();
    const { visible: previewModalVisible } = useAppSelector((state) => state.modals.preview);
    const { data: settingsData } = useGetSettingsQuery();
    const [getPreview] = useGetPreviewMutation({
        fixedCacheKey: SHARED_PREVIEW_CACHE_KEY,
    });
    const { sources } = useSources();

    useEffect(() => {
        if (previewModalVisible) {
            return;
        }

        storageService.getStorageValue<ILastPreviewData | null>(LAST_PREVIEW_STORAGE_KEY, null).then((lastPreview) => {
            if (lastPreview) {
                const { timestamp = 0 } = lastPreview;
                const timestampDifference = Date.now() - timestamp;
                const previewReopenTimeout = settingsData?.close_smart_preview_after_ms ?? 0;

                if (timestampDifference <= previewReopenTimeout) {
                    getPreview(lastPreview);
                    dispatch(restorePreview(lastPreview));

                    if (metrics) {
                        dispatch(
                            sendMetrics({
                                event: MixpanelEvent.OPEN_SMART_PREVIEW,
                                meta: {
                                    use_shortcut: false,
                                },
                                injectAnswersMetrics: {
                                    sources,
                                },
                            })
                        );
                    }
                }
            }
        });
    }, [dispatch, getPreview, settingsData, previewModalVisible, sources, metrics]);
};
