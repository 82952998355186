import { ProactiveShowedForEntries, ProactiveShowedForMap } from '../../redux/types';

import { PROACTIVE_SOURCES_LIST } from './source/proactive-sources-list';
import { IProactiveSource } from './types';

/** Checks if the proactive showed for URL record is expired
 *
 * @param createdAt - time the record has been created
 * @param ttl       - TTL in milliseconds
 *
 * @returns
 */
export const isShowedForEntryExpired = (createdAt: number, ttl: number): boolean => {
    return Date.now() - createdAt > ttl;
};

/**
 * Creates a proactive showed for map from the local storage entries
 *
 * @param entries - entries from local storage
 */
export const prepareShowedForMap = (entries: ProactiveShowedForEntries | undefined): ProactiveShowedForMap => {
    return Object.fromEntries(entries ?? []);
};

/**
 * Returns proactive source class by a ticket URL
 *
 * @param url - a ticket URL
 *
 * @returns - appropriate class or undefined
 */
export const getSourceByURL = (url: string): IProactiveSource | undefined => {
    return PROACTIVE_SOURCES_LIST.find((source) => source.extractTicketId(url) !== undefined);
};

/**
 * Creates a unique ticket ID containing source name and a ticket number
 * @param url - a ticket URL
 *
 * @returns - ID or undefined
 */
export const getUniqueTicketId = (url: string): string | undefined => {
    const source = getSourceByURL(url);

    if (!source) {
        return undefined;
    }

    return `${source.getName()}:${source.extractTicketId(url)}`;
};
