import packageJSON from '../../../package.json';
import { baseApi } from '../base-api';
import { IUsageMetricsData } from '../types';

const CLIENT_TYPE = 'chrome_extension';

export const usageMetricsEndpoint = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        sendMetrics: builder.mutation<void, IUsageMetricsData>({
            query: ({ type, meta }) => ({
                url: '/v1/usage_metric',
                method: 'POST',
                body: {
                    client_type: CLIENT_TYPE,
                    client_version: packageJSON.version,
                    event_type: type,
                    data: meta,
                },
            }),
        }),
    }),
});

export const { useSendMetricsMutation } = usageMetricsEndpoint;
