import { batch } from 'react-redux';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { IAnswerResponse, IAttributionsAnswer, ILastPreviewData, IPreviewRequestParams } from '../../api/types';
import { LAST_PREVIEW_STORAGE_KEY } from '../../services/storage/storage-keys-list';
import { storageService } from '../../services/storage/storage-service';
import type { AppThunk, IRootState } from '../core-store';
import { useAnswers } from '../hooks/answer/answer-hooks';
import { clearFocus, setCollapsed, setPreviousAnswerUuid } from '../slices/answers/answers-slice';
import { closePreviewModal, showPreviewModal } from '../slices/modals/preview-modal-slice';

export const previewAnswer =
    (answer: IAnswerResponse | IAttributionsAnswer): AppThunk =>
    (dispatch, getState) => {
        const { activeAnswerUuid } = getState().answers;
        batch(() => {
            dispatch(setPreviousAnswerUuid(activeAnswerUuid));
            dispatch(clearFocus());
            dispatch(showPreviewModal(answer));
        });
    };

export const storeLastPreviewData = createAsyncThunk(
    'modals/preview/storeLastPreviewData',
    async (data: IPreviewRequestParams, { getState }) => {
        const { prevAnswerUuid, collapsed } = (getState() as IRootState).answers;
        const lastPreviewData: ILastPreviewData = {
            ...data,
            prevAnswerUuid,
            collapsed,
            timestamp: Date.now(),
        };

        await storageService.setStorageValue(LAST_PREVIEW_STORAGE_KEY, lastPreviewData);
    }
);

export const restorePreview = createAsyncThunk(
    'modals/preview/restore',
    async (lastPreview: ILastPreviewData, { dispatch }) => {
        const { prevAnswerUuid, collapsed, doc_id } = lastPreview;
        const [, { data }] = useAnswers();
        const answer = data?.answers.find((answer) => answer.doc_id === doc_id);

        if (!answer) {
            return;
        }

        batch(() => {
            dispatch(setPreviousAnswerUuid(prevAnswerUuid));
            dispatch(setCollapsed(collapsed));
            dispatch(clearFocus());
            dispatch(showPreviewModal(answer));
        });
    }
);

export const closePreview = createAsyncThunk('modals/preview/close', async (_, { dispatch }) => {
    try {
        await storageService.removeStorageValue(LAST_PREVIEW_STORAGE_KEY);
    } finally {
        dispatch(closePreviewModal());
    }
});
