import { ProactiveSourceAbstract } from './proactive-source-abstract';

export class ProactiveSourceIntercom extends ProactiveSourceAbstract {
    protected regex = /intercom\.com.*\/conversations?\/(\d+)/;
    protected regexMatchIndex = 1;

    getName() {
        return 'intercom';
    }
}
