import React, { ChangeEventHandler, KeyboardEventHandler, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormErrorMessage, IconButton, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { Field, FieldProps, useFormikContext } from 'formik';

import { ReactComponent as AddIcon } from '../../../assets/icons/websites-black-list/add-plus.svg';
import { getUrlOrigin, isValidUrl } from '../../../utils/url';

import { IWebsitesBlacklistFormState } from './WebsitesBlacklist';

import styles from './WebsitesBlacklist.module.scss';

export const WebsiteInput: React.FC = () => {
    const { t } = useTranslation('translations');
    const { errors, values } = useFormikContext<IWebsitesBlacklistFormState>();

    return (
        <Field name="url">
            {({ field, form }: FieldProps<string, IWebsitesBlacklistFormState>) => {
                const addDomain = async () => {
                    const valid = isValidUrl(field.value);

                    if (!valid) {
                        form.setFieldError(field.name, t('websites-blacklist.domain.invalid'));
                    } else {
                        const value = getUrlOrigin(field.value);

                        const unique = new Set([...values.origins, value]);

                        form.setFieldValue('origins', Array.from(unique));
                        form.setFieldValue(field.name, '');
                        form.setFieldError(field.name, undefined);
                    }
                };

                const handleKeyDown: KeyboardEventHandler = async (event) => {
                    if (event.key === 'Enter' && field.value) {
                        event.preventDefault();
                        event.stopPropagation();

                        await addDomain();
                    }
                };

                const handleClick: MouseEventHandler = async () => {
                    await addDomain();
                };

                const handleOnChange: ChangeEventHandler = (event) => {
                    if (errors.url) {
                        form.setFieldError(field.name, undefined);
                    }

                    field.onChange(event);
                };

                return (
                    <FormControl isInvalid={!!errors.url}>
                        <InputGroup size="sm">
                            <Input
                                size="sm"
                                name={field.name}
                                placeholder={t('websites-blacklist.domain.placeholder')}
                                value={field.value}
                                onChange={handleOnChange}
                                onKeyDown={handleKeyDown}
                            />
                            <InputRightElement>
                                <IconButton
                                    size="sm"
                                    colorScheme="transparent"
                                    color={styles.addButtonColor}
                                    aria-label={t('websites-blacklist.domain.add-tooltip')}
                                    icon={<AddIcon />}
                                    isDisabled={!field.value}
                                    onClick={handleClick}
                                    onKeyDown={handleKeyDown}
                                />
                            </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>{errors.url}</FormErrorMessage>
                    </FormControl>
                );
            }}
        </Field>
    );
};
