import { BackgroundFetchEmitStreamChunkPayload, ReadableStreamDefaultReadResult } from '../types';

export const STREAM_CHUNK_EVENT = 'stream-chunk';
export const STREAM_ERROR_EVENT = 'stream-error';

export const emitStreamChunk = async <T extends Uint8Array>(
    stream: ReadableStream<T>,
    meta?: Record<string, unknown>
) => {
    const finalPayload = { data: { value: null, done: true }, meta };
    const { eventBusEmitter } = await import(
        /* webpackMode: "eager" */
        '../../core/event-bus/event-bus-emitter'
    );
    const emit = (payload: BackgroundFetchEmitStreamChunkPayload) => {
        const tabId = payload?.meta?.tabId as number | undefined;
        eventBusEmitter.emit(STREAM_CHUNK_EVENT, tabId, payload);
    };

    if (stream) {
        const reader = stream.getReader();

        let result: ReadableStreamDefaultReadResult<T>;
        while (!(result = await reader.read()).done) {
            const payload = {
                data: {
                    value: result.value?.toString(),
                    done: false,
                },
                meta,
            };

            emit(payload);
        }
    }

    emit(finalPayload);
};

export const emitStreamError = async (requestId: string, meta?: Record<string, unknown>) => {
    const { eventBusEmitter } = await import(
        /* webpackMode: "eager" */
        '../../core/event-bus/event-bus-emitter'
    );
    const tabId = meta?.tabId as number | undefined;

    eventBusEmitter.emit(STREAM_ERROR_EVENT, tabId, { meta: { ...meta, requestId } });
};
