import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikErrors, FormikHelpers, FormikTouched } from 'formik/dist/types';

export const useTranslateErrors = <Values>(
    errors: FormikErrors<Values>,
    touched: FormikTouched<Values>,
    setFieldTouched: FormikHelpers<Values>['setFieldTouched']
) => {
    const { i18n } = useTranslation();

    const handleLanguageChange = useCallback(() => {
        Object.keys(errors).forEach((error) => {
            if (Object.keys(touched).includes(error)) {
                setFieldTouched(error);
            }
        });
    }, [errors, touched, setFieldTouched]);

    useEffect(() => {
        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [i18n, errors, handleLanguageChange]);
};
