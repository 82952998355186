import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react';

import packageJson from '../../../package.json';
import i18n from '../../i18n';
import { IRootState } from '../../redux/core-store';
import { getToastService } from '../../services/toast/toast-service';
import { baseApi } from '../base-api';
import {
    AnnotationType,
    ApiTagTypes,
    IAnnotateAnswerRequestParams,
    IAnnotateAnswerResponse,
    ResponseStatus,
} from '../types';

export const thumbTypes: AnnotationType[] = [
    AnnotationType.THUMB_UP,
    AnnotationType.THUMB_DOWN,
    AnnotationType.INCORRECT_INFO,
    AnnotationType.DID_NOT_ANSWER,
];

const baseApiWithTags = baseApi.enhanceEndpoints({
    addTagTypes: [ApiTagTypes.USER],
});

export const annotateEndpoint = baseApiWithTags.injectEndpoints({
    endpoints: (builder) => ({
        annotateAnswer: builder.mutation<IAnnotateAnswerResponse, IAnnotateAnswerRequestParams>({
            queryFn: async (arg, { getState }, extraOptions, baseQuery) => {
                const {
                    type,
                    question,
                    question_id,
                    answer,
                    isArticleOrSection,
                    title,
                    url,
                    isAnswerMissing,
                    isProbablyExists,
                    project,
                    sources,
                    additionalData,
                    details,
                    unique_answer_id,
                } = arg;
                const state = getState() as IRootState;
                const {
                    auth: { user },
                    uat: { enabled: isUATEnabled },
                } = state;

                const body = {
                    answer: isArticleOrSection ? '' : answer,
                    ...(isArticleOrSection && { answer_is_article_section: true }),
                    customer_name: project,
                    is_test_set: false,
                    question,
                    question_id,
                    role: user.role,
                    title,
                    type,
                    url,
                    ...(isAnswerMissing && { answer_is_missing_from_docs: true }),
                    ...(isProbablyExists && { answer_not_found_by_ask_ai: true }),
                    user_id: user.uid,
                    user_org: user.user_org,
                    username: user.username,
                    selected_sources: sources?.filter((source) => source.active).map((source) => source.id),
                    is_thumbs_up_down: thumbTypes.includes(type),
                    client_version: packageJson.version,
                    ...(additionalData && { aux_data: additionalData }),
                    ...(details && { thumbs_down_details: details }),
                    unique_answer_id,
                    uat_mode: isUATEnabled,
                };

                const annotateResponse = await baseQuery({
                    url: '/v0_1/annotate_answer',
                    method: 'POST',
                    body,
                });

                const { data, error, meta } = annotateResponse as QueryReturnValue<
                    boolean,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >;

                if (error) {
                    const displayErrorForAnnotations = [
                        AnnotationType.ANNOTATE,
                        AnnotationType.THUMB_UP,
                        AnnotationType.THUMB_DOWN,
                    ];

                    if (error.status !== ResponseStatus.UNAUTHORIZED && displayErrorForAnnotations.includes(type)) {
                        const message = i18n.t('error.api.annotate', { ns: 'errors' });

                        getToastService().error(message, meta);
                    }

                    return {
                        error,
                    };
                }

                return {
                    data: !!data,
                };
            },
            invalidatesTags: [ApiTagTypes.USER],
        }),
    }),
});
