import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react';

import { storeNotificationId } from '../../redux/thunks/notification-thunk';
import { getLogger } from '../../utils/logger';
import { baseApi } from '../base-api';
import { ApiTagTypes, INotificationApiResponsePayload, Notification } from '../types';

const notificationsApiUrl = process.env.REACT_APP_API_NOTIFICATIONS_URL || '';

const logger = getLogger('notifications-endpoint');

const baseApiWithTags = baseApi.enhanceEndpoints({
    addTagTypes: [ApiTagTypes.NOTIFICATIONS],
});

export const notificationsEndpoint = baseApiWithTags.injectEndpoints({
    endpoints: (builder) => ({
        getLastNotification: builder.query<Notification | null, void>({
            providesTags: [ApiTagTypes.NOTIFICATIONS],
            queryFn: async (arg, { dispatch }, extraOptions, baseQuery) => {
                const apiResponse = await baseQuery({
                    url: `${notificationsApiUrl}/notifications/v2/last`,
                });

                const { data, error } = apiResponse as QueryReturnValue<
                    INotificationApiResponsePayload<Notification | null>,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >;

                dispatch(storeNotificationId(data?.data));

                if (error) {
                    logger.error('Failed to get last notification', error);

                    return { data: null };
                }

                return { data: data?.data || null };
            },
        }),
        markAsSeen: builder.mutation<null, Pick<Notification, 'id'>>({
            invalidatesTags: [ApiTagTypes.NOTIFICATIONS],
            queryFn: async (arg, baseQueryApi, extraOptions, baseQuery) => {
                const apiResponse = await baseQuery({
                    url: `${notificationsApiUrl}/notifications/v2/${arg.id}/seen`,
                    method: 'POST',
                });

                const { error } = apiResponse as QueryReturnValue<
                    INotificationApiResponsePayload,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >;

                if (error) {
                    logger.error('Failed to mark notification as seen', error);

                    return { error };
                }

                return { data: null };
            },
        }),
    }),
});

export const { useGetLastNotificationQuery, useMarkAsSeenMutation } = notificationsEndpoint;
