export const environmentBackend = process.env.REACT_APP_API_URL ?? '';
export const SHARED_PREVIEW_CACHE_KEY = 'shared-preview-post';
export const MATCH_ID_TEMPLATE = 'ask_ai_match';
export const PROACTIVE_MOCKED_ANSWER_ID = '__proactive_mocked_answer_id__';
export const ASK_AI_PROJECT_ID = process.env.REACT_APP_ASK_AI_PROJECT_ID ?? 'ask_ai';

export const POST_UNINSTALL_URL = process.env.REACT_APP_POST_UNINSTALL_URL;
export const ASK_WEB_PORTAL_URL = process.env.REACT_APP_ASK_WEB_PORTAL_URL ?? '';

export const DEFAULT_ANNOTATE_TAG_VALUE = 'NA';

export const PROACTIVE_FREQUENCY_GROUP_UNSPECIFIED = 'unspecified';

export const AnswersSortOrder = {
    DEFAULT: 'DEFAULT',
    ASC: 'ASC',
    DESC: 'DESC',
    DESC_PAST_ANNOTATIONS: 'DESC_PAST_ANNOTATIONS',
};

export const AnswersSortOrderTranslationKeys = {
    [AnswersSortOrder.DEFAULT]: 'annotate-answers.sort-order.default',
    [AnswersSortOrder.ASC]: 'annotate-answers.sort-order.ascending',
    [AnswersSortOrder.DESC]: 'annotate-answers.sort-order.descending',
    [AnswersSortOrder.DESC_PAST_ANNOTATIONS]: 'annotate-answers.sort-order.descending-past-annotations',
};

export const SUPPORTED_FEATURES = {
    attribution: 2,
};

export const QUESTION_QUERY_PARAMS_KEY = 'q';
export const ORIGINAL_QUESTION_ID_QUERY_PARAMS_KEY = 'original_question_id';
export const SHARED_QUERY_PARAMS_KEY = '_shared';
export const HIDDEN_QUERY_PARAMS_KEY = 'params';
export const PROJECT_STORAGE_KEY = 'customer_project_id';
export const SOURCES_STORAGE_KEY = 'disabled_sources';
