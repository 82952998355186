import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './LoadDocError.module.scss';

export const LoadDocError: React.FC = () => {
    const { t } = useTranslation('errors');

    return (
        <div className={styles.wrapper}>
            <main>
                <div className={styles.hint}>
                    <h1>{t('error.api.preview')}</h1>
                </div>
            </main>
        </div>
    );
};
