import { useTranslation } from 'react-i18next';

import { ReactComponent as PopupIcon } from '../../../../assets/icons/pop-up.svg';

import styles from './PopupAlert.module.scss';

export const PopupAlert = () => {
    const { t } = useTranslation('translations');

    return (
        <div className={styles.wrapper}>
            <PopupIcon />
            <div className={styles.textWrapper}>
                <span>{t('login.popup-alert.display')}</span>
            </div>
        </div>
    );
};
