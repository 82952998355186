import { BaseQueryFn, createApi, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';

import { setToken } from '../redux/slices/auth/auth-slice';
import { logout } from '../redux/thunks/user-thunk';
import { ssoService } from '../services/sso/sso-service';
import { SSOLogoutReason } from '../services/sso/types';

import { queryWithDynamicBackend } from './api';
import { ApiTagTypes } from './types';

const ERROR_CODES_FOR_RE_AUTHORIZE = new Set(['401', '403']);
const reAuthMutex = new Mutex();

const getLogoutReason = (status: FetchBaseQueryError['status']): SSOLogoutReason => {
    const statusString = String(status);

    const reason = Object.entries(SSOLogoutReason).find(([key]) => key === statusString);

    if (!reason) {
        return SSOLogoutReason.UNKNOWN;
    }

    return reason[1];
};

const queryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, options) => {
    await reAuthMutex.waitForUnlock();
    let result = await queryWithDynamicBackend(args, api, options);
    const requestUrl = typeof args === 'string' ? args : args.url;

    if (result.error && ERROR_CODES_FOR_RE_AUTHORIZE.has(String(result.error.status))) {
        if (!reAuthMutex.isLocked()) {
            const releaseReAuthMutex = await reAuthMutex.acquire();

            try {
                const newToken = await ssoService.safeForceRefreshToken();

                if (newToken) {
                    api.dispatch(setToken(newToken.accessToken));
                    result = await queryWithDynamicBackend(args, api, options);
                } else {
                    api.dispatch(
                        logout({
                            logoutFromPortal: false,
                            reason: getLogoutReason(result.error.status),
                            requestUrl: requestUrl,
                        })
                    );
                }
            } finally {
                releaseReAuthMutex();
            }
        } else {
            await reAuthMutex.waitForUnlock();
            result = await queryWithDynamicBackend(args, api, options);
        }
    }

    return result;
};

export const baseApi = createApi({
    reducerPath: 'api/base',
    baseQuery: queryWithReAuth,
    endpoints: () => ({}),
    tagTypes: Object.values(ApiTagTypes),
});
