import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Flex,
    Link,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
} from '@chakra-ui/react';

import { ReactComponent as CheckIcon } from '../../../assets/icons/success-check.svg';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/app-hooks';
import { closeSuccessModal } from '../../../redux/slices/modals/success-modal-slice';
import { restoreFocus } from '../../../redux/thunks/focus-thunk';
import { browserService } from '../../../services/browser/browser-service';
import { ModalContainer } from '../Container/ModalContainer';

import styles from './SuccessModal.module.scss';

export const SuccessModal: React.FC = () => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();
    const { visible, header, message, description, link } = useAppSelector((state) => state.modals.success);

    const handleCloseModal = () => {
        dispatch(closeSuccessModal());
    };

    const handleLinkClick = useCallback(async () => {
        if (!link) return;

        link.clickCallback();
        await browserService.createTab({ url: link.url, active: false, selected: true });
    }, [link]);

    return (
        <ModalContainer
            isOpen={visible}
            onClose={handleCloseModal}
            motionPreset="scale"
            scrollBehavior="inside"
            onCloseComplete={() => dispatch(restoreFocus())}
        >
            <ModalOverlay />
            <ModalContent className={styles.content}>
                <ModalHeader>{header}</ModalHeader>
                <ModalBody className={styles.body} data-testid="modal.success.body">
                    <CheckIcon />
                    <Text className={styles.message}>{message}</Text>
                    <Flex gap="1" align="center">
                        <Text className={styles.description}>{description}</Text>
                        {link && (
                            <Link variant="main" className={styles.link} onClick={handleLinkClick}>
                                {link.label}
                            </Link>
                        )}
                    </Flex>
                </ModalBody>
                <ModalFooter className={styles.footer}>
                    <Button
                        type="button"
                        variant="secondary"
                        onClick={handleCloseModal}
                        data-testid="modal.success.close"
                    >
                        {t('button.close')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </ModalContainer>
    );
};
