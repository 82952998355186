import { useMemo } from 'react';
import { useAppSelector } from 'answers-core/src/redux/hooks/app-hooks';
import { normalize } from 'answers-core/src/utils/strings';
import isStopWord from 'is-stop-words';

export const useAnnotatedTextHighlightQuery = () => {
    const { annotate_answers } = useAppSelector((state) => state.annotateAnswers);

    return useMemo(
        () =>
            annotate_answers.question
                .split(' ')
                .filter((word) => !isStopWord(word))
                .map(normalize),
        [annotate_answers.question]
    );
};
