const fixSectionFooters = (sidebarWidth: number) => {
    const footers = document.querySelectorAll<HTMLElement>(`#main_panes section footer`);

    if (footers.length === 0) {
        return;
    }

    footers.forEach((footer) => {
        footer.style.right = `${sidebarWidth}px`;
    });
};

const fixTicketFooters = (sidebarWidth: number) => {
    const footers = document.querySelectorAll<HTMLElement>(`[data-test-id=ticket-footer]`);

    if (footers.length === 0) {
        return;
    }

    footers.forEach((footer) => {
        if (window.getComputedStyle(footer)?.position === 'fixed') {
            footer.style.right = `${sidebarWidth}px`;
        }
    });
};

export const fixZendeskHostStyles = (sidebarWidth: number) => {
    fixSectionFooters(sidebarWidth);
    fixTicketFooters(sidebarWidth);
};
