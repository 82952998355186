import { createSlice } from '@reduxjs/toolkit';

interface IPointsModalState {
    visible: boolean;
}

const initialState: IPointsModalState = {
    visible: false,
};

export const pointsModalSlice = createSlice({
    name: 'points-modal',
    initialState,
    reducers: {
        openPointsModal(state) {
            state.visible = true;
        },
        closePointsModal(state) {
            state.visible = false;
        },
    },
});

export const { openPointsModal, closePointsModal } = pointsModalSlice.actions;
