import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IAnnotateTagsModalState {
    visible: boolean;
    editTagName: string | null;
}

const initialState: IAnnotateTagsModalState = {
    visible: false,
    editTagName: null,
};

export const annotateTagsModalSlice = createSlice({
    name: 'annotate-modal',
    initialState,
    reducers: {
        openAnnotateEditTagModal(state, action: PayloadAction<string>) {
            state.visible = true;
            state.editTagName = action.payload;
        },
        closeAnnotateEditTagModal(state) {
            state.visible = false;
            state.editTagName = null;
        },
    },
});

export const { openAnnotateEditTagModal, closeAnnotateEditTagModal } = annotateTagsModalSlice.actions;
