import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio } from '@chakra-ui/react';
import cx from 'clsx';

import { CheckBox } from '../../../../../components/Controls/CheckBox/CheckBox';

import { ReactComponent as FilterIcon } from './filter-icon.svg';

import styles from './ListItem.module.scss';

export interface ListItemProps {
    name: string;
    id: number;
    isActive: boolean;
    maxSelection: number;
    handleSelect: () => void;
    shouldCheckboxBeDisabled: () => boolean;
    handleSelectOne: () => void;
}

export const ListItem: React.FC<ListItemProps> = ({
    name,
    id,
    isActive,
    maxSelection,
    handleSelect,
    shouldCheckboxBeDisabled,
    handleSelectOne,
}) => {
    const { t } = useTranslation('translations');

    const renderListItem = () => {
        return (
            <div className={styles.wrapper}>
                <div className={styles.icon}>
                    <FilterIcon />
                </div>
                <span
                    className={cx(styles.text, {
                        [styles.textBold]: isActive,
                    })}
                >
                    {name}
                </span>
            </div>
        );
    };
    return (
        <li className={styles.listItem} key={id}>
            {maxSelection === 1 ? (
                <div className={styles.itemWrapper}>
                    <Radio isChecked={isActive} onChange={() => handleSelect()}>
                        {renderListItem()}
                    </Radio>
                </div>
            ) : (
                <CheckBox
                    className={cx(styles.itemWrapper, {
                        [styles.disabledCheckbox]: shouldCheckboxBeDisabled(),
                    })}
                    isChecked={isActive}
                    onChange={() => handleSelect()}
                >
                    {renderListItem()}
                </CheckBox>
            )}

            <button
                className={cx(styles.action, styles.actionSelectOnly)}
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    e.stopPropagation();

                    handleSelectOne();
                }}
            >
                {t('smart-filters.modal.item.show-only')}
            </button>
        </li>
    );
};
