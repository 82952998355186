import React from 'react';

import { PreviewModalBody } from './Body/PreviewModalBody';
import { PreviewModalHeader } from './Header/PreviewModalHeader';

export const ViewHtml: React.FC = () => {
    return (
        <>
            <PreviewModalHeader />
            <PreviewModalBody />
        </>
    );
};
