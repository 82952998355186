import { batch } from 'react-redux';

import { MixpanelEvent } from '../../services/mixpanel/types';
import { AppThunk } from '../core-store';
import {
    _closeOriginsBlacklistModal,
    _openOriginsBlacklistModal,
} from '../slices/modals/origins-blacklist-modal-slice';

import { sendMetrics } from './metrics-thunk';

export const openOriginsBlacklistModal =
    (metricsMeta?: Record<string, any>): AppThunk =>
    (dispatch) => {
        batch(() => {
            dispatch(_openOriginsBlacklistModal());
            dispatch(
                sendMetrics({
                    event: MixpanelEvent.WEBSITES_BLACKLIST_OPEN_SETTINGS,
                    meta: metricsMeta,
                })
            );
        });
    };

export const closeOriginsBlacklistModal =
    (metricsMeta?: Record<string, any>): AppThunk =>
    (dispatch) => {
        batch(() => {
            dispatch(_closeOriginsBlacklistModal());
            dispatch(
                sendMetrics({
                    event: MixpanelEvent.WEBSITES_BLACKLIST_CLOSE_SETTINGS,
                    meta: metricsMeta,
                })
            );
        });
    };
