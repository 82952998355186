export enum AppToggle {
    FLOATING_APP = 'floating-app', // If the user have access to the entire floating app, including: AI helper and floating bubble
    FLOATING_APP_WHITELIST = 'floating-app.whitelist', // Whitelist for the floating app, will enforce the whitelist if turned on, if turned of will display in all sites. Whitelist override is set as a variant
    RECENT_SEARCHES = 'recent-searches', // If the user is able to view their recent searches
    PROACTIVE_ANSWERS = 'proactive-answers', // Proactively answer the user question
    PROACTIVE_ANSWERS_MOCK = 'proactive-answers.mock', // Proactively answer the user question
    PROACTIVE_ANSWERS_DIRECT_ANSWER_POPUP = 'proactive-answers.direct-answer-popup', // Display a tiny popup with a direct answer when the sidebar is closed
    PROACTIVE_ANSWERS_FLOATING_ANIMATION = 'proactive-answers.floating-animation', // Display animated bubble instead of red dot when proactive answers are ready and contains direct answer
    PROACTIVE_ANSWERS_FREQUENCY = 'proactive-answers.frequency', // Allows to spawn proactive answer request based on probability value of a user group (classified based on how often users use the proactive feature)
    HANDLE_LAST_UPDATED_AT = 'handle_last_updated_at', // Show and have a re-ranking based on the document last updated at
    PDF_VIEWER_ENABLED = 'pdf_viewer', // Enable the PDF viewer for the smart preview
    UAT_MODE = 'uat_mode', // Enable the UAT mode to force users to give feedback for questions/answers
    ASK_AN_EXPERT = 'ask_an_expert', // Show ask an expert button only to chosen customers
    TRAINING_ENABLEMENT_CARD = 'training_enablement_card', // Enable the training and enablement quiz
    CHAT_TAB = 'chat-tab',
    CHAT_TAB_FILTERS = 'chat-tab-filters',
    CHAT_TAB_HISTORY = 'chat-tab-history', // Enables the list of user's chats in the chat tab
    ADD_KNOWLEDGE = 'add_knowledge', // Enables add knowledge to the AskAI Knowledgebase feature
    MULTI_LINGUAL_SHOW_LANG_SELECTOR = 'multi-lingual.show_lang_selector', // Show the language selector in the settings modal
    SMART_FILTERS = 'ask-smart-filters', // ability to smart-filters on ask search
    APPS_V2 = 'apps-v2',
    GET_PAGE_TEXT_V2 = 'get-page-text-v2',
}
