import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react';
import { filesToBase64 } from 'answers-core/src/utils/preview';

import i18n from '../../i18n';
import { getToastService } from '../../services/toast/toast-service';
import { baseApi } from '../base-api';
import {
    IGetChannelMembersParams,
    IGetSlackMembersParams,
    ISendAskAnExpertRequestParams,
    ISendAskAnExpertResponse,
    ISlackChannelsResponse,
    ISlackMember,
    ISlackMemberResponse,
    ISlackMembersResponse,
    ISlackOption,
    ResponseStatus,
} from '../types';

export const askAnExpertEndpoint = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        sendAskAnExpert: builder.mutation<string, ISendAskAnExpertRequestParams>({
            queryFn: async (arg, _queryApi, _extraOptions, baseQuery) => {
                const { body, channel, projectId } = arg;
                const base64files = await filesToBase64(body.files);

                const response = await baseQuery({
                    url: `/v1/ask_an_expert/customer/${projectId}/channel/${channel}/with_files`,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    body: {
                        ...body,
                        files: base64files,
                    },
                });

                const { error, data, meta } = response as QueryReturnValue<
                    ISendAskAnExpertResponse,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >;

                if (error) {
                    const message = i18n.t('error.api.ask-an-expert', { ns: 'errors' });

                    if (error.status !== ResponseStatus.UNAUTHORIZED) {
                        getToastService().error(message, meta);
                    }

                    return {
                        error,
                    };
                }
                return {
                    data: data?.slack_link || '',
                };
            },
        }),
        getSlackChannels: builder.query<ISlackOption[], string | undefined>({
            queryFn: async (projectId, _queryApi, _extraOptions, baseQuery) => {
                const response = await baseQuery({
                    url: `/v1/ask_an_expert/customer/${projectId}/channels`,
                    method: 'GET',
                });

                const { error, data } = response as QueryReturnValue<
                    ISlackChannelsResponse,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >;

                if (error) {
                    if (error.status !== ResponseStatus.NOT_FOUND) {
                        const message = i18n.t('error.api.get-slack-channels', { ns: 'errors' });
                        getToastService().error(message);
                    }

                    return {
                        error,
                    };
                }
                const channels = data.channels.map((channel) => ({ value: channel.id, label: channel.label }));
                return { data: channels };
            },
        }),
        getSlackChannelMembers: builder.query<ISlackMember[], IGetChannelMembersParams>({
            queryFn: async (arg, _queryApi, _extraOptions, baseQuery) => {
                const { projectId, channelId } = arg;
                const response = await baseQuery({
                    url: `/v1/ask_an_expert/customer/${projectId}/channel/${channelId}/members`,
                    method: 'GET',
                });

                const { error, data } = response as QueryReturnValue<
                    ISlackMembersResponse,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >;

                if (error) {
                    if (error.status !== ResponseStatus.NOT_FOUND) {
                        const message = i18n.t('error.api.get-slack-channel-members', { ns: 'errors' });
                        getToastService().error(message);
                    }

                    return {
                        error,
                    };
                }
                return { data: data.members };
            },
        }),
        getSlackUsers: builder.query<ISlackOption[], IGetSlackMembersParams>({
            queryFn: async (args, _queryApi, _extraOptions, baseQuery) => {
                const { projectId, shouldFetchAllMembers, slackId } = args;
                if (shouldFetchAllMembers) {
                    const response = await baseQuery({
                        url: `/v1/ask_an_expert/customer/${projectId}/members`,
                        method: 'GET',
                    });

                    const { error, data } = response as QueryReturnValue<
                        ISlackMembersResponse,
                        FetchBaseQueryError,
                        FetchBaseQueryMeta
                    >;

                    if (error) {
                        const message = i18n.t('error.api.get-slack-users', { ns: 'errors' });
                        getToastService().error(message);

                        return {
                            error,
                        };
                    }
                    return {
                        data:
                            data.members.map((member: ISlackMember) => ({
                                value: member.id,
                                label: member.name,
                            })) || [],
                    };
                } else {
                    if (!slackId) {
                        return { data: [] };
                    }

                    const response = await baseQuery({
                        url: `/v1/ask_an_expert/customer/${projectId}/member/${slackId}`,
                        method: 'GET',
                    });

                    const { error, data } = response as QueryReturnValue<
                        ISlackMemberResponse,
                        FetchBaseQueryError,
                        FetchBaseQueryMeta
                    >;

                    if (error) {
                        return {
                            error,
                        };
                    }
                    return { data: [{ label: data.member.name, value: data.member.id }] };
                }
            },
        }),
    }),
});

export const { useGetSlackChannelsQuery, useGetSlackUsersQuery, useLazyGetSlackChannelMembersQuery } =
    askAnExpertEndpoint;
