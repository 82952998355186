import { ssoService } from '../../../services/sso/sso-service';

type GetTokenAndRoles = (params: { previousToken?: string }) => Promise<{ token: string | null; roles: string[] }>;
export const getTokenAndRoles: GetTokenAndRoles = async ({ previousToken }) => {
    const emptyRoles: string[] = [];
    try {
        const user = await ssoService.getUser();

        if (!user) {
            // The user is NOT logged
            return { token: null, roles: emptyRoles };
        }

        const roles = user?.roles?.map((role) => role.key) ?? emptyRoles;
        return {
            roles,
            token: user.accessToken,
        };
    } catch {
        return { token: previousToken ?? null, roles: emptyRoles };
    }
};
