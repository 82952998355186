import React from 'react';
import { PopoverCloseButton, PopoverContent, Textarea } from '@chakra-ui/react';

import styles from './GeneralComment.module.scss';

export interface IPopoverCommentContent {
    inputRef: React.RefObject<HTMLTextAreaElement>;
    value: string;
    onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
}

export const PopoverGeneralCommentContent: React.FC<IPopoverCommentContent> = ({ inputRef, value, onChange }) => {
    return (
        <PopoverContent w="100%">
            <PopoverCloseButton size="sm" left="3px" color="#1565C0" />
            <div className={styles.commentForm}>
                <Textarea
                    value={value}
                    onChange={onChange}
                    ref={inputRef}
                    py="4px"
                    px="6px"
                    minHeight="48px"
                    minWidth="450px"
                />
            </div>
        </PopoverContent>
    );
};
