import { useCallback } from 'react';

import { AnnotationType } from '../../api/types';
import { ITrackEventOrigin, MixpanelEvent } from '../../services/mixpanel/types';
import { getAnswerUrl } from '../../utils/answer';
import { goToPageThunk } from '../thunks/go-to-page-thunk';
import { sendMetrics } from '../thunks/metrics-thunk';

import { useAnswers, useCurrentMatchUrl } from './answer/answer-hooks';
import { useAnalytics } from './analytics';
import { useAppDispatch, useAppSelector } from './app-hooks';
import { useSources } from './settings-hooks';

export interface IGoToPageMeta {
    answerId?: string;
    shortcutUsed: boolean;
    origin: ITrackEventOrigin;
}

export const useGoToPage = () => {
    const dispatch = useAppDispatch();
    const { currentAnswerId } = useAppSelector((state) => state.modals.preview);
    const [, { data }] = useAnswers();
    const [log] = useAnalytics();
    const extractUrl = useCurrentMatchUrl();
    const { sources } = useSources();

    return useCallback(
        (meta: IGoToPageMeta) => {
            const { answerId, shortcutUsed, origin } = meta;
            const idToGo = answerId ?? currentAnswerId;
            const answerToGo = data?.answers.find((answer) => answer.doc_id === idToGo);

            const url =
                origin === ITrackEventOrigin.ANSWERS_LIST
                    ? getAnswerUrl(answerToGo)
                    : extractUrl() ?? getAnswerUrl(answerToGo);

            dispatch(goToPageThunk(url));
            answerToGo && log(answerToGo, AnnotationType.CLICK);
            answerToGo &&
                dispatch(
                    sendMetrics({
                        event: MixpanelEvent.VISIT_LINK,
                        meta: {
                            selected_answer: answerToGo,
                            link: url,
                            use_shortcut: shortcutUsed,
                            from: origin,
                        },
                        injectAnswersMetrics: {
                            sources,
                        },
                    })
                );
        },
        [currentAnswerId, data?.answers, extractUrl, dispatch, log, sources]
    );
};
