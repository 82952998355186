import { Action, configureStore, createDynamicMiddleware, ThunkAction } from '@reduxjs/toolkit';

import { baseApi } from '../api/base-api';

import { onMouseDragMiddleware } from './middlewares/on-mouse-drag-middleware';
import { onSidebarResizeMiddleware } from './middlewares/on-sidebar-resize-middleware';
import { coreReducer } from './core-reducer';

const dynamicMiddleware = createDynamicMiddleware();

export const coreStore = configureStore({
    reducer: coreReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(
            baseApi.middleware,
            dynamicMiddleware.middleware,
            onMouseDragMiddleware,
            onSidebarResizeMiddleware
        ),
});

export type IRootState = ReturnType<typeof coreReducer>;
export type IAppDispatch = typeof coreStore.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, IRootState, unknown, Action>;

interface MiddlewareApiConfig {
    state: IRootState;
    dispatch: IAppDispatch;
}

export const { addMiddleware } = dynamicMiddleware;

export const dispatch = coreStore.dispatch;
export const appAddMiddleWare = addMiddleware.withTypes<MiddlewareApiConfig>();
