import * as React from 'react';

import { getLogger } from '../../utils/logger';

const logger = getLogger('usePreLoadImage hook');

export const usePreLoadImage = (imageSrc?: string) => {
    const [isLoaded, setLoaded] = React.useState<boolean>(false);
    const [isError, setError] = React.useState<boolean>(false);

    React.useEffect(() => {
        setLoaded(false);
        setError(false);

        if (imageSrc) {
            const handleLoad = () => {
                setLoaded(true);
            };

            const handleError = () => {
                logger.error('Failed to load image', { imageSrc });
                setError(true);
            };

            const image = new Image();
            image.addEventListener('load', handleLoad);
            image.addEventListener('error', handleError);
            image.src = imageSrc;

            return () => {
                image.removeEventListener('load', handleLoad);
                image.removeEventListener('error', handleError);
            };
        }
    }, [imageSrc, setLoaded, setError]);

    return {
        isLoaded,
        src: imageSrc,
        isError,
    };
};
