import React from 'react';
import { Spinner } from '@chakra-ui/react';

import styles from './PDFViewer.module.scss';

export const PDFViewerLoader = () => (
    <div className={styles.spinner}>
        <Spinner />
    </div>
);
