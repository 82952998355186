import styles from './LabelAnswer.module.scss';

export const naLabel = {
    value: 'NA',
    defaultStyles: { color: styles.annotateLabelLightGrey },
    checkedStyles: { color: styles.annotateLabelBlue },
    previouslyCheckedStyles: { color: styles.annotateLabelPrevChecked },
};

export const levelLabels = [
    {
        label: '1',
        value: '1',
        checkedStyles: { backgroundColor: styles.annotateLabelRed, color: styles.annotateLabelTextWhite },
    },
    {
        label: '1.5',
        value: '1.5',
        decimal: true,
        checkedStyles: { background: styles.annotateLabelGradientRedOrange },
    },
    {
        label: '2',
        value: '2',
        checkedStyles: { backgroundColor: styles.annotateLabelOrange, color: styles.annotateLabelTextWhite },
    },
    {
        label: '2.5',
        value: '2.5',
        decimal: true,
        checkedStyles: { background: styles.annotateLabelGradientOrangeYellow },
    },
    {
        label: '3',
        value: '3',
        checkedStyles: { backgroundColor: styles.annotateLabelYellow, color: styles.annotateLabelTextWhite },
    },
    {
        label: '3.5',
        value: '3.5',
        decimal: true,
        checkedStyles: { background: styles.annotateLabelGradientYellowLightGreen },
    },
    {
        label: '4',
        value: '4',
        checkedStyles: { backgroundColor: styles.annotateLabelLightGreen, color: styles.annotateLabelTextWhite },
    },
    {
        label: '4.5',
        value: '4.5',
        decimal: true,
        checkedStyles: { background: styles.annotateLabelGradientLightGreenGreen },
    },
    {
        label: '5',
        value: '5',
        checkedStyles: { backgroundColor: styles.annotateLabelGreen, color: styles.annotateLabelTextWhite },
    },
];
