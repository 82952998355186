import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';

import { MATCH_ID_TEMPLATE, SHARED_PREVIEW_CACHE_KEY } from '../../../../api/consts';
import { useGetPreviewMutation } from '../../../../api/endpoints/preview-endpoint';
import { AnnotationType, PreviewType } from '../../../../api/types';
import { ReactComponent as Arrow } from '../../../../assets/icons/arrow-left.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import { useAnalytics } from '../../../../redux/hooks/analytics';
import { useAnswers, useCurrentAnswer } from '../../../../redux/hooks/answer/answer-hooks';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks/app-hooks';
import { usePrevious } from '../../../../redux/hooks/previous';
import { useProject, useSources } from '../../../../redux/hooks/settings-hooks';
import { nextMatch, prevMatch } from '../../../../redux/slices/modals/preview-modal-slice';
import { sendMetrics } from '../../../../redux/thunks/metrics-thunk';
import { MixpanelEvent } from '../../../../services/mixpanel/types';
import { getAnswerQuery } from '../../../../utils/answer';
import { Tooltip } from '../../../Tooltips/Tooltip';

import styles from './PreviewModalHeader.module.scss';

export const PreviewSearch: React.FC = () => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();
    const { currentAnswerId } = useAppSelector((state) => state.modals.preview);
    const { project } = useProject();
    const [log] = useAnalytics();
    const [, { data: answersData }] = useAnswers();
    const { sources } = useSources();
    const [getPreview, { data }] = useGetPreviewMutation({
        fixedCacheKey: SHARED_PREVIEW_CACHE_KEY,
    });
    const isPreviewAvailable = data?.type !== PreviewType.REFERENCE;

    const answer = useCurrentAnswer();

    const initialQuestion = getAnswerQuery(answer);
    const [question, setQuestion] = useState<string>(initialQuestion);
    const prevQuestion = usePrevious(question);

    const handleQuestionChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {
            target: { value },
        } = e;

        setQuestion(value);
    };

    const handleQuestionKeyPress = async (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.code === 'Enter') {
            if (question === prevQuestion) {
                dispatch(nextMatch());
            } else {
                const result = await getPreview({
                    doc_id: currentAnswerId ?? '',
                    query: question,
                    project,
                    source_id: answer?.source_id,
                    source_type: answer?.source_type,
                }).unwrap();
                dispatch(
                    sendMetrics({
                        event: MixpanelEvent.SEARCH_WITHIN_SMART_PREVIEW,
                        meta: { query: question },
                        injectAnswersMetrics: {
                            answers: answersData,
                            sources,
                        },
                    })
                );
                if (answer) {
                    if (result.content.search(MATCH_ID_TEMPLATE) !== -1) {
                        log(answer, AnnotationType.SEARCH_PREVIEW, question);
                    } else {
                        log(answer, AnnotationType.SEARCH_PREVIEW_FAILED, question);
                    }
                }
            }
        }
    };

    const handlePrevButtonClick = () => {
        dispatch(prevMatch());
    };

    const handleNextButtonClick = () => {
        dispatch(nextMatch());
    };

    return (
        <div className={styles.search}>
            <InputGroup>
                <Input
                    value={question}
                    onChange={handleQuestionChange}
                    onKeyDown={handleQuestionKeyPress}
                    placeholder={t('preview.search.placeholder')}
                    disabled={!isPreviewAvailable}
                    tabIndex={-1}
                />
                <InputRightElement width={styles.rightElementLength}>
                    <SearchIcon className={styles.searchButton} />
                    <hr className={styles.divider} />
                    <div className={styles.selector}>
                        <Tooltip label={t('preview.hint.next')} placement="bottom-end" hasArrow>
                            <Arrow className={styles.nextButton} onClick={handleNextButtonClick} />
                        </Tooltip>
                        <Tooltip label={t('preview.hint.previous')} placement="bottom-end" hasArrow>
                            <Arrow className={styles.prevButton} onClick={handlePrevButtonClick} />
                        </Tooltip>
                    </div>
                </InputRightElement>
            </InputGroup>
        </div>
    );
};
