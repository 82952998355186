import { batch } from 'react-redux';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { PROACTIVE_MOCKED_ANSWER_ID } from '../../api/consts';
import { proactiveAnswersEndpoint } from '../../api/endpoints/proactive/proactive-answers-endpoint';
import { clearPollingTimer } from '../../api/endpoints/proactive/proactive-answers-endpoint-helpers';
import { DirectAnswerStatus } from '../../api/types';
import {
    MixpanelEvent,
    ProactiveGenerativeStartedScenario,
    ProactiveShownScenario,
} from '../../services/mixpanel/types';
import { PROACTIVE_POPUP_SHOWED_FOR } from '../../services/storage/storage-keys-list';
import { storageService } from '../../services/storage/storage-service';
import { tabsSyncService } from '../../services/tabs/tabs-sync-service';
import { getUniqueTicketId, isShowedForEntryExpired } from '../../utils/proactive-answers/proactive-answer';
import { SHARED_PROACTIVE_ANSWERS_CACHE_KEY } from '../constants';
import { AppThunk, IRootState } from '../core-store';
import {
    _setProactiveDirectAnswerReady,
    _setShowedForTTL,
    _setTicketVisited,
    setFloatingPopupVisible,
    setLoading,
    setLoadingMessage,
    setProactiveNoDirectAnswerBannerVisible,
    setResultReady,
    setSessionId,
    updateShowedFor,
} from '../slices/proactive-answers/proactive-answers-slice';
import { setProactivelyGenerated, setQuestion } from '../slices/question/question-slice';
import { IProactiveAnswersState, ProactiveShowedForEntries } from '../types';

import { sendMetrics } from './metrics-thunk';

const MESSAGE_UPDATE_INTERVAL = 2000;
const messages = [
    'answers.spinner.text.proactive.initial',
    'answers.spinner.text.proactive.searching',
    'answers.spinner.text.proactive.populating',
];
let timerId: ReturnType<typeof setInterval> | undefined = undefined;

export const startLoading = createAsyncThunk('proactive-answers/startLoading', async (_, { dispatch }) => {
    batch(() => {
        dispatch(setQuestion(''));
        dispatch(
            setFloatingPopupVisible({
                visible: false,
                byUser: false,
            })
        );
        dispatch(setProactivelyGenerated(true));
        dispatch(setLoading(true));
        dispatch(setResultReady(false));
        dispatch(setProactiveDirectAnswerReady(false));
        dispatch(setProactiveNoDirectAnswerBannerVisible(false));
    });

    clearInterval(timerId);

    let messageIndex = -1;

    const updateMessage = () => {
        messageIndex += 1;
        dispatch(setLoadingMessage(messages[messageIndex]));

        if (messageIndex >= messages.length - 1) {
            clearInterval(timerId);
        }
    };

    timerId = setInterval(updateMessage, MESSAGE_UPDATE_INTERVAL);

    updateMessage();
});

export interface IEndLoadingThunkParams {
    resultReady: boolean;
    expectDirectAnswer?: boolean;
}

export const endLoading = createAsyncThunk(
    'proactive-answers/endLoading',
    async (params: IEndLoadingThunkParams, { dispatch }) => {
        const { resultReady, expectDirectAnswer = false } = params;

        clearInterval(timerId);

        batch(() => {
            if (!expectDirectAnswer) {
                dispatch(setLoading(false));
            }

            dispatch(setResultReady(resultReady));
        });
    }
);

export const abortProactiveQuestion = (): AppThunk => (dispatch) => {
    clearPollingTimer();
    const proactiveRequest = dispatch(
        proactiveAnswersEndpoint.util.getRunningMutationThunk('getProactiveAnswers', SHARED_PROACTIVE_ANSWERS_CACHE_KEY)
    );

    if (proactiveRequest) {
        proactiveRequest.abort();

        dispatch(
            sendMetrics({
                event: MixpanelEvent.PROACTIVE_CANCELED,
                injectProactiveFrequencyMetrics: {},
            })
        );

        batch(() => {
            dispatch(setSessionId(null));
            dispatch(endLoading({ resultReady: false }));
        });
    }
};

export const pendingStatusLoading = (): AppThunk => (dispatch) => {
    clearInterval(timerId);
    dispatch(setLoadingMessage('answers.spinner.text.proactive.waiting'));
};

export const setProactiveTicketVisited = createAsyncThunk(
    'proactive-answers/setProactiveTicketVisited',
    async (ticketURL: string, { dispatch, getState }) => {
        const {
            question: { proactiveMeta },
            settings: { fullTicketAppUrl },
        } = getState() as IRootState;

        if (!proactiveMeta || fullTicketAppUrl) {
            return;
        }

        const ticketId = getUniqueTicketId(ticketURL);

        if (!ticketId) {
            return;
        }

        dispatch(_setTicketVisited(ticketId));

        const {
            proactiveAnswers: { showedFor, showedForTTL },
        } = getState() as IRootState;

        const entriesFilteredByTTL = Object.entries(showedFor).filter(([, createdAt]) =>
            showedForTTL ? !isShowedForEntryExpired(createdAt, showedForTTL) : true
        );
        await storageService.setStorageValue(PROACTIVE_POPUP_SHOWED_FOR, entriesFilteredByTTL);
        await tabsSyncService.syncProactiveShowedFor(entriesFilteredByTTL);
    }
);

export const setProactiveDirectAnswerReady = createAsyncThunk(
    'proactive-answers/setProactiveDirectAnswerReady',
    async (ready: boolean, { dispatch, getState }) => {
        dispatch(_setProactiveDirectAnswerReady(ready));

        if (ready) {
            const {
                question: { proactiveMeta },
            } = getState() as IRootState;

            if (!proactiveMeta) {
                return;
            }

            dispatch(setProactiveTicketVisited(proactiveMeta.original_url));
        }
    }
);

interface IHandleDirectAnswerLoadedThunkParams {
    questionId: string;
    isAnswerable: boolean;
    status: DirectAnswerStatus;
}

export const handleDirectAnswerLoaded = createAsyncThunk(
    'proactive-answers/handleDirectAnswerLoaded',
    async (params: IHandleDirectAnswerLoadedThunkParams, { dispatch, getState }) => {
        const { questionId: directAnswerQuestionId, isAnswerable, status } = params;
        const {
            proactiveAnswers: { resultReady, questionId, directAnswerReady },
            app: { sidebarVisible },
            question: { proactiveMeta, proactivelyGenerated },
        } = getState() as IRootState;

        dispatch(setLoading(false));

        if (directAnswerQuestionId === questionId) {
            if (!isAnswerable && status === DirectAnswerStatus.DONE) {
                dispatch(setProactiveNoDirectAnswerBannerVisible(true));
            }
        }

        if (proactivelyGenerated && isAnswerable && !directAnswerReady) {
            dispatch(
                sendMetrics({
                    event: MixpanelEvent.PROACTIVE_GENERATIVE_STARTED,
                    meta: {
                        item_id: proactiveMeta?.item_id,
                        item_url: proactiveMeta?.original_url,
                        scenario: sidebarVisible
                            ? ProactiveGenerativeStartedScenario.FLOATING_OPEN
                            : ProactiveGenerativeStartedScenario.POPUP,
                    },
                    injectProactiveFrequencyMetrics: {},
                })
            );
        }

        if (sidebarVisible) {
            if (questionId && proactivelyGenerated && isAnswerable && !directAnswerReady) {
                dispatch(
                    sendMetrics({
                        event: MixpanelEvent.PROACTIVE_SHOWN,
                        meta: {
                            item_id: proactiveMeta?.item_id,
                            item_url: proactiveMeta?.original_url,
                            question_id: questionId,
                            scenario: ProactiveShownScenario.FLOATING_OPEN,
                        },
                        injectProactiveFrequencyMetrics: {},
                    })
                );
                dispatch(setProactiveDirectAnswerReady(true));
            }

            return;
        }

        if (directAnswerQuestionId === PROACTIVE_MOCKED_ANSWER_ID) {
            batch(() => {
                dispatch(
                    setFloatingPopupVisible({
                        visible: true,
                        byUser: false,
                    })
                );
                dispatch(setProactiveDirectAnswerReady(true));
            });

            return;
        }

        if (!resultReady || directAnswerQuestionId !== questionId) {
            return;
        }

        batch(() => {
            dispatch(
                setFloatingPopupVisible({
                    visible: isAnswerable,
                    byUser: false,
                })
            );
            dispatch(setProactiveDirectAnswerReady(isAnswerable));
        });
    }
);

export const closeFloatingPopup = (): AppThunk => (dispatch, getState) => {
    const {
        question: { proactiveMeta },
    } = getState() as IRootState;

    batch(() => {
        dispatch(
            setFloatingPopupVisible({
                visible: false,
                byUser: true,
            })
        );
        dispatch(setProactiveDirectAnswerReady(false));
    });

    dispatch(
        sendMetrics({
            event: MixpanelEvent.PROACTIVE_POPUP_CLOSED,
            meta: {
                item_id: proactiveMeta?.item_id,
                item_url: proactiveMeta?.original_url,
            },
            injectProactiveFrequencyMetrics: {},
        })
    );
};

export const closeFloatingPopupDueFrequencyLimit = createAsyncThunk(
    'proactive-answers/closeFloatingPopupDueFrequencyLimit',
    async (_, { dispatch }) => {
        await dispatch(endLoading({ resultReady: false }));

        dispatch(setProactiveDirectAnswerReady(false));
        dispatch(
            setFloatingPopupVisible({
                visible: false,
                byUser: false,
            })
        );
    }
);

let lastProactiveShownMetricsQuestionId: string = '';

export const sendProactiveShownMetrics =
    (questionId: string): AppThunk =>
    (dispatch, getState) => {
        const {
            question: { proactiveMeta },
            proactiveAnswers: { floatingAnimationEnabled, directAnswerPopupEnabled },
        } = getState() as IRootState;

        const getScenario = (): ProactiveShownScenario => {
            if (floatingAnimationEnabled && directAnswerPopupEnabled) {
                return ProactiveShownScenario.FLOATING_ANIMATION_AND_POPUP;
            }

            if (floatingAnimationEnabled) {
                return ProactiveShownScenario.FLOATING_ANIMATION;
            }

            if (directAnswerPopupEnabled) {
                return ProactiveShownScenario.POPUP;
            }

            return ProactiveShownScenario.RED_DOT;
        };

        if (lastProactiveShownMetricsQuestionId !== questionId) {
            dispatch(
                sendMetrics({
                    event: MixpanelEvent.PROACTIVE_SHOWN,
                    meta: {
                        item_id: proactiveMeta?.item_id,
                        item_url: proactiveMeta?.original_url,
                        question_id: questionId,
                        scenario: getScenario(),
                    },
                    injectProactiveFrequencyMetrics: {},
                })
            );

            lastProactiveShownMetricsQuestionId = questionId;
        }
    };

export const setShowedForTTL = createAsyncThunk(
    'proactive-answers/setShowedForTTL',
    async (ttl: IProactiveAnswersState['showedForTTL'], { getState, dispatch }) => {
        const {
            proactiveAnswers: { showedForTTL },
        } = getState() as IRootState;

        if (ttl !== showedForTTL) {
            if (ttl !== undefined) {
                const entriesFromLocalStorage = await storageService.getStorageValue<ProactiveShowedForEntries>(
                    PROACTIVE_POPUP_SHOWED_FOR,
                    []
                );
                const entriesFilteredByTTL = entriesFromLocalStorage.filter(
                    ([, createdAt]) => !isShowedForEntryExpired(createdAt, ttl)
                );

                dispatch(updateShowedFor(entriesFilteredByTTL));
                await storageService.setStorageValue(PROACTIVE_POPUP_SHOWED_FOR, entriesFilteredByTTL);
            }

            dispatch(_setShowedForTTL(ttl));
        }
    }
);
