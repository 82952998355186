import moment from 'moment';

import { DatesFilterRelativeName, IDateFilter } from '../../../../api/types';

type DatesFilterMapping = {
    [key in DatesFilterRelativeName]: (startDate?: string, endDate?: string) => IDateFilter;
};

export const datesFilterMapping: DatesFilterMapping = {
    [DatesFilterRelativeName.ANY_TIME]: () => ({
        relative_name: DatesFilterRelativeName.ANY_TIME,
        start_date_iso: undefined,
        end_date_iso: undefined,
    }),
    [DatesFilterRelativeName['30D']]: () => ({
        relative_name: DatesFilterRelativeName['30D'],
        start_date_iso: moment().subtract(30, 'days').startOf('day').utc().toISOString(),
        end_date_iso: moment().endOf('day').utc().toISOString(),
    }),
    [DatesFilterRelativeName['3M']]: () => ({
        relative_name: DatesFilterRelativeName['3M'],
        start_date_iso: moment().subtract(3, 'months').startOf('month').utc().toISOString(),
        end_date_iso: moment().endOf('day').utc().toISOString(),
    }),
    [DatesFilterRelativeName['6M']]: () => ({
        relative_name: DatesFilterRelativeName['6M'],
        start_date_iso: moment().subtract(6, 'months').startOf('month').utc().toISOString(),
        end_date_iso: moment().endOf('day').utc().toISOString(),
    }),
    [DatesFilterRelativeName['12M']]: () => ({
        relative_name: DatesFilterRelativeName['12M'],
        start_date_iso: moment().subtract(12, 'months').startOf('month').utc().toISOString(),
        end_date_iso: moment().endOf('day').utc().toISOString(),
    }),
    [DatesFilterRelativeName['CUSTOM']]: (startDate?: string, endDate?: string) => ({
        relative_name: DatesFilterRelativeName.CUSTOM,
        start_date_iso: startDate ?? undefined,
        end_date_iso: endDate ?? undefined,
    }),
};

export const formatSelectedDates = (startDate: Date, endDate: Date): string => {
    return `${moment(startDate).format('MMM DD, YYYY')} - ${moment(endDate).format('MMM DD, YYYY')}`;
};
