import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CopyIcon } from '../../../../../../assets/icons/copy-link-action.svg';
import { ReactComponent as CopyPrivateIcon } from '../../../../../../assets/icons/copy-link-private-action.svg';
import { useAppSelector } from '../../../../../../redux/hooks/app-hooks';
import { useCopyLink } from '../../../../../../redux/hooks/copy-link';
import { ITrackEventOrigin } from '../../../../../../services/mixpanel/types';
import { EntityType, PublicPrivateTooltip } from '../../../../../Tooltips/PublicPrivate/PublicPrivateTooltip';
import { IAnswerActionsProps } from '../../Actions';

import styles from '../CopyAction.module.scss';

export const CopyLinkAction: React.FC<IAnswerActionsProps> = ({ answer }) => {
    const { t } = useTranslation('translations');
    const copyLink = useCopyLink();
    const { visible } = useAppSelector((state) => state.modals.preview);

    const handleButtonClick: MouseEventHandler = (e) => {
        e.stopPropagation();

        copyLink({
            answerId: answer.doc_id,
            shortcutUsed: false,
            origin: visible ? ITrackEventOrigin.PREVIEW_WINDOW : ITrackEventOrigin.ANSWERS_LIST,
        });
    };

    if (!answer) {
        return null;
    }

    const className = answer.source_is_public ? styles.public : styles.private;
    const Icon = answer.source_is_public ? CopyIcon : CopyPrivateIcon;

    return (
        <PublicPrivateTooltip answer={answer} type={EntityType.LINK}>
            <Icon title={t('button.copy.link')} onClick={handleButtonClick} className={className} />
        </PublicPrivateTooltip>
    );
};
