import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { IUseSmartFilter } from '../../../../api/types';
import { useAppDispatch } from '../../../../redux/hooks/app-hooks';
import { useSmartFilters } from '../../../../redux/hooks/settings-hooks';
import { sendMetrics } from '../../../../redux/thunks/metrics-thunk';
import { MixpanelEvent } from '../../../../services/mixpanel/types';
import { Accordion } from '../Accordion/Accordion';
import { FILTER_KEY, IFormValues } from '../FiltersForm';

import { ListItem } from './ListItem/ListItem';

import styles from './ListOfSmartFilters.module.scss';

const MAX_SELECTIONS = 1;

interface ListOfSmartFiltersProps {
    maxSelection?: number;
}
export const ListOfSmartFilters: React.FC<ListOfSmartFiltersProps> = ({ maxSelection = MAX_SELECTIONS }) => {
    const { t } = useTranslation('translations');
    const { values, setFieldValue } = useFormikContext<IFormValues>();
    const dispatch = useAppDispatch();
    const { canUseSmartFilters } = useSmartFilters();
    const smartFilters = values.smartFilters;

    const filterValue = values[FILTER_KEY].trim();

    const smartFiltersToRender = useMemo(() => {
        if (!filterValue) {
            return smartFilters;
        }

        return smartFilters.filter((filter) => filter.name.toLowerCase().includes(filterValue.toLowerCase()));
    }, [smartFilters, filterValue]);

    const selectedCount = useMemo(() => {
        return smartFilters.filter((filter) => filter.active).length;
    }, [smartFilters]);

    const sendSelectMetrics = useCallback(
        (filterIndex: number) => {
            dispatch(
                sendMetrics({
                    event: MixpanelEvent.SMART_FILTERS_MODAL_ITEM_CLICK,
                    meta: {
                        clickedFilter: { ...smartFilters[filterIndex] },
                    },
                })
            );
        },
        [dispatch, smartFilters]
    );

    const handleSelectedOne = useCallback(
        (filterIdx: number, filters: IUseSmartFilter[]) => {
            filters.forEach((_, idx) => {
                setFieldValue(`smartFilters[${idx}].active`, filterIdx === idx);

                if (filterIdx === idx) {
                    sendSelectMetrics(filterIdx);
                }
            });
        },
        [setFieldValue, sendSelectMetrics]
    );

    const handleSelect = useCallback(
        (filterIdx: number, filters: IUseSmartFilter[]) => {
            const isSelected = filters[filterIdx].active;

            if (selectedCount < maxSelection || isSelected) {
                setFieldValue(`smartFilters[${filterIdx}].active`, !isSelected);
            }

            if (maxSelection === 1) {
                filters.forEach((_, idx) => {
                    setFieldValue(`smartFilters[${idx}].active`, filterIdx === idx ? !isSelected : false);
                });
            }

            sendSelectMetrics(filterIdx);
        },
        [setFieldValue, selectedCount, sendSelectMetrics, maxSelection]
    );

    const shouldCheckboxBeDisabled = useCallback(
        (filterId: string) => {
            return selectedCount === maxSelection && !smartFilters.find((filter) => filterId === filter.id)?.active;
        },
        [smartFilters, maxSelection, selectedCount]
    );

    const handleClearAll = useCallback(() => {
        smartFilters.forEach((_, idx) => {
            setFieldValue(`smartFilters[${idx}].active`, false);
        });
    }, [setFieldValue, smartFilters]);

    const shouldRenderRadioButtons = maxSelection === 1;

    if (!canUseSmartFilters) {
        return <></>;
    }

    return (
        <Accordion
            title={t('smart-filters.modal.title')}
            handleClearAll={handleClearAll}
            selectionCount={{ selectedCount, maxSelection }}
        >
            {selectedCount === maxSelection && !shouldRenderRadioButtons && (
                <Text className={styles.maxSelectedLabel}>{t('smart-filters.modal.chosen-maximum-label')}</Text>
            )}
            <ul className={styles.list}>
                {smartFiltersToRender.map((filterRender, idx, initArray) => {
                    return (
                        <ListItem
                            key={filterRender.name}
                            id={idx}
                            name={filterRender.name}
                            isActive={filterRender.active}
                            maxSelection={maxSelection}
                            handleSelect={() => {
                                handleSelect(idx, initArray);
                            }}
                            handleSelectOne={() => {
                                handleSelectedOne(idx, initArray);
                            }}
                            shouldCheckboxBeDisabled={() => shouldCheckboxBeDisabled(filterRender.id)}
                        />
                    );
                })}
            </ul>
        </Accordion>
    );
};
