import React from 'react';
import { PopoverCloseButton, PopoverContent, Textarea } from '@chakra-ui/react';

import styles from './CommentAnswer.module.scss';

export interface IPopoverCommentContent {
    inputRef: React.RefObject<HTMLTextAreaElement>;
    value: string;
    onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
}

export const PopoverCommentContent: React.FC<IPopoverCommentContent> = ({ inputRef, value, onChange }) => {
    return (
        <PopoverContent maxWidth="130px">
            <PopoverCloseButton size="sm" left="3px" color="#1565C0" />
            <div className={styles.commentForm}>
                <Textarea value={value} onChange={onChange} ref={inputRef} py="4px" px="6px" />
            </div>
        </PopoverContent>
    );
};
