import { isContentMode } from '../../../utils/extension-mode';

import { EventBusStub } from './implementations/event-bus-stub';
import { EventBusListenersMap, IEventBus } from './types';

let eventBusInstance: IEventBus<EventBusListenersMap> | null = null;

export const initEventBus = async () => {
    if (!isContentMode()) {
        eventBusInstance = new EventBusStub();
    } else {
        const eventBusRuntimeModule = await import(
            /* webpackMode: "eager" */
            './implementations/event-bus-runtime'
        );

        eventBusInstance = new eventBusRuntimeModule.default();
    }
};

export const getEventBus = () => {
    if (!eventBusInstance) {
        throw Error("The Event Bus isn't initialized. Call the `initEventBus` first.");
    }

    return eventBusInstance;
};
