import { createAsyncThunk } from '@reduxjs/toolkit';

import { annotateEndpoint } from '../../api/endpoints/annotate-endpoint';
import { AnnotationType, IAnswerResponse, IDirectAnswerResult, IUseSource } from '../../api/types';
import { getUserProject } from '../../utils/user';
import { IRootState } from '../core-store';

export interface ISendAnnotationPayload {
    answer: IAnswerResponse;
    type: AnnotationType;
    additionalData?: string;
    sources: IUseSource[];
}

export const sendAnnotationThunk = createAsyncThunk(
    'analytics/annotate',
    async (payload: ISendAnnotationPayload, { dispatch, getState }) => {
        const state = getState() as IRootState;
        const { answer, type, sources, additionalData } = payload;

        const {
            question: { question, question_id },
        } = state;
        const project = getUserProject(state);

        return dispatch(
            annotateEndpoint.endpoints.annotateAnswer.initiate({
                type,
                question,
                question_id,
                answer: answer.text,
                title: answer.title,
                isArticleOrSection: false,
                isAnswerMissing: false,
                isProbablyExists: false,
                url: answer.url,
                project,
                sources,
                additionalData,
                unique_answer_id: answer.unique_answer_id,
            })
        );
    }
);

export interface ISendDirectAnswerAnnotationPayload {
    directAnswer: IDirectAnswerResult;
    type: AnnotationType;
    sources: IUseSource[];
}

export const sendDirectAnswerAnnotationThunk = createAsyncThunk(
    'analytics/annotate-direct-answer',
    async (payload: ISendDirectAnswerAnnotationPayload, { dispatch, getState }) => {
        const state = getState() as IRootState;
        const { directAnswer, type, sources } = payload;

        const {
            question: { question, question_id },
        } = state;
        const project = getUserProject(state);

        return dispatch(
            annotateEndpoint.endpoints.annotateAnswer.initiate({
                type,
                question,
                question_id,
                project,
                sources,
                answer: directAnswer.answer,
                unique_answer_id: question_id,
                title: '',
                url: '',
                isArticleOrSection: false,
                isAnswerMissing: false,
                isProbablyExists: false,
            })
        );
    }
);
