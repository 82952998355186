import { isWebMode } from '../../utils/extension-mode';
import { createWorkerService } from '../core/worker/worker-service';
import { ServiceWorkerContext } from '../types';

import { IBrowserInfo, IBrowserTab, ICreateTabProps } from './types';

const createTab = async (props: ICreateTabProps): Promise<IBrowserTab> => {
    const { url, active, selected } = props;

    if (isWebMode()) {
        window.open(url, active ? '_self' : '_blank');

        return {
            id: undefined,
        };
    } else {
        const browser = require('webextension-polyfill');

        if (active) {
            return browser.tabs.update({ url });
        }

        return browser.tabs.create({ url, active: Boolean(selected) });
    }
};

const getInfo = async (): Promise<IBrowserInfo> => {
    if (isWebMode()) {
        return {
            version: 'dev',
            os: 'dev',
        };
    } else {
        const browser = require('webextension-polyfill');

        const info = await browser.runtime.getPlatformInfo();

        return {
            version: 'unknown',
            os: info.os,
        };
    }
};

const service = createWorkerService({
    name: 'browser',
    context: ServiceWorkerContext.BACKGROUND,
    handlers: () => ({
        createTab,
        getInfo,
    }),
});

export const browserService = service.actions;

export const registerBrowserService = service.register;
