import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'clsx';

import { useCurrentAnswer } from '../../../../redux/hooks/answer/answer-hooks';
import { useAppSelector } from '../../../../redux/hooks/app-hooks';
import { usePreviewIFrameShortcutsHandling } from '../../../../redux/hooks/preview';
import { useProject } from '../../../../redux/hooks/settings-hooks';
import { currentPreviewId, getPreviewPdfURL, previewService } from '../../../../services/preview/preview-service';
import { getAnswerQuery } from '../../../../utils/answer';

import styles from './PreviewModalBody.module.scss';

export const IFrameContentScriptModePdf: React.FC = () => {
    const { t } = useTranslation('translations');
    const sidebarResizeStarted = useAppSelector((state) => state.resize.sidebarResizeStarted);
    const answer = useCurrentAnswer();
    const initialQuestion = getAnswerQuery(answer);
    const { project } = useProject();

    const answerContentRef = useRef<HTMLIFrameElement>(null);

    usePreviewIFrameShortcutsHandling(answerContentRef);

    const sendContentToPreviewFrame = async () => {
        if (answer) {
            const payload = {
                doc_id: answer.doc_id,
                project_id: project,
                source_id: answer.source_id,
                source_type: answer.source_type,
                initial_question: initialQuestion,
            };

            await previewService.loadPdfPreview(currentPreviewId, payload);
        }
    };

    const src = answer ? getPreviewPdfURL() : undefined;

    return (
        <>
            <iframe
                ref={answerContentRef}
                src={src}
                title={t('preview.hint.content')}
                onLoad={sendContentToPreviewFrame}
                className={cx({ [styles.disableEvents]: sidebarResizeStarted })}
            />
            <div className={styles.dimmer} />
        </>
    );
};
