let gmailMainContainerRef: HTMLElement | null = null;

export const fixGmailHostStyles = (sidebarWidth: number) => {
    const windowWidth = window.innerWidth;

    if (!gmailMainContainerRef) {
        const maybeFound =
            document.querySelector<HTMLElement>(`div[style*="width: ${window.innerWidth}px"]`) ||
            document.querySelector<HTMLElement>(`div[style*="width: ${window.innerWidth - sidebarWidth}px"]`);

        if (maybeFound) {
            window.addEventListener(
                'resize',
                () => {
                    maybeFound.style.width = `${document.body.clientWidth}px`;
                },
                { passive: true }
            );

            gmailMainContainerRef = maybeFound;
        }
    }

    if (gmailMainContainerRef) {
        gmailMainContainerRef.style.width = `${windowWidth - sidebarWidth}px`;

        const gmailMessageContainer = document.querySelector<HTMLElement>('div[style*="float:"]');

        if (gmailMessageContainer) {
            gmailMessageContainer.style.marginInlineEnd = `${sidebarWidth}px`;
        }
    }

    window.dispatchEvent(new Event('resize'));
};
