import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

import styles from './theme.module.scss';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tableAnatomy.keys);

const sizes = {
    'generative-answer': definePartsStyle({
        th: defineStyle({
            padding: '4px 12px',
            fontSize: 'xs',
            fontWeight: 700,
            lineHeight: '1',
            color: styles.textDarkGray,
        }),
        td: defineStyle({
            padding: '8px 12px',
            fontSize: 'xs',
            lineHeight: '1',
            color: styles.textPositive,
        }),
    }),
};

export const tableTheme = defineMultiStyleConfig({ sizes });
