import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, ModalHeader } from '@chakra-ui/react';

import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';

import { PreviewSearch } from './PreviewSearch';
import { PreviewTitleContent } from './PreviewTitleContent';

import styles from './PreviewModalHeader.module.scss';

export const PreviewModalHeader: React.FC = () => {
    const { t } = useTranslation('translations');
    const [searchMode, setSearchMode] = useState<boolean>(false);

    const renderHeaderContent = () => {
        if (searchMode) {
            return <PreviewSearch />;
        }

        return (
            <div className={styles.title}>
                <PreviewTitleContent />
                <IconButton
                    className={styles.searchIconButton}
                    variant="preview-search"
                    aria-label={t('preview.search.aria-label')}
                    icon={<SearchIcon />}
                    onClick={() => setSearchMode(true)}
                />
            </div>
        );
    };

    return <ModalHeader className={styles.wrapper}>{renderHeaderContent()}</ModalHeader>;
};
