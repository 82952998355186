import * as Yup from 'yup';

export const validationSchema = () =>
    Yup.object().shape({
        title: Yup.string().defined().required(),
        answer: Yup.string().defined().required(),
        accessLevel: Yup.object()
            .shape({
                value: Yup.string().defined().required(),
            })
            .required(),
    });
