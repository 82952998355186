import React from 'react';
import { useTranslation } from 'react-i18next';

import { IAnswerResponse } from '../../../api/types';
import { ReactComponent as PrivateResourceIcon } from '../../../assets/icons/private-resource.svg';
import { ReactComponent as PublicResourceIcon } from '../../../assets/icons/public-resource.svg';
import { ITooltipProps, Tooltip } from '../Tooltip';

import copyActionStyles from '../../Answers/Answer/Actions/Copy/CopyAction.module.scss';

export enum EntityType {
    LINK = 'link',
    ANSWER = 'answer',
}

export interface IPublicPrivateTooltip {
    answer: IAnswerResponse;
    type: EntityType;
    children: ITooltipProps['children'];
}

export const PublicPrivateTooltip: React.FC<IPublicPrivateTooltip> = ({ answer, type, children }) => {
    const { t } = useTranslation('translations');

    const renderTooltipContent = () => {
        return (
            <div className={copyActionStyles.tooltip}>
                {answer.source_is_public ? (
                    <>
                        <PublicResourceIcon />
                        <span>{t(`${type}.copy.tooltip.public`)}</span>
                    </>
                ) : (
                    <>
                        <PrivateResourceIcon />
                        <span>{t(`${type}.copy.tooltip.private`)}</span>
                    </>
                )}
            </div>
        );
    };

    return (
        <Tooltip label={renderTooltipContent()} placement="top-end" hasArrow>
            {children}
        </Tooltip>
    );
};
