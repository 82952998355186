import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IAnnotateDirectAnswerModalState {
    visible: boolean;
    questionId?: string;
}

const initialState: IAnnotateDirectAnswerModalState = {
    visible: false,
};

export const directAnswerFeedbackModalSlice = createSlice({
    name: 'annotate-direct-answer-modal',
    initialState,
    reducers: {
        openDirectAnswerFeedbackModal(state, action: PayloadAction<string>) {
            state.visible = true;
            state.questionId = action.payload;
        },
        closeDirectAnswerFeedbackModal(state) {
            state.visible = false;
            state.questionId = undefined;
        },
    },
});

export const { openDirectAnswerFeedbackModal, closeDirectAnswerFeedbackModal } = directAnswerFeedbackModalSlice.actions;
