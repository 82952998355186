export enum AppVariants {
    FLOATING_APP_WHITELIST = 'floating-app.whitelist',
    PROACTIVE_ANSWERS_URLS_WHITELIST = 'proactive-answers.urls-whitelist',
    PROACTIVE_ANSWERS_DIRECT_ANSWER_POPUP = 'proactive-answers.direct-answer-popup', // Controls how many lines of text should be displayed within the direct answer popup
    PROACTIVE_ANSWERS_KEEP_TRACK_OF_VISITED = 'proactive-answers.keep-track-of-visited', // Turns on track of visited ticket URLs to avoid displaying of repeated proactive popups for the same ticket
    UAT_MODE_MODAL = 'uat_mode.modal',
    UAT_MODE_POPUP = 'uat_mode.popup',
    SCRAPPING_AI_HELPERS_URLS_WHITELIST = 'ai-helpers.scrapping-urls-whitelist',
    FULL_WEBPAGE_AI_HELPERS_URLS_BLACKLIST = 'ai-helpers.full-webpage-urls-blacklist', // The list of URLs where apps with the 'full_webpage' trigger type is disallowed
    DIRECT_ANSWER_CHAT = 'direct_answer.chat',
}

export enum AppVariantNames {
    PROACTIVE_ANSWERS_KEEP_TRACK_OF_VISITED_TTL = 'ttl-minutes',
}
