import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloudIcon } from 'answers-core/src/assets/icons/cloud.svg';
import { useAppDispatch, useAppSelector } from 'answers-core/src/redux/hooks/app-hooks';
import { setFilesDraggingOver } from 'answers-core/src/redux/slices/modals/ask-an-expert-modal-slice';

import styles from './DragAndDropArea.module.scss';

interface IDragAndDrop {
    onFileDrop: Function;
}

export const DragAndDropArea: FC<IDragAndDrop> = ({ onFileDrop }) => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();
    const isVisible = useAppSelector((state) => state.modals.askAnExpert.areFilesDraggingOver);

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        // To prevent blinks while dragging over logo
        if (!event.relatedTarget || !(event.currentTarget as HTMLElement).contains(event.relatedTarget as Node)) {
            dispatch(setFilesDraggingOver(false));
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        dispatch(setFilesDraggingOver(false));
        onFileDrop(Array.from(event.dataTransfer.files));
    };

    if (!isVisible) {
        return null;
    }

    return (
        <div className={styles.area} onDragLeave={handleDragLeave} onDragOver={handleDragOver} onDrop={handleDrop}>
            <div className={styles.title}>
                <CloudIcon />
                {t('files-upload.drag-and-drop')}
            </div>
        </div>
    );
};
