export class AbortManager {
    private readonly abortControllersMap = new Map<string, AbortController>();

    public addController(requestId: string, controller: AbortController) {
        this.abortControllersMap.set(requestId, controller);
    }

    public getController(requestId: string) {
        return this.abortControllersMap.get(requestId);
    }

    public removeController(requestId: string) {
        this.abortControllersMap.delete(requestId);
    }

    public abort(requestId: string, reason?: unknown) {
        const abortController = this.getController(requestId);

        if (abortController && !abortController.signal.aborted) {
            abortController.abort(reason ?? `Aborting background fetch request with requestId: ${requestId}`);
        }

        this.abortControllersMap.delete(requestId);
    }
}

export const abortManager = new AbortManager();
