import * as Yup from 'yup';

import i18n from '../../../i18n';

import { ACCEPTED_FILE_FORMATS, FILE_SIZE_LIMIT } from './constants';

export const validationSchema = () =>
    Yup.object().shape({
        text: Yup.string().required(i18n.t('error.field.required', { ns: 'errors' })),
        channel: Yup.string().required(i18n.t('error.field.required', { ns: 'errors' })),
        userId: Yup.string().required(i18n.t('error.field.required', { ns: 'errors' })),
        experts: Yup.array().notRequired(),
        files: Yup.array()
            .test('fileSize', i18n.t('error.field.file-size-error', { ns: 'errors' }), function (files) {
                if (!files || files.length === 0) return true;

                const hasSizeError = files.some((file) => file && file.size > FILE_SIZE_LIMIT);
                return !hasSizeError;
            })
            .test('fileFormat', i18n.t('error.field.unsupported-file-format', { ns: 'errors' }), function (files) {
                if (!files || files.length === 0) return true;

                const hasFormatError = files.some((file) => file && !ACCEPTED_FILE_FORMATS.includes(file.type));
                return !hasFormatError;
            })
            .notRequired(),
    });
