import { ProactiveSourceAbstract } from './proactive-source-abstract';

export class ProactiveSourceSalesforce extends ProactiveSourceAbstract {
    protected regex = /(lightning\.force|salesforce).*\/Case\/(\w+)\/view/;
    protected regexMatchIndex = 2;

    getName() {
        return 'salesforce';
    }
}
