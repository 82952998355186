import { createSlice } from '@reduxjs/toolkit';

interface ISettingsModalState {
    visible: boolean;
}

const initialState: ISettingsModalState = {
    visible: false,
};

export const settingsModalSlice = createSlice({
    name: 'settings-modal',
    initialState,
    reducers: {
        openSettingsModal(state) {
            state.visible = true;
        },
        closeSettingsModal(state) {
            state.visible = false;
        },
    },
});

export const { openSettingsModal, closeSettingsModal } = settingsModalSlice.actions;
