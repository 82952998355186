import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IUATState } from '../../../api/types';

const initialState: IUATState = {
    enabled: false,
    forceFeedbackVisible: false,
};

export const uatSlice = createSlice({
    name: 'uat',
    initialState,
    reducers: {
        setEnabled(state, action: PayloadAction<boolean>) {
            state.enabled = action.payload;
        },
        _openForceFeedback(state) {
            state.forceFeedbackVisible = true;
        },
        _closeForceFeedback(state) {
            state.forceFeedbackVisible = false;
        },
    },
});

export const { setEnabled, _openForceFeedback, _closeForceFeedback } = uatSlice.actions;
