import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { storageService } from '../../../services/storage/storage-service';
import { getLogger } from '../../../utils/logger';
import { PreloadAnimation } from '../../Animations/Preload/PreloadAnimation';

import styles from './ErrorBoundary.module.scss';

export interface IAppErrorBoundaryProps extends WithTranslation {
    children: React.ReactNode;
}

interface IAppErrorBoundaryState {
    error: boolean;
}

const logger = getLogger('General Error');

class AppErrorBoundaryComponent extends React.Component<IAppErrorBoundaryProps, IAppErrorBoundaryState> {
    constructor(props: IAppErrorBoundaryProps) {
        super(props);
        this.state = {
            error: false,
        };
    }

    static getDerivedStateFromError() {
        return {
            error: true,
        };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logger.error(error, errorInfo.componentStack);
    }

    private handleReloadClick = (e: React.MouseEvent) => {
        e.preventDefault();
        storageService.resetStorage().then(() => window.location.reload());
    };

    public render() {
        const { children, t } = this.props;

        if (!this.state.error) {
            return children;
        }

        return (
            <div className={styles.wrapper}>
                <PreloadAnimation />
                <div className={styles.error}>
                    {t('error.app.general', { ns: 'errors' })}
                    <span onClick={this.handleReloadClick} className={styles.resetLink}>
                        {t('error.app.reloadButton', { ns: 'errors' })}
                    </span>
                </div>
            </div>
        );
    }
}

export const AppErrorBoundary = withTranslation()(AppErrorBoundaryComponent);
