import errors from './errors.json';
import languages from './languages.json';
import translations from './translations.json';

const namespaces = {
    errors,
    translations,
    languages,
};

export default namespaces;
