const POLL_PROACTIVE_INTERVAL = 10_000;

let pollingTimerId: number | undefined = undefined;

export const schedulePolling = (callback: Function) => {
    pollingTimerId = setTimeout(callback, POLL_PROACTIVE_INTERVAL);
};

export const clearPollingTimer = () => {
    clearInterval(pollingTimerId);
    pollingTimerId = undefined;
};
