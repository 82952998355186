import { createToastFn, UseToastOptions } from '@chakra-ui/react';
import { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react';

import { ErrorToast } from '../../components/Toasts/Error/ErrorToast';
import { InfoToast } from '../../components/Toasts/Info/InfoToast';
import { WarningToast } from '../../components/Toasts/Warning/WarningToast';
import { getTheme } from '../../styles/chakra/theme';

import { IToastService } from './types';

export const TOAST_BOTTOM_OFFSET = 100;
export const TOAST_HIDE_DELAY = 3000;
const INFO_TOAST_ID = 'info';
const WARNING_TOAST_ID = 'warning';

const containerStyle: UseToastOptions['containerStyle'] = {
    minWidth: 'auto',
};

const info = (toast: IToastService['toast']) => (description: string, styles?: UseToastOptions['containerStyle']) => {
    if (!toast.isActive(INFO_TOAST_ID)) {
        toast({
            id: INFO_TOAST_ID,
            position: 'bottom',
            duration: TOAST_HIDE_DELAY,
            render: () => <InfoToast description={description} />,
            containerStyle: {
                ...containerStyle,
                ...styles,
            },
        });
    }
};

const warning =
    (toast: IToastService['toast']) => (description: string, styles?: UseToastOptions['containerStyle']) => {
        if (!toast.isActive(WARNING_TOAST_ID)) {
            toast({
                id: WARNING_TOAST_ID,
                position: 'bottom',
                duration: TOAST_HIDE_DELAY,
                render: () => <WarningToast description={description} />,
                containerStyle: {
                    ...containerStyle,
                    ...styles,
                },
            });
        }
    };

const error =
    (toast: IToastService['toast']) =>
    (description: string, meta?: FetchBaseQueryMeta, styles?: UseToastOptions['containerStyle']) => {
        toast({
            status: 'error',
            position: 'top-right',
            description: <ErrorToast description={description} meta={meta} />,
            containerStyle: {
                ...containerStyle,
                marginRight: '25px',
                marginTop: '25px',
                ...styles,
            },
            duration: TOAST_HIDE_DELAY,
            isClosable: true,
        });
    };

export const getToastService = () => {
    const service = createToastFn(getTheme().direction);

    return {
        toast: service,
        info: info(service),
        warning: warning(service),
        error: error(service),
    };
};
