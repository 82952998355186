import React, { useContext } from 'react';
import { ModalContent, ModalOverlay } from '@chakra-ui/react';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks/app-hooks';
import { restoreFocus } from '../../../redux/thunks/focus-thunk';
import { closePreview } from '../../../redux/thunks/preview-thunk';
import { SidebarContext } from '../../Sidebar/sidebar-context';
import { ModalContainer } from '../Container/ModalContainer';

import { Viewer } from './Viewer';

export const PreviewModal: React.FC = () => {
    const { visible, answer } = useAppSelector((state) => state.modals.preview);

    const dispatch = useAppDispatch();
    const isSidebarMode = useContext(SidebarContext);

    if (!answer) {
        return null;
    }

    const modalVariant = isSidebarMode ? 'preview-sidebar' : 'preview';

    return (
        <ModalContainer
            isOpen={visible}
            onClose={() => dispatch(closePreview())}
            motionPreset="scale"
            variant={modalVariant}
            scrollBehavior="inside"
            onCloseComplete={() => dispatch(restoreFocus())}
            closeOnOverlayClick
        >
            <ModalOverlay />
            <ModalContent>
                <Viewer />
            </ModalContent>
        </ModalContainer>
    );
};
