export const isWebMode = () => {
    return String(process.env.REACT_APP_EXTENSION_MODE).toLowerCase() === 'web';
};

export const isAnnotateWebMode = () => {
    return isWebMode() && String(process.env.REACT_APP_APP_TYPE).toLowerCase() === 'annotation';
};

export const isPortalWebMode = () => {
    return isWebMode() && String(process.env.REACT_APP_APP_TYPE).toLowerCase() === 'web-portal';
};

export const isPopupMode = () => {
    return window?.location.href.startsWith('chrome-extension') ?? false;
};

export const isContentMode = () => {
    return !isWebMode() && !isPopupMode();
};
