import { isWebMode } from '../../utils/extension-mode';

let extensionURLPrefix = '';

export const initializeResourcesService = async () => {
    if (isWebMode()) {
        return;
    }

    const browser = require('webextension-polyfill');
    extensionURLPrefix = browser.runtime.getURL('').slice(0, -1);
};

export const getExtensionURLPrefix = () => extensionURLPrefix;
