import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, IconButton } from '@chakra-ui/react';
import { useDatePicker } from '@rehookify/datepicker';
import cx from 'clsx';

import { ReactComponent as ArrowLeft } from '../icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../icons/arrow-right.svg';
import { ReactComponent as DoubleArrowLeft } from '../icons/double-arrow-left.svg';
import { ReactComponent as DoubleArrowRight } from '../icons/double-arrow-right.svg';

import styles from './DatePickerCalendar.module.scss';

export interface DatePickerCalendarProps {
    dateRange: Date[];
    onDateRangeChange: (range: Date[]) => void;
    customActions?: React.ReactNode;
}

export const DatePickerCalendar: React.FC<DatePickerCalendarProps> = ({
    dateRange,
    onDateRangeChange,
    customActions,
}) => {
    const [today] = useState<Date>(() => {
        const date = new Date();
        date.setHours(0, 0, 0, 0);

        return date;
    });

    const { t } = useTranslation('translations');
    const {
        data: { calendars, weekDays },
        propGetters: { addOffset, subtractOffset, setOffset, dayButton },
    } = useDatePicker({
        dates: {
            mode: 'range',
            selectSameDate: true,
            maxDate: today,
        },
        selectedDates: dateRange,
        onDatesChange: onDateRangeChange,
    });

    const { days, month, year } = calendars[0];

    return (
        <Box className={styles.wrapper}>
            <Box className={styles.controls}>
                <Box className={styles.offsetButtons}>
                    <IconButton
                        variant="icon-button"
                        aria-label="double-arrow-left-icon"
                        icon={<DoubleArrowLeft />}
                        {...subtractOffset({ years: 1 })}
                    />
                    <IconButton
                        variant="icon-button"
                        aria-label="arrow-left-icon"
                        icon={<ArrowLeft />}
                        {...subtractOffset({ months: 1 })}
                    />
                </Box>
                <Box className={styles.monthYear}>
                    <span>{month}</span>
                    <span>{year}</span>
                </Box>
                <Box className={styles.offsetButtons}>
                    <IconButton
                        variant="icon-button"
                        aria-label="arrow-right-icon"
                        icon={<ArrowRight />}
                        {...addOffset({ months: 1 })}
                    />
                    <IconButton
                        variant="icon-button"
                        icon={<DoubleArrowRight />}
                        aria-label="double-arrow-right-icon"
                        {...addOffset({ years: 1 })}
                    />
                </Box>
            </Box>

            <Box className={styles.days}>
                {weekDays.map((day) => (
                    <span key={day} className={styles.weekDay}>
                        {day}
                    </span>
                ))}
                {days.map((day) => {
                    const className = cx({
                        [styles.dateButton]: ![
                            'will-be-range-start',
                            'range-start',
                            'range-end',
                            'will-be-range-end',
                        ].includes(day.range),
                        [styles.notCurrentMonth]: !day.inCurrentMonth,
                        [styles.inRange]: day.range === 'in-range',
                        [styles.rangeStart]:
                            ['will-be-range-start', 'range-start'].includes(day.range) && !day.disabled,
                        [styles.rangeEnd]: ['will-be-range-end', 'range-end'].includes(day.range) && !day.disabled,
                        [styles.rangeStartEnd]: day.range === 'range-start range-end',
                        [styles.willBeInRange]: day.range === 'will-be-in-range' && !day.disabled,
                        [styles.now]: day.now,
                    });

                    return (
                        <Button key={day.$date.toString()} variant="calendar" {...dayButton(day)} className={className}>
                            {day.day}
                        </Button>
                    );
                })}
            </Box>

            <Box className={styles.actions}>
                <Button {...setOffset(today)} variant="text-button" className={styles.today}>
                    {t('dates-picker.today-button')}
                </Button>
                {customActions}
            </Box>
        </Box>
    );
};
