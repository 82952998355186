import { IAnswerResponse } from '../api/types';

export const prepareAnswersMetricsInfo = (answers: IAnswerResponse[]) => {
    const directAnswer = answers.find((answer) => answer.direct);
    const candidateAnswers = answers.filter((answer) => !answer.direct);

    return {
        candidate_answers: candidateAnswers,
        ...(directAnswer && {
            direct_answer: directAnswer,
        }),
    };
};
