import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Textarea,
} from '@chakra-ui/react';
import { Field, FieldProps, Formik } from 'formik';

import { useAddAnswerMutation } from '../../../../api/endpoints/user-activities-endpoint';
import { AnnotationType } from '../../../../api/types';
import { ReactComponent as LinkIcon } from '../../../../assets/icons/link.svg';
import { useAnswers } from '../../../../redux/hooks/answer/answer-hooks';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks/app-hooks';
import { useSources } from '../../../../redux/hooks/settings-hooks';
import { closeAnnotateAnswerModal } from '../../../../redux/slices/modals/annotate-answer-modal-slice';
import { openSuccessModal } from '../../../../redux/slices/modals/success-modal-slice';
import { setForceFeedbackForQuestion } from '../../../../redux/slices/question/question-slice';
import { sendMetrics } from '../../../../redux/thunks/metrics-thunk';
import { MixpanelEvent } from '../../../../services/mixpanel/types';
import { ModalContainer } from '../../Container/ModalContainer';

import { addAnswerValidationSchema } from './validation-schema';

import styles from './AnnotateAnswerModal.module.scss';
interface IFormData {
    answer_link: string;
    answer: string;
}

export const AnnotateAnswerModal: React.FC = () => {
    const isUatMode = useAppSelector((state) => state.uat.enabled);
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();
    const [annotate] = useAddAnswerMutation();

    const { visible, origin } = useAppSelector((state) => state.modals.answerFeedback);

    const [, { data }] = useAnswers();
    const { sources } = useSources();
    const [showMore, setShowMore] = useState(false);

    const handleCloseModal = () => {
        dispatch(closeAnnotateAnswerModal());
    };

    const handleCancelClick = () => {
        handleCloseModal();
        dispatch(
            sendMetrics({
                event: MixpanelEvent.CANCEL_ADD_ANSWER,
                injectAnswersMetrics: {
                    answers: data,
                    sources,
                },
                meta: {
                    uat_mode: isUatMode,
                },
            })
        );
    };

    const renderForm = () => {
        const initialFormState: IFormData = {
            answer: '',
            answer_link: '',
        };

        const handleFormSubmit = async (formData: IFormData) => {
            const { answer, answer_link } = formData;

            const response = await annotate({
                type: AnnotationType.ANNOTATE,
                answer_link,
                answer,
                sources,
                answers: data?.answers ?? [],
            });

            handleCloseModal();

            if ('data' in response && response.data) {
                dispatch(
                    openSuccessModal({
                        header: t('annotate.header'),
                        message: t('annotate.success.message'),
                        description: t('annotate.success.description'),
                    })
                );
            }

            batch(() => {
                dispatch(setForceFeedbackForQuestion(false));
                dispatch(
                    sendMetrics({
                        event: MixpanelEvent.ADD_ANSWER,
                        meta: {
                            form_content: answer,
                            origin,
                            uat_mode: isUatMode,
                        },
                        injectAnswersMetrics: {
                            answers: data,
                            sources,
                        },
                    })
                );
            });
        };

        const renderHintText = () => {
            if (showMore) {
                return t('annotate.hint-full');
            }

            return (
                <>
                    {t('annotate.hint-short')}
                    <span className={styles.showMore} onClick={() => setShowMore(true)}>
                        {t('button.learn-more')}
                    </span>
                </>
            );
        };

        return (
            <Formik
                initialValues={initialFormState}
                onSubmit={handleFormSubmit}
                validationSchema={addAnswerValidationSchema()}
                validateOnChange={false}
            >
                {(form) => {
                    return (
                        <>
                            <ModalBody className={styles.body} data-testid="annotate.answer.body">
                                <Text fontSize="1.4em" color="mid-gray" fontWeight="medium">
                                    {renderHintText()}
                                </Text>

                                <div>
                                    <Field name="answer_link">
                                        {({ field }: FieldProps<string>) => (
                                            <FormControl
                                                isInvalid={!!form.errors.answer_link && form.touched.answer_link}
                                            >
                                                <FormLabel size="xs">{t('annotate.answer-link.label')}</FormLabel>
                                                <InputGroup>
                                                    <Input
                                                        name={field.name}
                                                        value={field.value}
                                                        onChange={field.onChange}
                                                        placeholder={t('annotate.answer-link.placeholder')}
                                                        className={styles.input}
                                                        data-testid="annotate.answer-link.input"
                                                    />
                                                    <InputRightElement>
                                                        <LinkIcon />
                                                    </InputRightElement>
                                                </InputGroup>
                                                <FormErrorMessage>{form.errors.answer_link}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </div>

                                <div>
                                    <Field name="answer">
                                        {({ field }: FieldProps<string>) => (
                                            <FormControl isInvalid={!!form.errors.answer && form.touched.answer}>
                                                <FormLabel size="xs">{t('annotate.direct-answer.label')}</FormLabel>
                                                <Textarea
                                                    variant="thin"
                                                    name={field.name}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    className={styles.input}
                                                    data-testid="annotate.direct-answer.input"
                                                />
                                                <FormErrorMessage>{form.errors.answer}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </div>
                            </ModalBody>
                            <ModalFooter className={styles.footer}>
                                <Button variant="secondary" onClick={handleCancelClick}>
                                    {t('button.cancel')}
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={form.submitForm}
                                    isLoading={form.isSubmitting}
                                    data-testid="annotate.answer.submit"
                                >
                                    {t('button.submit')}
                                </Button>
                            </ModalFooter>
                        </>
                    );
                }}
            </Formik>
        );
    };

    return (
        <ModalContainer isOpen={visible} onClose={handleCloseModal} motionPreset="scale" scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent className={styles.content}>
                <ModalHeader>{t('annotate.header')}</ModalHeader>
                {renderForm()}
            </ModalContent>
        </ModalContainer>
    );
};
