import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'clsx';

import { IUseSource } from '../../../api/types';
import { useAppDispatch } from '../../../redux/hooks/app-hooks';
import { sendMetrics } from '../../../redux/thunks/metrics-thunk';
import { MixpanelEvent } from '../../../services/mixpanel/types';
import { translateAnswerSource } from '../../../utils/sources';
import { Tooltip } from '../../Tooltips/Tooltip';

import styles from './Source.module.scss';

export interface ISourceProps {
    source: IUseSource;
    preventToggleLastActive?: boolean;
    toggleActive?: (sourceId: string) => void;
}

export const Source: React.FC<ISourceProps> = ({ source, toggleActive, preventToggleLastActive }) => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();

    const { id, type, icon, active } = source;

    const sourceName = translateAnswerSource(source);

    const handleToggleActive = useCallback(() => {
        if (!toggleActive) {
            return;
        }

        // prevent deactivation of the last active
        if (active && preventToggleLastActive) {
            return;
        }

        toggleActive(id);
        dispatch(
            sendMetrics({
                event: active ? MixpanelEvent.SOURCE_DESELECT : MixpanelEvent.SOURCE_SELECT,
                meta: {
                    name: sourceName,
                    source_id: id,
                    source_type: type,
                },
            })
        );
    }, [active, dispatch, id, preventToggleLastActive, sourceName, toggleActive, type]);

    return (
        <Tooltip label={sourceName} hasArrow>
            <div onClick={handleToggleActive} className={cx(styles.wrapper, { [styles.disabled]: !active })}>
                <img src={icon} alt={t('source.title', { type: sourceName })} className={styles.icon} />
            </div>
        </Tooltip>
    );
};
