/**
 * Swaps the given array elements
 *
 * @param array
 * @param indexA
 * @param indexB
 */
export const swapElements = <T>(array: T[], indexA: number, indexB: number): T[] => {
    if (indexA === indexB) {
        return array;
    }

    if (array[indexA] === undefined || array[indexB] === undefined) {
        return array;
    }

    const _indexA = indexA > indexB ? indexB : indexA;
    const _indexB = indexA > indexB ? indexA : indexB;

    return [
        ...array.slice(0, _indexA),
        array[_indexB],
        ...array.slice(_indexA + 1, _indexB),
        array[_indexA],
        ...array.slice(_indexB + 1),
    ];
};

/**
 * Shuffles array using the Fisher–Yates algorithm in place
 * https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
 *
 * @param array
 */
export const shuffleElements = <T>(array: T[]) => {
    let currentIndex: number = array.length;
    let randomIndex: number = 0;

    while (currentIndex > 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
};

/**
 * Trim the array size considering the array as classic queue,
 * so values from the start pf the array will be deleted.
 *
 * It returns a shallow copy of the initial array.
 *
 * @param array
 * @param size - a new array size
 */
export const trimQueueSize = <T>(array: T[], size: number): T[] => {
    if (size > array.length) {
        return [...array];
    }

    return array.slice(array.length - size);
};
