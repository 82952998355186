import React, { MouseEventHandler } from 'react';

import { AnnotationType, DirectAnnotationType, IAnswerResponse, IDirectAnswerResult } from '../../../../../api/types';
import { useAnswers } from '../../../../../redux/hooks/answer/answer-hooks';
import { useAppDispatch } from '../../../../../redux/hooks/app-hooks';
import { useProject, useSources } from '../../../../../redux/hooks/settings-hooks';
import {
    annotateByThumbThunk,
    annotateDirectAnswerByThumbsThunk,
    IAnnotateByThumbThunkPayload,
    IAnnotateDirectAnswerByThumbThunkPayload,
} from '../../../../../redux/thunks/annotate-thunk';
import { getLogger } from '../../../../../utils/logger';
import { Tooltip } from '../../../../Tooltips/Tooltip';

import { IThumbActionProps } from './types';

import styles from './ThumbAction.module.scss';

export const ThumbAction: React.FC<IThumbActionProps> = ({
    answer,
    type,
    title,
    icon,
    onClick = () => false,
    isDirectAnswer = false,
}) => {
    const dispatch = useAppDispatch();
    const { project } = useProject();
    const { sources } = useSources();
    const [, { data: answersData }] = useAnswers();

    const handleButtonClick: MouseEventHandler = async (e) => {
        e.stopPropagation();

        if (isDirectAnswer) {
            const questionId = (answer as IDirectAnswerResult)?.question_id ?? answersData?.question_id;
            if (!questionId) {
                getLogger('ThumbAction').warn('Question Id is not defined');
                return;
            }

            const payload: IAnnotateDirectAnswerByThumbThunkPayload = {
                annotationType: type as DirectAnnotationType,
                customerProjectId: project,
                questionId,
            };

            await dispatch(annotateDirectAnswerByThumbsThunk(payload));
        } else {
            const payload: IAnnotateByThumbThunkPayload = {
                answer: answer as IAnswerResponse,
                type: type as AnnotationType,
                project,
                sources,
            };
            await dispatch(annotateByThumbThunk(payload));
        }

        onClick();
    };

    const Icon = icon;

    return (
        <Tooltip label={title} placement="top-end" hasArrow>
            <Icon onClick={handleButtonClick} className={styles.button} />
        </Tooltip>
    );
};
