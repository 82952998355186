import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ASK_AI_PROJECT_ID } from '../../../api/consts';
import { IUser } from '../../../api/types';
import { getPermissionsFromRoles } from '../../../utils/permissions';
import { USER_ROLE_ADMIN } from '../../constants';
import { logout, setUser } from '../../thunks/user-thunk';
import { IAuthState } from '../../types';

const initialUser: IUser = {
    username: '',
    email: '',
    user_org: '',
    uid: '',
    role: '',
    customer_projects: [],
    competition: null,
    settings: {
        open_link_in_new_page: true,
    },
    identities: {},
};

const initialState: IAuthState = {
    username: '',
    token: null, // will be loaded asynchronously in App Init middleware
    user: initialUser,
    shouldDisplayFronteggAdminPanel: false,
    isAskAIUser: false,
    roles: [],
    permissions: {
        preCannedQuestionsAccess: false,
        disableOnlineMemory: false,
        changeBackendApi: false,
        debugProactiveAccess: false,
    },
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUserName(state, action: PayloadAction<string>) {
            state.username = action.payload;
        },
        setToken(state, action: PayloadAction<string>) {
            state.token = action.payload;
        },
        setRoles(state, action: PayloadAction<IAuthState['roles']>) {
            state.roles = action.payload;
            state.permissions = getPermissionsFromRoles(action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logout.fulfilled, () => {
            return initialState;
        });
        builder.addCase(setUser.fulfilled, (state, action) => {
            const { user } = action.payload;
            state.user = user;
            state.shouldDisplayFronteggAdminPanel = user.role === USER_ROLE_ADMIN;
            state.isAskAIUser = user.customer_projects.map((item) => item.value).includes(ASK_AI_PROJECT_ID);
        });
    },
});

export const { setUserName, setToken, setRoles } = authSlice.actions;
