import React from 'react';

import { Tag } from './Tag/Tag';

import styles from './Tags.module.scss';

export type ITag = string;

export interface ITagsProps {
    tags: ITag[];
    onEditTag: (tag: ITag) => void;
}

export const Tags: React.FC<ITagsProps> = ({ tags, onEditTag }) => {
    return (
        <div className={styles.wrapper}>
            {tags.map((tag) => (
                <Tag key={tag} value={tag} onEditClick={onEditTag} />
            ))}
        </div>
    );
};
