import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popover, PopoverAnchor, PopoverTrigger } from '@chakra-ui/react';
import { DEFAULT_ANNOTATE_TAG_VALUE } from 'answers-core/src/api/consts';
import { useAnnotateAnswersMutation } from 'answers-core/src/api/endpoints/annotate-answers-endpoint';
import { useAppDispatch, useAppSelector } from 'answers-core/src/redux/hooks/app-hooks';
import {
    resetAnswersLabel,
    setGeneralComment,
} from 'answers-core/src/redux/slices/annotate-answers/annotate-answers-slice';
import { openAnnotateEditTagModal } from 'answers-core/src/redux/slices/modals/annotate-tags-modal-slice';
import cx from 'clsx';

import { ReactComponent as AddNoteIcon } from '../../../../assets/icons/annotate-add-note.svg';
import { ReactComponent as AddNoteNotedIcon } from '../../../../assets/icons/annotate-add-note-noted.svg';
import { ReactComponent as ClearIcon } from '../../../../assets/icons/annotate-clear.svg';
import { EditTagCtaDialog } from '../EditTagCtaDialog/EditTagCtaDialog';

import { PopoverGeneralCommentContent } from './GeneralCommentAnswers/PopoverGeneralCommentContent';

import styles from './AnnotateAnswerActions.module.scss';

export const SubmitAnnotation: React.FC = () => {
    const dispatch = useAppDispatch();
    const inputRef = React.useRef<HTMLTextAreaElement>(null);
    const timeoutRef = React.useRef<NodeJS.Timeout>();
    const [displaySuccess, setDisplaySuccess] = React.useState(false);
    const { t } = useTranslation('translations');
    const { annotate_answers, labeled_chunks_map, comment_chunks_map, comment_general } = useAppSelector(
        (state) => state.annotateAnswers
    );
    const { chunks, tags, question_id } = annotate_answers;
    const [annotateAnswers, { isLoading }] = useAnnotateAnswersMutation();
    const [showDialog, setShowDialog] = React.useState(false);

    React.useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    const sendAnnotatePayload = React.useCallback(() => {
        annotateAnswers()
            .unwrap()
            .then(() => {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }

                setDisplaySuccess(true);

                timeoutRef.current = setTimeout(() => {
                    setDisplaySuccess(false);
                }, 1500);
            });
    }, [annotateAnswers, setDisplaySuccess]);

    const shouldEditTag = React.useMemo(() => {
        return tags.length === 1 && tags[0] === DEFAULT_ANNOTATE_TAG_VALUE;
    }, [tags]);

    const handleSubmit = React.useCallback(() => {
        if (shouldEditTag) {
            setShowDialog(true);
        } else {
            sendAnnotatePayload();
        }
    }, [sendAnnotatePayload, setShowDialog, shouldEditTag]);

    const closeEditTagCtaDialog = React.useCallback(() => {
        setShowDialog(false);
    }, [setShowDialog]);

    const handleStartEditTag = React.useCallback(() => {
        closeEditTagCtaDialog();
        dispatch(openAnnotateEditTagModal(tags[0]));
    }, [closeEditTagCtaDialog, tags, dispatch]);

    const handleClear = React.useCallback(() => {
        dispatch(resetAnswersLabel());
    }, [dispatch]);

    const handleCommentChange: React.ChangeEventHandler<HTMLTextAreaElement> = React.useCallback(
        ({ target }) => {
            dispatch(setGeneralComment({ question_id, comment: target.value || '' }));
        },
        [dispatch, question_id]
    );

    if (chunks.length === 0) {
        return null;
    }

    const totalChunks = chunks.length;
    const labeledChunksCount = Object.keys(labeled_chunks_map).length;
    const commentedChunksCount = Object.keys(comment_chunks_map).length;

    const disabledClear = (!commentedChunksCount && !labeledChunksCount) || isLoading;
    const disableSubmit = labeledChunksCount !== totalChunks;

    const renderContent = () => {
        if (displaySuccess) {
            return <span>{t('annotate-answers.on-success')}</span>;
        }

        return (
            <>
                <span>
                    {t('annotate-answers.title')} - {`${labeledChunksCount} / ${totalChunks}`}
                </span>
                <div className={styles.spacer} />
                <div className={styles.annotateButtons}>
                    <PopoverTrigger>
                        <Button
                            h="32px"
                            colorScheme="transparent"
                            variant="ghost"
                            isDisabled={isLoading || !totalChunks}
                            leftIcon={comment_general ? <AddNoteNotedIcon /> : <AddNoteIcon />}
                        >
                            {t('button.add-note')}
                        </Button>
                    </PopoverTrigger>
                    <Button
                        h="32px"
                        colorScheme="transparent"
                        variant="ghost"
                        isDisabled={disabledClear}
                        onClick={handleClear}
                        leftIcon={<ClearIcon />}
                    >
                        {t('button.clear')}
                    </Button>
                    <Button
                        h="32px"
                        variant="annotate-primary"
                        isDisabled={disableSubmit}
                        isLoading={isLoading}
                        onClick={handleSubmit}
                    >
                        {t('button.submit')}
                    </Button>
                </div>
            </>
        );
    };

    return (
        <>
            <Popover isLazy id="submit-annotation" placement="top" strategy="fixed" closeOnBlur={false}>
                <PopoverAnchor>
                    <div className={styles.submitAnnotationWrapper}>
                        <div className={cx(styles.submitAnnotation, { [styles.onSuccess]: displaySuccess })}>
                            {renderContent()}
                        </div>
                    </div>
                </PopoverAnchor>
                <PopoverGeneralCommentContent
                    inputRef={inputRef}
                    value={comment_general}
                    onChange={handleCommentChange}
                />
            </Popover>
            {showDialog && <EditTagCtaDialog onClose={closeEditTagCtaDialog} onStartEdit={handleStartEditTag} />}
        </>
    );
};
