import { batch } from 'react-redux';
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react';
import { QueryReturnValue } from '@reduxjs/toolkit/src/query/baseQueryTypes';
import { v4 } from 'uuid';

import i18n from '../../../i18n';
import { SHARED_ANSWERS_CACHE_KEY } from '../../../redux/constants';
import { IRootState } from '../../../redux/core-store';
import {
    setPendingToSave,
    setProactiveNoDirectAnswerBannerVisible,
    setQuestionId,
    setSessionId,
} from '../../../redux/slices/proactive-answers/proactive-answers-slice';
import { setProactivelyGenerated, setQuestionSliceState } from '../../../redux/slices/question/question-slice';
import { sendMetrics } from '../../../redux/thunks/metrics-thunk';
import {
    abortProactiveQuestion,
    endLoading,
    pendingStatusLoading,
    setProactiveTicketVisited,
    startLoading,
} from '../../../redux/thunks/proactive-answer-thunk';
import { MixpanelEvent } from '../../../services/mixpanel/types';
import { LAST_ANSWERS_STORAGE_KEY } from '../../../services/storage/storage-keys-list';
import { storageService } from '../../../services/storage/storage-service';
import { getToastService } from '../../../services/toast/toast-service';
import { getUserProject } from '../../../utils/user';
import { baseApi } from '../../base-api';
import { SUPPORTED_FEATURES } from '../../consts';
import {
    ActionType,
    IApiProactiveAnswersResponse,
    IProactiveAnswersRequestParams,
    IProactiveAnswersResponse,
    ProactiveStatus,
    ResponseStatus,
} from '../../types';
import { askEndpoint } from '../ask-endpoint';
import { prepareAnswers } from '../utils/api-answers-utils';

const EMPTY_RESPONSE_QUESTION_ID = '__empty_response_question_id__';

const EMPTY_RESPONSE: IProactiveAnswersResponse = {
    question_id: EMPTY_RESPONSE_QUESTION_ID,
    question: '',
    answers: [],
    expect_direct_answer: false,
    status: ProactiveStatus.READY,
    url: '',
    item_id: '',
    no_answers_status: null,
};

export const proactiveAnswersEndpoint = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getProactiveAnswers: builder.mutation<IProactiveAnswersResponse, IProactiveAnswersRequestParams>({
            queryFn: async (arg, { getState, dispatch }, _, baseQuery) => {
                const { url } = arg;
                const state = getState() as IRootState;
                const {
                    settings: { disableOnlineMemory, confidenceSettings, simulateCustomerProject, fullTicketAppUrl },
                    auth: { permissions },
                    app: { sidebarVisible },
                } = state;

                const isSimulateProactive = url === fullTicketAppUrl;

                const customerProjectId = isSimulateProactive ? simulateCustomerProject : getUserProject(state);

                const proactiveSessionId = v4();

                batch(() => {
                    dispatch(setSessionId(proactiveSessionId));
                    dispatch(
                        sendMetrics({
                            event: MixpanelEvent.PROACTIVE_INITIATED,
                            meta: {
                                item_url: url,
                            },
                            injectProactiveFrequencyMetrics: {},
                        })
                    );
                    dispatch(setProactivelyGenerated(true));
                });

                const response = await baseQuery({
                    url: '/v1/proactive/answers',
                    method: 'POST',
                    body: {
                        url,
                        customer_project_id: customerProjectId,
                        debug_customer_project_id: permissions.debugProactiveAccess
                            ? simulateCustomerProject
                            : undefined,
                        is_side_panel_open: sidebarVisible,
                        ...(disableOnlineMemory === true && {
                            max_num_user_feedback_chunks: 0,
                        }),
                        supported_features: SUPPORTED_FEATURES,
                    },
                });

                const { data, error, meta } = response as QueryReturnValue<
                    IApiProactiveAnswersResponse,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >;

                if (error) {
                    if (error.status === ResponseStatus.PARSING_ERROR) {
                        // We could not find any answer matching your question
                        return { data: EMPTY_RESPONSE };
                    }

                    return {
                        error,
                        meta,
                    };
                } else {
                    return {
                        data: {
                            question_id: data.question_id,
                            question: data.question,
                            url: data.url,
                            item_id: data.item_id,
                            answers: prepareAnswers(data.candidate_answers, confidenceSettings),
                            expect_direct_answer: data.expect_direct_answer,
                            status: data.status,
                            no_answers_status: data.no_answers_status,
                        },
                    };
                }
            },
            async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
                dispatch(abortProactiveQuestion());
                dispatch(startLoading());
                let status;
                let answersLength: number = 0;
                let proactiveAnswersResponse: IProactiveAnswersResponse | undefined = undefined;
                const { url } = arg;

                try {
                    const { data } = await queryFulfilled;
                    proactiveAnswersResponse = data;
                    status = data.status;
                    if (data.status === ProactiveStatus.PENDING) {
                        dispatch(pendingStatusLoading());
                        return;
                    }

                    dispatch(setQuestionId(data.question_id));

                    answersLength = data.answers.length;

                    const lastAnswersData = {
                        question_id: data.question_id,
                        question: data.question ?? '',
                        answers: data.answers,
                        expect_direct_answer: data.expect_direct_answer,
                        action_type: ActionType.SEARCH,
                        custom_action: null,
                        userStartedTyping: false,
                        proactiveMeta: {
                            item_id: data.item_id,
                            item_url: data.url,
                            original_url: url,
                        },
                        proactivelyGenerated: true,
                        forceFeedback: false,
                        timestamp: Date.now(),
                    };

                    if (data.no_answers_status === null && data.question_id !== EMPTY_RESPONSE_QUESTION_ID) {
                        dispatch(
                            sendMetrics({
                                event: MixpanelEvent.PROACTIVE_RECEIVED,
                                meta: {
                                    item_id: lastAnswersData.proactiveMeta.item_id,
                                    item_url: url,
                                    question_id: lastAnswersData.question_id,
                                    expect_direct_answer: lastAnswersData.expect_direct_answer,
                                },
                                injectProactiveFrequencyMetrics: {
                                    question: lastAnswersData.question,
                                },
                            })
                        );
                    } else {
                        dispatch(
                            sendMetrics({
                                event: MixpanelEvent.PROACTIVE_RECEIVED_NO_ANSWER,
                                meta: {
                                    item_id: lastAnswersData.proactiveMeta.item_id,
                                    item_url: url,
                                    question_id: lastAnswersData.question_id,
                                    no_answers_status: data.no_answers_status,
                                },
                                injectProactiveFrequencyMetrics: {
                                    question: lastAnswersData.question,
                                },
                            })
                        );
                        dispatch(setProactiveTicketVisited(url));
                    }

                    const state = getState() as IRootState;
                    const { sidebarVisible } = state.app;
                    const { simulateCustomerProject, project } = state.settings;

                    if (sidebarVisible && (project === simulateCustomerProject || !simulateCustomerProject)) {
                        if (answersLength) {
                            await storageService.setStorageValue(LAST_ANSWERS_STORAGE_KEY, lastAnswersData);
                        } else {
                            // we don't want to display the `No Proactive Answers` placeholder
                            // next time a user re-opens the sidebar/popup
                            await storageService.removeStorageValue(LAST_ANSWERS_STORAGE_KEY);
                        }
                    }

                    batch(() => {
                        dispatch(
                            askEndpoint.internalActions.removeMutationResult({
                                fixedCacheKey: SHARED_ANSWERS_CACHE_KEY,
                            })
                        );
                        dispatch(setQuestionSliceState(lastAnswersData));

                        if (!data.expect_direct_answer && answersLength !== 0) {
                            dispatch(setProactiveNoDirectAnswerBannerVisible(true));
                        }
                    });

                    if (!sidebarVisible) {
                        // save the results of the proactive answers to temporary field,
                        // so they can be saved to local storage when user opens the sidebar
                        dispatch(
                            setPendingToSave({
                                answers: lastAnswersData,
                            })
                        );
                    }

                    if (status !== ProactiveStatus.PENDING) {
                        dispatch(
                            endLoading({
                                resultReady: answersLength !== 0,
                                expectDirectAnswer: proactiveAnswersResponse?.expect_direct_answer,
                            })
                        );
                    }
                } catch (e) {
                    const abortError = e as { error?: Error };

                    if (abortError.error?.name === 'AbortError') {
                        return;
                    }

                    dispatch(
                        endLoading({
                            resultReady: answersLength !== 0,
                            expectDirectAnswer: false,
                        })
                    );
                    dispatch(setProactiveTicketVisited(url));

                    const errorWrapper = e as { error: FetchBaseQueryError; meta: FetchBaseQueryMeta };

                    if (errorWrapper?.error?.status === 'FETCH_ERROR') {
                        return;
                    }

                    const message = i18n.t('error.api.answers.proactive', { ns: 'errors' });

                    getToastService().error(message, errorWrapper.meta);
                }
            },
        }),
    }),
});

export const { useGetProactiveAnswersMutation } = proactiveAnswersEndpoint;
