import { createSlice } from '@reduxjs/toolkit';

interface ISourcesDisplayFilterModalState {
    visible: boolean;
}

const initialState: ISourcesDisplayFilterModalState = {
    visible: false,
};

export const sourcesFilterSlice = createSlice({
    name: 'sources-filter-modal',
    initialState,
    reducers: {
        openSourcesFilterModal(state) {
            state.visible = true;
        },
        closeSourcesFilterModal(state) {
            state.visible = false;
        },
    },
});

export const { openSourcesFilterModal, closeSourcesFilterModal } = sourcesFilterSlice.actions;
