import * as Yup from 'yup';

import { API_SETTINGS_PATH } from '../../../api/endpoints/settings-endpoint';
import i18n from '../../../i18n';
import { fetchInBackground } from '../../../services/background-fetch/background-fetch-service';
import { isStringInPatternsList } from '../../../utils/strings';

const isProactiveUrlInWhitelist = (url?: string, proactiveWhitelist?: string) => {
    return !url || isStringInPatternsList(url, proactiveWhitelist);
};
const notInProactiveWhitelistErrorMessage = i18n.t('error.url-not-in-proactive-whitelist', { ns: 'errors' });

export const userSettingsValidationSchema = (proactiveWhitelist?: string) =>
    Yup.object().shape({
        backend_url: Yup.string()
            .url(i18n.t('error.url-invalid', { ns: 'errors' }))
            .test('is valid backend url', i18n.t('error.server-not-responding', { ns: 'errors' }), async (value) => {
                if (!value) {
                    return true;
                }

                try {
                    const response = await fetchInBackground(value + API_SETTINGS_PATH);

                    return response.ok;
                } catch {
                    return false;
                }
            }),
        proactive_url: Yup.string().test('is url in whitelist', notInProactiveWhitelistErrorMessage, (url) =>
            isProactiveUrlInWhitelist(url, proactiveWhitelist)
        ),
        full_ticket_app_url: Yup.string().test('is url in whitelist', notInProactiveWhitelistErrorMessage, (url) =>
            isProactiveUrlInWhitelist(url, proactiveWhitelist)
        ),
    });
