import { createAsyncThunk } from '@reduxjs/toolkit';

import { getRecentsQuestionsFromSearchHistory, removeQuestionFromHistory } from '../../utils/askArea';
import { IRootState } from '../core-store';
import { setSearchHistory, setSuggestions } from '../slices/suggestions/suggestions-slice';

export const setAllQuestionsToSearchHistory = createAsyncThunk(
    'answer/setAllQuestionsToSearchHistory',
    async (_, { dispatch }) => {
        const allQuestions = await getRecentsQuestionsFromSearchHistory();
        dispatch(setSearchHistory(allQuestions));
    }
);

export const deleteSuggestion = createAsyncThunk(
    'answer/deleteSuggestion',
    async (itemToDelete: string, { dispatch, getState }) => {
        const { suggestions } = (getState() as IRootState).suggestions;
        await removeQuestionFromHistory(itemToDelete);
        dispatch(setSuggestions(suggestions.filter((suggestion) => suggestion !== itemToDelete)));
        dispatch(setAllQuestionsToSearchHistory());
    }
);
