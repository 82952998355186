import React from 'react';
import ReactSelect, { ActionMeta, MultiValue, SingleValue } from 'react-select';
import cx from 'clsx';

import { getStyles, getTheme } from '../../../styles/react-select/theme';

import styles from './Select.module.scss';

export type OptionType = {
    value: string;
    label: string;
};

type OnChangeType<T extends boolean> = T extends true ? string[] : string | undefined;

export interface SelectProps {
    value: string;
    className?: string;
    name?: string;
    options: OptionType[];
    placeholder?: string;
    isLoading?: boolean;
    isMulti?: boolean;
    onChange: (value: OnChangeType<boolean>) => void;
    handleMenuOpen?: () => void;
    components?: Record<string, any>;
    customStyles?: Record<string, any>;
    menuIsOpen?: boolean;
}

export const Select: React.FC<SelectProps> = (props) => {
    const {
        value,
        className,
        options,
        name,
        placeholder,
        components,
        onChange,
        handleMenuOpen,
        isLoading = false,
        isMulti = false,
        menuIsOpen,
        customStyles = {},
    } = props;

    const getValueFromChangeEvent = (
        val: MultiValue<OptionType> | SingleValue<OptionType>
    ): OnChangeType<typeof isMulti> => {
        if (Array.isArray(val)) return val?.map((c: OptionType) => c.value);
        if (val && 'value' in val) return val?.value;
        return undefined;
    };

    return (
        <ReactSelect
            value={options.find((option) => option.value === value)}
            className={cx(className, styles.select)}
            isClearable={false}
            options={options}
            isLoading={isLoading}
            theme={getTheme}
            onChange={(val: MultiValue<OptionType> | SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
                const value = getValueFromChangeEvent(val);
                onChange(value);
            }}
            components={{
                IndicatorSeparator: () => null,
                ...components,
            }}
            name={name}
            placeholder={placeholder}
            onMenuOpen={handleMenuOpen}
            styles={{ ...customStyles, ...getStyles }}
            isMulti={isMulti}
            menuIsOpen={menuIsOpen}
        />
    );
};
