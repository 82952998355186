import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';

import { ReactComponent as CalendarEventIcon } from '../../../assets/icons/calendar-event.svg';
import { ReactComponent as FirstRankBadgeIcon } from '../../../assets/icons/first-rank-badge.svg';
import { ReactComponent as TrophyBadgeIcon } from '../../../assets/icons/trophy-badge.svg';

import styles from './CompetitionTooltipContent.module.scss';

export type CompetitionTooltipContentProps = {
    pointsFromFirstPlace: number;
    onLearnMoreClick?: () => void;
    competitionStartDate?: string;
    competitionEndDate?: string;
};

export const CompetitionTooltipContent: React.FC<CompetitionTooltipContentProps> = ({
    pointsFromFirstPlace,
    competitionStartDate,
    competitionEndDate,
    onLearnMoreClick,
}) => {
    const { t } = useTranslation('translations');

    const startOfTheDayMoment = moment.utc().startOf('day');
    const competitionEndDayMoment = moment.utc(competitionEndDate).endOf('day');

    const isFirstRank = pointsFromFirstPlace === 0;
    const displayMore = !isFirstRank;
    const isCompetitionActive = Boolean(
        competitionStartDate && competitionEndDate && startOfTheDayMoment.isSameOrBefore(competitionEndDayMoment)
    );

    // Add 1 to the difference to have '1 day left' instead of '0 days left' at the end of the competition
    const daysCompetitionEnds = competitionEndDayMoment.diff(startOfTheDayMoment, 'days') + 1;
    const descriptionKey = isFirstRank ? 'competition.first-rank-description' : 'competition.rank-description';

    return (
        <div>
            <div className={styles.rankDescription}>
                {isFirstRank ? <FirstRankBadgeIcon /> : <TrophyBadgeIcon />}
                <div className={styles.description}>
                    <span>
                        <Trans t={t} i18nKey={descriptionKey} values={{ count: pointsFromFirstPlace }} />
                    </span>
                    {displayMore && (
                        <span className={styles.learnMore} onClick={onLearnMoreClick}>
                            {t('competition.description-learn-more')}
                        </span>
                    )}
                </div>
            </div>
            {isCompetitionActive && (
                <div className={styles.competitionEnds}>
                    <CalendarEventIcon />
                    <span>
                        {t('competition.ends', {
                            count: daysCompetitionEnds,
                        })}
                    </span>
                </div>
            )}
        </div>
    );
};
