import { ProactiveShowedForEntries } from '../../redux/types';
import { isWebMode } from '../../utils/extension-mode';
import { createWorkerService } from '../core/worker/worker-service';
import { ServiceWorkerContext } from '../types';

const syncFloatingPosition = async (yPosition: number | null) => {
    if (!isWebMode()) {
        const { eventBusEmitter } = await import(
            /* webpackMode: "eager" */
            '../core/event-bus/event-bus-emitter'
        );

        eventBusEmitter.emit('onFloatingPositionChanged', undefined, yPosition);
    }
};

const syncProactiveShowedFor = async (entries: ProactiveShowedForEntries) => {
    if (!isWebMode()) {
        const { eventBusEmitter } = await import(
            /* webpackMode: "eager" */
            'answers-core/src/services/core/event-bus/event-bus-emitter'
        );

        eventBusEmitter.emit('onProactiveShowedForChanged', undefined, entries);
    }
};

const syncOriginsBlacklist = async (origins: string[]) => {
    if (!isWebMode()) {
        const { eventBusEmitter } = await import(
            /* webpackMode: "eager" */
            'answers-core/src/services/core/event-bus/event-bus-emitter'
        );

        eventBusEmitter.emit('onOriginsBlacklistUpdate', undefined, origins);
    }
};

const service = createWorkerService({
    name: 'tabs-sync',
    context: ServiceWorkerContext.BACKGROUND,
    handlers: () => ({
        syncFloatingPosition,
        syncProactiveShowedFor,
        syncOriginsBlacklist,
    }),
});

export const tabsSyncService = service.actions;
export const registerTabsSyncService = service.register;
