import { createAsyncThunk } from '@reduxjs/toolkit';

import { Notification } from '../../api/types';
import { LAST_NOTIFICATION_ID } from '../../services/storage/storage-keys-list';
import { storageService } from '../../services/storage/storage-service';

export const storeNotificationId = createAsyncThunk(
    'notifications/storeNotificationId',
    async (data?: Notification | null) => {
        await storageService.setStorageValue(LAST_NOTIFICATION_ID, data?.id || '');
    }
);
