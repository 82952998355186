import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import { IQuizAnswer } from '../../../api/types';
import { setSidebarVisibleThunk } from '../../thunks/app-thunk';
import { setProject } from '../../thunks/settings-thunk';
import { logout } from '../../thunks/user-thunk';
import { ITrainingEnablementState, TrainingEnablementPopupMode } from '../../types';

const initialState: ITrainingEnablementState = {
    visible: false,
    mode: TrainingEnablementPopupMode.MINI,
    question: null,
    selectedAnswerId: null,
};

export const trainingEnablementSlice = createSlice({
    name: 'training-enablement',
    initialState,
    reducers: {
        setVisible(state, action: PayloadAction<boolean>) {
            state.visible = action.payload;
        },
        setQuestion(state, action: PayloadAction<ITrainingEnablementState['question']>) {
            const { payload } = action;

            state.question = action.payload;

            if (payload) {
                state.visible = true;
                state.mode = TrainingEnablementPopupMode.MINI;
            }
        },
        expand(state) {
            state.mode = TrainingEnablementPopupMode.FULL;
        },
        setSelectedAnswerId(state, action: PayloadAction<IQuizAnswer['quiz_answer_id'] | null>) {
            state.selectedAnswerId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setSidebarVisibleThunk.fulfilled, (state, action) => {
            const visible = action.payload;

            if (!visible) {
                state.visible = false;
            }
        });
        builder.addMatcher(isClearTrainingEnablementStateAction, (state) => {
            return initialState;
        });
    },
});

export const isClearTrainingEnablementStateAction = isAnyOf(setProject.fulfilled, logout.fulfilled);

export const { setVisible, setQuestion, expand, setSelectedAnswerId } = trainingEnablementSlice.actions;
