export const CUSTOM_DOC_EVENT = 'custom-doc-event';
export const DOC_EVENT_SEARCH = 'doc-event-search';
export const DOC_EVENT_ZOOM = 'doc-event-zoom';
export const DOC_EVENT_JUMP_TO = 'doc-event-jump-to';
export const DOC_EVENT_FILE_URL = 'doc-event-file-url';
export const DOC_EVENT_ERROR = 'doc-event-error';

export enum JUMP_TO {
    NEXT = 'next',
    PREV = 'prev',
}

export enum ZOOM_TO {
    IN = 'in',
    OUT = 'out',
}
