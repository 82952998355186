import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AttentionIcon } from '../../../assets/icons/ask-ai-knowledgebase/attention.svg';

import styles from './AddKnowledgeConfirmModal.module.scss';

export const AddKnowledgeConfirmModal: React.FC = () => {
    const { t } = useTranslation('translations');

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.icon}>
                    <AttentionIcon />
                </div>
                <div className={styles.heading}>{t('ask-ai-kb.submitting-knowledge.title')}</div>
                <div className={styles.description}>{t('ask-ai-kb.submitting-knowledge.description')}</div>
            </div>
        </div>
    );
};
