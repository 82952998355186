import React from 'react';
import { FastField, FieldProps, getIn } from 'formik';

import { RichTextEditor } from './RichTextEditor/RichTextEditor';

export const AnswerBody = () => {
    return (
        <FastField name="answer">
            {({ field, form }: FieldProps<string>) => (
                <RichTextEditor
                    initialValue={form.initialValues.answer}
                    onChange={(value: string) => {
                        form.setFieldValue(field.name, value);
                    }}
                    error={getIn(form.errors, field.name) && getIn(form.touched, field.name)}
                    isDisabled={form.isSubmitting}
                />
            )}
        </FastField>
    );
};
