import React from 'react';

import { ReactComponent as WarningIcon } from '../../../assets/icons/alert-triangle.svg';

import styles from './WarningToast.module.scss';

export interface IWarningToast {
    description: string;
}

export const WarningToast: React.FC<IWarningToast> = ({ description }) => {
    return (
        <div className={styles.wrapper}>
            <WarningIcon />
            <span>{description}</span>
        </div>
    );
};
