import { IConfig } from 'unleash-proxy-client';

import packageJson from '../../../package.json';
import { fetchInBackground } from '../../services/background-fetch/background-fetch-service';
import { StorageKey } from '../../services/storage/storage-key';
import { storageService } from '../../services/storage/storage-service';
import { StorageKeyContext } from '../../services/storage/types';
import { isWebMode } from '../../utils/extension-mode';

const DEFAULT_UNLEASH_FLAGS_UPDATE_INTERVAL_SEC = 30;
export const DEFAULT_EMPTY_CONTEXT = {
    properties: {
        appVersion: packageJson.version,
    },
};

export const unleashClientConfig: IConfig = {
    appName: 'answers-ui',
    environment: process.env.REACT_APP_UNLEASH_ENVIRONMENT,
    url: process.env.REACT_APP_UNLEASH_API_URL!,
    clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY!,
    context: DEFAULT_EMPTY_CONTEXT,
    refreshInterval:
        parseInt(process.env.REACT_APP_UNLEASH_FLAGS_UPDATE_INTERVAL_SEC!, 10) ||
        DEFAULT_UNLEASH_FLAGS_UPDATE_INTERVAL_SEC,
    fetch: isWebMode() ? window.fetch.bind(window) : fetchInBackground,
    storageProvider: {
        save(name: string, data: any) {
            const key = new StorageKey(name, StorageKeyContext.SHARED);

            return storageService._passthrough.setStorageValue(key, data);
        },
        get(name: string) {
            const key = new StorageKey(name, StorageKeyContext.SHARED);

            return storageService._passthrough.getStorageValue(key, undefined);
        },
    },
};
