import React from 'react';

import { ActionType, ActionTypeV2 } from '../api/types';
import { ReactComponent as DirectAnswerIconAsset } from '../assets/icons/direct-answer.svg';
import { ReactComponent as RephraseIcon } from '../assets/icons/direct-answer-rephrase.svg';
import { ReactComponent as SummarizeIcon } from '../assets/icons/direct-answer-summarize.svg';

import { footNoteUnfinishedRefsRe, hasInlinedFootNote } from './attributions';

import style from '../components/Answers/DirectAnswer/DirectAnswer.module.scss';

interface IconAndTooltip {
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
    tooltip: string;
}

export const actionTypeToIcon: Record<ActionType, IconAndTooltip> = {
    [ActionType.SEARCH]: {
        icon: DirectAnswerIconAsset,
        tooltip: 'source.name.direct_answer',
    },
    [ActionType.SUMMARIZE]: {
        icon: SummarizeIcon,
        tooltip: 'action-bar.summarize.tooltip',
    },
    [ActionType.REPHRASE]: {
        icon: RephraseIcon,
        tooltip: 'action-bar.rephrase.tooltip',
    },
};

export const actionTypeToStyle: Record<ActionType | ActionTypeV2, string> = {
    [ActionType.SEARCH]: style.search,
    [ActionType.REPHRASE]: style.rephrase,
    [ActionType.SUMMARIZE]: style.summarize,
    [ActionTypeV2.CUSTOM]: style.custom,
};

export const isSearchShouldBeFocused = (action: ActionType | ActionTypeV2 | null) => {
    return action === null || action === ActionType.SEARCH;
};

export interface ITruncateTextParams {
    maxLength: number;
    linesNumber: number;
    lineSeparator?: string;
}

const hasLineSeparator = (text: string, lineSeparator: string) => text.includes(lineSeparator);

export const truncateText = (text: string, params: ITruncateTextParams) => {
    const { maxLength, linesNumber, lineSeparator = '\n' } = params;

    // if we have a line separator, we can truncate by lines
    if (hasLineSeparator(text, lineSeparator)) {
        const lines = text.split(lineSeparator);

        lines.length = Math.min(lines.length, linesNumber);

        return lines.join(lineSeparator);
    }

    let truncated = text.substring(0, maxLength);

    // if we have part of inlined footnotes, we need to remove them
    if (hasInlinedFootNote(truncated)) {
        truncated = truncated.replace(footNoteUnfinishedRefsRe, '');
    }

    return truncated;
};
