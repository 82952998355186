import React, { useCallback } from 'react';
import { ModalBody } from '@chakra-ui/react';

import { useCurrentAnswer } from '../../../../redux/hooks/answer/answer-hooks';
import { previewService } from '../../../../services/preview/preview-service';
import { getAnswerQuery } from '../../../../utils/answer';
import {
    DOC_EVENT_JUMP_TO,
    DOC_EVENT_SEARCH,
    DOC_EVENT_ZOOM,
    JUMP_TO,
    ZOOM_TO,
} from '../../../PDFViewer/custom-doc-events';
import { IFrameContentScriptModePdf } from '../Body/IFrameContentScriptModePdf';
import { PreviewPdfModalHeader } from '../Header/PreviewPdfModalHeader';

import styles from '../Body/PreviewModalBody.module.scss';

export const ContentScriptMode: React.FC = () => {
    const answer = useCurrentAnswer();
    const initialQuestion = getAnswerQuery(answer);

    const handleSearch = useCallback((value: string) => {
        previewService.dispatchEvent(DOC_EVENT_SEARCH, value);
    }, []);

    const handleZoomIn = useCallback(() => {
        previewService.dispatchEvent(DOC_EVENT_ZOOM, ZOOM_TO.IN);
    }, []);

    const handleZoomOut = useCallback(() => {
        previewService.dispatchEvent(DOC_EVENT_ZOOM, ZOOM_TO.OUT);
    }, []);

    const handleNextMatch = useCallback(() => {
        previewService.dispatchEvent(DOC_EVENT_JUMP_TO, JUMP_TO.NEXT);
    }, []);

    const handlePrevMatch = useCallback(() => {
        previewService.dispatchEvent(DOC_EVENT_JUMP_TO, JUMP_TO.PREV);
    }, []);

    return (
        <>
            <PreviewPdfModalHeader
                initialQuestion={initialQuestion}
                onSearch={handleSearch}
                onZoomIn={handleZoomIn}
                onZoomOut={handleZoomOut}
                onNextMatch={handleNextMatch}
                onPrevMatch={handlePrevMatch}
            />
            <ModalBody className={styles.wrapper}>
                <IFrameContentScriptModePdf />
            </ModalBody>
        </>
    );
};
