import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { INotificationsState } from '../../types';

const initialState: INotificationsState = {
    upgradeToBetaVisible: true,
};

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setUpgradeToBetaVisible(state, action: PayloadAction<boolean>) {
            state.upgradeToBetaVisible = action.payload;
        },
    },
});

export const { setUpgradeToBetaVisible } = notificationsSlice.actions;
