import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BackIcon } from '../../../../../../assets/icons/back-arrow.svg';
import { useCurrentAnswer } from '../../../../../../redux/hooks/answer/answer-hooks';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks/app-hooks';
import { sendMetrics } from '../../../../../../redux/thunks/metrics-thunk';
import { closePreview } from '../../../../../../redux/thunks/preview-thunk';
import { MixpanelEvent } from '../../../../../../services/mixpanel/types';
import { Tooltip } from '../../../../../Tooltips/Tooltip';

import styles from '../FooterButton.module.scss';

export const BackFooterButton: React.FC = () => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();
    const { question, question_id } = useAppSelector((state) => state.question);
    const answer = useCurrentAnswer();

    const handleButtonClick = () => {
        dispatch(closePreview());
        dispatch(
            sendMetrics({
                event: MixpanelEvent.CLOSE_SMART_PREVIEW,
                meta: {
                    use_shortcut: false,
                    question,
                    question_id,
                    doc_id: answer?.doc_id,
                },
            })
        );
    };

    return (
        <Tooltip label={t('button.close')} placement="top" hasArrow>
            <BackIcon onClick={handleButtonClick} className={styles.button} />
        </Tooltip>
    );
};
