import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import {
    Button,
    Link,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
} from '@chakra-ui/react';
import { useFlag, useVariant } from '@unleash/proxy-client-react';
import { Formik } from 'formik';

import packageJson from '../../../../package.json';
import { useGetUserQuery } from '../../../api/endpoints/users-endpoint';
import { IUser } from '../../../api/types';
import { AppToggle } from '../../../clients/unleash/app-toggle-names';
import { AppVariants } from '../../../clients/unleash/app-variants-names';
import { DEFAULT_LANGUAGE_CODE } from '../../../redux/constants';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/app-hooks';
import { useChangeSettings, useProject } from '../../../redux/hooks/settings-hooks';
import { closeSettingsModal } from '../../../redux/slices/modals/settings-modal-slice';
import { sendMetrics } from '../../../redux/thunks/metrics-thunk';
import { openOriginsBlacklistModal } from '../../../redux/thunks/origins-blacklist-thunk';
import { setDebugMode, setDisableOnlineMemory, setShouldDisplaySidebarApp } from '../../../redux/thunks/settings-thunk';
import { IChangeProject } from '../../../redux/types';
import { browserService } from '../../../services/browser/browser-service';
import { MixpanelEvent } from '../../../services/mixpanel/types';
import { isPortalWebMode } from '../../../utils/extension-mode';
import { WithTranslateErrors } from '../../WithTranslateErrors/WithTranslateErrors';
import { ModalContainer } from '../Container/ModalContainer';

import { languageCodeOptions } from './language-code-options';
import { SettingInput } from './SettingInput';
import { userSettingsValidationSchema } from './validation-schema';

import styles from './SettingsModal.module.scss';

export const SettingsModal: React.FC = () => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();
    const changeSettings = useChangeSettings();
    const { visible } = useAppSelector((state) => state.modals.settings);
    const { token, permissions } = useAppSelector((state) => state.auth);
    const {
        backendUrl,
        disableOnlineMemory,
        shouldDisplayFloatingApp,
        simulateCustomerProject,
        languageCode,
        fullTicketAppUrl,
        debugMode,
    } = useAppSelector((state) => state.settings);
    const { data } = useGetUserQuery(undefined, { skip: token === null });
    const { project } = useProject();
    const showFloatingAppMenuItemFlag = useFlag(AppToggle.FLOATING_APP);
    const whiteList = useVariant(AppVariants.PROACTIVE_ANSWERS_URLS_WHITELIST);
    const enabledProactive = useFlag(AppToggle.PROACTIVE_ANSWERS);
    const canChangeLanguageCode = useFlag(AppToggle.MULTI_LINGUAL_SHOW_LANG_SELECTOR);
    const isChangeBackendAllowed = permissions.changeBackendApi;
    const isDisableOnlineMemoryAllowed = permissions.disableOnlineMemory;
    const isDebugModeAllowed = permissions.debugMode;
    const canViewProactiveAdminSettings = enabledProactive && permissions.debugProactiveAccess;
    const showFloatingAppMenuItem = !isPortalWebMode() && showFloatingAppMenuItemFlag;

    const handleCloseModal = () => {
        dispatch(closeSettingsModal());
        dispatch(sendMetrics({ event: MixpanelEvent.CLOSE_SETTING_PANEL }));
    };

    const handleUpdateShortcutClick = async () => {
        await browserService.createTab({ url: 'chrome://extensions/shortcuts', active: true, selected: false });
    };

    const handleOriginsBlacklistSettings: MouseEventHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();

        dispatch(openOriginsBlacklistModal({ location: 'Settings', url: window.location.href }));
    };

    const renderForm = (user: IUser) => {
        const initialFormState: IChangeProject = {
            open_link_in_new_page: user.settings.open_link_in_new_page,
            customer_project: project,
            backend_url: backendUrl,
            disable_online_memory: isDisableOnlineMemoryAllowed ? disableOnlineMemory : null,
            debug_mode: isDebugModeAllowed ? debugMode : null,
            display_floating_app: shouldDisplayFloatingApp ?? true,
            simulate_customer_project: simulateCustomerProject || project,
            proactive_url: '',
            full_ticket_app_url: fullTicketAppUrl,
            lang_code: canChangeLanguageCode ? languageCode : DEFAULT_LANGUAGE_CODE,
        };

        const handleFormSubmit = async (changeProjectData: IChangeProject) => {
            await changeSettings(changeProjectData);

            batch(() => {
                dispatch(setShouldDisplaySidebarApp(changeProjectData.display_floating_app));
                dispatch(setDisableOnlineMemory(changeProjectData.disable_online_memory));
                dispatch(setDebugMode(changeProjectData.debug_mode));
                dispatch(closeSettingsModal());
            });
        };

        return (
            <Formik
                initialValues={initialFormState}
                onSubmit={handleFormSubmit}
                validationSchema={userSettingsValidationSchema(whiteList.payload?.value)}
                validateOnChange={false}
            >
                {({ values, errors, touched, setFieldTouched, submitForm, isSubmitting, setFieldValue }) => {
                    const isBackendUrlChanged = values['backend_url'] !== backendUrl;

                    return (
                        <WithTranslateErrors errors={errors} touched={touched} setFieldTouched={setFieldTouched}>
                            <ModalBody className={styles.body}>
                                <div className={styles.checkboxWrapper}>
                                    <SettingInput
                                        name="display_floating_app"
                                        inputType="checkbox"
                                        hidden={!showFloatingAppMenuItem}
                                    >
                                        <p className={styles.label}>
                                            <b>{t('settings.enable-floating-app')}</b>
                                            <br />
                                            {t('settings.enable-floating-app.helper')}
                                        </p>
                                    </SettingInput>
                                </div>
                                <Link
                                    fontSize="1.4em"
                                    fontWeight={500}
                                    onClick={handleOriginsBlacklistSettings}
                                    variant="main"
                                >
                                    {t('settings.display-floating-app.exclude-domains-list')}
                                </Link>
                                <SettingInput
                                    name="open_link_in_new_page"
                                    inputType="checkbox"
                                    disabled={isBackendUrlChanged}
                                >
                                    {t('settings.open-link')}
                                </SettingInput>
                                <SettingInput
                                    name="customer_project"
                                    label={t('settings.project')}
                                    inputType="select"
                                    disabled={isBackendUrlChanged}
                                    data-testid="settings.project"
                                    hidden={user.customer_projects.length <= 1}
                                    handleChange={(e) => {
                                        const value = e.target.value;
                                        if (canViewProactiveAdminSettings) {
                                            // Make sure the proactive project is the same as the selected project IF the user can use proactive
                                            setFieldValue('simulate_customer_project', value);
                                        }
                                        setFieldValue('customer_project', value);
                                    }}
                                >
                                    {user.customer_projects.map((project) => (
                                        <option key={project.value} value={project.value}>
                                            {project.text}
                                        </option>
                                    ))}
                                </SettingInput>

                                <SettingInput
                                    name="lang_code"
                                    label={t('settings.language.title')}
                                    inputType="select"
                                    disabled={isBackendUrlChanged}
                                    hidden={!canChangeLanguageCode}
                                >
                                    {languageCodeOptions.map(({ value, label }) => (
                                        <option key={value} value={value}>
                                            {label}
                                        </option>
                                    ))}
                                </SettingInput>

                                {canViewProactiveAdminSettings && (
                                    <>
                                        <SettingInput
                                            name="simulate_customer_project"
                                            inputType="select"
                                            label={t('settings.simulated-customer-project')}
                                            data-testid="settings.simulated-customer-project"
                                            hidden={user.customer_projects.length <= 1}
                                        >
                                            {user.customer_projects.map((project) => (
                                                <option key={project.value} value={project.value}>
                                                    {project.text}
                                                </option>
                                            ))}
                                        </SettingInput>

                                        <SettingInput
                                            withError
                                            withClear
                                            name="proactive_url"
                                            inputType="input"
                                            label={t('settings.proactive-url')}
                                            disabled={!!values.full_ticket_app_url}
                                            handleChange={(event) => {
                                                setFieldValue('full_ticket_app_url', '');
                                                setFieldValue('proactive_url', event.target.value);
                                            }}
                                        />
                                        <SettingInput
                                            withError
                                            withClear
                                            name="full_ticket_app_url"
                                            inputType="input"
                                            label={t('settings.full-ticket-app-url')}
                                            disabled={!!values.proactive_url}
                                            handleChange={(event) => {
                                                setFieldValue('proactive_url', '');
                                                setFieldValue('full_ticket_app_url', event.target.value);
                                            }}
                                        />
                                    </>
                                )}
                                <SettingInput
                                    withError
                                    name="backend_url"
                                    label={t('settings.backend-url')}
                                    inputType="input"
                                    hidden={!isChangeBackendAllowed}
                                />

                                <SettingInput
                                    name="disable_online_memory"
                                    inputType="checkbox"
                                    disabled={isBackendUrlChanged}
                                    hidden={!isDisableOnlineMemoryAllowed}
                                >
                                    {t('settings.disable-online-memory')}
                                </SettingInput>
                                <SettingInput name="debug_mode" inputType="checkbox" hidden={!isDebugModeAllowed}>
                                    {t('settings.debug-mode')}
                                </SettingInput>
                                <div>
                                    <Link onClick={handleUpdateShortcutClick} variant="main">
                                        {t('settings.change-shortcut')}
                                    </Link>
                                </div>
                            </ModalBody>
                            <ModalFooter className={styles.footer}>
                                <Button
                                    variant="secondary"
                                    onClick={handleCloseModal}
                                    data-testid="settings.button.cancel"
                                >
                                    {t('button.cancel')}
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={submitForm}
                                    isLoading={isSubmitting}
                                    data-testid="settings.button.save"
                                >
                                    {t('button.save')}
                                </Button>
                            </ModalFooter>
                        </WithTranslateErrors>
                    );
                }}
            </Formik>
        );
    };

    return (
        <ModalContainer
            isOpen={visible}
            onClose={handleCloseModal}
            motionPreset="scale"
            scrollBehavior="inside"
            data-testid="modal.settings"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader className={styles.header}>
                    {t('settings.header')}
                    <span className={styles.version}>
                        {t('settings.version')}: {packageJson.version}
                    </span>
                </ModalHeader>

                {!data ? (
                    <ModalBody>
                        <Spinner />
                    </ModalBody>
                ) : (
                    renderForm(data)
                )}
            </ModalContent>
        </ModalContainer>
    );
};
