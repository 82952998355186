import React from 'react';
import {
    Checkbox,
    FormControl,
    FormErrorMessage,
    FormLabel,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Select,
} from '@chakra-ui/react';
import { FieldProps, getIn, useFormikContext } from 'formik';

import { ReactComponent as Clear } from '../../../assets/icons/close.svg';
import { IChangeProject } from '../../../redux/types';

type InputType = 'input' | 'select' | 'checkbox';

type SettingInputProps<T extends IChangeProject> = {
    name: keyof T extends string ? keyof T : never;
    inputType: InputType;
    label?: string;
    withError?: boolean;
    withClear?: boolean;
    disabled?: boolean;
    'data-testid'?: string;
    handleChange?: (event: React.ChangeEvent<any>) => void;
    children?: React.ReactNode;
    hidden?: boolean;
};

export function SettingInput<T extends IChangeProject>(props: SettingInputProps<T>): React.ReactElement | null {
    const { errors, touched, getFieldProps, setFieldValue } = useFormikContext<FieldProps<T[keyof T], T>>();

    const { name, inputType, label, withError, withClear, handleChange, hidden, ...settingsInputProps } = props;

    if (hidden) {
        return null;
    }

    let inputNode: React.ReactNode = inputType === 'input' ? <Input /> : null;
    const fieldProps = getFieldProps(name);
    const onChange = handleChange ?? fieldProps.onChange;

    switch (inputType) {
        case 'input': {
            inputNode = <Input {...fieldProps} {...settingsInputProps} size="sm" onChange={onChange} />;

            if (withClear) {
                inputNode = (
                    <InputGroup size="sm">
                        {inputNode}
                        {!!fieldProps.value && (
                            <InputRightElement>
                                <IconButton
                                    size="sm"
                                    variant="solid"
                                    color="gray"
                                    colorScheme="transparent"
                                    aria-label="clears input"
                                    icon={<Clear />}
                                    onClick={() => setFieldValue(fieldProps.name, '')}
                                />
                            </InputRightElement>
                        )}
                    </InputGroup>
                );
            }

            break;
        }
        case 'checkbox': {
            const { value, ...rest } = fieldProps;
            inputNode = <Checkbox {...rest} {...settingsInputProps} isChecked={value} onChange={onChange} />;
            break;
        }
        case 'select': {
            inputNode = <Select {...fieldProps} {...settingsInputProps} size="sm" onChange={onChange} />;
            break;
        }
    }

    if (!inputNode) {
        throw new Error(`Unsupported input type: ${inputType}`);
    }

    const labelNode = label ? <FormLabel size="xs">{label}</FormLabel> : null;
    const errorMessageNode = withError ? <FormErrorMessage>{getIn(errors, name)}</FormErrorMessage> : null;
    const isInvalid = withError && Boolean(getIn(errors, name) && getIn(touched, name));

    return (
        <FormControl isInvalid={isInvalid}>
            {labelNode}
            {inputNode}
            {errorMessageNode}
        </FormControl>
    );
}
