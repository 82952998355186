import { askEndpoint } from '../../api/endpoints/ask-endpoint';
import { SHARED_ANSWERS_CACHE_KEY } from '../constants';
import { AppThunk } from '../core-store';

export const abortAskQuestion = (): AppThunk => (dispatch) => {
    const askRequest = dispatch(askEndpoint.util.getRunningMutationThunk('getAnswers', SHARED_ANSWERS_CACHE_KEY));
    if (askRequest) {
        askRequest.abort();
    }
};
