import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAppState } from '../../types';

const initialState: IAppState = {
    initialized: false,
    sidebarVisible: false,
    sidebarOpenedBy: null,
    loading: false,
    error: false,
};

export interface ISetSidebarVisiblePayload {
    visible: IAppState['sidebarVisible'];
    openedBy: IAppState['sidebarOpenedBy'];
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        //don't use directly, use the `setSidebarVisibleThunk` instead
        setSidebarVisible(state, action: PayloadAction<ISetSidebarVisiblePayload>) {
            const { visible, openedBy } = action.payload;

            state.sidebarVisible = visible;
            state.sidebarOpenedBy = openedBy;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
    },
});

export const { setSidebarVisible, setLoading } = appSlice.actions;
