let LogRocket:
    | {
          getSessionURL(callback: (sessionURL: string) => void): void;
      }
    | undefined = undefined;

/**
 * Get LogRocket session URL, will timeout after the given ms and return undefined
 * @param ms
 * @returns
 */
export const getLogRocketSessionUrlTimed = (ms: number): Promise<string | undefined> => {
    return new Promise((resolve) => {
        let timeout: NodeJS.Timeout | undefined = setTimeout(() => {
            resolve(undefined);
            timeout = undefined;
        }, ms);

        const performGetSessionURL = () => {
            LogRocket?.getSessionURL((url) => {
                if (timeout) {
                    clearTimeout(timeout);
                }
                resolve(url);
            });
        };

        if (!LogRocket) {
            import('logrocket').then((acquiredLogRocket) => {
                LogRocket = acquiredLogRocket;
                performGetSessionURL();
            });
        } else {
            performGetSessionURL();
        }
    });
};
