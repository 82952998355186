import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAnswerResponse, IAttributionsAnswer } from '../../../api/types';

export interface IPreviewModalState {
    visible: boolean;
    currentAnswerId: string | null;
    matchesCount: number;
    currentMatch: number;
    answer?: IAnswerResponse | IAttributionsAnswer;
}

const initialState: IPreviewModalState = {
    visible: false,
    currentAnswerId: null,
    matchesCount: 0,
    currentMatch: 0,
};

export const previewModalSlice = createSlice({
    name: 'preview-modal',
    initialState,
    reducers: {
        setCurrentAnswer(state, action: PayloadAction<string | null>) {
            state.currentAnswerId = action.payload;
        },
        showPreviewModal(state, action: PayloadAction<IAnswerResponse | IAttributionsAnswer>) {
            const answer = action.payload;
            state.answer = answer;
            state.currentAnswerId = answer.doc_id;
            state.visible = true;
        },
        closePreviewModal(state) {
            state.visible = false;
            state.currentMatch = 0;
            state.currentAnswerId = null;
        },
        setMatchesCount(state, action: PayloadAction<number>) {
            state.currentMatch = 0;
            state.matchesCount = action.payload;
        },
        prevMatch(state) {
            const { currentMatch, matchesCount } = state;

            const followingMatch = currentMatch - 1;
            state.currentMatch = followingMatch >= 0 ? followingMatch : matchesCount - 1;
        },
        nextMatch(state) {
            const { currentMatch, matchesCount } = state;

            const followingMatch = currentMatch + 1;
            state.currentMatch = followingMatch >= matchesCount ? 0 : followingMatch;
        },
    },
});

export const { showPreviewModal, setMatchesCount, prevMatch, nextMatch, closePreviewModal } = previewModalSlice.actions;
