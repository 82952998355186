import browser from 'webextension-polyfill';

import { AbstractEventHandler } from '../abstract-event-handler';

export class OnCommandEvent extends AbstractEventHandler {
    register() {
        browser.commands.onCommand.addListener((command, tab) => {
            this.emitter.emit('onCommand', tab?.id, command);
        });
    }
}
