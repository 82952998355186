import React from 'react';

import { ActionType, ActionTypeV2, IAnswerResponse } from '../api/types';
import { ReactComponent as DirectAnswerIconAsset } from '../assets/icons/direct-answer.svg';
import { ReactComponent as RephraseIcon } from '../assets/icons/direct-answer-rephrase.svg';
import { ReactComponent as SummarizeIcon } from '../assets/icons/direct-answer-summarize.svg';
import i18n from '../i18n';

import { getAnswerUrl } from './answer';

import style from '../components/Answers/DirectAnswer/DirectAnswer.module.scss';

interface IconAndTooltip {
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
    tooltip: string;
}

export const footNoteRefsRe = /\[\^([^\]]+)\]/g; // capture all inlined footnotes '[^doc_id]'
const footNoteUnfinishedRefsRe = /\[\^([^\]]+)/g; // capture unfinished inlined footnotes '[^doc_id'
const footNoteDescription = /(\n\[\^.\S+\]: \S*\n?)/; // capture the first footnote '\n[^doc_id]: doc_title'

export const actionTypeToIcon: Record<ActionType, IconAndTooltip> = {
    [ActionType.SEARCH]: {
        icon: DirectAnswerIconAsset,
        tooltip: 'source.name.direct_answer',
    },
    [ActionType.SUMMARIZE]: {
        icon: SummarizeIcon,
        tooltip: 'action-bar.summarize.tooltip',
    },
    [ActionType.REPHRASE]: {
        icon: RephraseIcon,
        tooltip: 'action-bar.rephrase.tooltip',
    },
};

export const actionTypeToStyle: Record<ActionType | ActionTypeV2, string> = {
    [ActionType.SEARCH]: style.search,
    [ActionType.REPHRASE]: style.rephrase,
    [ActionType.SUMMARIZE]: style.summarize,
    [ActionTypeV2.CUSTOM]: style.custom,
};

export const isSearchShouldBeFocused = (action: ActionType | ActionTypeV2 | null) => {
    return action === null || action === ActionType.SEARCH;
};

export interface ITruncateTextParams {
    maxLength: number;
    linesNumber: number;
    lineSeparator?: string;
}

const hasLineSeparator = (text: string, lineSeparator: string) => text.includes(lineSeparator);
const hasInlinedFootNote = (text: string) => text.search(footNoteUnfinishedRefsRe) !== -1;

export const truncateText = (text: string, params: ITruncateTextParams) => {
    const { maxLength, linesNumber, lineSeparator = '\n' } = params;

    // if we have a line separator, we can truncate by lines
    if (hasLineSeparator(text, lineSeparator)) {
        const lines = text.split(lineSeparator);

        lines.length = Math.min(lines.length, linesNumber);

        return lines.join(lineSeparator);
    }

    let truncated = text.substring(0, maxLength);

    // if we have part of inlined footnotes, we need to remove them
    if (hasInlinedFootNote(truncated)) {
        truncated = truncated.replace(footNoteUnfinishedRefsRe, '');
    }

    return truncated;
};

/**
 * Parse direct answer text to clean, dirty and footnotes
 *
 * @param answer - direct answer text (Markdown markup)
 * @returns object - 'clean' - text without inlined refs and footnotes description, 'dirty' - text with inlined refs, 'footnotes' - footnotes description
 * */
export const parseDirectAnswerTextFootnotes = (answer: string) => {
    const footnoteDescriptionIdx = answer.search(footNoteDescription);
    const hasFootnotesDescription = footnoteDescriptionIdx !== -1;

    const clean = hasFootnotesDescription
        ? answer.slice(0, footnoteDescriptionIdx).replaceAll(footNoteRefsRe, '')
        : answer.replaceAll(footNoteRefsRe, '');

    const dirty = hasFootnotesDescription ? answer.slice(0, footnoteDescriptionIdx) : answer;
    const footnotes = hasFootnotesDescription ? answer.slice(footnoteDescriptionIdx) : '';

    return { clean, footnotes, dirty } as const;
};

/**
 * Replace inlined footnotes with links
 *
 * example:
 * input: 'This is a text with a footnote[^doc_id]'
 * output: 'This is a text with a footnote[please refer to https://answer-visit-page.url for more info]'
 *
 * @param text - direct answer text (Markdown markup)
 * @param answers - array of answers to find the footnote reference
 *
 * @returns string - text with replaced footnotes
 * */
export const replaceFootnoteRefWithLink = (text: string, answers: IAnswerResponse[]): string => {
    return text.replace(footNoteRefsRe, (_, docId) => {
        const answer = answers.find((answer) => answer.doc_id === docId);
        if (!answer) {
            return '';
        }

        const url = getAnswerUrl(answer);

        return url
            ? ` [${i18n.t('direct-answer.attributions.refer-to', {
                  ns: 'translations',
                  replace: { url },
                  interpolation: { escapeValue: false },
              })}]`
            : '';
    });
};
