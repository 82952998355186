import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { abortManager } from '../background-fetch/abort-manager/abort-manager';
import { createWorkerService } from '../core/worker/worker-service';
import { ServiceWorkerContext } from '../types';

import { ApiQueryServiceFn } from './types';
import { isFormDataContentType, prepareArgsBodyToFormData } from './utils';

const makeApiQuery: ApiQueryServiceFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, extra) => {
    const { token, baseUrl, type, queryId } = extra;

    const prepareHeaders: FetchBaseQueryArgs['prepareHeaders'] = (headers) => {
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }

        // remove the content-type header for FormData, fetch should set it automatically
        // https://stackoverflow.com/a/39281156
        if (headers.has('content-type') && headers.get('content-type') === 'multipart/form-data') {
            headers.delete('content-type');
        }

        return headers;
    };

    const abortController = new AbortController();

    abortManager.addController(queryId, abortController);

    if (isFormDataContentType(args)) {
        await prepareArgsBodyToFormData(args);
    }

    const baseQuery = fetchBaseQuery({ baseUrl, prepareHeaders });

    try {
        const result = await baseQuery(
            args,
            {
                getState: () => {},
                abort: abortController.abort,
                extra: null,
                endpoint: 'fetchBaseQuery wrapper',
                dispatch: () => false,
                type,
                signal: abortController.signal,
            },
            {}
        );

        return result;
    } finally {
        abortManager.removeController(queryId);
    }
};

const cancelApiQuery = async (queryId: string, reason: unknown) => {
    abortManager.abort(queryId, reason);
};

const service = createWorkerService({
    name: 'api-query',
    context: ServiceWorkerContext.BACKGROUND,
    handlers: () => ({
        makeApiQuery,
        cancelApiQuery,
    }),
});

export const apiQueryService = service.actions;

export const registerApiQueryService = service.register;
