import { Browser } from 'webextension-polyfill';

import { isWebMode } from '../../utils/extension-mode';
import { WorkerServiceRegisterCallback } from '../core/worker/types';

export type GetTabIdMessage = {
    name: 'get-tab-id-message';
};

export const isGetTabIdMessage = (message: unknown): message is GetTabIdMessage => {
    return !!message && typeof message === 'object' && 'name' in message && message.name === 'get-tab-id-message';
};

const getTabId = async (): Promise<number | undefined> => {
    if (isWebMode()) {
        return undefined;
    }

    const browser = require('webextension-polyfill');

    const { tabId } = await browser.runtime.sendMessage({ name: 'get-tab-id-message' });

    return tabId;
};

export const tabsService = { getTabId, _passthrough: { getTabId } };

export const registerTabsService: WorkerServiceRegisterCallback = () => {
    const callbacks = new Map();

    if (!isWebMode()) {
        const browser: Browser = require('webextension-polyfill');
        browser.runtime.onMessage.addListener((message: unknown, sender) => {
            if (isGetTabIdMessage(message)) {
                const tabId = sender.tab?.id ?? sender.url;

                return Promise.resolve({ tabId });
            }
        });
    }

    return callbacks;
};
