import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'answers-core/src/components/Tooltips/Tooltip';
import cx from 'clsx';

import styles from './Icon.module.scss';

interface IconData {
    source_icon: string;
    source_id: string;
}
export interface IAnswerIconProps {
    iconData: IconData;
    onClick: () => void;
    className?: string;
}

export const AnswerIcon: React.FC<IAnswerIconProps> = ({ iconData, onClick, className }) => {
    const { t } = useTranslation('translations');
    const { source_icon, source_id } = iconData;
    const translatedSourceName = t(`source.name.${source_id}`, { defaultValue: source_id });
    return (
        <Tooltip label={translatedSourceName} hasArrow>
            <div className={cx(styles.wrapper, className)}>
                <img src={source_icon} alt={t('source.title', { type: translatedSourceName })} onClick={onClick} />
            </div>
        </Tooltip>
    );
};
