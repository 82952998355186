import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { BaseQueryApi, QueryReturnValue } from '@reduxjs/toolkit/src/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/src/query/tsHelpers';
import { v4 } from 'uuid';

import { IRootState } from '../redux/core-store';
import { apiQueryService } from '../services/api-query/api-query-service';
import { BACKEND_URL_STORAGE_KEY } from '../services/storage/storage-keys-list';
import { storageService } from '../services/storage/storage-service';

export const userDefinedBackend = async () => {
    const value = await storageService.getStorageValue(BACKEND_URL_STORAGE_KEY, '');

    return value;
};

export type QueryWithDynamicBackendFn<
    Args = any,
    Result = unknown,
    Error = unknown,
    DefinitionExtraOptions = {},
    Meta = {}
> = (
    args: Args,
    api: BaseQueryApi,
    extraOptions: DefinitionExtraOptions
) => MaybePromise<QueryReturnValue<Result, Error, Meta>>;

export const queryWithDynamicBackend: QueryWithDynamicBackendFn<string | FetchArgs, unknown, FetchBaseQueryError> &
    Pick<FetchBaseQueryArgs, 'prepareHeaders'> = async (args, api, options) => {
    const { backendUrl, gatewayUrl } = (api.getState() as IRootState).settings;
    const { token } = (api.getState() as IRootState).auth;
    const customUrl = await userDefinedBackend();

    let baseUrl = backendUrl;

    if (customUrl) {
        baseUrl = customUrl;
    } else if (gatewayUrl) {
        baseUrl = gatewayUrl;
    }

    const queryId = v4();

    const abortHandler = () => {
        apiQueryService.cancelApiQuery(queryId, api.signal.reason);
    };

    api.signal.addEventListener('abort', abortHandler);

    try {
        const result = await apiQueryService.makeApiQuery(args, { token, baseUrl, type: api.type, queryId });

        return result;
    } catch (e) {
        throw e;
    } finally {
        api.signal.removeEventListener('abort', abortHandler);
    }
};
