import { useCallback } from 'react';
import { AnswersSortOrder, AnswersSortOrderTranslationKeys } from 'answers-core/src/api/consts';
import { useAppDispatch, useAppSelector } from 'answers-core/src/redux/hooks/app-hooks';
import { setSortOrder } from 'answers-core/src/redux/slices/annotate-answers/annotate-answers-slice';

export const useAnswersSort = () => {
    const dispatch = useAppDispatch();
    const { sort_order } = useAppSelector((state) => state.annotateAnswers);
    const changeSortOrder = useCallback(
        (value: string) => {
            dispatch(setSortOrder(value));
        },
        [dispatch]
    );
    const order = {
        translationKey: 'annotate-answers.sort-order.default',
        value: AnswersSortOrder.DEFAULT,
    };

    if (AnswersSortOrderTranslationKeys[sort_order]) {
        order.translationKey = AnswersSortOrderTranslationKeys[sort_order];
        order.value = sort_order;
    } else {
        console.error('Invalid sort order value. Falling back to default.');
    }

    return [order, changeSortOrder] as const;
};
