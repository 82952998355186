import { batch } from 'react-redux';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react';

import i18n from '../../i18n';
import { DEFAULT_FLOATING_ICON_HIDE_DELAY_MS, DEFAULT_SEARCH_STATE_RELEVANCE_TIME } from '../../redux/constants';
import {
    setApiGatewayUrl,
    setConfidenceSettings,
    setFloatingIconHideDelay,
    setProactiveFrequencySettings,
} from '../../redux/slices/settings/settings-slice';
import { saveBackendUrl, setSearchStateRelevanceTime } from '../../redux/thunks/settings-thunk';
import { userDefinedBackend } from '../api';
import { baseApi } from '../base-api';
import { environmentBackend } from '../consts';
import { IApiSettings, ISettings } from '../types';

export const API_SETTINGS_PATH = '/v1/settings';

export const settingsEndpoint = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getSettings: builder.query<ISettings, void>({
            queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
                const { dispatch } = queryApi;
                const settingsResponse = await baseQuery({
                    url: API_SETTINGS_PATH,
                });

                const { data, error } = settingsResponse as QueryReturnValue<
                    IApiSettings,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >;

                if (error || !data) {
                    return {
                        error: {
                            status: -1,
                            data: i18n.t('error.api.settings', { ns: 'errors' }),
                        },
                    };
                }

                batch(() => {
                    dispatch(
                        setFloatingIconHideDelay(
                            data.floating_icon_hide_delay_ms ?? DEFAULT_FLOATING_ICON_HIDE_DELAY_MS
                        )
                    );
                    dispatch(setConfidenceSettings(data.show_low_confidence_tresh));
                    dispatch(
                        setSearchStateRelevanceTime(
                            data.search_state_relevance_time ?? DEFAULT_SEARCH_STATE_RELEVANCE_TIME
                        )
                    );
                    dispatch(setProactiveFrequencySettings(data.proactive_frequency));
                });

                const definedBackend = await userDefinedBackend();

                if (definedBackend || data.backend_url === environmentBackend) {
                    return {
                        data,
                    };
                }

                dispatch(saveBackendUrl(data.api_gateway_url ?? data.backend_url));

                const customBackendSettings = await baseQuery({
                    url: API_SETTINGS_PATH,
                });

                const { data: customBackendData, error: customBackendError } =
                    customBackendSettings as QueryReturnValue<IApiSettings, FetchBaseQueryError, FetchBaseQueryMeta>;

                if (customBackendError || !customBackendData) {
                    return {
                        error: {
                            status: -1,
                            data: i18n.t('error.api.settings', { ns: 'errors' }),
                        },
                    };
                }

                batch(() => {
                    dispatch(
                        setSearchStateRelevanceTime(
                            customBackendData.search_state_relevance_time ?? DEFAULT_SEARCH_STATE_RELEVANCE_TIME
                        )
                    );
                    dispatch(setConfidenceSettings(customBackendData.show_low_confidence_tresh));
                    dispatch(setApiGatewayUrl(customBackendData.api_gateway_url));
                    dispatch(setProactiveFrequencySettings(customBackendData.proactive_frequency));
                });

                return {
                    data: customBackendData,
                };
            },
        }),
    }),
});

export const { useGetSettingsQuery } = settingsEndpoint;
