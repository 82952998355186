import { batch } from 'react-redux';

import { MixpanelEvent } from '../../services/mixpanel/types';
import { AppThunk } from '../core-store';
import { AnnotateAnswerModalOrigin, openAnnotateAnswerModal } from '../slices/modals/annotate-answer-modal-slice';
import { _openRequestKnowledgeModal } from '../slices/modals/request-knowledge-modal-slice';

import { sendMetrics } from './metrics-thunk';

export const openRequestKnowledgeModal = (): AppThunk => (dispatch) => {
    batch(() => {
        dispatch(_openRequestKnowledgeModal());
        dispatch(sendMetrics({ event: MixpanelEvent.OPEN_KNOWLEDGE_REQUEST_MAIN_PANEL }));
    });
};

export const openAddAnswerModal = (): AppThunk => (dispatch) => {
    batch(() => {
        dispatch(
            openAnnotateAnswerModal({
                origin: AnnotateAnswerModalOrigin.ADD_ANSWER,
            })
        );
        dispatch(sendMetrics({ event: MixpanelEvent.OPEN_ADD_ANSWER_MAIN_PANEL }));
    });
};
