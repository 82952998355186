const SIDEBAR_OVERLAY_Z_INDEX = '998';

export const fixNotionHostStyles = (sidebarWidth: number) => {
    const notionContentContainer = document.querySelector<HTMLElement>('div.notion-cursor-listener');
    const sidebarContainer = document.querySelector<HTMLElement>(`#${SHADOW_DOM_ROOT_ID}`);

    if (notionContentContainer) {
        notionContentContainer.style.width = '100%';
    }
    if (sidebarContainer) {
        sidebarContainer.style.position = 'absolute';
        sidebarContainer.style.zIndex = SIDEBAR_OVERLAY_Z_INDEX;
    }

    const notionInnerContentContainer = document.querySelector<HTMLElement>(
        'div.notion-frame  div.notion-scroller.vertical'
    );

    if (notionInnerContentContainer) {
        notionInnerContentContainer.style.paddingRight = `${sidebarWidth}px`;
    }
};
