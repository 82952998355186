import { useCallback, useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { IMutableContext, UnleashClient, useFlag, useFlagsStatus, useUnleashClient } from '@unleash/proxy-client-react';

import { AppToggle } from '../../clients/unleash/app-toggle-names';
import { AppVariantNames, AppVariants } from '../../clients/unleash/app-variants-names';
import { getLogger } from '../../utils/logger';
import { getNumberVariant } from '../../utils/unleash';
import { getUserProject } from '../../utils/user';
import { setFloatingEnabled } from '../slices/metrics/metrics-slice';
import {
    setDirectAnswerPopupEnabled,
    setFloatingAnimationEnabled,
    setProactiveFrequencyEnabled,
} from '../slices/proactive-answers/proactive-answers-slice';
import { setSmartFiltersEnabled } from '../slices/smart-filters/smart-filters-slice';
import { setEnabled as setUATEnabled } from '../slices/uat/uat-slice';
import { setShowedForTTL } from '../thunks/proactive-answer-thunk';

import { useAppDispatch, useAppSelector } from './app-hooks';
import { useAsyncTaskQueue } from './async-task-queue';

const logger = getLogger('unleash-context-handler');

export const useIsUnleashReady = () => {
    const [isUnleashFailed, setIsUnleashFailed] = useState(false);
    const { flagsReady, flagsError } = useFlagsStatus();
    const client = useUnleashClient();

    useEffect(() => {
        const onReadyHandler = () => {
            if (isUnleashFailed) {
                setIsUnleashFailed(false);
            }
        };

        const onErrorHandler = (err: any) => {
            console.error('Unleash client error', err);
            setIsUnleashFailed(true);

            if ('code' in err && err.code === 0) {
                logger.log('Extension context invalidated, stop client');

                client.stop();
            }
        };

        client.on('ready', onReadyHandler);
        client.on('error', onErrorHandler);

        return () => {
            client.off('ready', onReadyHandler);
            client.off('error', onErrorHandler);
        };
    }, [client, isUnleashFailed]);

    // Unleash is ready when flags are ready or when there is an error
    const isUnleashReady = flagsReady || flagsError || isUnleashFailed;

    return { isUnleashReady };
};

let prevUnleashContext: IMutableContext | undefined = undefined;

export const useUnleashContextHandler = () => {
    const dispatch = useAppDispatch();
    const client = useUnleashClient();
    const auth = useAppSelector((state) => state.auth);
    const settings = useAppSelector((state) => state.settings);
    const userProject = getUserProject({ auth, settings });

    const { uid: userId, user_org: userOrg } = auth.user;

    const handleErrors = useCallback((reason: unknown) => {
        logger.error(`Error clearing Unleash context: ${reason}`);
    }, []);

    const { addTask: queueContextUpdate } = useAsyncTaskQueue({ onError: handleErrors });

    useEffect(() => {
        const updateContext = (clearContext?: boolean) => {
            if (clearContext && prevUnleashContext !== undefined && Object.keys(prevUnleashContext).length === 0) {
                return;
            }

            const context = clearContext
                ? {}
                : {
                      userId,
                      properties: {
                          userId,
                          userOrg,
                          userProject,
                      },
                  };

            logger.log(clearContext ? 'Clearing Unleash context' : 'Update Unleash context');

            prevUnleashContext = context;
            queueContextUpdate(() => client.updateContext(context));
        };

        updateContext(!userId);

        return () => {
            updateContext(true);
        };
    }, [userId, userOrg, userProject, client, queueContextUpdate]);

    useEffect(() => {
        if (!client) {
            return;
        }

        const onUpdateHandler = () => {
            const floatingEnabled = client.isEnabled(AppToggle.FLOATING_APP);
            const uatEnabled = client.isEnabled(AppToggle.UAT_MODE);
            const proactiveFrequencyEnabled = client.isEnabled(AppToggle.PROACTIVE_ANSWERS_FREQUENCY);
            const directAnswerPopupEnabled = client.isEnabled(AppToggle.PROACTIVE_ANSWERS_DIRECT_ANSWER_POPUP);
            const floatingAnimationEnabled = client.isEnabled(AppToggle.PROACTIVE_ANSWERS_FLOATING_ANIMATION);
            const smartFiltersEnabled = client.isEnabled(AppToggle.SMART_FILTERS);
            const proactiveShowedForTTLInMinutes = getNumberVariant(
                client,
                AppVariants.PROACTIVE_ANSWERS_KEEP_TRACK_OF_VISITED,
                AppVariantNames.PROACTIVE_ANSWERS_KEEP_TRACK_OF_VISITED_TTL
            );

            batch(() => {
                dispatch(setFloatingEnabled(floatingEnabled));
                dispatch(setUATEnabled(uatEnabled));
                dispatch(setProactiveFrequencyEnabled(proactiveFrequencyEnabled));
                dispatch(setDirectAnswerPopupEnabled(directAnswerPopupEnabled));
                dispatch(setFloatingAnimationEnabled(floatingAnimationEnabled));
                dispatch(
                    setShowedForTTL(
                        proactiveShowedForTTLInMinutes ? proactiveShowedForTTLInMinutes * 60_000 : undefined
                    )
                );
                dispatch(setSmartFiltersEnabled(smartFiltersEnabled));
            });
        };

        client.on('ready', onUpdateHandler);
        client.on('update', onUpdateHandler);

        return () => {
            client.off('ready', onUpdateHandler);
            client.off('update', onUpdateHandler);
        };
    }, [dispatch, client]);
};

const isUserContextReady = (client: UnleashClient): boolean => {
    const context = client.getContext();

    return context.userId !== undefined;
};

export const useUserFlag = (name: string): boolean | undefined => {
    const client = useUnleashClient();
    const flagValue = useFlag(name);
    const [flagReady, setFlagReady] = useState<boolean>(() => isUserContextReady(client));

    useEffect(() => {
        const onUpdateHandler = () => {
            setFlagReady(isUserContextReady(client));
        };

        client.on('ready', onUpdateHandler);
        client.on('update', onUpdateHandler);

        return () => {
            client.off('ready', onUpdateHandler);
            client.off('update', onUpdateHandler);
        };
    }, [client, flagValue, name]);

    return flagReady ? flagValue : undefined;
};
