import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { VisuallyHidden } from '@chakra-ui/react';
import cx from 'clsx';

import { useMarkAsSeenMutation } from '../../../api/endpoints/notifications-endpoint';
import { INotificationV1 } from '../../../api/types';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { useAppDispatch } from '../../../redux/hooks/app-hooks';
import { usePreLoadImage } from '../../../redux/hooks/pre-load-image';
import { sendMetrics } from '../../../redux/thunks/metrics-thunk';
import { browserService } from '../../../services/browser/browser-service';
import { MixpanelEvent } from '../../../services/mixpanel/types';

import styles from './NotificationBannerV1.module.scss';

export interface INotificationBannerV1Props {
    notification: INotificationV1;
}

export const NotificationBannerV1: React.FC<INotificationBannerV1Props> = ({ notification }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('translations');
    const [markAsSeen, { isLoading: mutationIsLoading }] = useMarkAsSeenMutation();
    const { id, full_body_img_url, img_link_url } = notification;
    const { isLoaded: isImageLoaded, isError: isImageLoadError } = usePreLoadImage(full_body_img_url);

    useEffect(() => {
        if (isImageLoadError) {
            dispatch(
                sendMetrics({
                    event: MixpanelEvent.NOTIFICATION_BANNER_IMAGE_ERROR,
                    meta: {
                        notification,
                    },
                })
            );
        }
    }, [isImageLoadError, dispatch, notification]);

    useEffect(() => {
        if (isImageLoaded) {
            dispatch(sendMetrics({ event: MixpanelEvent.NOTIFICATION_BANNER_SHOWED, meta: { notificationId: id } }));
        }
    }, [isImageLoaded, id, dispatch]);

    const handleClick = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            dispatch(sendMetrics({ event: MixpanelEvent.NOTIFICATION_BANNER_DISMISSED, meta: { notificationId: id } }));
            markAsSeen({ id });
        },
        [id, dispatch, markAsSeen]
    );

    const handleDivClick = useCallback(async () => {
        if (!img_link_url) {
            return;
        }

        dispatch(
            sendMetrics({
                event: MixpanelEvent.NOTIFICATION_BANNER_LINK_CLICKED,
                meta: {
                    notificationId: id,
                },
            })
        );
        await browserService.createTab({ url: img_link_url, active: false, selected: true });
    }, [id, img_link_url, dispatch]);

    if (!isImageLoaded) {
        return null;
    }

    return (
        <div className={cx(styles.banner, { [styles.haveLink]: img_link_url })} onClick={handleDivClick}>
            <img className={styles.image} src={full_body_img_url} alt="" />
            <button className={styles.close} onClick={handleClick} disabled={mutationIsLoading}>
                <CloseIcon />
                <VisuallyHidden>{t('button.close')}</VisuallyHidden>
            </button>
        </div>
    );
};
