import { SEARCH_HISTORY_LOCAL_STORAGE_KEY } from '../services/storage/storage-keys-list';
import { storageService } from '../services/storage/storage-service';

import { COUNT_OF_DISPLAYING_SEARCH_SUGGESTIONS, SEARCH_HISTORY_MAX_ITEMS_COUNT } from './history';

export interface ISearchHistory {
    [key: string]: ISearchHistoryItemValue;
}
export interface ISearchHistoryItemValue {
    numOfTimes: number;
    lastAsked: Date;
}

export const pushQuestionToHistory = async (question: string): Promise<void> => {
    const { getStorageValue, setStorageValue } = storageService;

    const history: ISearchHistory = await getStorageValue(SEARCH_HISTORY_LOCAL_STORAGE_KEY, {});

    if (history[question]) {
        const oldQuestion = history[question];
        history[question] = {
            numOfTimes: oldQuestion.numOfTimes + 1,
            lastAsked: new Date(),
        };
        return setStorageValue(SEARCH_HISTORY_LOCAL_STORAGE_KEY, history);
    }

    if (Object.keys(history).length >= SEARCH_HISTORY_MAX_ITEMS_COUNT) {
        const [oldestQuestion] = Object.entries(history).reduce(
            ([prevKey, prevValue], current) =>
                !prevValue.lastAsked || current[1].lastAsked < prevValue.lastAsked ? current : [prevKey, prevValue],
            ['', {} as ISearchHistoryItemValue]
        );

        delete history[oldestQuestion];
    }

    history[question] = { numOfTimes: 1, lastAsked: new Date() };
    return setStorageValue(SEARCH_HISTORY_LOCAL_STORAGE_KEY, history);
};

export const removeQuestionFromHistory = async (itemToDelete: string): Promise<void> => {
    const { getStorageValue, setStorageValue } = storageService;
    const history: ISearchHistory = await getStorageValue(SEARCH_HISTORY_LOCAL_STORAGE_KEY, {});
    delete history[itemToDelete];
    await setStorageValue(SEARCH_HISTORY_LOCAL_STORAGE_KEY, history);
};

export const getAllQuestionsFromSearchHistory = async (): Promise<string[]> => {
    const { getStorageValue } = storageService;
    const history: ISearchHistory = await getStorageValue(SEARCH_HISTORY_LOCAL_STORAGE_KEY, {});

    return Object.keys(history);
};

export const getRecentsQuestionsFromSearchHistory = async (): Promise<string[]> => {
    const { getStorageValue } = storageService;
    const history: ISearchHistory = await getStorageValue(SEARCH_HISTORY_LOCAL_STORAGE_KEY, {});
    const historyEntries = Object.entries(history);

    const sortByLastAsked = (first: ISearchHistoryItemValue, second: ISearchHistoryItemValue) => {
        if (historyEntries.length < COUNT_OF_DISPLAYING_SEARCH_SUGGESTIONS) return 0;
        const firstTime = new Date(first.lastAsked).getTime();
        const secondTime = new Date(second.lastAsked).getTime();
        return secondTime - firstTime;
    };

    const recentsHistoryItemsByNumOfTimes = historyEntries.sort(([, first], [, second]) => {
        return second.numOfTimes - first.numOfTimes || sortByLastAsked(first, second);
    });

    return recentsHistoryItemsByNumOfTimes.map(([question]) => question);
};
