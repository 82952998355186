import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react';

import i18n from '../../i18n';
import { IRootState } from '../../redux/core-store';
import { getUserProject } from '../../utils/user';
import { baseApi } from '../base-api';
import { ApiTagTypes, SmartFilter, SmartFiltersResponse } from '../types';

const baseApiWithTags = baseApi.enhanceEndpoints({
    addTagTypes: [ApiTagTypes.SMART_FILTERS],
});

export const smartFiltersEndpoint = baseApiWithTags.injectEndpoints({
    endpoints: (builder) => ({
        getSmartFilters: builder.query<SmartFilter[], string | undefined>({
            queryFn: async (arg, { getState }, _, baseQuery) => {
                const project = arg ?? getUserProject(getState() as IRootState);

                const filtersResponse = await baseQuery({
                    url: `${process.env.REACT_APP_API_USER_URL}/v1/filters/${project}`,
                });

                const { data, error } = filtersResponse as QueryReturnValue<
                    SmartFiltersResponse,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >;

                if (error) {
                    return {
                        error: {
                            status: -1,
                            data: i18n.t('error.api.smart-filters', { ns: 'errors' }),
                        },
                    };
                } else {
                    const filters: SmartFilter[] = data.smartFilters.map((filter) => ({
                        id: filter.id,
                        name: filter.name,
                    }));

                    return {
                        data: filters,
                    };
                }
            },
        }),
    }),
});

export const { useGetSmartFiltersQuery } = smartFiltersEndpoint;
