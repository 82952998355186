import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Textarea,
} from '@chakra-ui/react';
import { Field, FieldProps, Formik } from 'formik';

import { useRequestKnowledgeMutation } from '../../../api/endpoints/user-activities-endpoint';
import { useAnswers } from '../../../redux/hooks/answer/answer-hooks';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/app-hooks';
import { useProject, useSources } from '../../../redux/hooks/settings-hooks';
import { _closeRequestKnowledgeModal } from '../../../redux/slices/modals/request-knowledge-modal-slice';
import { openSuccessModal } from '../../../redux/slices/modals/success-modal-slice';
import { setForceFeedbackForQuestion } from '../../../redux/slices/question/question-slice';
import { sendMetrics } from '../../../redux/thunks/metrics-thunk';
import { MixpanelEvent } from '../../../services/mixpanel/types';
import { WithTranslateErrors } from '../../WithTranslateErrors/WithTranslateErrors';
import { ModalContainer } from '../Container/ModalContainer';

import { requestKnowledgeValidationSchema } from './validation-schema';

import styles from './RequestKnowledge.module.scss';

interface IFormData {
    message: string;
}

export const RequestKnowledgeModal: React.FC = () => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();
    const { visible } = useAppSelector((state) => state.modals.requestKnowledge);
    const isUatMode = useAppSelector((state) => state.uat.enabled);
    const { sources } = useSources();
    const [requestKnowledge] = useRequestKnowledgeMutation();

    const { project } = useProject();
    const [, { data: answersData }] = useAnswers();
    const [showMore, setShowMore] = useState(false);

    const handleCloseModal = () => {
        dispatch(_closeRequestKnowledgeModal());
        dispatch(sendMetrics({ event: MixpanelEvent.CANCEL_KNOWLEDGE_REQUEST, meta: { uat_mode: isUatMode } }));
    };

    const initialFormState: IFormData = {
        message: '',
    };

    const handleFormSubmit = async (formData: IFormData) => {
        const response = await requestKnowledge({
            answers: answersData?.answers ?? [],
            message: formData.message,
            project,
            sources,
        });

        dispatch(_closeRequestKnowledgeModal());

        if ('data' in response && response.data) {
            dispatch(
                openSuccessModal({
                    header: t('request-knowledge.header'),
                    message: t('request-knowledge.success.message'),
                    description: t('request-knowledge.success.description'),
                })
            );
        }

        batch(() => {
            dispatch(setForceFeedbackForQuestion(false));
            dispatch(
                sendMetrics({
                    event: MixpanelEvent.SEND_KNOWLEDGE_REQUEST,
                    meta: {
                        form_content: formData.message,
                        uat_mode: isUatMode,
                    },
                })
            );
        });
    };

    const renderHintText = () => {
        if (showMore) {
            return t('request-knowledge.hint-full');
        }

        return (
            <>
                {t('request-knowledge.hint-short')}
                <span className={styles.showMore} onClick={() => setShowMore(true)}>
                    {t('button.learn-more')}
                </span>
            </>
        );
    };

    return (
        <ModalContainer isOpen={visible} onClose={handleCloseModal} motionPreset="scale" scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent className={styles.content}>
                <ModalHeader>{t('request-knowledge.header')}</ModalHeader>
                <Formik
                    initialValues={initialFormState}
                    onSubmit={handleFormSubmit}
                    validationSchema={requestKnowledgeValidationSchema()}
                    validateOnChange={false}
                >
                    {({ errors, touched, setFieldTouched, submitForm, isSubmitting }) => {
                        return (
                            <WithTranslateErrors errors={errors} touched={touched} setFieldTouched={setFieldTouched}>
                                <ModalBody className={styles.body} data-testid="request-knowledge.body">
                                    <Text fontSize="1.4em" color="mid-gray" fontWeight="medium">
                                        {renderHintText()}
                                    </Text>
                                    <Field name="message">
                                        {({ field }: FieldProps<string>) => (
                                            <FormControl isInvalid={!!errors.message && touched.message}>
                                                <FormLabel size="xs">{t('request-knowledge.label')}</FormLabel>
                                                <Textarea
                                                    variant="outline"
                                                    name={field.name}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    className={styles.textarea}
                                                    placeholder={t('request-knowledge.placeholder')}
                                                    data-testid="request-knowledge.input"
                                                />
                                                <FormErrorMessage>{errors.message}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </ModalBody>
                                <ModalFooter className={styles.footer}>
                                    <Button variant="secondary" onClick={handleCloseModal}>
                                        {t('button.cancel')}
                                    </Button>
                                    <Button
                                        variant="primary"
                                        onClick={submitForm}
                                        isLoading={isSubmitting}
                                        data-testid="request-knowledge.submit"
                                    >
                                        {t('button.submit')}
                                    </Button>
                                </ModalFooter>
                            </WithTranslateErrors>
                        );
                    }}
                </Formik>
            </ModalContent>
        </ModalContainer>
    );
};
