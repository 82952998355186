import React from 'react';
import { useTranslation } from 'react-i18next';
import { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react';

import { usageMetricsEndpoint } from '../../../api/endpoints/usage-metrics-endpoint';
import { UsageMetricsEventType } from '../../../api/types';
import { dispatch } from '../../../redux/core-store';
import { getExtendedMeta } from '../../../redux/thunks/metrics-thunk';
import { mixpanelService } from '../../../services/mixpanel/mixpanel-service';
import { MixpanelEvent } from '../../../services/mixpanel/types';
import { sendEmail } from '../../../utils/mail';

import styles from './ErrorToast.module.scss';

const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_MAIL ?? '';

export interface IErrorToastProps {
    description: string;
    meta?: FetchBaseQueryMeta;
}

export const ErrorToast: React.FC<IErrorToastProps> = ({ description, meta }) => {
    const { t } = useTranslation('errors');

    const handleContactSupportClick = () => {
        const requestId = meta?.response?.headers?.get('X-Request-ID');
        const subject = `${t('error.support.request')}${requestId ? ` - ${requestId}` : ''}`;
        const body = requestId ? `X-Request-ID: ${requestId}` : '';
        sendEmail(SUPPORT_EMAIL, subject, body);
        dispatch(
            usageMetricsEndpoint.endpoints.sendMetrics.initiate({
                type: UsageMetricsEventType.CONTACT_SUPPORT_ERROR_NOTIFICATION,
            })
        );

        getExtendedMeta().then((extendedMeta) => {
            mixpanelService.track({
                event: MixpanelEvent.CONTACT_SUPPORT_ERROR_NOTIFICATION,
                meta: {
                    'X-Request-ID': requestId,
                    ...extendedMeta,
                },
            });
        });
    };

    return (
        <div className={styles.wrapper}>
            <span>{description}</span>
            {SUPPORT_EMAIL && (
                <span className={styles.link} onClick={handleContactSupportClick}>
                    {t('error.contact.support')}
                </span>
            )}
        </div>
    );
};
