import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react';

import i18n from '../../i18n';
import { IRootState } from '../../redux/core-store';
import { getToastService } from '../../services/toast/toast-service';
import { getUserProject } from '../../utils/user';
import { baseApi } from '../base-api';
import {
    AnnotationType,
    ApiTagTypes,
    IAddAnswerRequestParams,
    IRequestKnowledgeParams,
    ISendFeedbackRequestParams,
    ResponseStatus,
} from '../types';

const baseApiWithTags = baseApi.enhanceEndpoints({
    addTagTypes: [ApiTagTypes.USER],
});

const userActivitiesEndpoint = baseApiWithTags.injectEndpoints({
    endpoints: (builder) => ({
        sendFeedback: builder.mutation<boolean, ISendFeedbackRequestParams>({
            queryFn: async (arg, { getState, dispatch }, extraOptions, baseQuery) => {
                const state = getState() as IRootState;
                const { answers, message, sources } = arg;
                const { question, question_id } = state.question;

                const project = getUserProject(state);
                const sources_ids = sources.map((item) => item.id);
                const directAnswer = answers.find((answer) => answer.direct);

                const candidateAnswers = answers
                    .filter((answer) => !answer.direct)
                    .map((answer) => {
                        const { reader_is_article_or_section } = answer;

                        return {
                            ...answer,
                            is_dynamic_kb: false,
                            is_article_or_section: reader_is_article_or_section,
                        };
                    });

                const response = await baseQuery({
                    url: '/v1/user_activities/feedback',
                    method: 'POST',
                    body: {
                        question,
                        question_id,
                        customer_project_id: project,
                        selected_data_source_ids: sources_ids,
                        direct_answer: directAnswer,
                        candidate_answers: candidateAnswers,
                        user_feedback: {
                            text: message,
                        },
                    },
                });

                const { error, meta } = response as QueryReturnValue<boolean, FetchBaseQueryError, FetchBaseQueryMeta>;

                if (error) {
                    const message = i18n.t('error.api.feedback', { ns: 'errors' });

                    if (error.status !== ResponseStatus.UNAUTHORIZED) {
                        getToastService().error(message, meta);
                    }

                    return {
                        error,
                    };
                }
                return {
                    data: !!meta?.response?.ok,
                };
            },
            invalidatesTags: [ApiTagTypes.USER],
        }),
        addAnswer: builder.mutation<boolean, IAddAnswerRequestParams>({
            queryFn: async (arg, { getState, dispatch }, extraOptions, baseQuery) => {
                const state = getState() as IRootState;
                const { answer, type, answer_link, sources, answers } = arg;
                const {
                    question: { question, question_id },
                    uat: { enabled: isUATEnabled },
                } = state;

                const project = getUserProject(state);
                const sources_ids = sources.map((item) => item.id);
                const directAnswer = answers.find((answer) => answer.direct);

                const candidateAnswers = answers
                    .filter((answer) => !answer.direct)
                    .map((answer) => {
                        const { reader_is_article_or_section } = answer;

                        return {
                            ...answer,
                            is_dynamic_kb: false,
                            is_article_or_section: reader_is_article_or_section,
                        };
                    });

                const body = {
                    customer_project_id: project,
                    question,
                    question_id,
                    selected_data_source_ids: sources_ids,
                    direct_answer: directAnswer,
                    candidate_answers: candidateAnswers,
                    add_answer: {
                        url: answer_link,
                        answer: answer ?? '',
                    },
                    uat_mode: isUATEnabled,
                };

                const response = await baseQuery({
                    url: '/v1/user_activities/add-answer',
                    method: 'POST',
                    body,
                });

                const { error, meta } = response as QueryReturnValue<boolean, FetchBaseQueryError, FetchBaseQueryMeta>;

                if (error) {
                    const displayErrorForAnnotations = [
                        AnnotationType.ANNOTATE,
                        AnnotationType.THUMB_UP,
                        AnnotationType.THUMB_DOWN,
                    ];

                    if (error.status !== ResponseStatus.UNAUTHORIZED && displayErrorForAnnotations.includes(type)) {
                        const message = i18n.t('error.api.annotate', { ns: 'errors' });

                        getToastService().error(message, meta);
                    }

                    return {
                        error,
                    };
                }

                return {
                    data: !!meta?.response?.ok,
                };
            },
            invalidatesTags: [ApiTagTypes.USER],
        }),
        requestKnowledge: builder.mutation<boolean, IRequestKnowledgeParams>({
            queryFn: async (arg, { getState, dispatch }, extraOptions, baseQuery) => {
                const state = getState() as IRootState;
                const { answers, message, sources } = arg;
                const {
                    question: { question, question_id },
                    uat: { enabled: isUATEnabled },
                } = state;

                const project = getUserProject(state);
                const sources_ids = sources.map((item) => item.id);
                const directAnswer = answers.find((answer) => answer.direct);

                const candidateAnswers = answers
                    .filter((answer) => !answer.direct)
                    .map((answer) => {
                        const { reader_is_article_or_section } = answer;

                        return {
                            ...answer,
                            is_dynamic_kb: false,
                            is_article_or_section: reader_is_article_or_section,
                        };
                    });

                const response = await baseQuery({
                    url: '/v1/user_activities/missing-knowledge',
                    method: 'POST',
                    body: {
                        customer_project_id: project,
                        question,
                        question_id,
                        selected_data_source_ids: sources_ids,
                        direct_answer: directAnswer,
                        candidate_answers: candidateAnswers,
                        request_knowledge: {
                            text: message,
                        },
                        uat_mode: isUATEnabled,
                    },
                });

                const { error, meta } = response as QueryReturnValue<boolean, FetchBaseQueryError, FetchBaseQueryMeta>;

                if (error) {
                    const message = i18n.t('error.api.knowledge', { ns: 'errors' });

                    if (error.status !== ResponseStatus.UNAUTHORIZED) {
                        getToastService().error(message, meta);
                    }

                    return {
                        error,
                    };
                }

                return {
                    data: !!meta?.response?.ok,
                };
            },
            invalidatesTags: [ApiTagTypes.USER],
        }),
    }),
});

export const { useSendFeedbackMutation, useAddAnswerMutation, useRequestKnowledgeMutation } = userActivitiesEndpoint;
