import { createSlice } from '@reduxjs/toolkit';

import { IMessage } from '../../../api/types';

interface IChatEditModalState {
    visible: boolean;
    message?: IMessage;
}

const initialState: IChatEditModalState = {
    visible: false,
};

export const chatEditModalSlice = createSlice({
    name: 'chat-edit-modal',
    initialState,
    reducers: {
        openChatEditModal(state, action) {
            if (!action.payload) {
                return;
            }
            state.message = action.payload;
            state.visible = true;
        },
        closeChatEditModal(state) {
            state.visible = false;
        },
    },
});

export const { openChatEditModal, closeChatEditModal } = chatEditModalSlice.actions;
