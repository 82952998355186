import { v4 } from 'uuid';
import browser, { Runtime } from 'webextension-polyfill';

import {
    IWorkerPipeSenderFactoryParams,
    IWorkerPipeSenderParams,
    IWorkerServiceMessage,
    IWorkerServiceMessageCallback,
    IWorkerServiceResponse,
} from '../types';

class WorkerPipeSender {
    private readonly context: string;

    private readonly reconnectOnEachMessage: boolean;

    private port: Runtime.Port | null = null;

    private requestsQueue: Map<string, IWorkerServiceMessageCallback> = new Map();

    constructor(params: IWorkerPipeSenderParams) {
        const { context, reconnectOnEachMessage = false } = params;

        this.context = context;
        this.reconnectOnEachMessage = reconnectOnEachMessage;

        this.trackBFCacheRestoration();
    }

    private disconnect() {
        this.port?.disconnect();
        this.port?.onMessage.removeListener(this.onMessage);
        this.port = null;
    }

    private initPort() {
        if (this.port) {
            return;
        }

        this.port = browser.runtime.connect({ name: this.context });
        this.port.onMessage.addListener(this.onMessage);
        this.port.onDisconnect.addListener(() => {
            this.disconnect();
        });
    }

    private trackBFCacheRestoration() {
        if (typeof window !== 'undefined') {
            window.addEventListener('pageshow', ({ persisted }) => {
                if (persisted) {
                    this.disconnect();
                    this.initPort();
                }
            });
        }
    }

    async send<T extends IWorkerServiceMessage>(message: T) {
        if (this.reconnectOnEachMessage) {
            this.disconnect();
        }

        this.initPort();

        return new Promise((resolve) => {
            const id = v4();
            this.requestsQueue.set(id, resolve);

            this.port?.postMessage({
                id,
                ...message,
            });
        });
    }

    private onMessage = <T extends IWorkerServiceResponse>(message: T, port: Runtime.Port) => {
        const { id, payload } = message;

        const resolveCallback = this.requestsQueue.get(id);

        if (resolveCallback) {
            resolveCallback(payload);
            this.requestsQueue.delete(id);
        }
    };
}

class WorkerPipeSenderFactory {
    private senders: Map<string, WorkerPipeSender> = new Map();

    public get(context: string, params: IWorkerPipeSenderFactoryParams = {}): WorkerPipeSender {
        const { reconnectOnEachMessage } = params;

        const sender = this.senders.get(context);

        if (sender) {
            return sender;
        }

        const newSender = new WorkerPipeSender({ context, reconnectOnEachMessage });
        this.senders.set(context, newSender);

        return newSender;
    }
}

export const workerPipeSenderFactory = new WorkerPipeSenderFactory();
