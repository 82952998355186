import * as Yup from 'yup';

import i18n from '../../../../i18n';

export const addAnswerValidationSchema = () =>
    Yup.object().shape({
        answer_link: Yup.string().required(i18n.t('error.answer-url-empty', { ns: 'errors' })),
        answer: Yup.string().notRequired(),
        elaboration: Yup.string().notRequired(),
    });
