import { extendTheme, StyleFunctionProps, SystemStyleObject } from '@chakra-ui/react';
import { theme as chakraDefaultTheme } from '@chakra-ui/theme';
import { mode } from '@chakra-ui/theme-tools';

import { CustomCheckbox } from '../../components/Controls/CheckBox/styles';
import { CustomCheckboxThemeKey } from '../../components/Controls/CheckBox/styles';

import { buttonTheme } from './button-theme';
import { withCustomInputStyles, withCustomScrollbar } from './consts';
import { formLabelTheme, inputTheme } from './input-theme';
import { modalTheme } from './modal-theme';
import { tableTheme } from './table-theme';

import styles from './theme.module.scss';

export const font = 'https://fonts.googleapis.com/css?family=Inter:400,500,600,700';

export const getTheme = () => {
    const extended = extendTheme({
        styles: {
            global: (props: StyleFunctionProps) => {
                const globalStyles = chakraDefaultTheme.styles.global as { body: SystemStyleObject } | undefined;

                return {
                    ':host': {
                        ...(globalStyles && {
                            ...globalStyles.body,
                        }),
                        color: mode('black', 'black')(props),
                    },
                    ':host > *, :host([data-container-size="default"]) > *': {
                        fontSize: 'base.default',
                    },
                    ':host([data-container-size="small"]) > *': {
                        fontSize: 'base.small',
                    },
                    '@media print': {
                        ':host': {
                            visibility: 'hidden',
                        },
                    },
                };
            },
        },
        fonts: {
            heading: styles.fontPrimary,
            body: styles.fontPrimary,
        },
        fontSizes: {
            base: {
                default: '10px',
                small: '9px',
            },
        },
        colors: {
            'light-gray': styles.textLightGray,
            'mid-gray': styles.textMidGray,
            'dark-gray': styles.textDarkGray,
            'blue-gray': styles.blueGray,
            indigo: {
                50: styles.colorIndigo50,
                300: styles.colorIndigo300,
                400: styles.colorIndigo400,
                600: styles.colorIndigo600,
                900: styles.colorIndigo900,
            },
            'text-control': styles.textControl,
            link: styles.linkPrimary,
            'button-search-action': {
                400: '#2962FF',
                500: styles.surfacePrimary,
                600: '#BBDEFB',
            },
            'button-summarize-action': {
                400: '#C51162',
                500: styles.surfacePrimary,
                600: '#F8BBD0',
            },
            'button-rephrase-action': {
                400: '#00897B',
                500: styles.surfacePrimary,
                600: '#B2DFDB',
            },
            'button-close-sidebar': {
                400: '#2785FF',
                500: '#F4F4F4',
                600: '#F4F4F4',
            },
        },
        components: {
            Heading: {
                sizes: {
                    h1: {
                        fontSize: '2em',
                        fontWeight: 500,
                        lineHeight: '24px',
                        color: styles.textPositive,
                    },
                    h2: {
                        fontSize: '1.6em',
                        fontWeight: 600,
                        lineHeight: '24px',
                        color: styles.textLightGray,
                    },
                },
            },
            Select: {
                variants: {
                    outline: {
                        field: {
                            height: '40px',
                            ...withCustomInputStyles,
                        },
                        icon: {
                            color: 'rgba(26, 26, 26, 0.3)',
                        },
                    },
                },
            },
            [CustomCheckboxThemeKey]: CustomCheckbox,
            Textarea: {
                variants: {
                    'message-input': {
                        height: '40px',
                        minHeight: '40px',
                        background: 'transparent',
                        display: 'flex',
                        lineHeight: '1.5',
                        fontSize: '14px',
                        '&::placeholder': {
                            color: styles.textSuggestion,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                        },
                    },
                    'search-bar': {
                        fontSize: '1.4em',
                        lineHeight: '1.5',
                        height: '1em',
                        minHeight: '1em',
                        boxSizing: 'content-box',
                        paddingTop: '1px',
                        paddingBottom: '1px',
                        transitionDuration: styles.defaultTransitionDuration,
                        transitionProperty: 'padding',

                        '&::placeholder': {
                            color: styles.textSuggestion,
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                        },

                        '&:placeholder-shown': {
                            textOverflow: 'ellipsis',
                        },
                    },
                    outline: {
                        resize: 'none',
                        minHeight: '136px',
                        ...withCustomInputStyles,
                        ...withCustomScrollbar,
                    },
                    thin: {
                        resize: 'none',
                        minHeight: '64px',
                        ...withCustomInputStyles,
                        ...withCustomScrollbar,
                        _disabled: {
                            backgroundColor: styles.backgroundDisabled,
                            opacity: 0.4,
                        },
                    },
                },
            },
            Link: {
                variants: {
                    main: {
                        color: styles.linkPrimary,
                        fontSize: '1.2em',
                        fontWeight: 700,
                        textDecoration: 'underline',
                    },
                    answer: {
                        color: styles.linkPrimary,
                        fontSize: '1em',
                        fontWeight: 500,
                    },
                    notification: {
                        color: styles.surfacePrimary,
                        fontSize: '1em',
                        fontWeight: 700,
                        textDecoration: 'underline',
                    },
                },
            },
            Checkbox: {
                baseStyle: {
                    control: {
                        backgroundColor: styles.surfacePrimary,
                        borderColor: styles.textMidGray,
                        color: styles.linkPrimary,
                        _checked: {
                            backgroundColor: styles.surfacePrimary,
                            borderColor: styles.linkPrimary,
                            color: styles.linkPrimary,
                            _hover: {
                                backgroundColor: styles.surfacePrimary,
                                borderColor: styles.linkPrimary,
                                color: styles.linkPrimary,
                                filter: 'grayscale(0.2)',
                            },
                        },
                        _hover: {
                            borderColor: styles.linkPrimary,
                        },
                    },
                    label: {
                        color: styles.textControl,
                        marginStart: '11px',
                        fontWeight: 500,
                        lineHeight: '24px',
                    },
                },
                sizes: {
                    md: {
                        label: {
                            fontSize: '1.4em',
                        },
                    },
                },
            },
            Radio: {
                baseStyle: {
                    control: {
                        borderColor: styles.textMidGray,
                        backgroundColor: styles.surfacePrimary,
                        _checked: {
                            backgroundColor: styles.surfacePrimary,
                            borderColor: styles.linkPrimary,
                            _hover: {
                                borderColor: styles.linkPrimary,
                                backgroundColor: styles.surfacePrimary,
                            },
                            _before: {
                                width: '65%',
                                height: '65%',
                                backgroundColor: styles.linkPrimary,
                            },
                        },
                        _hover: {
                            borderColor: styles.linkPrimary,
                        },
                    },
                    label: {
                        color: styles.textControl,
                        marginStart: '8px',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '24px',
                    },
                },
            },
            Tooltip: {
                baseStyle: {
                    border: `1px solid ${styles.textTooltip}`,
                    borderRadius: '8px',
                    boxShadow: 'none',
                    filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06))',
                    color: styles.textTooltipDark,
                    fontFamily: 'body',
                    fontWeight: 500,
                    fontSize: '1.2em',
                    lineHeight: '16px',
                    padding: '8px 9px',
                },
            },
            Popover: {
                baseStyle: {
                    content: {
                        width: 'fit-content',
                        borderColor: styles.textTooltip,
                        color: styles.textControl,
                        borderRadius: '8px',
                        boxShadow: '0 14px 64px -4px rgba(24, 39, 75, 0.12), 0px 8px 22px -6px rgba(24, 39, 75, 0.12)',
                        padding: '8px',
                        cursor: 'default',
                    },
                },
                variants: {
                    'deactivate-menu': {
                        content: {
                            padding: '40px 0 0 0',
                            minWidth: '288px',
                            minHeight: '208px',
                            borderColor: '', // reset style from baseStyle
                            border: styles.floatingComponentBorder,
                            borderRadius: '24px',
                        },
                        body: {
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            padding: '0 8px 16px',
                        },
                    },
                },
            },
            Menu: {
                baseStyle: {
                    list: {
                        border: '1px solid rgba(26, 26, 26, 0.1)',
                        borderRadius: '8px',
                        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)',
                        marginTop: '18px',
                    },
                    divider: {
                        opacity: '1',
                        borderBottom: '1px solid rgba(26, 26, 26, 0.1)',
                    },
                    item: {
                        color: 'rgba(26, 26, 26, 0.7)',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '24px',
                    },
                },
            },
            Tabs: {
                variants: {
                    line: {
                        tab: {
                            _selected: {
                                color: styles.linkPrimary,
                            },

                            color: styles.textMidGray,
                        },
                        tablist: {
                            borderBottom: '2px solid',
                            borderColor: 'transparent',
                        },
                    },
                },
            },
            Divider: {
                baseStyle: {
                    color: '#e1e1e1',
                },
            },
            Input: inputTheme,
            FormLabel: formLabelTheme,
            Button: buttonTheme,
            Modal: modalTheme,
            Table: tableTheme,
        },
    });

    return extended;
};
