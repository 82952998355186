import * as Yup from 'yup';

import { AnnotationType } from '../../../../api/types';
import i18n from '../../../../i18n';

export const answerFeedbackValidationSchema = () =>
    Yup.object().shape({
        type: Yup.string().required(i18n.t('error.select-option', { ns: 'errors' })),
        details: Yup.string().when('type', (type, schema) => {
            if (type === AnnotationType.INCORRECT_INFO) {
                return schema.required(i18n.t('error.field.required', { ns: 'errors' }));
            }

            return schema;
        }),
    });
