import React, { MouseEventHandler } from 'react';

import { ReactComponent as CopyIcon } from '../../../../../../assets/icons/copy-answer-action.svg';
import { ReactComponent as CopyPrivateIcon } from '../../../../../../assets/icons/copy-answer-private-action.svg';
import { useAppSelector } from '../../../../../../redux/hooks/app-hooks';
import { useCopyAnswer } from '../../../../../../redux/hooks/copy-answer';
import { ITrackEventOrigin } from '../../../../../../services/mixpanel/types';
import { EntityType, PublicPrivateTooltip } from '../../../../../Tooltips/PublicPrivate/PublicPrivateTooltip';
import { IAnswerActionsProps } from '../../Actions';

import styles from '../CopyAction.module.scss';

export const CopyAnswerAction: React.FC<IAnswerActionsProps> = ({ answer }) => {
    const copyAnswer = useCopyAnswer();
    const { visible } = useAppSelector((state) => state.modals.preview);

    const handleCopyButtonClick: MouseEventHandler = async (e) => {
        e.stopPropagation();

        await copyAnswer({
            answerId: answer.doc_id,
            shortcutUsed: false,
            origin: visible ? ITrackEventOrigin.PREVIEW_WINDOW : ITrackEventOrigin.ANSWERS_LIST,
        });
    };

    const className = answer.source_is_public ? styles.public : styles.private;
    const Icon = answer.source_is_public ? CopyIcon : CopyPrivateIcon;

    return (
        <PublicPrivateTooltip answer={answer} type={EntityType.ANSWER}>
            <Icon onClick={handleCopyButtonClick} className={className} />
        </PublicPrivateTooltip>
    );
};
