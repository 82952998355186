const appType = process.env.REACT_APP_APP_TYPE ?? '';

enum AppType {
    DEV = 'dev',
}

class Logger {
    private name: string;

    constructor(name: string) {
        this.name = name;
    }

    public log(message: string | Error | unknown, ...optionalParams: any[]) {
        console.log(this.getDate(), this.name, message, ...optionalParams);
    }

    public warn(message: string | Error | unknown, ...optionalParams: any[]) {
        console.warn(this.getDate(), this.name, message, ...optionalParams);
    }

    public error(message: string | Error | unknown, ...optionalParams: any[]) {
        console.error(this.getDate(), this.name, message, ...optionalParams);
    }

    public debug(message: string | Error | unknown, ...optionalParams: any[]) {
        if (appType !== AppType.DEV) {
            return;
        }

        this.log(message, ...optionalParams);
    }

    private getDate() {
        const now = new Date();

        return now.toISOString();
    }
}

export const getLogger = (name: string): Logger => {
    return new Logger(name);
};
