import { StorageKey } from './storage-key';
import { StorageKeyContext } from './types';

export const CACHED_TOKEN_KEY = new StorageKey('ssoToken', StorageKeyContext.SHARED);
export const CACHED_TOKEN_EXPIRY_KEY = new StorageKey('ssoTokenExpiry', StorageKeyContext.SHARED);
export const LOCKOUT_KEY = new StorageKey('ssoLockout', StorageKeyContext.SHARED);
export const TOKEN_STORAGE_KEY = new StorageKey('bearer', StorageKeyContext.SHARED);
export const BACKEND_URL_STORAGE_KEY = new StorageKey('backend_url', StorageKeyContext.SHARED);
export const LAST_ANSWERS_STORAGE_KEY = new StorageKey('last_answers', StorageKeyContext.INDIVIDUAL);
export const LAST_MOCKED_DIRECT_ANSWER_KEY = new StorageKey('last_mocked_direct_answer', StorageKeyContext.INDIVIDUAL);
export const SOURCES_STORAGE_KEY = new StorageKey('disabled_sources', StorageKeyContext.INDIVIDUAL);
export const PROJECT_STORAGE_KEY = new StorageKey('customer_project_id', StorageKeyContext.INDIVIDUAL);
export const ONLINE_MEMORY_STORAGE_KEY = new StorageKey('disable_online_memory', StorageKeyContext.INDIVIDUAL);
export const SHOULD_DISPLAY_FLOATING_APP = new StorageKey('should-display-floating-app_v2', StorageKeyContext.SHARED);
export const ORIGINS_BLACKLIST_STORAGE_KEY = new StorageKey('origins-blacklist', StorageKeyContext.SHARED);
export const FLOATING_ICON_POSITION_STORAGE_KEY = new StorageKey('floating_icon_position', StorageKeyContext.SHARED);
export const REOPEN_SIDEBAR_BY_ANSWER_PAGE = new StorageKey('reopen_sidebar_by_answer_page', StorageKeyContext.SHARED);
export const LAST_NOTIFICATION_ID = new StorageKey('last_notification_id', StorageKeyContext.SHARED);
export const SEARCH_STATE_RELEVANCE_TIME_STORAGE_KEY = new StorageKey(
    'search-state-relevance-time',
    StorageKeyContext.INDIVIDUAL
);
export const PROACTIVE_VIEW_HISTORY_STORAGE_KEY = new StorageKey(
    'proactive_view_history_storage_key',
    StorageKeyContext.INDIVIDUAL
);
export const PINNED_STORAGE_KEY = new StorageKey('has_been_pinned', StorageKeyContext.SHARED);
export const EXTENSION_SHORTCUT = new StorageKey('extension_shortcut', StorageKeyContext.SHARED);
export const LAST_PREVIEW_STORAGE_KEY = new StorageKey('last_preview', StorageKeyContext.INDIVIDUAL);
export const LAST_FORCE_FEEDBACK_STORAGE_KEY = new StorageKey('last_force_feedback', StorageKeyContext.INDIVIDUAL);
export const TRAINING_ENABLEMENT_LAST_QUIZ_QUESTION_STORAGE_KEY = new StorageKey(
    'training_enablement_last_quiz_question',
    StorageKeyContext.INDIVIDUAL
);
export const SEARCH_HISTORY_LOCAL_STORAGE_KEY = new StorageKey('search-history', StorageKeyContext.SHARED);
export const LANGUAGE_CODE_STORAGE_KEY = new StorageKey('language-code', StorageKeyContext.SHARED);
export const PROACTIVE_POPUP_SHOWED_FOR = new StorageKey('proactive_popup_showed_for', StorageKeyContext.INDIVIDUAL); // stores the list of a tickets URLs the proactive answer popup has been showed for
export const SELECTED_SMART_FILTERS_KEY = new StorageKey('selected_smart_filters', StorageKeyContext.INDIVIDUAL);
export const SELECTED_DATES_FILTER_KEY = new StorageKey('selected_dates_filter_key', StorageKeyContext.INDIVIDUAL);
