import { isContentMode, isWebMode } from '../../utils/extension-mode';

import { IStorageKey, StorageKeyContext } from './types';

const POPUP_CONTEXT_PREFIX = 'popup';
const CONTENT_CONTEXT_PREFIX = 'content';

export class StorageKey implements IStorageKey {
    constructor(private readonly key: string, private readonly context: StorageKeyContext) {}

    getValue(): string {
        if (this.context === StorageKeyContext.SHARED || isWebMode()) {
            return this.key;
        }

        if (isContentMode()) {
            return `${CONTENT_CONTEXT_PREFIX}_${this.key}`;
        }

        return `${POPUP_CONTEXT_PREFIX}_${this.key}`;
    }
}
