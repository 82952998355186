import React from 'react';
import { useTranslation } from 'react-i18next';
import { PopoverTrigger } from '@chakra-ui/react';
import { IAnnotateAnswerChunk } from 'answers-core/src/api/types';
import { Tooltip } from 'answers-core/src/components/Tooltips/Tooltip';
import { useAppSelector } from 'answers-core/src/redux/hooks/app-hooks';

import { ReactComponent as AnnotateNoteIcon } from '../../../../../assets/icons/annotate-note.svg';
import { ReactComponent as AnnotateNoteCommentedIcon } from '../../../../../assets/icons/annotate-note-commented.svg';

import styles from './CommentAnswer.module.scss';

export interface ICommentAnswerProps {
    chunk: IAnnotateAnswerChunk;
}

export const CommentAnswer: React.FC<ICommentAnswerProps> = ({ chunk }) => {
    const { t } = useTranslation('translations');
    const { comment_chunks_map } = useAppSelector((state) => state.annotateAnswers);
    const { chunk_id } = chunk;

    const Icon = !!comment_chunks_map[chunk_id] ? AnnotateNoteCommentedIcon : AnnotateNoteIcon;

    return (
        <Tooltip label={t('annotate-answers.add-comment')}>
            <div className={styles.comment}>
                <PopoverTrigger>
                    <Icon className={styles.button} />
                </PopoverTrigger>
            </div>
        </Tooltip>
    );
};
