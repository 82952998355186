import { api, ContextHolder, ContextOptions, ILoginResponse } from '@frontegg/rest-api';

import { getLogger } from '../../utils/logger';
import { createWorkerService } from '../core/worker/worker-service';
import { CACHED_TOKEN_EXPIRY_KEY, CACHED_TOKEN_KEY, LOCKOUT_KEY } from '../storage/storage-keys-list';
import { storageService } from '../storage/storage-service';
import { ServiceWorkerContext } from '../types';

import { IGetCachedTokenAndExpiryDateResponse, SSOLogoutReason } from './types';

const contextOptions: ContextOptions = {
    baseUrl: process.env.REACT_APP_FRONTEGG_BASE_URL || 'https://auth-dev.ask-ai.co',
    clientId: process.env.REACT_APP_FRONTEGG_CLIENT_ID || '9857bef1-5217-40d4-9a65-fb2befc57f01',
    requestCredentials: 'include',
};
ContextHolder.setContext(contextOptions);

const EXPIRY_GRACE_TIME = 600000; // 1 hour
const LOCKOUT_TIME = 60 * 1000; // 60 seconds

const logger = getLogger('SSOService');

/**
 * Removing user lockout
 */
const removeLockout = async () => {
    await storageService._passthrough.removeStorageValue(LOCKOUT_KEY);
};

/**
 * Updating cached token
 *
 * If token was not provided, will remove the cached token
 * If the token was provided, will cache it and set the expiry time
 * @param token
 * @returns
 */
const setCachedToken = async (token: ILoginResponse | undefined) => {
    if (!token) {
        logger.log('Removing cached token');
        await storageService._passthrough.removeStorageValue([CACHED_TOKEN_KEY, CACHED_TOKEN_EXPIRY_KEY]);
        return;
    }

    logger.log('Caching token');

    let expiresIn;

    if ('exp' in token) {
        // If Frontegg returns us the expiry time, use that instead
        expiresIn = new Date((token as any).exp * 1000 - EXPIRY_GRACE_TIME);
    } else {
        // Calculate the expiry time based on Frontegg token expiresIn
        expiresIn = new Date();
        expiresIn.setTime(expiresIn.getTime() + token.expiresIn * 1000 - EXPIRY_GRACE_TIME);
    }

    // Storing the token and expiry time
    await Promise.all([
        storageService._passthrough.setStorageValue(CACHED_TOKEN_KEY, token),
        storageService._passthrough.setStorageValue(CACHED_TOKEN_EXPIRY_KEY, expiresIn),
    ]);
};

/**
 * Retrieve the cached token and expiry time
 * @returns
 */
const getCachedTokenAndExpiryDate = async (): Promise<IGetCachedTokenAndExpiryDateResponse> => {
    const values = await storageService._passthrough.getStorageValues([CACHED_TOKEN_KEY, CACHED_TOKEN_EXPIRY_KEY]);
    const cachedToken: ILoginResponse | null = values[CACHED_TOKEN_KEY.getValue()];
    const expiryDate: Date | null = values[CACHED_TOKEN_EXPIRY_KEY.getValue()]
        ? new Date(values[CACHED_TOKEN_EXPIRY_KEY.getValue()])
        : null;

    if (!cachedToken) {
        return { cachedToken: null, expiryDate: null };
    }

    return { cachedToken, expiryDate };
};

/**
 * Check if the user is locked out
 * @returns
 */
const getIsLockedOut = async (): Promise<boolean> => {
    const now = new Date();
    const lockoutValue = await storageService._passthrough.getStorageValue(LOCKOUT_KEY, null);
    if (!lockoutValue) {
        logger.log('The user was not locked out');
        return false;
    }

    const lockoutDate = new Date(lockoutValue);
    const diff = now.getTime() - lockoutDate.getTime();

    // The lockout didn't expire - the user is locked out
    if (diff <= LOCKOUT_TIME) {
        return true;
    }

    logger.log('The lockout expired - removing lockout');
    await removeLockout();
    return false;
};

/**
 * Refreshing token from Frontegg
 * @returns
 */
const refreshToken = async () => {
    try {
        logger.log('Refreshing the token');
        const token = await api.auth.silentOAuthRefreshToken();
        await setCachedToken(token);
        return token;
    } catch (e) {
        return undefined;
    }
};

/**
 * Getting the token
 *
 * If the user is locked out, will return undefined
 * If the user have cached token that did NOT expired, will return it
 * If the user doesn't have cached token, or the cached token expired, will refresh the token
 * @returns
 */
const getToken = async () => {
    try {
        logger.log('Getting token');
        const isLockedOut = await getIsLockedOut();
        if (isLockedOut) {
            logger.log('User is locked out - skipping');
            return undefined;
        }
        const now = new Date();
        const { cachedToken, expiryDate } = await getCachedTokenAndExpiryDate();

        if (cachedToken && expiryDate && expiryDate.getTime() > now.getTime()) {
            logger.log(`There is cached token - using it, expiry date=${expiryDate}`);
            return cachedToken;
        } else if (cachedToken && ((expiryDate && expiryDate.getTime() < now.getTime()) || !expiryDate)) {
            logger.log('The cached token expired - refreshing it');
        }
        const token = await refreshToken();

        return token;
    } catch (e) {
        logger.log('Failed to get token from Frontegg', e);
        return undefined;
    }
};

/**
 * Locking the user out
 */
const setLockout = async () => {
    const lockoutAt = new Date().toISOString();
    await storageService._passthrough.setStorageValue(LOCKOUT_KEY, lockoutAt);
};

/**
 * Removing the cached token
 */
const resetToken = async () => {
    logger.log('Resetting token');
    await setCachedToken(undefined);
};

/**
 * Logging the user out
 */
const logout = async (reason: SSOLogoutReason, requestUrl?: string) => {
    logger.log(`Login out from SSO - reason=${reason} - requestUrl=${requestUrl}`);
    await setLockout();
    await resetToken();
};

/**
 * Refreshing the token from Frontegg (safely)
 * @returns
 */
const safeForceRefreshToken = async () => {
    try {
        logger.log('Refreshing SSO token');
        const token = await refreshToken();
        logger.log('Refreshed SSO token');

        return token;
    } catch (e) {
        logger.log('Failed to safe refresh token from Frontegg', e);
    }
};

const service = createWorkerService({
    name: 'sso',
    context: ServiceWorkerContext.BACKGROUND,
    handlers: () => ({
        getUser: getToken,
        removeLockout: removeLockout,
        logout,
        safeForceRefreshToken,
    }),
});
export const ssoService = service.actions;

export const registerSsoService = service.register;
