import React from 'react';
import { useFlag } from '@unleash/proxy-client-react';

import { AppToggle } from '../../clients/unleash/app-toggle-names';

import { AskAreaController } from './AskAreaController';
import { SearchBar } from './SearchBar';

export interface IAskAreaProps {
    goToAnswers?: () => void;
    rightIcon?: React.ReactElement;
}

export const AskArea: React.FC<IAskAreaProps> = ({ goToAnswers, rightIcon }) => {
    const showRecentSearches = useFlag(AppToggle.RECENT_SEARCHES);

    return (
        <AskAreaController goToAnswers={goToAnswers}>
            {({
                question,
                searchFocused,
                userStartedTyping,
                onChange,
                onBlur,
                onClick,
                onClear,
                onPaste,
                activeSuggestion,
                suggestions,
                onSelectSuggestion,
                onDeleteSuggestion,
                inputRef,
                triggerSearchQuery,
            }) => (
                <SearchBar
                    ref={inputRef}
                    value={question}
                    focused={searchFocused}
                    typingStarted={userStartedTyping}
                    suggestions={suggestions}
                    activeSuggestion={activeSuggestion}
                    onSelectSuggestion={onSelectSuggestion}
                    onDeleteSuggestion={onDeleteSuggestion}
                    recentSearchesEnabled={showRecentSearches}
                    onChange={onChange}
                    onBlur={onBlur}
                    onClick={onClick}
                    onClear={onClear}
                    onPaste={onPaste}
                    rightIcon={rightIcon}
                    triggerSearchQuery={triggerSearchQuery}
                />
            )}
        </AskAreaController>
    );
};
