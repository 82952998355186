import React from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';

import { ActionType, AnnotationType, DirectAnnotationType } from '../../../../../api/types';
import { ReactComponent as ThumbUp } from '../../../../../assets/icons/thumb-up.svg';
import { useAnswers } from '../../../../../redux/hooks/answer/answer-hooks';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks/app-hooks';
import { useSources } from '../../../../../redux/hooks/settings-hooks';
import { setForceFeedbackForQuestion } from '../../../../../redux/slices/question/question-slice';
import { sendMetrics } from '../../../../../redux/thunks/metrics-thunk';
import { ITrackEventOrigin, MixpanelEvent, MixpanelEventProperties } from '../../../../../services/mixpanel/types';

import { ThumbAction } from './ThumbAction';
import { IThumbActionProps } from './types';

export interface IThumbUpActionProps extends Pick<IThumbActionProps, 'answer' | 'isDirectAnswer'> {
    actionType?: ActionType;
}

export const ThumbUpAction: React.FC<IThumbUpActionProps> = ({ answer, actionType, isDirectAnswer = false }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('translations');
    const { visible: previewModalVisible } = useAppSelector((state) => state.modals.preview);
    const isUatMode = useAppSelector((state) => state.uat.enabled);
    const [, { data }] = useAnswers();
    const { sources } = useSources();

    const handleButtonClick = () => {
        const meta: MixpanelEventProperties = {
            use_shortcut: false,
            origin: previewModalVisible ? ITrackEventOrigin.PREVIEW_WINDOW : ITrackEventOrigin.ANSWERS_LIST,
            is_direct_answer: isDirectAnswer,
            uat_mode: isUatMode,
        };
        if (actionType) {
            meta.action_type = actionType;
        }

        batch(() => {
            dispatch(
                sendMetrics({
                    event: MixpanelEvent.THUMB_UP,
                    meta,
                    injectAnswersMetrics: {
                        answers: data,
                        sources,
                    },
                })
            );
            dispatch(setForceFeedbackForQuestion(false));
        });
    };

    return (
        <ThumbAction
            answer={answer}
            type={isDirectAnswer ? DirectAnnotationType.ThumbsUp : AnnotationType.THUMB_UP}
            icon={ThumbUp}
            title={t('answer.feedback.thumb-up.hint')}
            onClick={handleButtonClick}
            isDirectAnswer={isDirectAnswer}
        />
    );
};
