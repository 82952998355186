import { createSlice } from '@reduxjs/toolkit';

interface IOriginsBlacklistModalState {
    visible: boolean;
}

const initialState: IOriginsBlacklistModalState = {
    visible: false,
};

export const originsBlacklistModalSlice = createSlice({
    name: 'origins-blacklist-modal',
    initialState,
    reducers: {
        _openOriginsBlacklistModal(state) {
            state.visible = true;
        },
        _closeOriginsBlacklistModal(state) {
            state.visible = false;
        },
    },
});

export const { _openOriginsBlacklistModal, _closeOriginsBlacklistModal } = originsBlacklistModalSlice.actions;
