import { IProactiveSource } from '../types';

export abstract class ProactiveSourceAbstract implements IProactiveSource {
    protected abstract regex: RegExp;
    protected abstract regexMatchIndex: number;

    abstract getName(): string;

    extractTicketId(url: string) {
        const found = this.regex.exec(url);

        if (found?.[this.regexMatchIndex] === undefined) {
            return undefined;
        }

        return found[this.regexMatchIndex];
    }
}
