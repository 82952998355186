import { isAnyOf, Middleware } from '@reduxjs/toolkit';

import { setSidebarWidth } from '../slices/resize/resize-slice';

import theme from '../../styles/chakra/theme.module.scss';

const FONT_CHANGE_BREAKPOINT = parseInt(theme.fontChangeBreakpoint, 10);

export const onSidebarResizeMiddleware: Middleware = () => (next) => (action) => {
    const isResizeAction = isAnyOf(setSidebarWidth);

    if (isResizeAction(action)) {
        const { payload: size } = action;

        const host = document.querySelector<HTMLDivElement>(`#${SHADOW_DOM_ROOT_ID}`);

        if (host) {
            host.dataset['containerSize'] = size <= FONT_CHANGE_BREAKPOINT ? 'small' : 'default';
        }
    }

    return next(action);
};
