import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'clsx';

import { SHARED_PREVIEW_CACHE_KEY } from '../../../../api/consts';
import { useGetPreviewMutation } from '../../../../api/endpoints/preview-endpoint';
import { useAppSelector } from '../../../../redux/hooks/app-hooks';
import { useHighlightPointerPopupMode, usePreviewKeyDownHandler } from '../../../../redux/hooks/preview';
import { isDomContentLoadedMessage } from '../../../../utils/message';

import styles from './PreviewModalBody.module.scss';

export const IFramePopupMode: React.FC = () => {
    const { t } = useTranslation('translations');
    const [, { data, isLoading }] = useGetPreviewMutation({
        fixedCacheKey: SHARED_PREVIEW_CACHE_KEY,
    });
    const { currentMatch } = useAppSelector((state) => state.modals.preview);
    const sidebarResizeStarted = useAppSelector((state) => state.resize.sidebarResizeStarted);

    const answerContentRef = useRef<HTMLIFrameElement>(null);

    const { showPointer, hidePointer } = useHighlightPointerPopupMode();

    const handleIFrameKeyDown = usePreviewKeyDownHandler();

    const handleShowPointer = useCallback(() => {
        if (answerContentRef.current?.contentDocument) {
            showPointer(answerContentRef.current?.contentDocument, currentMatch);
        }
    }, [currentMatch, showPointer]);

    const handleIFrameLoad = useCallback(
        (event: MessageEvent) => {
            if (isDomContentLoadedMessage(event)) {
                answerContentRef.current?.contentDocument?.addEventListener('keydown', handleIFrameKeyDown);
                handleShowPointer();
                answerContentRef.current?.focus();
            }
        },
        [handleIFrameKeyDown, handleShowPointer]
    );

    useEffect(() => {
        window.addEventListener('message', handleIFrameLoad);

        return () => window.removeEventListener('message', handleIFrameLoad);
    }, [handleIFrameLoad]);

    useEffect(() => {
        if (!isLoading) {
            handleShowPointer();
        }

        return () => {
            hidePointer();
        };
    }, [isLoading, handleShowPointer, hidePointer]);

    return (
        <>
            <iframe
                ref={answerContentRef}
                srcDoc={data?.content}
                title={t('preview.hint.content')}
                className={cx({ [styles.disableEvents]: sidebarResizeStarted })}
            />
            <div className={styles.dimmer} />
        </>
    );
};
