import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

import i18n from '../../i18n';
import { getToastService } from '../../services/toast/toast-service';
import { baseApi } from '../base-api';
import {
    ApiTagTypes,
    DirectAnnotationType,
    IAnnotateDirectAnswerRequestParams,
    IAnnotateDirectAnswerResponse,
    ResponseStatus,
} from '../types';

const baseApiWithTags = baseApi.enhanceEndpoints({
    addTagTypes: [ApiTagTypes.USER],
});

export const annotateDirectAnswerEndpoint = baseApiWithTags.injectEndpoints({
    endpoints: (builder) => ({
        annotateDirectAnswer: builder.mutation<IAnnotateDirectAnswerResponse, IAnnotateDirectAnswerRequestParams>({
            queryFn: async (arg, { getState }, extraOptions, baseQuery) => {
                const { customerProjectId, questionId, annotationType, feedback, editedData } = arg;

                const body = {
                    annotationType,
                    feedback,
                    editedData,
                };

                const annotateResponse = await baseQuery({
                    url: `${process.env.REACT_APP_DIRECT_ANSWER_URL}/direct_answers/${customerProjectId}/${questionId}/annotate`,
                    method: 'POST',
                    body,
                });

                const { data, error, meta } = annotateResponse as QueryReturnValue<
                    boolean,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >;

                if (error) {
                    const displayErrorForAnnotations = [DirectAnnotationType.ThumbsDown, DirectAnnotationType.ThumbsUp];

                    if (
                        error.status !== ResponseStatus.UNAUTHORIZED &&
                        displayErrorForAnnotations.includes(annotationType)
                    ) {
                        const message = i18n.t('error.api.annotate', { ns: 'errors' });

                        getToastService().error(message, meta);
                    }

                    return {
                        error,
                    };
                }

                return {
                    data: !!data,
                };
            },
            invalidatesTags: [ApiTagTypes.USER],
        }),
    }),
});

export const { useAnnotateDirectAnswerMutation } = annotateDirectAnswerEndpoint;
