import React, { ChangeEvent, FocusEvent, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useConst } from '@chakra-ui/react';

import { ILastPreviewData } from '../../api/types';
import { useAnswers } from '../../redux/hooks/answer/answer-hooks';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/app-hooks';
import { useSources } from '../../redux/hooks/settings-hooks';
import {
    BACK_SPACE,
    SHORTCUT_DOWN_ARROW,
    SHORTCUT_ENTER,
    SHORTCUT_UP_ARROW,
    useShortcut,
} from '../../redux/hooks/shortcut';
import { setSearchFocused } from '../../redux/slices/answers/answers-slice';
import { setIsTimerEnabled } from '../../redux/slices/proactive-answers/proactive-answers-slice';
import { setQuestion } from '../../redux/slices/question/question-slice';
import { setSuggestions } from '../../redux/slices/suggestions/suggestions-slice';
import { openForceFeedbackDialog } from '../../redux/thunks/force-feedback-thunk';
import { sendMetrics } from '../../redux/thunks/metrics-thunk';
import { clearQuestion } from '../../redux/thunks/question-thunk';
import { deleteSuggestion, setAllQuestionsToSearchHistory } from '../../redux/thunks/search-history-thunk';
import { MixpanelEvent } from '../../services/mixpanel/types';
import { LAST_PREVIEW_STORAGE_KEY } from '../../services/storage/storage-keys-list';
import { storageService } from '../../services/storage/storage-service';
import { pushQuestionToHistory } from '../../utils/askArea';
import { COUNT_OF_DISPLAYING_SEARCH_SUGGESTIONS } from '../../utils/history';
import { getActiveSources } from '../../utils/transforms';

export interface IAskAreaInputProps {
    inputRef: React.RefObject<HTMLTextAreaElement>;
    question: string;
    searchFocused: boolean;
    userStartedTyping: boolean;
    triggerSearchQuery: () => void;
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    onBlur: (e: FocusEvent<HTMLTextAreaElement>) => void;
    onClick: (e: MouseEvent<HTMLTextAreaElement>) => void;
    onClear: () => void;
    onPaste: () => void;
    activeSuggestion: string;
    suggestions: string[];
    onSelectSuggestion: (suggestion: string, e: MouseEvent<HTMLElement>) => void;
    onDeleteSuggestion: (suggestion: string, e: MouseEvent<HTMLElement>) => void;
}

export interface IAskAreaControllerProps {
    goToAnswers?: () => void;
    children?: (props: IAskAreaInputProps) => React.ReactElement;
}

export const AskAreaController: React.FC<IAskAreaControllerProps> = ({ children, goToAnswers }) => {
    const dispatch = useAppDispatch();
    const { question, userStartedTyping, forceFeedback } = useAppSelector((state) => state.question);
    const { searchFocused } = useAppSelector((state) => state.answers);
    const initialSearchFocused = useConst(searchFocused);
    const { searchHistory, suggestions } = useAppSelector((state) => state.suggestions);
    const [answer] = useAnswers();
    const { sources } = useSources();
    const isAfterPaste = useRef(false);

    const [tempQuestion, setTempQuestion] = useState<string>('');
    const [selectedIndex, setSelectedIndex] = useState<number>(-1); // saving index of element that chosen by pressing ArrowUp and ArrowDown keys
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const resetSuggestions = useCallback(() => {
        if (suggestions.length !== 0) {
            dispatch(setIsTimerEnabled(false));
            dispatch(setSuggestions([]));
        }
    }, [dispatch, suggestions]);

    const handleSuggestionAutocomplete = (value: string) => {
        if (value === '') {
            resetSuggestions();
            return;
        }
        const inputValue = value.toLowerCase();
        const inputLength = inputValue.length;
        const filteredSuggestions =
            inputLength === 0
                ? []
                : searchHistory.filter((suggestion) => suggestion.toLowerCase().includes(value.toLocaleLowerCase()));

        if (filteredSuggestions.length) {
            dispatch(setSuggestions(filteredSuggestions.slice(0, COUNT_OF_DISPLAYING_SEARCH_SUGGESTIONS)));
        } else {
            resetSuggestions();
        }
    };

    const handleQuestionChange = ({ target }: ChangeEvent<HTMLTextAreaElement>) => {
        if (!searchFocused) {
            dispatch(setSearchFocused(true));
        }

        const { value } = target;
        const text = isAfterPaste.current ? value.trim() : value;

        if (isAfterPaste.current) {
            isAfterPaste.current = false;
        }

        handleSuggestionAutocomplete(text);
        dispatch(setQuestion(text));
    };

    const handleClear = () => {
        dispatch(setSearchFocused(true));
        handleSuggestionAutocomplete('');
        setSelectedIndex(-1);
        dispatch(setQuestion(''));
    };

    const handlePaste = () => {
        isAfterPaste.current = true;
    };

    const handlePressItem = useCallback(
        (value: string) => {
            dispatch(setQuestion(value));
            pushQuestionToHistory(value);
            answer({
                sources: getActiveSources(sources),
            });
            resetSuggestions();
        },
        [dispatch, answer, resetSuggestions, sources]
    );

    const handlePressRecentItem = useCallback(
        (value: string) => {
            handlePressItem(value);
            dispatch(
                sendMetrics({ event: MixpanelEvent.CLICK_ON_RECENT_SEARCH, meta: { item: value, use_shortcut: false } })
            );
        },
        [dispatch, handlePressItem]
    );

    const triggerSearchQuery = async () => {
        if (question.trim() === '') {
            await dispatch(clearQuestion());
        } else {
            await pushQuestionToHistory(question.trim());
            dispatch(setAllQuestionsToSearchHistory());
            answer({
                sources: getActiveSources(sources),
            });
            dispatch(
                sendMetrics({
                    event: MixpanelEvent.USE_SEARCH,
                    meta: { question: question.trim() },
                })
            );

            if (selectedIndex > -1) {
                dispatch(
                    sendMetrics({
                        event: MixpanelEvent.CLICK_ON_RECENT_SEARCH,
                        meta: { item: question.trim(), use_shortcut: true },
                    })
                );
            }
        }
        resetSuggestions();
    };

    const handleEnterPress = (e: KeyboardEvent) => {
        e.preventDefault();

        return triggerSearchQuery();
    };

    const handleQuestionOnBlur = () => {
        dispatch(setSearchFocused(false));
        setSelectedIndex(-1);
    };

    const onDeleteSuggestion = useCallback(
        (itemToDelete: string, e: MouseEvent<HTMLElement>) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(deleteSuggestion(itemToDelete));
            dispatch(sendMetrics({ event: MixpanelEvent.DELETE_RECENT_SEARCH, meta: { item: itemToDelete } }));
        },
        [dispatch]
    );

    const handlePressUpArrow = (e: KeyboardEvent) => {
        e.stopPropagation();
        const isUpPressInvokedFromFirstItem = selectedIndex === 0;
        const isSuggestionsAndSearchFocused = suggestions.length && searchFocused;
        if (isSuggestionsAndSearchFocused && isUpPressInvokedFromFirstItem) {
            setSelectedIndex(-1);
            handleSuggestionAutocomplete(tempQuestion);
            return dispatch(setQuestion(tempQuestion));
        }
        if (isSuggestionsAndSearchFocused) {
            setSelectedIndex((prev) => Math.max(prev - 1, 0));
        }
    };

    const handlePressDownArrow = (e: KeyboardEvent) => {
        e.stopPropagation();

        if (goToAnswers && !suggestions.length && inputRef.current?.selectionStart === question.length) {
            goToAnswers();
        }

        const lastItemIndex = suggestions.length - 1;
        const isDownPressInvokedFirstTime = selectedIndex === -1;
        if (isDownPressInvokedFirstTime) {
            setTempQuestion(question);
        }
        if (selectedIndex === suggestions.length - 1) {
            return setSelectedIndex(0);
        }
        if (searchFocused && suggestions.length) {
            setSelectedIndex((prev) => Math.min(prev + 1, lastItemIndex));
        }
    };

    const handleBackSpacePress = () => {
        setSelectedIndex(-1);
    };

    const handleAskAreaClick = useCallback(() => {
        dispatch(setSearchFocused(true));

        if (forceFeedback) {
            dispatch(openForceFeedbackDialog());
        }
    }, [dispatch, forceFeedback]);

    useEffect(() => {
        const chosenSuggestion = suggestions[selectedIndex];
        if (chosenSuggestion) {
            dispatch(setQuestion(chosenSuggestion));
            return;
        }
    }, [selectedIndex, dispatch, suggestions]);

    useEffect(() => {
        storageService.getStorageValue<ILastPreviewData | null>(LAST_PREVIEW_STORAGE_KEY, null).then((lastPreview) => {
            //Prevent changing user focus from selected text to the floating app
            const backgroundSelectedText = window.getSelection()?.toString();
            if (backgroundSelectedText) {
                return;
            }

            if (!lastPreview && initialSearchFocused) {
                inputRef.current?.select();
            }
        });
    }, [initialSearchFocused]);

    useEffect(() => {
        //Prevent changing user focus from selected text to the floating app
        const backgroundSelectedText = window.getSelection()?.toString();
        if (backgroundSelectedText) {
            return;
        }

        if (searchFocused) {
            const endPosition = inputRef.current?.value.length ?? 0;
            inputRef.current?.setSelectionRange(endPosition, endPosition);
            inputRef.current?.focus();
        } else {
            inputRef.current?.blur();
        }
    }, [searchFocused]);

    useEffect(() => {
        if (!question.length) {
            dispatch(setSuggestions(searchHistory.slice(0, COUNT_OF_DISPLAYING_SEARCH_SUGGESTIONS)));
        }
    }, [searchFocused, question, dispatch, searchHistory]);

    useShortcut(
        {
            [SHORTCUT_ENTER]: handleEnterPress,
            [SHORTCUT_UP_ARROW]: handlePressUpArrow,
            [SHORTCUT_DOWN_ARROW]: handlePressDownArrow,
            [BACK_SPACE]: handleBackSpacePress,
        },
        inputRef.current
    );

    useEffect(() => {
        dispatch(setAllQuestionsToSearchHistory());
    }, [dispatch]);

    return children
        ? children({
              inputRef,
              activeSuggestion: suggestions[selectedIndex],
              question,
              userStartedTyping,
              searchFocused,
              suggestions,
              triggerSearchQuery,
              onClick: handleAskAreaClick,
              onChange: handleQuestionChange,
              onClear: handleClear,
              onPaste: handlePaste,
              onBlur: handleQuestionOnBlur,
              onDeleteSuggestion,
              onSelectSuggestion: handlePressRecentItem,
          })
        : null;
};
