import React from 'react';
import { ModalBody, Spinner } from '@chakra-ui/react';

import { SHARED_PREVIEW_CACHE_KEY } from '../../../../api/consts';
import { useGetPreviewMutation } from '../../../../api/endpoints/preview-endpoint';
import { PreviewType } from '../../../../api/types';
import { isContentMode } from '../../../../utils/extension-mode';

import { UnavailablePreview } from './UnavailablePreview/UnavailablePreview';
import { IFrameContentScriptMode } from './IFrameContentScriptMode';
import { IFramePopupMode } from './IFramePopupMode';

import styles from './PreviewModalBody.module.scss';

export const PreviewModalBody: React.FC = () => {
    const [, { data, isLoading }] = useGetPreviewMutation({
        fixedCacheKey: SHARED_PREVIEW_CACHE_KEY,
    });

    const isPreviewAvailable = data?.type !== PreviewType.REFERENCE;
    const isSpinnerVisible = isLoading;

    const renderIFrame = () => {
        return isContentMode() ? <IFrameContentScriptMode /> : <IFramePopupMode />;
    };

    return (
        <ModalBody className={styles.wrapper}>
            {isSpinnerVisible && (
                <div className={styles.spinner}>
                    <Spinner />
                </div>
            )}
            {!isSpinnerVisible && (isPreviewAvailable ? renderIFrame() : <UnavailablePreview />)}
        </ModalBody>
    );
};
