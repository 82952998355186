import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ITabState, Tabs } from '../../types';

const initialState: ITabState = {
    activeTab: Tabs.SEARCH,
};

export const tabsSlice = createSlice({
    name: 'tabs',
    initialState,
    reducers: {
        setActiveTab(state, action: PayloadAction<Tabs>) {
            state.activeTab = action.payload;
        },
    },
});

export const { setActiveTab } = tabsSlice.actions;
