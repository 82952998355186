import type { ILoginResponse } from '@frontegg/rest-api';

import { ssoService } from '../../../services/sso/sso-service';
import { getEndUserScreenDimensions } from '../../../utils/browser';

const getWindowCords = async (windowHeight: number, windowWidth: number) => {
    const winDimension = await getEndUserScreenDimensions();

    const left = Math.round(winDimension.width / 2 - windowWidth / 2 + winDimension.left);
    const top = Math.round(winDimension.height / 2 - windowHeight / 2 + winDimension.top);

    return { top, left };
};

const openNewWindow = async (url: string) => {
    const windowHeight = 560;
    const windowWidth = 750;
    const { top, left } = await getWindowCords(windowHeight, windowWidth);
    const settings = `width=${windowWidth},height=${windowHeight},left=${left},top=${top},toolbar=0,resizable=0,menubar=0,location=0,status=0,scrollbars=0`;

    return window.open(url, '_blank', settings);
};

export const openLoginWindowAndWait = (url: string) =>
    new Promise<ILoginResponse | undefined | null>(async (res) => {
        const loginWindow: Window | undefined = (await openNewWindow(url)) || undefined;

        const waitInterval = setInterval(async () => {
            let user: ILoginResponse | undefined | null = undefined;
            try {
                user = await ssoService.getUser();
            } catch (e) {}
            if (loginWindow?.closed || user) {
                clearInterval(waitInterval);
                res(user);
            }
        }, 1000);
    });
