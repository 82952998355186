import React from 'react';

import styles from './InfoToast.module.scss';

export interface IInfoToast {
    description: string;
}
export const InfoToast: React.FC<IInfoToast> = ({ description }) => {
    return <div className={styles.wrapper}>{description}</div>;
};
