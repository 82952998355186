import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@chakra-ui/react';
import useResizeObserver from '@react-hook/resize-observer';
import debounce from 'lodash.debounce';

import { IAskRequestParams } from '../../../api/types';
import { ReactComponent as SourceFilterIcon } from '../../../assets/icons/source-list/sources-filter.svg';
import { useAnswers } from '../../../redux/hooks/answer/answer-hooks';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/app-hooks';
import { useFilters } from '../../../redux/hooks/filters-hook';
import { useSources } from '../../../redux/hooks/settings-hooks';
import { openSourcesFilterModal } from '../../../redux/slices/modals/sources-filter-modal-slice';
import { sendMetrics } from '../../../redux/thunks/metrics-thunk';
import { MixpanelEvent } from '../../../services/mixpanel/types';
import { sortByActiveFirst } from '../../../utils/sources';
import { getActiveSources } from '../../../utils/transforms';
import { Source } from '../Source/Source';

import styles from './SourcesList.module.scss';

const API_CALL_DEBOUNCE_DELAY = 700;

const SOURCE_WIDTH = parseInt(styles.sourceWidth, 10);
const SOURCE_GAP = parseInt(styles.sourceGap, 10);

export const SourcesList: React.FC = () => {
    const dispatch = useAppDispatch();
    const { sources, toggleSource } = useSources();
    const question = useAppSelector((state) => state.question.question);
    const [answer] = useAnswers();
    const { appliedFiltersCount } = useFilters();
    const [countVisible, setCountVisible] = useState<number>(0);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const debouncedAnswer = useRef(
        debounce((params: IAskRequestParams) => answer(params), API_CALL_DEBOUNCE_DELAY)
    ).current;

    useEffect(() => {
        return () => debouncedAnswer.cancel();
    }, [debouncedAnswer]);

    const recalculateCountVisible = useCallback(
        (width: number) => {
            const sourceWidth = SOURCE_WIDTH + SOURCE_GAP;

            // -1 to account for the filter button
            const maxSources = Math.max(Math.floor(width / sourceWidth) - 1, 0);

            if (maxSources === 0) {
                return;
            }

            const countToRender = Math.min(sources.length, maxSources);
            setCountVisible(countToRender);
        },
        [sources.length]
    );

    useLayoutEffect(() => {
        const wrapperWidth = wrapperRef.current?.getBoundingClientRect().width ?? 0;

        if (wrapperWidth > 0) {
            recalculateCountVisible(wrapperWidth);
        }
    }, [recalculateCountVisible, sources.length]);

    useResizeObserver(wrapperRef, (entry) => {
        recalculateCountVisible(entry.contentRect.width);
    });

    const handleToggleActive = (sourceId: string) => {
        const newSources = toggleSource(sourceId);
        if (question !== '') {
            debouncedAnswer({
                sources: getActiveSources(newSources),
            });
        }
    };

    const renderFilterButton = () => {
        const handleFilterClick = () => {
            dispatch(openSourcesFilterModal());
            dispatch(sendMetrics({ event: MixpanelEvent.OPEN_SOURCE_FILTER }));
        };

        return (
            <div className={styles.filterButtonWrapper}>
                <Box className={styles.filterButton} onClick={handleFilterClick}>
                    <SourceFilterIcon />
                </Box>
                {appliedFiltersCount > 0 && <Box className={styles.indicationBadge}>{appliedFiltersCount}</Box>}
            </div>
        );
    };

    const activeCount = sources.reduce((acc, source) => acc + Number(source.active), 0);
    const preventToggleLastActive = activeCount === 1;

    const sourcesToRender = useMemo(
        () => Array.from(sources).sort(sortByActiveFirst).slice(0, countVisible),
        [sources, countVisible]
    );

    return (
        <div ref={wrapperRef} className={styles.wrapper} data-testid="sources.list">
            <div className={styles.sources}>
                {sourcesToRender.map((source) => (
                    <Source
                        key={source.id}
                        source={source}
                        toggleActive={handleToggleActive}
                        preventToggleLastActive={preventToggleLastActive}
                    />
                ))}
            </div>
            {renderFilterButton()}
        </div>
    );
};
