import { defineStyle, defineStyleConfig, StyleFunctionProps } from '@chakra-ui/react';

import styles from './theme.module.scss';

interface IMainButtonStyles {
    color: string;
    background: string;
    minWidth?: string;
    minHeight?: string;
    _hover: {
        background: string;
        color?: string;
    };
}

interface IOutlinedButtonStyles {
    color: string;
    border: string;
    _hover: {
        background?: string;
        color?: string;
        border?: string;
    };
}

const getMainButtonStyles = ({
    color,
    background,
    _hover,
    minWidth = '100px',
    minHeight = '40px',
}: IMainButtonStyles) => {
    return defineStyle({
        background: background,
        borderColor: background,
        color: color,
        _hover: {
            background: _hover.background,
            borderColor: _hover.background,
            color: _hover.color ?? color,
            _disabled: {
                background: _hover.background,
                borderColor: _hover.background,
                color: _hover.color ?? color,
            },
        },
        minWidth,
        minHeight,
        fontSize: '1.4em',
        fontWeight: 700,
        lineHeight: '22px',
    });
};

const getOutlinedButtonStyles = ({ color, border, _hover }: IOutlinedButtonStyles) => {
    const { color: hoverColor, background: hoverBackground, border: hoverBorder } = _hover;

    return defineStyle({
        background: styles.surfacePrimary,
        borderColor: border,
        color: color,
        _hover: {
            ...(hoverBackground && { background: hoverBackground }),
            ...(hoverColor && { color: hoverColor }),
            ...(hoverBorder && { borderColor: hoverBorder }),
        },
        borderWidth: '2px',
        borderRadius: '4px',
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '22px',
        minWidth: '104px',
        height: '36px',
    });
};

const variants = {
    primary: getMainButtonStyles({
        color: styles.surfacePrimary,
        background: styles.buttonPrimary,
        _hover: {
            background: styles.buttonPrimaryHover,
        },
    }),
    secondary: getMainButtonStyles({
        color: styles.textPositive,
        background: styles.buttonSecondary,
        _hover: {
            background: styles.buttonSecondaryHover,
        },
    }),
    'primary-outlined': getOutlinedButtonStyles({
        color: styles.buttonPrimaryOutlined,
        border: styles.buttonPrimaryOutlined,
        _hover: {
            background: styles.buttonPrimaryOutlinedHover,
        },
    }),
    'primary-outlined-warning': getOutlinedButtonStyles({
        color: styles.buttonPrimaryOutlined,
        border: styles.buttonPrimaryOutlined,
        _hover: {
            color: styles.buttonPrimaryOutlinedWarning,
            border: styles.buttonPrimaryOutlinedWarning,
            background: styles.buttonPrimaryOutlinedWarningHover,
        },
    }),
    'secondary-outlined': getOutlinedButtonStyles({
        color: styles.textPositive,
        border: styles.buttonSecondaryOutlined,
        _hover: {
            background: styles.surfacePrimary,
        },
    }),
    'icon-button': defineStyle({
        backgroundColor: styles.surfacePrimary,
    }),
    'action-primary': getMainButtonStyles({
        minWidth: '80px',
        color: styles.surfacePrimary,
        background: styles.buttonPrimary,
        _hover: {
            background: styles.buttonPrimaryHover,
        },
    }),
    'send-message': defineStyle({
        bg: '#2785FF',
        height: '26px',
        minWidth: '26px',
        color: 'white',
        borderRadius: '100%',
        _disabled: {
            bg: styles.buttonPrimaryHover,
            opacity: 1,
        },
        _hover: {
            _disabled: {
                bg: styles.buttonPrimaryHover,
            },
        },
    }),
    'action-bar': ({ colorScheme }: StyleFunctionProps) =>
        defineStyle({
            height: '34px',
            padding: '7px',
            width: '34px',
            minWidth: '34px',
            borderRadius: '50%',
            bg: `${colorScheme}.500`,
            color: styles.actionBarButtonColor,
            _hover: {
                color: `${colorScheme}.400`,
                bg: `${colorScheme}.600`,
                _disabled: {
                    color: styles.actionBarButtonColor,
                },
            },
            _active: {
                bg: `${colorScheme}.600`,
            },
        }),
    'close-sidebar': ({ colorScheme }: StyleFunctionProps) =>
        defineStyle({
            height: '30px',
            padding: '7px',
            width: '30px',
            minWidth: '30px',
            borderRadius: '4px',
            bg: `${colorScheme}.500`,
            color: styles.actionBarButtonColor,
            _hover: {
                color: `${colorScheme}.400`,
                bg: `${colorScheme}.600`,
                _disabled: {
                    color: styles.actionBarButtonColor,
                },
            },
            _active: {
                bg: `${colorScheme}.600`,
            },
        }),
    'action-bar-collapse-toggle': defineStyle({
        height: '34px',
        padding: '7px',
        width: '34px',
        minWidth: '34px',
        borderRadius: '50%',
        bg: '#f4f4f4',
        _hover: {
            bg: 'gray.300',
        },
        _active: {
            bg: 'gray.200',
        },
    }),
    'annotate-primary': getMainButtonStyles({
        minWidth: '97px',
        minHeight: '32px',
        color: styles.actionSubmitBlue,
        background: styles.surfacePrimary,
        _hover: {
            background: styles.buttonSecondaryHover,
        },
    }),
    'button-tab': defineStyle({
        height: '30px',
        width: '90px',
        fontSize: '1.2em',
        lineHeight: '22px',
        fontWeight: '500',
        padding: '8px',
        color: styles.textMidGray,
        background: styles.buttonSecondary,
        _hover: {
            background: styles.buttonSecondaryHover,
        },
    }),
    'active-tab': defineStyle({
        height: '30px',
        width: '90px',
        fontSize: '1.2em',
        lineHeight: '22px',
        fontWeight: '500',
        padding: '8px',
        color: styles.linkPrimary,
        background: styles.activeTabBackground,
    }),
    'bottom-panel': defineStyle({
        minWidth: '50px',
        height: '32px',
        color: styles.linkPrimary,
        fontSize: '1.2em',
        lineHeight: '22px',
        fontWeight: '500',
        padding: '8px',
        background: styles.buttonSecondary,
        _hover: {
            background: styles.buttonSecondaryHover,
        },
        _disabled: {
            color: styles.buttonPrimaryDisabled,
            _hover: {
                color: styles.buttonPrimaryDisabled,
            },
        },
    }),
    'preview-search': defineStyle({
        minWidth: '24px',
        width: '24px',
        height: '24px',
        background: 'none',
    }),
    'chat-ask-an-expert': defineStyle({
        width: 'fit-content',
        height: 'fit-content',
        padding: '5px',
        bg: 'transparent',
        color: '#2785FF',
        fontSize: '.9em',
    }),
    'answers-header-action': {
        width: '100%',
        height: '38px',
        color: styles.linkPrimary,
        fontSize: '1.2em',
        lineHeight: '22px',
        fontWeight: '500',
        borderRadius: '4px',
        padding: '8px',
        background: 'white',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05), 0 4px 15px rgba(0, 0, 0, 0.05)',
        _hover: {
            background: styles.buttonSecondaryHover,
        },
        _disabled: {
            color: styles.buttonPrimaryDisabled,
            _hover: {
                color: styles.buttonPrimaryDisabled,
            },
        },
    },
    'help-center-button': getMainButtonStyles({
        color: styles.blueGray500,
        background: styles.blueGray20,
        _hover: {
            background: styles.blueGray50,
            color: styles.blueGray600,
        },
    }),
    calendar: {
        color: '#000',
        minHeight: '32px',
        minWidth: '32px',
        padding: '4px',
        fontWeight: 400,
        border: '1px solid transparent',
        transition: 'none',
        fontSize: '14px',
        '.chakra-button__icon': {
            marginRight: 0,
            marginLeft: 0,
        },
        _disabled: {
            color: 'gray.300',
            opacity: 0.5,
        },
    },
    'text-button': {
        bg: 'transparent',
        color: 'gray.600',
        fontSize: 'sm',
        padding: '4px 8px',
        transition: 'color 0.2s ease-in-out',
        _hover: {
            color: styles.linkPrimary,
        },
        _focus: {
            boxShadow: 'none',
        },
        _disabled: {
            opacity: 0.5,
            cursor: 'not-allowed',
        },
    },
};

export const buttonTheme = defineStyleConfig({ variants });
