import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { Link, VisuallyHidden } from '@chakra-ui/react';
import remarkGfm from 'remark-gfm';

import { useMarkAsSeenMutation } from '../../../api/endpoints/notifications-endpoint';
import { INotificationV2 } from '../../../api/types';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { useAppDispatch } from '../../../redux/hooks/app-hooks';
import { sendMetrics } from '../../../redux/thunks/metrics-thunk';
import { browserService } from '../../../services/browser/browser-service';
import { MixpanelEvent } from '../../../services/mixpanel/types';

import styles from './NotificationBannerV2.module.scss';

export interface INotificationBannerV2Props {
    notification: INotificationV2;
}

export const NotificationBannerV2: React.FC<INotificationBannerV2Props> = ({ notification }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('translations');
    const { id, content, link, link_text } = notification;
    const [markAsSeen, { isLoading: mutationIsLoading }] = useMarkAsSeenMutation();

    const handleCloseClick = useCallback(() => {
        dispatch(sendMetrics({ event: MixpanelEvent.NOTIFICATION_BANNER_DISMISSED, meta: { notificationId: id } }));
        markAsSeen({ id });
    }, [dispatch, id, markAsSeen]);

    const handleLinkClick = useCallback(async () => {
        markAsSeen({ id });
        dispatch(
            sendMetrics({
                event: MixpanelEvent.NOTIFICATION_BANNER_LINK_CLICKED,
                meta: {
                    notificationId: id,
                },
            })
        );
        await browserService.createTab({ url: link, active: false, selected: true });
    }, [dispatch, id, link, markAsSeen]);

    useEffect(() => {
        dispatch(sendMetrics({ event: MixpanelEvent.NOTIFICATION_BANNER_SHOWED, meta: { notificationId: id } }));
    }, [dispatch, id]);

    return (
        <div className={styles.wrapper}>
            <button className={styles.close} onClick={handleCloseClick} disabled={mutationIsLoading}>
                <CloseIcon />
                <VisuallyHidden>{t('button.close')}</VisuallyHidden>
            </button>
            <div className={styles.markdown}>
                <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    components={{
                        a: ({ node, ...props }) => <Link {...props} target="_blank" rel="noreferrer" />,
                    }}
                >
                    {content}
                </ReactMarkdown>
            </div>
            <Link variant="notification" onClick={handleLinkClick}>
                {link_text}
            </Link>
        </div>
    );
};
