import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'clsx';

import { ReactComponent as AskIcon } from '../../../assets/icons/informative-empty-state-ask.svg';
import { ReactComponent as CopyIcon } from '../../../assets/icons/informative-empty-state-copy.svg';
import { ReactComponent as FindIcon } from '../../../assets/icons/informative-empty-state-find.svg';

import styles from './InformativeEmptyState.module.scss';

const listContent = [
    {
        key: 'ask',
        icon: <AskIcon />,
    },
    {
        key: 'find',
        icon: <FindIcon />,
    },
    {
        key: 'copy',
        icon: <CopyIcon />,
    },
];

export type InformativeEmptyStateProps = {
    className?: string;
};

export const InformativeEmptyState: React.FC<InformativeEmptyStateProps> = ({ className }) => {
    const { t } = useTranslation('translations');

    return (
        <div className={cx(styles.wrapper, className)}>
            <div className={styles.heading}>{t('placeholder.informative-empty-state.heading')}</div>
            <div className={styles.list}>
                {listContent.map(({ key, icon }) => (
                    <div key={key} className={styles.listItem}>
                        <div className={styles.itemIcon}>{icon}</div>
                        <div className={styles.itemContent}>
                            <span className={styles.itemDescription}>
                                {t(`placeholder.informative-empty-state.${key}.description`)}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
